import { Action } from '@ngrx/store';
import { UserReportsDateFilter, UserReportsProject, UserReportsPerson, UserReportsMarket, UserReport, UserReportData } from './user-reports.model'
import { OrderTask } from 'src/app/redux/order-task/order-task.model';

export const SET_INITIAL_FILTER = '[Task] set initial filter';
export const SET_PROJECTS = '[Task] set projects';
export const SET_COORDINATORS = '[Task] set coordinators';
export const SET_MARKETS = '[Task] set markets';
export const SET_RESPONSIBLE_PERSONS  = '[Task] set responsiblePersons';
export const SET_REPORTS  = '[Task] set reports';
export const SET_REPORTS_DETAILS  = '[Task] set reports details';
export const SET_ORDERS  = '[Task] set orders';

export const START_LOADING = '[Task] start loading';
export const STOP_LOADING = '[Task] stop loading';


export class SetInitalFilter implements Action {
  readonly type = SET_INITIAL_FILTER;
  constructor(public payload: UserReportsDateFilter) { }
}

export class SetProjects implements Action {
  readonly type = SET_PROJECTS;
  constructor(public payload: UserReportsProject[]) { }
}

export class SetCoordinators implements Action {
  readonly type = SET_COORDINATORS;
  constructor(public payload: UserReportsPerson[]) { }
}

export class SetMarkets implements Action {
  readonly type = SET_MARKETS;
  constructor(public payload: UserReportsMarket[]) { }
}

export class SetResponsiblePersons implements Action {
  readonly type = SET_RESPONSIBLE_PERSONS;
  constructor(public payload: UserReportsPerson[]) { }
}

export class SetReports implements Action {
  readonly type = SET_REPORTS;
  constructor(public payload: UserReport[]) { }
}

export class SetReportsDetails implements Action {
  readonly type = SET_REPORTS_DETAILS;
  constructor(public payload: UserReportData[]) { }
}

export class SetOrders implements Action {
  readonly type = SET_ORDERS;
  constructor(public payload: OrderTask[]) { }
}

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
  readonly type = STOP_LOADING;
}

export type TaskActions =
SetInitalFilter
| StartLoading
| StopLoading
| SetProjects
| SetCoordinators
| SetMarkets
| SetResponsiblePersons
| SetReports
| SetReportsDetails
| SetOrders;
