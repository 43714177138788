import { Order, OrderScheduleHistory, ReportConfig } from './order.model';
import {
    OrderActions,
    SELECTED_ORDER,
    SELECTED_ORDER_SCHEDULE,
    ADD,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_ORDERS,
    SET_USER_ORDERS,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING,
    SET_REPORT_CONFIG,
    SET_MESSAGE,
    SET_RELOAD_DATA_GRID
} from './order.actions';

export interface State {
    orders: Order[];
    selectedOrder: Order;
    selectedOrderSchedule: OrderScheduleHistory;
    isEditing: boolean;
    isLoading: boolean;
    reportConfigList: ReportConfig[];
    message: string;
    reloadDataGrid: boolean;
}
const initialState: State = {
    orders: [],
    selectedOrder: null,
    selectedOrderSchedule: null,
    isEditing: false,
    isLoading: false,
    reportConfigList: [],
    message: null,
    reloadDataGrid: false,
};

export function orderReducer(state = initialState, action: OrderActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_ORDER: {
            return {
                ...state,
                selectedOrder: action.payload
            };
        }
        case SELECTED_ORDER_SCHEDULE: {
          return {
              ...state,
              selectedOrderSchedule: action.payload
          };
      }
        case ADD: {
            return {
                ...state,
                orders: [...state.orders, action.payload]
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_ORDERS: {
            return {
                ...state,
                orders: action.payload
            };
        }
        case SET_USER_ORDERS: {
          return {
              ...state,
              userOrders: action.payload
          };
      }
        case UPDATE: {
            const index = state.orders.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selOrder = state.selectedOrder;
            if (selOrder && selOrder.id === action.payload.id) {
              selOrder = action.payload;
            }
            const updatedOrders = [
                ...state.orders.slice(0, index),
                Object.assign({}, state.orders[index], action.payload),
                ...state.orders.slice(index + 1)
            ];

            return {
                ...state,
                orders: updatedOrders,
                selectedOrder: selOrder
            };
        }
        case REMOVE: {
            return {
                ...state,
                orders: state.orders.filter(c => c.id !== action.payload),
            };
        }
        case SET_REPORT_CONFIG: {
            return {
                ...state,
                reportConfigList: action.payload
            };
        }
        case SET_MESSAGE: {
            return {
                ...state,
                message: action.payload
            };
        }
        case SET_RELOAD_DATA_GRID: {
            return {
                ...state,
                reloadDataGrid: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const orders = (state: State) => state.orders;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedOrder = (state: State) => state.selectedOrder;
export const selectedOrderSchedule = (state: State) => state.selectedOrderSchedule;
export const reportConfigList = (state: State) => state.reportConfigList;
export const message = (state: State) => state.message;
export const reloadDataGrid = (state: State) => state.reloadDataGrid;