import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as OrderTaskActions from 'src/app/redux/order-task/order-task.actions';
import { environment } from 'src/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { OrderTask, TaskPhoto, ProductBase, TasksGallery } from 'src/app/redux/order-task/order-task.model';

@Injectable()
export class WorkTimeService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();
  }
}
