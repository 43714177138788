import { SurveyPattern } from './survey.model';
import {
    SurveyActions,
    SELECTED_SURVEY_PATTERN,
    ADD,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_SURVEY_PATTERNS,
    SET_AVAILABLE_SURVEY_PATTERNS_FOR_PROJECT,
    SET_AVAILABLE_ORDER_TASKS_FOR_RESULTS,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING
} from './survey.actions';

export interface State {
    surveyPatterns: SurveyPattern[];
    surveyPatternsForProject: SurveyPattern[];
    selectedSurveyPattern: SurveyPattern;
    orderTasksForResults: number[];
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    surveyPatterns: [],
    surveyPatternsForProject: [],
    orderTasksForResults: [],
    selectedSurveyPattern: null,
    isEditing: false,
    isLoading: false
};

export function surveyReducer(state = initialState, action: SurveyActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_SURVEY_PATTERN: {
            return {
                ...state,
                selectedSurveyPattern: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                surveyPatterns: [...state.surveyPatterns, action.payload]
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_SURVEY_PATTERNS: {
            return {
                ...state,
                surveyPatterns: action.payload
            };
        }
        case SET_AVAILABLE_SURVEY_PATTERNS_FOR_PROJECT: {
          return {
              ...state,
              surveyPatternsForProject: action.payload
          };
        }
        case SET_AVAILABLE_ORDER_TASKS_FOR_RESULTS: {
          return {
              ...state,
              orderTasksForResults: action.payload
          };
        }
        case UPDATE: {
            const index = state.surveyPatterns.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selSurveyPattern = state.selectedSurveyPattern;
            if (selSurveyPattern && selSurveyPattern.id === action.payload.id) {
              selSurveyPattern = action.payload;
            }
            const updatedSurveyPatterns = [
                ...state.surveyPatterns.slice(0, index),
                Object.assign({}, state.surveyPatterns[index], action.payload),
                ...state.surveyPatterns.slice(index + 1)
            ];

            return {
                ...state,
                surveyPatterns: updatedSurveyPatterns,
                selectedOrder: selSurveyPattern
            };
        }
        case REMOVE: {
            return {
                ...state,
                surveyPatterns: state.surveyPatterns.filter(c => c.id !== action.payload),
            };
        }
        default: {
            return state;
        }
    }
}

export const surveyPatterns = (state: State) => state.surveyPatterns;
export const surveyPatternsForProject = (state: State) => state.surveyPatternsForProject;
export const orderTasksForResults = (state: State) => state.orderTasksForResults;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedSurveyPattern = (state: State) => state.selectedSurveyPattern;
