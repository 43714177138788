import { AppRoles } from './role.model';
import { ApplicationData } from './user.model';
import { IAuthData, IAuthResetPassword, IAuthResetPasswordPostToken } from './auth-data.model';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UIService } from '../shared/ui.service';
import { Subject } from 'rxjs';
import { CommonService } from '../shared/common.service';
import { ApplicationUser } from '../_models/application-user.model';
import * as Common from '../shared/common.actions';
import * as fromRoot from '../app.reducer';
import * as Auth from './auth.actions';
import * as UI from '../shared/ui.actions';
import * as CompanyActions from 'src/app/administration/companies/company.actions';
import * as UserActions from 'src/app/redux/user/user.actions';
import * as GroupActions from 'src/app/redux/group/group.actions';
import * as ProjectKindsActions from 'src/app/redux/contractor/project-kind.actions';
import { PasswordConfig } from '../_models/password-config.model';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeFormDialogComponent } from '../shared/password-change-form/password-change-form-dialog.component';
import { HOME_PATH } from '../app.routing.module';
import { AppHub } from '../hubs/app.hub';
import { UserService } from '../services/user/user.service';
import { GroupService } from '../services/group/group.service';
import { RoleService } from '../services/role/role.service';
import { CompanyService } from '../services/company/company.service';
import { HrComponent } from '../_models/hrComponent.model';
import { ContactModuleModel } from './modules/contact-module.model';
import { Company } from '../redux/company/company.model';
import { GroupHrElement } from '../redux/company/group-hr-element.model';
import { ProjectKindService } from '../services/contractor/project-kind.service';
import { AccessConfigService } from '../services/access-config/access-config.service';
import { ContractorService } from '../services/contractor/contractor.service';
import { ProjectService } from '../services/project/project.service';
import { CustomFieldService } from '../services/custom-fields/custom-field.service';
import { LocalizationService } from '../services/localization/localization.service';
import { EmployeeFormService } from '../services/employee-form/employee-form.service';
import { OrderService } from '../services/order/order.service';
import { OrderTaskService } from '../services/order-task/order-task.service';
import { ServiceService } from '../services/service/service.service';
import { CentralPromotionService } from '../services/central-promotion/central-promotion.service';

@Injectable()
export class AuthService {
  private _onDestroy = new Subject<void>();
  toolbarToggled = new EventEmitter();
  appRoles: AppRoles;
  allRoles: any[] = [];

  currentLanguage$ = this.store.select(state => state.common.currentLanguage);
  currentLanguage: string;
  contactDataSourceWasLoaded = false;

  constructor(
    private router: Router,
    private store: Store<fromRoot.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
    private commonSerivce: CommonService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public hub: AppHub,
    private userService: UserService,
    private groupService: GroupService,
    private roleService: RoleService,
    private companyService: CompanyService,
    private projectKindService: ProjectKindService,
    private contractorService: ContractorService,
    private projectService: ProjectService,
    private orderService: OrderService,
    private serviceService: ServiceService,
    private orderTaskService: OrderTaskService,
    private localizationService: LocalizationService,
    private customFieldService: CustomFieldService,
    private accessConfigService: AccessConfigService,
    private employeeFormService: EmployeeFormService,
    private centralPromotionService: CentralPromotionService

  ) {
    this._initAppRoleObject();

    this.hub.connectionReconected
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.getAppUserData());
    this.hub.reloadDataTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.getAppUserData());
    this.hub.reloadUsersTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        //this.userService.getAll();
        //this.userService.getCoordinators();
      });
    this.hub.reloadContactModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.getContactModuleInfo());
    this.hub.reloadHrComponentsTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.companyService.getHrComponents());
    this.hub.reloadTranslationsTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.commonSerivce.getDictionaries());
    this.currentLanguage$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((language) => this.currentLanguage = language);
    this.hub.reloadContractorModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        this.projectKindService.getAllProjectKindRole();
        this.projectKindService.getAll();
        this.contractorService.getContractors();
      });
    this.hub.reloadProjectModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        this.projectService.getProjects();
      });
    this.hub.reloadOrderModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        //this.orderService.getOrders(true);
      });
    this.hub.reloadServiceModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        //this.serviceService.getServices();
        //this.serviceService.getUserServices();
      });
    this.hub.reloadOrderTaskModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        //this.orderTaskService.getTasks();
        //this.orderTaskService.getMyTasks();
        //this.orderTaskService.getSurveyPatterns();
      });
    this.hub.reloadCustomFieldsModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        this.customFieldService.getCustomFields();
        this.customFieldService.getCustomPages();
      });
    this.hub.reloadAccessConfigsTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        this.accessConfigService.getAll();
      });
    this.hub.reloadCentralPromotionModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        this.centralPromotionService.getCentralPromotions();
      });
    this.hub.reloadEmployeeFormModuleTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => {
        this.employeeFormService.getAllEmployeesForms();
      });
  }

  initAuth(withUser = true) {
    console.log('[auth service] init auth');
    const currentUser = this.currentUser;
    if (currentUser && currentUser.token && currentUser.company) {
      console.log('[auth service] set authenticated');
      this.initServices();
      //this.store.dispatch(new Auth.SetAuthenticated());
      if (withUser) {
        this.getUserById(true);
      }
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.store.dispatch(new Auth.SetAuthenticated());
      this.store.dispatch(new Auth.SetCurrentCompany(currentUser.company));
    } else {
      console.log('[auth service] set unauthenticated');
      this.store.dispatch(new Auth.SetUnauthenticated());
      this.store.dispatch(new Common.SetAvailableDictionaries(null));
      this.store.dispatch(new Auth.SetAvailableRoles(null));
      this.store.dispatch(new Auth.SetCurrentCompany(null));
    }
  }

  setLanguage(language: string) {
    if (!language) {
      language = 'pl';
    }
    this.store.dispatch(new Common.SetLanguage(language));
  }

  initServices() {
    this.userService.initService();
    this.companyService.initService();
    this.groupService.initService();
    this.roleService.initService();
    this.projectKindService.initService();
    this.contractorService.initService();
    this.projectService.initService();
    this.serviceService.initService();
    this.orderService.initService();
    this.customFieldService.initService();
    this.employeeFormService.initService();
  }

  doImport() {
    let language = localStorage.getItem('language');
    if (!language) {
      language = 'pl';
    }
    console.log('[auth service] import start');
    this.store.dispatch(new Auth.ImportStartGettingTheDataFromTheSymfonia());
    this.http.get<any>(environment.apiUrl + '/import/manually/' + language)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => { }, (error) => { });
  }

  toggle() {
    this.toolbarToggled.next();
  }

  getPasswordConfig() {
    console.log('[auth service] get password config');
    this.store.dispatch(new UI.StartLoading());
    this.http.get<PasswordConfig>(environment.apiUrl + '/auth/PasswordConfig')
      .subscribe(
        (config) => {
          this.store.dispatch(new Auth.SetPasswordConfig(config));
          this.store.dispatch(new UI.StopLoading());
        }, (error) => {
          console.error(error);
          this.store.dispatch(new Auth.SetPasswordConfig(null));
          this.store.dispatch(new UI.StopLoading());
        }
      );
  }

  get currentUser(): ApplicationData {
    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser;
  }

  getRoles() {
    console.log('[auth service] get roles');
    this.store.dispatch(new UI.StartLoading());
    this.http.get<any>(environment.apiUrl + '/auth/role/user')
      .subscribe(
        (roles) => {
          const json = JSON.parse(roles.jwt);
          const currentUser = this.currentUser;
          if (!json.token) {
            this.logout();
          }
          currentUser.token = json.token;
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.store.dispatch(new Auth.SetAvailableRoles(roles.perms));
          this.spinner.hide();
          this.store.dispatch(new UI.StopLoading());
        }, (error) => {
          console.error(error);
          this.store.dispatch(new Auth.SetAvailableRoles(null));
          this.store.dispatch(new UI.StopLoading());
          this.logout();
        }
      );
  }

  forgotPassword(data: IAuthResetPassword) {
    console.log('[auth service] forgot password');
    this.store.dispatch(new UI.StartLoading());
    this.http.post<any>(environment.apiUrl + '/auth/forgorPassword', data)
      .subscribe(
        (response) => {
          this.store.dispatch(new UI.StopLoading());
          if (response.messageCode) {
            this.uiService.openSnack(response.messageCode);
          }
          this.router.navigate(['/auth/login']);
        }, (err) => {
          if (err.error) {
            this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);
          } else {
            this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
          }
          this.store.dispatch(new UI.StopLoading());
          console.warn('Changing password failed;');
          console.error(err);
        });
  }

  resetPassword(model: IAuthResetPasswordPostToken) {
    console.log('[auth service] reset password');
    const language = localStorage.getItem('language');
    model.language = language;
    this.store.dispatch(new UI.StartLoading());
    this.http.post<any>(environment.apiUrl + '/auth/resetPassword', model)
      .subscribe(
        (response) => {
          if (response.messageCode) {
            this.uiService.openSnack(response.messageCode);
          }
          this.store.dispatch(new UI.StopLoading());
          this.router.navigate(['/auth/login']);
        }, (err) => {
          if (err.error) {
            this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);
          } else {
            this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
          }
          this.store.dispatch(new UI.StopLoading());
          console.error(err);
        });
  }

  changePassword(model) { // newPassword
    console.log('[auth service] change password');

    return new Promise(r => {
      this.store.dispatch(new UI.StartLoading());
      this.http.put<any>(environment.apiUrl + '/auth/changePassword', model)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (response) => {
            this.store.dispatch(new UI.StopLoading());
            if (response.messageCode) {
              this.uiService.openSnack(response.messageCode);
            }
            const currentUser = this.currentUser;
            currentUser.hash = response.body;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            r();
          }, err => {
            if (err.error) {
              this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);
            } else {
              this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
            }
            this.store.dispatch(new UI.StopLoading());
            console.warn('Changing password failed;');
            console.error(err);
          }
        );
    });

  }

  setRedirectData(url: string, params: string) {
    this.store.dispatch(new Auth.SetRedirectUrl(url));
    this.store.dispatch(new Auth.SetRedirectParameters(params));
  }

  openChangePasswordDialog() {
    this.store.dispatch(new Auth.SetAvailableRoles(null));
    this.uiService.openSnack('Wymagana zmiana hasła', null, 5_000);
    this.spinner.hide();
    const ref = this.dialog.open(PasswordChangeFormDialogComponent, {
      // width: '75%',
      data: true
    });
    ref.afterClosed()
      .subscribe(
        (result) => {
          if (result && result.logout) {
            this.logout();
          } else if (result && result.close) {
            this.initAuth();
          } else {
            this.openChangePasswordDialog();
          }
        }
      );
  }

  // signInWithGoogle(): void {
  //   this.social.signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((user) => {
  //       console.log(user);
  //       this.getGoogleUserToken(user.authToken);
  //     });
  // }

  // signInWithFacebook() {
  //   this.social.signIn(FacebookLoginProvider.PROVIDER_ID)
  //     .then((user) => {
  //       this.getFacebookUserToken(user.authToken);
  //     });
  // }

  getGoogleUserToken(authToken: string, companyId: string = null) {
    this.store.dispatch(new UI.StartLoading());
    return new Promise((r) => {
      this.http.post<ApplicationData>(environment.apiUrl + '/auth/login/google',
        { accessToken: authToken, companyId })
        .pipe(takeUntil(this._onDestroy))
        .subscribe((response) => {
          localStorage.setItem('currentUser', JSON.stringify(response));
          // localStorage.setItem('last-user', JSON.stringify(response));
          if (!companyId) {
            this.getUserById(false);
          }
          this.store.dispatch(new UI.StopLoading());
          r();
        }, (err) => {
          if (err.error && err.error.messageCode) {
            this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);

          } else {
            this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
          }
          this.logout();
        });
    });
  }

  getFacebookUserToken(authToken: string, companyId: string = null) {
    this.store.dispatch(new UI.StartLoading());
    return new Promise((r) => {
      this.http.post<ApplicationData>(environment.apiUrl + '/auth/login/facebook',
        { accessToken: authToken, companyId })
        .pipe(takeUntil(this._onDestroy))
        .subscribe((response) => {
          localStorage.setItem('currentUser', JSON.stringify(response));
          // localStorage.setItem('last-user', JSON.stringify(response));
          if (!companyId) {
            this.getUserById(false);
          }
          this.store.dispatch(new UI.StopLoading());
          r();
        }, (err) => {
          if (err.error && err.error.messageCode) {
            this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);

          } else {
            this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
          }
          this.logout();
        });
    });
  }

  // signOutSocial(): void {
  //   this.social.signOut();
  // }

  login(authData: IAuthData, getroles: boolean) {
    console.log('[auth service] login');
    return new Promise(r => {
      this.store.dispatch(new UI.StartLoading());
      this.http.post<ApplicationData>(environment.apiUrl + '/auth/login', authData)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((user) => {
          this.store.dispatch(new UI.StopLoading());
          localStorage.setItem('currentUser', JSON.stringify(user));
          // localStorage.setItem('last-user', JSON.stringify(user));
          this.getUserById(getroles);
          r(user);
        }, (err) => {
          console.error(err);
          this.store.dispatch(new Auth.SetCurrentUser(null));
          this.store.dispatch(new Auth.SetCurrentCompany(null));
          this.store.dispatch(new UI.StopLoading());
          if (err.error && err.error.messageCode && !err.error.messageCodePlus) {
            this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);

          } else if (err.error && err.error.messageCodePlus) {
            this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);
          } else {
            this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
          }
          this.logout();
        });
    });
  }

  startLoading() {
    this.store.dispatch(new UI.StartLoading());
  }

  logout() {
    this.cancelSubscriptions();
    // if (this.socialUser) {
    //   this.signOutSocial();
    // }
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
    console.log('[auth service] logout');
    this.store.dispatch(new Auth.AuthLogout());
    this.initAuth();
    this.router.navigate(['/auth/login']);
    this.spinner.hide();
  }

  changeCompany(company: Company) {
    console.log('[auth service] change company');
    const currentUser = this.currentUser;

    const auth: IAuthData = {
      username: currentUser.userName,
      password: currentUser.hash,
      provider: currentUser.provider
    };

    this.spinner.show();

    this.appRoles = {};
    // this.hub.cancelSubscriptions();
    this.cancelSubscriptions();
    this._resetRedux();

    // this.store.dispatch(new Auth.SetUnauthenticated());
    this.store.dispatch(new Auth.AuthChangeCompany());
    this.setCompany(auth, company);
  }

  private _resetRedux() {
    this.contactDataSourceWasLoaded = false;
    this.store.dispatch(new Auth.SetAvailableRoles([]));
    this.store.dispatch(new UserActions.SetAvailableUsers({ data: [], totalCount: 0 }));
    this.store.dispatch(new UserActions.SetDataSouce(null));
    this.store.dispatch(new GroupActions.SetAvailableGroups({ data: [], totalCount: 0 }));
    this.store.dispatch(new GroupActions.SetAvailableManagers([]));
    this.store.dispatch(new UserActions.UserUnloaded());
    this.store.dispatch(new CompanyActions.SetUserListFilter(null));
    this.store.dispatch(new ProjectKindsActions.SetAvailableProjectKinds([]));
    this.store.dispatch(new ProjectKindsActions.SetAvailableProjectKindRole([]));
    this.store.dispatch(new ProjectKindsActions.SetAvailableProjectKindAttachments([]));
  }

  setCompany(auth: IAuthData, company: Company) {
    console.log('[auth service] set company');
    if (company) {
      auth.companyId = company.id;
      // if (auth.provider === 'Google') {
      //   this.getGoogleUserToken(this.socialUser.authToken, company.id)
      //     .then((_) => {
      //       this.store.dispatch(new UI.StopLoading());
      //       const currentUser = this.currentUser;
      //       currentUser.company = company;
      //       localStorage.setItem('currentUser', JSON.stringify(currentUser));
      //       this.store.dispatch(new Auth.SetAuthenticated());
      //       this.store.dispatch(new Auth.SetCurrentCompany(company));
      //       localStorage.setItem('lastCompanyId', company.id);
      //       this.store.dispatch(new Auth.ImportStop());
      //       this._resetRedux();
      //       this.initAuth();
      //     });
      //   this.router.navigate([HOME_PATH]);
      //   return;
      // }
      // if (auth.provider === 'Facebook') {
      //   this.getFacebookUserToken(this.socialUser.authToken, company.id)
      //     .then((_) => {
      //       this.store.dispatch(new UI.StopLoading());
      //       const currentUser = this.currentUser;
      //       currentUser.company = company;
      //       localStorage.setItem('currentUser', JSON.stringify(currentUser));
      //       this.store.dispatch(new Auth.SetAuthenticated());
      //       this.store.dispatch(new Auth.SetCurrentCompany(company));
      //       localStorage.setItem('lastCompanyId', company.id);
      //       this.store.dispatch(new Auth.ImportStop());
      //       this._resetRedux();
      //       this.initAuth();
      //     });
      //   this.router.navigate([HOME_PATH]);
      //   return;
      // }

      this.login(auth, true)
        .then((user: any) => {
          this.store.dispatch(new UI.StopLoading());
          const currentUser = this.currentUser;
          currentUser.company = company;
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          //this.store.dispatch(new Auth.SetAuthenticated());
          this.store.dispatch(new Auth.SetCurrentCompany(company));
          localStorage.setItem('lastCompanyId', company.id);
          this.store.dispatch(new Auth.ImportStop());
          this._resetRedux();
          this.initAuth(false);
        });
    }
  }

  getUserById(getroles: boolean) {
    this.store.dispatch(new UI.StartLoading());
    console.log('[auth service] get user details');
    this.http.get<ApplicationUser>(environment.apiUrl + '/applicationUser')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (user) => {
          this.store.dispatch(new UI.StopLoading());
          const currentUser = this.currentUser;
          if (!user || !currentUser) {
            // localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.store.dispatch(new Auth.SetCurrentCompany(null));
            this.store.dispatch(new Auth.SetCurrentUser(null));
            this.logout();
            return;
          }

          this.store.dispatch(new Auth.SetCurrentCompany(currentUser.company));
          this.store.dispatch(new Auth.SetCurrentUser(user));

          if (getroles) {
            this.hub.init();
            if (user.forceChangePassword && currentUser.provider === 'ePMT') {
              this.openChangePasswordDialog();
            } else {
              if (!this.allRoles || this.allRoles.length === 0) {
                this.getRoles();
                this.userService.getOnline();
              }
            }

          }
        }, (error) => {
          this.store.dispatch(new UI.StopLoading());
          this.store.dispatch(new Auth.SetCurrentUser(null));
          this.store.dispatch(new Auth.SetCurrentCompany(null));
          this.uiService.openSnack('Nieoczekiwany błąd, skontaktuj się z administratorem', 'Błąd', 10_000);
          console.error('Error while getting the current user.');
          console.error(error);
          this.logout();
        }
      );
  }

  hasPermissionTimeoutRedirectToHomePage(perm) {
    setTimeout(() => {
      const hasPerm = this.hasPermission(perm);
      if (!hasPerm) {
        const currentUser = this.currentUser;
        if (currentUser && currentUser.token && currentUser.company) {
          this.router.navigate([HOME_PATH]);
        }
      }
    }, 2000);
  }

  hasPermission(permission: string) {
    switch (permission) {
      case 'administration/company/list': {
        return this.appRoles.canAdministrationCompany;
      }
      case 'administration': {
        return this.appRoles.canAdministration;
      }
      default: {
        return false;
      }
    }
  }

  cancelSubscriptions() {
    console.log('[auth service] Cancel subscriptions');
    this.hub.cancelSubscriptions();
    this.userService.cancelSubscriptions();
    this.companyService.cancelSubscriptions();
    this.groupService.cancelSubscriptions();
    this.roleService.cancelSubscriptions();
    // this._onDestroy.next();
    // this._onDestroy.complete();
  }

  public hasOnlyImportRoleInAdministration() {
    return this.appRoles.canAdministration
      && this.appRoles.canAdministrationImport
      && !this.appRoles.canAdministrationDicationary
      && !this.appRoles.canAdministrationCompany
      && !this.appRoles.canAdministrationStructure
      && !this.appRoles.canAdministrationConfiguration
      && !this.appRoles.canAdministrationTranslation
      && !this.appRoles.canAdministrationUser
      && !this.appRoles.canAdministrationPermission
      && !this.appRoles.canAdministrationSecurity
      && !this.appRoles.canAdministrationGroup;
  }

  private getContactModuleInfo() {
    console.log('[auth service] Get contact module info');
    this.http.get<{
      hrComponentGroups: GroupHrElement[],
      hrComponents: HrComponent[],
      contactShowPhoto: boolean,
      contactHrElementsCaptionFromPrint: boolean,
      contactHrElementsByGroup: boolean
    }>(environment.apiUrl + '/company/module/contact')
      .toPromise()
      .then((result) => {
        const contactModule: ContactModuleModel = {
          hrComponentGroups: result.hrComponentGroups.slice(),
          hrComponents: result.hrComponents.sort((one, two) => one.sortIndex - two.sortIndex).slice(),
          configuration: {
            contactHrElementsByGroup: result.contactHrElementsByGroup,
            contactHrElementsCaptionFromPrint: result.contactHrElementsCaptionFromPrint,
            contactShowPhoto: result.contactShowPhoto,
          }
        };

        this.store.dispatch(new Auth.SetContactModule(contactModule));
      })
      .catch((err) => { /*ignore*/ });
  }

  isOrderTaskListDetailsRoute(): boolean {
    const url = this.router.url;
    const regex = /^\/order-task-list\/details;id=\w+$/;
    return regex.test(url);
  }

  private getAppUserData() {
    this.store.dispatch(new Auth.SetContactModule(null));
    //this.userService.getAll();
    if (this.appRoles.canStructureAndContact) {
      this.getContactModuleInfo();
    }
    this.commonSerivce.getDictionaries();
    this.projectKindService.getAll();
    this.projectKindService.getAllProjectKindRole();
    this.accessConfigService.getAll();
    this.contractorService.getContractors();
    this.projectService.getProjects();
    if (!this.isOrderTaskListDetailsRoute()) {
      //this.localizationService.getLocalizations();
    }

    this.customFieldService.getCustomFields();
    this.customFieldService.getCustomPages();
    //this.userService.getCoordinators();
    //this.orderService.getOrders(true);
    //this.orderTaskService.getTasks();
    //this.orderTaskService.getMyTasks();
    //this.serviceService.getServices();
    //this.serviceService.getUserServices();
    //this.orderTaskService.getSurveyPatterns();
    this.centralPromotionService.getCentralPromotions();
    this.employeeFormService.getAllEmployeesForms();

    if (this.appRoles.canAdministration) {
      if (this.appRoles.canAdministrationCompany) {
        this.companyService.getAll();
      }
      if (this.appRoles.canAdministrationPermission) {
        this.roleService.roleGetPermissions();
      }
      if (this.appRoles.canAdministrationPermissionRole) {
        //this.roleService.roleGetAll();
      }
    }
  }

  private _initAppRoleObject() {
    this.store.select(fromRoot.getRoles)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(roles => {
        this.allRoles = roles;
        this.appRoles = {};
        // console.log('[auth service] roles redux get');
        if (roles && roles.length > 0) {
          // console.log('[auth service] init permissions. Length: ' + roles.length);
          for (const role of roles) {
            this.appRoles[role] = true;
          }
        }
        if (roles && roles.length > 0) {
          this.getAppUserData();
        }
      });
  }
}
