import { Action } from '@ngrx/store';
import { Project, ProjectBudget } from './project.model';

export const SET_AVAILABLE_PROJECTS = '[Project] Set available projects';
export const SET_USER_PROJECTS = '[Project] Set user projects';
export const START_EDITING = '[Project] Start editing';
export const STOP_EDITING = '[Project] Stop editing';
export const ADD = '[Project] Add new project';
export const REMOVE = '[Project] Remove project';
export const UPDATE = '[Project] Update project';
export const SELECTED_PROJECT = '[Project] Selected project';
export const SELECTED_PROJECT_FOR_CHARTS = '[Project] Selected project for charts';
export const SELECTED_PROJECT_BUDGET = '[Project] Selected project budget';
export const SET_RELOAD_DATA_GRID = '[Project] Set reload data grid';

export const START_LOADING = '[Project] start loading';
export const STOP_LOADING = '[Project] stop loading';

export class SetAvailableProjects implements Action {
    readonly type = SET_AVAILABLE_PROJECTS;
    constructor(public payload: Project[]) { }
}

export class SetAvailableProjectsForUser implements Action {
    readonly type = SET_USER_PROJECTS;
    constructor(public payload: Project[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Project) { }
}

export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Project) { }
}
export class SetSelectedProject implements Action {
    readonly type = SELECTED_PROJECT;
    constructor(public payload: Project) { }
}
export class SetSelectedProjectForCharts implements Action {
    readonly type = SELECTED_PROJECT_FOR_CHARTS;
    constructor(public payload: Project) { }
}
export class SetSelectedProjectBudget implements Action {
    readonly type = SELECTED_PROJECT_BUDGET;
    constructor(public payload: ProjectBudget) { }
}
export class SetReloadDataGrid implements Action {
    readonly type = SET_RELOAD_DATA_GRID;
    constructor(public payload: boolean) { }
}

export type ProjectActions =
    SetAvailableProjects
    | SetAvailableProjectsForUser
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | Remove
    | Update
    | SetSelectedProject
    | SetSelectedProjectForCharts
    | SetSelectedProjectBudget
    | SetReloadDataGrid;
