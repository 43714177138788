import { Component, OnInit, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { Product, ProductPhoto } from 'src/app/redux/project/product.model';
import { AuthService } from 'src/app/auth/auth.service';
import { Store } from '@ngrx/store';
import { MatDialogRef, MatDialog } from '@angular/material';
import * as fromRoot from '../../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { DxFileUploaderModule, DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { ApplicationData } from 'src/app/auth/user.model';
import { ProjectService } from 'src/app/services/project/project.service';
import { Project } from 'src/app/redux/project/project.model';
import {MatAccordion} from '@angular/material/expansion';
import { ProductIndexMarketComponent } from '../product-index-market/product-index-market.component';
import * as ProductActions from 'src/app/redux/project/product.actions';
import { UIService } from 'src/app/shared/ui.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  model: Product = {};
  isEditing = false;
  productApp: Product;
  selectedProject: Project;
  value: any[] = [];
  apiUrl = environment.apiUrl;
  headers: any;
  productPhostos: ProductPhoto[] = [];
  public fullScreen: boolean;

  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderModule;
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;
  @ViewChild('productIndexGrid', { static: false }) productIndexGrid: DxDataGridComponent;

  constructor(public auth: AuthService,
              private service: ProjectService,
              private store: Store<fromRoot.IRootState>,
              private dialogRef: MatDialogRef<ProductDetailsComponent>,
              private dialog: MatDialog,
              private render: Renderer2,
              private uiService: UIService,
              private http: HttpClient) { }

  ngOnInit() {
    this.store.select(fromRoot.getProductSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        res !== null ? this.productApp = res : this.productApp = {};
        this.model = { ...this.productApp };
      });

    this.store.select(fromRoot.getProjectSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        res !== null ? this.selectedProject = res : this.selectedProject = {};
      });

    this.store.select(fromRoot.getProductIsEditing)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.isEditing = res;
      });

    this.store.select(fromRoot.getProductPhotosList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.productPhostos = res;
      });

    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = { 'Authorization': 'Bearer ' + currentUser.token };

    this.getProductPhotos(this.model.id);
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel(): void {
    this.service.stopProductEditing();
    this.model = { ...this.productApp };
    this.service.clearProductPhotos();
  }

  startEditing() {
    this.service.startProductEditing();
  }

  save(close: boolean) {
    this.model.projectId = this.selectedProject.id;
    if (this.model.id && this.model.id > 0 ) {
      this.service.updateProduct(this.model);
      this.productApp = { ...this.model };
    } else {
      this.service.createProduct(this.model);
    }
    if (close) {
      this.service.clearProductPhotos();
      this.dialogRef.close();
    }
  }

  getHeader() {
    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    const uploadHeaders = { Authorization: 'Bearer ' + currentUser.token };
  }

  getProductPhotos(productId: number) {
    this.service.getProductPhotos(productId);
  }

  removePhoto(id: number) {
    this.service.removeProductPhoto(id);
  }

  downloadPhoto(photo: ProductPhoto) {
    this.service.downloadProductPhoto(photo);
  }

  public onFullscreenClick() {
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    const content = document.getElementsByClassName('mat-dialog-content');

    if (this.fullScreen) {
      this.render.removeClass(dialog[0], 'full-screen');
      this.render.removeClass(content[0], 'full-screen');
    } else {
      this.render.addClass(dialog[0], 'full-screen');
      this.render.addClass(content[0], 'full-screen');
    }

    this.fullScreen = !this.fullScreen;
  }

  close() {
    this.cancel();
    this.dialogRef.close();
  }

  addProductIndex() {
    this.accordion.closeAll();

    this.dialog.open(ProductIndexMarketComponent, {
      width: '90%',
      autoFocus: false
    });
  }

deleteProductIndex() {
    this.accordion.closeAll();
    this.doWork(this.productIndexGrid.selectedRowKeys);
  }

  async doWork(marketIds: number[]) {
    const promises = marketIds.map(async (marketId, idx) => {
      await this.service.detachMarketIndex(this.model.id, marketId);
    });

    await Promise.all(promises);
  }
}
