import { Component, OnInit } from '@angular/core';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppHubConnection } from 'src/app/redux/user/hub_connection.model';
import { User } from 'src/app/redux/user/user.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ScreenService } from 'src/app/redux/screen-state/screen.service';

@Component({
  selector: 'app-online-users-chart-dx',
  templateUrl: './online-users-chart-dx.component.html',
  styleUrls: ['./online-users-chart-dx.component.scss']
})
export class OnlineUsersChartDxComponent implements OnInit {
  private _onDestroy = new Subject<void>();

  single: any[] = [];
  online: AppHubConnection[];
  _allUsers: User[] = [];
  isLoaded = false;
  view: any[] = [700, 150];
  colorScheme = {
    domain: ['#2979ff', '#81C784', '#F44336', '#FF9800', '#9E9E9E']
  };

  desktop: boolean;

  constructor(
    private store: Store<fromRoot.IRootState>,
    private translate: TranslateService,
    public auth: AuthService,
    private screen: ScreenService
  ) { }

  ngOnInit() {
    this.store.select(fromRoot.getUserOnline)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((online) => {
        this.online = online;
        this.buildUsersChart();
      });
    this.store.select(fromRoot.getUserList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(users => {
        this._allUsers = users;
        this.buildUsersChart();
      });
    this.translate.onLangChange
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => {
        this.buildUsersChart();
      });
    this.store.select(fromRoot.getUserIsLoaded)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((isLoaded) => {
        this.isLoaded = isLoaded;
      });
  }

  onSelect(event) {
    // console.log(event);
  }

  buildUsersChart() {
    if (this._allUsers && this._allUsers.length > 0 && this.online) {
      this.single = [];


      this.online = this.online.filter(o => this._allUsers.some(user => user.id === o.userIdentifier));
      const activeUsers = this._allUsers.filter(c => !c.disabled && c.firstLogin && !c.blocked
        && !this.online.some(some => some.userIdentifier === c.id)).length;
      const disabled = this._allUsers.filter(c => c.disabled
        && !this.online.some(some => some.userIdentifier === c.id)).length;
      const blocked = this._allUsers.filter(c => !c.disabled && c.blocked
        && !this.online.some(some => some.userIdentifier === c.id)).length;
      const nologgedin = this._allUsers.filter(c => !c.disabled && !c.blocked && !c.firstLogin
        && !this.online.some(some => some.userIdentifier === c.id)).length;

      const online = this.online.map(item => item.userIdentifier)
        .filter((value, index, self) => self.indexOf(value) === index).length;

      if (online) {
        this.translate.get('Online')
          .pipe(takeUntil(this._onDestroy))
          .subscribe
          (c => {
            this.single.push({ name: c, value: online });
          });
      }
      if (activeUsers) {
        this.translate.get('Aktywni')
          .pipe(takeUntil(this._onDestroy))
          .subscribe
          (c => {
            this.single.push({ name: c, value: activeUsers });
          });
      }
      if (disabled) {
        this.translate.get('Archiwalni')
          .pipe(takeUntil(this._onDestroy))
          .subscribe
          (c => {
            this.single.push({ name: c, value: disabled });
          });
      }
      if (blocked) {
        this.translate.get('Zablokowani')
          .pipe(takeUntil(this._onDestroy))
          .subscribe
          (c => {
            this.single.push({ name: c, value: blocked });
          });
      }
      if (nologgedin) {
        this.translate.get('Nigdy niezalogowani')
          .pipe(takeUntil(this._onDestroy))
          .subscribe
          (c => {
            this.single.push({ name: c, value: nologgedin });
          });
      }

    }
  }

}
