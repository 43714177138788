import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ClientTicketDataGridData, ClientTicketDataGridItem, ClientTicketDetails, ClientTicketFormDetails, ClientTicketListItem, ClientTicketListingResponse, ClientTicketMessage, ClientTicketType } from 'src/app/redux/client-ticket/client-ticket.model';
import * as ClientTicketActions from 'src/app/redux/client-ticket/client-ticket.actions';
import { LoadOptions } from 'devextreme/data/load_options';

@Injectable(
)
export class ClientTicketService {
  private _onDestroy = new Subject<void>();
  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService
  ) { }

  ticketStatuses: string[] = [
    "Nowe",
    "W trakcie",
    "Zrobione",
    "Anulowane"
  ];

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getImageData(fileName: string): Observable<ArrayBuffer> {
    var request = {
      "fileName": fileName,
    }
    return this.http.post<any>(environment.apiUrl + '/ClientTicket/image/byte', request)
  }

  getClientTicketDetails(id: string) {
    this.store.dispatch(new ClientTicketActions.StartLoading());
    this.http.get<ClientTicketDetails>(environment.apiUrl + '/ClientTicket/' + id)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new ClientTicketActions.SetClientTicketDetails(response));
          this.store.dispatch(new ClientTicketActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new ClientTicketActions.SetClientTicketDetails(null));
          this.store.dispatch(new ClientTicketActions.StopLoading());
        }
      );
  }

  startLoading(value: boolean) {
    if (value) {
      this.store.dispatch(new ClientTicketActions.StartLoading());
    }
    else {
      this.store.dispatch(new ClientTicketActions.StopLoading());
    }
  }

  getClientTickets(loadOptions: any) {
    this.store.dispatch(new ClientTicketActions.StartLoading());

    const params = this.getParams(loadOptions);
    var result = this.http.get<any>(environment.apiUrl + '/ClientTicket/getList', { params }).toPromise();
    
     return result;
  }

  getParams(loadOptions: LoadOptions) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });
    return params;
  }


  mapItems(items: ClientTicketListItem[]): ClientTicketDataGridItem[] {
    const mappedItems: ClientTicketDataGridItem[] = [];
    items.forEach(element => {
      mappedItems.push({
        id: element.id,
        type: ClientTicketType[element.type],
        orderTaskId: element.orderTaskId,
        projectName: element.projectName,
        orderTitle: element.orderTitle,
        localizationDetails: element.localizationDetails,
        status: this.ticketStatuses[element.status],
        reportedBy: element.reportedBy,
        updatedAt: element.updatedAt,
        createdAt: element.createdAt,
      })
    });
    return mappedItems;
  }

  getClientTicketFormDetails(projectId: number, locId: number, orderTaskId: number) {
    var request = {
      "projectId": projectId,
      "localizationId": locId,
      "orderTaskId": orderTaskId,
    }

    this.store.dispatch(new ClientTicketActions.StartLoading());
    this.http.post<ClientTicketFormDetails>(environment.apiUrl + '/ClientTicket/formDetails', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new ClientTicketActions.SetClientTicketFormDetails(response));
          this.store.dispatch(new ClientTicketActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new ClientTicketActions.StopLoading());
        }
      );
  }

  updateClientTicketStatus(id: string, status: string) {
    var request = {
      "id": id,
      "status": status,
    }
    this.store.dispatch(new ClientTicketActions.StartLoading());
    this.http.put<any>(environment.apiUrl + '/ClientTicket/updateStatus', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new ClientTicketActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new ClientTicketActions.StopLoading());
        }
      );
  }

  unsetMessageFromServer() {
    this.store.dispatch(new ClientTicketActions.SetMessage(null));
  }

  createClientTicket(file1, file2, type: string, comment: string, projectId: string, orderTaskId: string, localizationId: string) {
    const formData = new FormData();

    formData.append('file1', file1);
    formData.append('file2', file2);
    formData.append('type', type);
    formData.append('comment', comment);
    formData.append('projectId', projectId);
    formData.append('orderTaskId', orderTaskId);
    formData.append('localizationId', localizationId);

    this.http.post<any>(environment.apiUrl + '/ClientTicket', formData)
      .toPromise()
      .then(
        () => {
          const message: ClientTicketMessage = {
            isError: false,
            message: 'Zgłoszenie zostało wysłane. Dziękujemy!'
          };
          this.store.dispatch(new ClientTicketActions.SetMessage(message));
        })
      .catch(err => {
        const message: ClientTicketMessage = {
          isError: true,
          message: 'Nieoczekiwany błąd w tworzeniu zgłoszenia'
        };
        this.store.dispatch(new ClientTicketActions.SetMessage(message));
        console.error(err);
      });
  }
}
