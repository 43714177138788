import { CustomField, CustomPage } from './custom-filed.model';
import {
    CustomFieldActions,
    SELECTED_CUSTOM_FIELD,
    ADD_FIELD,
    ADD_PAGE,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_CUSTOM_FIELDS,
    SET_AVAILABLE_CUSTOM_PAGES,
    UPDATE_FIELD,
    UPDATE_PAGE,
    REMOVE_FIELD,
    REMOVE_PAGE,
    START_LOADING,
    STOP_LOADING
} from './custom-field.actions';

export interface State {
    customFields: CustomField[];
    customPages: CustomPage[];
    selectedCustomField: CustomField;
    selectedCustomPage: CustomPage;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    customFields: [],
    customPages: [],
    selectedCustomField: null,
    selectedCustomPage: null,
    isEditing: false,
    isLoading: false
};

export function customFieldsReducer(state = initialState, action: CustomFieldActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_CUSTOM_FIELD: {
            return {
                ...state,
                selectedCustomField: action.payload
            };
        }
        case ADD_FIELD: {
            return {
                ...state,
                customFields: [...state.customFields, action.payload]
            };
        }
        case ADD_PAGE: {
          return {
              ...state,
              customPages: [...state.customPages, action.payload]
          };
      }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_CUSTOM_FIELDS: {
            return {
                ...state,
                customFields: action.payload
            };
        }
        case SET_AVAILABLE_CUSTOM_PAGES: {
          return {
              ...state,
              customPages: action.payload
          };
      }
        case UPDATE_FIELD: {
          const index = state.customFields.map(d => d.id).indexOf(action.payload.id);
          if (index === -1) {
              return state;
          }
          let selCustomField = state.selectedCustomField;
          if (selCustomField && selCustomField.id === action.payload.id) {
            selCustomField = action.payload;
          }
          const updatedCustomFields = [
              ...state.customFields.slice(0, index),
              Object.assign({}, state.customFields[index], action.payload),
              ...state.customFields.slice(index + 1)
          ];
          return {
              ...state,
              customFields: updatedCustomFields,
              selectedCustomField: selCustomField
          };
        }
        case UPDATE_PAGE: {
          const index = state.customPages.map(d => d.id).indexOf(action.payload.id);
          if (index === -1) {
              return state;
          }
          let selCustomPage = state.selectedCustomPage;
          if (selCustomPage && selCustomPage.id === action.payload.id) {
            selCustomPage = action.payload;
          }
          const updatedCustomPages = [
              ...state.customPages.slice(0, index),
              Object.assign({}, state.customPages[index], action.payload),
              ...state.customPages.slice(index + 1)
          ];
          return {
              ...state,
              customPages: updatedCustomPages,
              selectedCustomPage: selCustomPage
          };
        }
        case REMOVE_FIELD: {
            return {
                ...state,
                customFields: state.customFields.filter(c => c.id !== action.payload),
            };
        }
        case REMOVE_PAGE: {
          return {
              ...state,
              customPages: state.customPages.filter(c => c.id !== action.payload),
          };
      }
        default: {
            return state;
        }
    }
}

export const customFields = (state: State) => state.customFields;
export const customPages = (state: State) => state.customPages;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedCustomField = (state: State) => state.selectedCustomField;
export const selectedCustomPage = (state: State) => state.selectedCustomPage;
