import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn
} from '@angular/forms';
import {Directive} from '@angular/core';

export function pesel(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
        isValidPESEL(control.value) ? null : {wrongPesel: 'Wprowadź poprawny numer pesel'};
}

export function isValidPESEL(input: string) {
    if(!input) {
      return true;
    }
    let weights: number[] = [ 1, 3, 7, 9, 1, 3, 7, 9, 1, 3 ];
    let result = false;
    if (input.length == 11)
    {
        let controlSum = calculateControlSum(input, weights);
        let controlNum = controlSum % 10;
        controlNum = 10 - controlNum;
        if (controlNum == 10)
        {
            controlNum = 0;
        }
        let lastDigit = Number(input[input.length - 1].toString());
        result = controlNum === lastDigit;
    }
    return result;
}

export function calculateControlSum(input: string, weights: number[], offset: number = 0) {
    let controlSum = 0;
    for (let i = 0; i < input.length - 1; i++)
    {
        controlSum += weights[i + offset] * Number(input[i].toString());
    }
    return controlSum;
}

@Directive({
  selector: '[pesel]',
  providers: [{
      provide: NG_VALIDATORS,
      useExisting: PeselValidatorDirective,
      multi: true
  }]
})

export class PeselValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
      return pesel()(control);
  }
}
