import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateService } from '@ngx-translate/core';
import { AppDateAdapter } from '../directives/app-date-adapter.adapter';

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

@NgModule({
    imports: [
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatListModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatSliderModule,
        MatRadioModule,
        MatSlideToggleModule,
        NgxMatSelectSearchModule,
        MatExpansionModule,
        MatChipsModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatTreeModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatGridListModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatCardModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatRippleModule,
        MatAutocompleteModule,
        DragDropModule
    ],
    exports: [
        MatRippleModule,
        MatSidenavModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatSelectModule,
        MatListModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatSliderModule,
        MatRadioModule,
        MatSlideToggleModule,
        NgxMatSelectSearchModule,
        MatExpansionModule,
        MatChipsModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatTreeModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatGridListModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatCardModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        DragDropModule
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        },
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})
export class MaterialModule { }

export class PaginatorIntlService extends MatPaginatorIntl {
    translate: TranslateService;
    itemsPerPageLabel = 'Items per page';
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';
    getRangeLabel = function (page, pageSize, length) {
        const of = this.translate ? this.translate.instant('PAGINATOR.OF') : 'of';
        if (length === 0 || pageSize === 0) {
            return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
    };

    injectTranslateService(translate: TranslateService) {
        this.translate = translate;

        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels() {
        this.itemsPerPageLabel = this.translate.instant('ITEMS_PER_PAGE_LABEL');
        this.nextPageLabel = this.translate.instant('NEXT_PAGE_LABEL');
        this.previousPageLabel = this.translate.instant('PREVIOUS_PAGE_LABEL');
        this.firstPageLabel = this.translate.instant('FIRST_PAGE_LABEL');
        this.lastPageLabel = this.translate.instant('LAST_PAGE_LABEL');
    }

}



