import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface YesNoModel {
    titleKey?: string;
    contentKey?: string;
    description?: string;
}

@Component({
    selector: 'app-yes-no-dialog',
    template: `
        <h2 mat-dialog-title *ngIf="model.titleKey">{{ model.titleKey }}</h2>
        <mat-dialog-content *ngIf="model.contentKey">
            <span>{{ model.contentKey }}</span><br>
            <span><b>{{ model.description }}</b></span>
        </mat-dialog-content>
        <mat-dialog-actions float-right margin-top>
            <button mat-button color="primary" (click)="submit()">
                {{ 'Tak' }}
            </button>
            <button mat-button color="primary" (click)="cancel()">
                {{ 'Nie' }}
            </button>
        </mat-dialog-actions>
    `
})
export class YesNoDialogComponent {

    model: YesNoModel;

    constructor(
        public dialogRef: MatDialogRef<YesNoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: YesNoModel
    ) {
        this.model = data;
    }

    submit() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
