import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.css";
import * as Survey from "survey-angular";
import * as widgets from "surveyjs-widgets";
import { init as initCustomWidget } from "./customwidget";
import "survey-angular/survey.css";
import { FileUploadreData, SessionStorageFileUploaderDataKey, FileInfoResource  } from '../../redux/survey/survey.model'

import { environment } from "src/environments/environment";
import { ApplicationData } from 'src/app/auth/user.model';
widgets.icheck(Survey);
widgets.select2(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey);
widgets.jqueryuidatepicker(Survey);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
// widgets.ckeditor(Survey);
widgets.autocomplete(Survey);
widgets.bootstrapslider(Survey);
widgets.prettycheckbox(Survey);
//widgets.emotionsratings(Survey);
initCustomWidget(Survey);

Survey.JsonObject.metaData.addProperty("questionbase", "popupdescription:text");
Survey.JsonObject.metaData.addProperty("page", "popupdescription:text");

Survey.StylesManager.applyTheme("default");

function showDescription(data: string) {
    alert(data);

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "survey",
  template: `<div fxflex>
    <div class="survey-container contentcontainer codecontainer">
    <div id="surveyElement"></div>
  </div>`,
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  @Output() submitSurvey = new EventEmitter<any>();
  @Output() surveyResult: string;
  @Input() json: object;
  @Input() data: string;
  @Input() canEdit: boolean;

  result: any;

  saveData(data) {
    this.submitSurvey.emit(data);
  }

  brakOdpowiedzi(params) {
    if (params[0] === null || params[0] === undefined) {
      return true;
    }
    if(!JSON.stringify(params).includes('"OOS":true')) {
      return true;
    }


    return false;
  }

  czyEkspozycja(params) {
    if (params[0] === null || params[0] === undefined) {
      return false;
    }
    if(JSON.stringify(params).includes('"CzyEkspozycja":"1"')) {
      return true;
    }

    return false;
  }

  ngOnInit() {

    //widgets.jqueryuidatepicker(Survey);

    Survey.FunctionFactory
    .Instance
    .register("brakOdpowiedzi", this.brakOdpowiedzi);

    Survey.FunctionFactory
    .Instance
    .register("czyEkspozycja", this.czyEkspozycja);

    const surveyModel = new Survey.Model(this.json);

    if(this.data && this.data !== '' && this.data !== undefined) {
      surveyModel.data = JSON.parse(this.data);
    }

    surveyModel.clearInvisibleValues = true;
    surveyModel
    .onClearFiles
    .add(function (survey, options) {

        // Code to remove files stored on your service
        // SurveyJS Service doesn't allow to remove files
        /*console.log(options.value);
        options.value.map((file, index)=>{
          console.log(file);

        })*/
        options.callback("success");
    });
    surveyModel.onUploadFiles.add(function(survey, options){
      options
      .files
      .forEach(function (file) {
          const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
          var fileUploaderData =  JSON.parse(sessionStorage.getItem(SessionStorageFileUploaderDataKey)) as FileUploadreData;    
          var formData = new FormData();
          formData.append("file", file);
          fileUploaderData.formDataParamiters.map((param,index)=>{
              formData.append(param.key, param.value)
          })
          if(fileUploaderData.fileInfoResource !== undefined){
            var fileInfoResource = {
              lastModificationDate: fileUploaderData.fileInfoResource.lastModificationDate,
              waterMark: true,
              localization: fileUploaderData.fileInfoResource.localization              
            } as FileInfoResource
            formData.append("fileInfoJson", JSON.stringify(fileInfoResource) )
          }
          $.ajax({
              url: fileUploaderData.apiUrl,
              type: "POST",
              success: function (data) {
                  options.callback("success", [
                      {
                          file: file,
                          content: environment.apiUrl + data
                      }
                  ]);
              },
              headers: {'Authorization': `Bearer ${currentUser.token}`},
              error: function (error) {
                console.log(error)

              },
              async: true,
              data: formData,
              cache: false,
              contentType: false,
              processData: false,
              timeout: 60000
          });
      });
    })
    surveyModel.onAfterRenderQuestion.add((survey, options) => {
      if(options.question.getType() == 'file'){
        var link = options.htmlElement.querySelector("a");
        try{
          link.style.pointerEvents = "initial";
          link.setAttribute('target', '_blank');
        }
        catch{};
      }
      if (!options.question.popupdescription) {
        return;
      }
      // Add a button;
      const btn = document.createElement("button");
      btn.className = "btn btn-info btn-xs";
      btn.innerHTML = "More Info";
      btn.onclick = function () {
        // showDescription(question);
        alert(options.question.popupdescription);
      };
      const header = options.htmlElement.querySelector("h5");
      const span = document.createElement("span");
      span.innerHTML = "  ";
      if(header !== null && header !== undefined) {
        header.appendChild(span);
        header.appendChild(btn);
      }
    });

    surveyModel
    .onAfterRenderPage
    .add(function (survey, options) {

        //Create a 'More Info' button to invoke a modal popup
        var btn = document.createElement("button");
        btn.type = "button";
        btn.className = "my-button";
        btn.innerHTML = "Zapisz wyninki";
        btn.onclick = function () {
          survey.doComplete(true);
        }
        //Insert the created 'Zapisz wyniki' button into the rendered page's header
        var header = options
            .htmlElement
            .querySelector("h4");
        var span = document.createElement("span");
        span.innerHTML = "  ";
        if(header !== null && header !== undefined) {
          header.appendChild(span);
          header.appendChild(btn);
        }
    });

    surveyModel.onComplete.add((result, options) => {
      this.submitSurvey.emit({ res: result.data, isCompleteOnTrigger: options.isCompleteOnTrigger });
      this.result = result.data;

    });

    surveyModel.onValueChanged.add(function (sender, options) {
      //console.log(sender);
      //console.log(options);
      // if (options.question.maxChoices) {
      //     var question = container.find('#' + options.question.id);
      //     var checked = question.find('input[type="checkbox"]:checked').length;

      //     if (checked > options.question.maxChoices) {
      //         options.value.pop();
      //     }
      // }
    });

    //surveyModel.startTimer();

    // surveyModel.onTimer.add((sender) => {
    //   console.log('surveyModel.onTimer');
    //   //sender.clear(true, false);
    //   sender.clearInvisibleValues = 'onHidden';
    // });


    surveyModel.onTextMarkdown
      .add(function (survey, options) {
          options.html = options.text;
      });

      if(!this.canEdit) {
        surveyModel.mode = 'display';
      }

    Survey.SurveyNG.render("surveyElement", { model: surveyModel });
  }
}
