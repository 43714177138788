import { Page } from 'src/app/services/navigation.service';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { NavigatedTo } from '../actions/navigation.action';

export class NavigationStateModel {
    navigatedPage: Page;
}

@State<NavigationStateModel>({
    name: 'navigation',
    defaults: {
        navigatedPage: null
    }
})
export class NavigationState {
    @Selector()
    static getNavigatedPage(state: NavigationStateModel) {
        return state.navigatedPage;
    }

    @Action(NavigatedTo)
    selectUser({ patchState }: StateContext<NavigationStateModel>, { payload }: NavigatedTo) {
        patchState({
            navigatedPage: payload
        });
    }
}
