import { Injectable } from '@angular/core';
import { User, UserCompany, AccountType, UserBase, ClientAccountType, UserGroupMembersData, UserGroupManagersData, UserListItem, UserContactListItem, UserContactDetails, UserAdditionalData, ProjectKindGroupManagersResource, GroupManagersBody } from 'src/app/redux/user/user.model';
import * as root from 'src/app/app.reducer';
import * as UserActions from 'src/app/redux/user/user.actions';
import * as GroupActions from 'src/app/redux/group/group.actions';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from 'src/app/shared/ui.service';
import { ApiResponse } from 'src/app/_models/api-response.model';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Group } from 'src/app/redux/group/group.model';
import { AppHubConnection } from 'src/app/redux/user/hub_connection.model';
import { YesNoModel, YesNoDialogComponent } from 'src/app/shared/yes-no-dialog/yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserState } from 'src/app/store/states/user.state';
import { Select, Store as NStore } from '@ngxs/store';
import { SelectUser, UnselectUser } from 'src/app/store/actions/user.action';
import { ProjectKind } from 'src/app/redux/contractor/project-kind.model';
import { projectKinds } from 'src/app/redux/contractor/project-kind.reducer';
import { EmploymentStatus } from 'src/app/redux/service/service.model';
import { LoadOptions } from 'devextreme/data/load_options';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

@Injectable()
export class UserService {
    private _onDestroy = new Subject<void>();

    @Select(UserState.getSelectedUser) selectedUser$: Observable<User>;

    constructor(
        private store: Store<root.IRootState>,
        private http: HttpClient,
        private translate: TranslateService,
        private uiService: UIService,
        private dialog: MatDialog,
        private ngxsStore: NStore
    ) { }

    cancelSubscriptions() {
        // console.log('[User service] Cancel subscriptions');
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    initService() {
        this.cancelSubscriptions();
        this._onDestroy = new Subject<void>();
    }

    reloadDataGrid() {
        this.store.dispatch(new UserActions.SetReloadDataGrid(true));
    }

    unsetReloadDataGrid() {
        this.store.dispatch(new UserActions.SetReloadDataGrid(false));
    }

    selectUser(user: User) {
        this.ngxsStore.dispatch(new SelectUser(user));
    }

    unselectUser() {
        this.ngxsStore.dispatch(new UnselectUser());
    }

    setSelectedGroupIds(ids: number[]) {
        this.store.dispatch(new UserActions.SetSelectedGroupIds(ids));
    }

    unselectContactUser() {
        this.store.dispatch(new UserActions.SetUserContactDetails(null));
    }

    startEditing() {
        this.store.dispatch(new UserActions.StartEditing());
    }

    stopEditing() {
        this.store.dispatch(new UserActions.StopEditing());
    }

    dispatchFunction = (roleName: string, res: UserListItem[]) => {
        switch (roleName) {
            case 'Koordynator lokalizacji':
                this.store.dispatch(new UserActions.SetAvailableLocalizationCoordinatorsV2(res));
                break;
            case 'Menedżer Regionalny':
                this.store.dispatch(new UserActions.SetAvailableManagersV2(res));
                break;
            case 'Koordynator ds. Merchandisingu':
                this.store.dispatch(new UserActions.SetAvailablePmtCoordinatorsV2(res));
                break;
            case 'Koordynator ds. Promocji':
                this.store.dispatch(new UserActions.SetAvailablePromCoordinatorsV2(res));
                break;
            case 'Merchandiser':
                this.store.dispatch(new UserActions.SetAvailablePmtMerchandisersV2(res));
                break;
            case 'Hostessa':
                this.store.dispatch(new UserActions.SetAvailableHostessesV2(res));
                break;
            case 'Specjalista':
                this.store.dispatch(new UserActions.SetAvailableSupervisorsV2(res));
                break;
        }
    };

    getUserById(id: string) {
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<User>(environment.apiUrl + '/applicationuser/v2/user/' + id)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedUser(res));

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedUser(null));
                    console.error('error');
                    console.error(error);
                }
            );
    }

    getUsersByRoleName(roleName: string) {
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserListItem[]>(environment.apiUrl + '/applicationuser/v2/users-by-role-name?roleName=' + roleName)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.dispatchFunction(roleName, res);

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error('error');
                    console.error(error);
                }
            );
    }

    searchUsersByRoleName(roleName: string, searchQuery: string = null) {
        if (searchQuery === null || searchQuery.length < 3) {
            return;
        }
        const searchParams = searchQuery === null ? '' : '&searchQuery=' + searchQuery;
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserListItem[]>(environment.apiUrl + '/applicationuser/v2/search-users-by-role-name?roleName=' + roleName + searchParams)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.dispatchFunction(roleName, res);

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error('error');
                    console.error(error);
                }
            );
    }

    searchUsersByPrincipalAndRoleName(principalId: string, roleName: string, searchQuery: string = null) {
        if (searchQuery === null || searchQuery.length < 3) {
            return;
        }
        const searchParams = searchQuery === null ? '' : '&searchQuery=' + searchQuery;
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserListItem[]>(environment.apiUrl + '/applicationuser/v2/search-users-by-principal-and-role-name?principalId=' + principalId + '&roleName=' + roleName + searchParams)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.dispatchFunction(roleName, res);

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error('error');
                    console.error(error);
                }
            );
    }
    searchUsers(searchQuery: string) {
        if (searchQuery === null || searchQuery.length === 0) {
            return;
        }
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserListItem[]>(environment.apiUrl + '/applicationuser/v2/search-users?searchQuery=' + searchQuery)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSearchUsers(res));

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error('error');
                    console.error(error);
                }
            );
    }

    searchUsersByGroupId(groupId: number, searchQuery: string) {
        if (searchQuery === null || searchQuery.length === 0) {
            return;
        }
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserListItem[]>(environment.apiUrl + '/applicationuser/v2/search-users-by-group-id?groupId=' + groupId + '&searchQuery=' + searchQuery)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSearchUsers(res));

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error('error');
                    console.error(error);
                }
            );
    }

    changePassword(user: User, newPassword: string) {
        console.log('[User service] Change user password');
        const body: { applicationUserId: string, newPassword: string } = {
            applicationUserId: user.id,
            newPassword
        };
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put<ApiResponse<User>>(environment.apiUrl + '/auth/changePasswordForUser', body)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                response => {
                    this.store.dispatch(new UserActions.StopLoading());
                    if (response.messageCode) {
                        this.translate.get(response.messageCode)
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text);
                            });
                    }
                }, err => {
                    this.store.dispatch(new UserActions.StopLoading());
                    if (err && err.error && err.error.messageCode) {
                        this.translate.get([err.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations[err.error.messageCode], translations.ERROR, 10_000);
                            });
                    } else {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                    }
                    console.error(err);
                }
            );
    }

    updateUserStatus(body: UserContactListItem) {
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put<ApiResponse<User>>(environment.apiUrl + '/applicationUser/UpdateUserStatus', body)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                () => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.reloadDataGrid();
                    this.uiService.openSnack('Zakończono pomyślnie');
                }, error => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                    console.error(error);
                }
            );
    }

    updateV2(user: UserContactDetails) {

        const body: UserCompany = {
            applicationUserId: user.id,
            userName: user.userName,
            pesel: user.pesel,
            email: user.email,
            disabled: user.disabled,
            companyId: '',
            firstName: user.firstName,
            lastName: user.lastName,
            projectKinds: user.projectKindsIds,
            phoneNumber: user.phoneNumber,
            userProjects: user.projectsIds,
            userMarkets: user.marketsIds,
            clientAccountType: user.clientAccountType,
            employmentStatus: user.employmentStatus,
            fv: user.fv !== undefined ? user.fv : false,
            delegation: user.delegation !== undefined ? user.delegation : false,
        };

        return new Promise((r, reject) => {
            this.store.dispatch(new UserActions.StartLoading());
            this.http.put<any>(environment.apiUrl + '/applicationUser', body)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    res => {
                        this.stopEditing();
                        this.store.dispatch(new UserActions.StopLoading());
                        if (res && res.messageCode) {
                            this.uiService.openSnack(res.messageCode);
                        }
                    }, err => {
                        this.stopEditing();
                        this.store.dispatch(new UserActions.StopLoading());
                        if (err && err.error && err.error.messageCode) {
                            this.translate.get([err.error.messageCode, 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((text) => {
                                    this.uiService.openSnack(text[err.error.messageCode], text.ERROR, 10_000);
                                });
                        } else {
                            this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((translations) => {
                                    this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                                });
                        }
                        console.error(err);
                    });
        });
    }

    update(user: User) {

        const body: UserCompany = {
            applicationUserId: user.id,
            userName: user.userName,
            pesel: user.pesel,
            email: user.email,
            disabled: user.disabled,
            companyId: '',
            firstName: user.firstName,
            lastName: user.lastName,
            projectKinds: user.projectKinds,
            phoneNumber: user.phoneNumber,
            userProjects: user.userProjects,
            userMarkets: user.userMarkets,
            clientAccountType: user.clientAccountType,
            employmentStatus: user.employmentStatus,
            fv: user.fv !== undefined ? user.fv : false,
            delegation: user.delegation !== undefined ? user.delegation : false,
        };

        return new Promise((r, reject) => {
            this.store.dispatch(new UserActions.StartLoading());
            this.http.put<any>(environment.apiUrl + '/applicationUser', body)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    res => {
                        this.stopEditing();
                        this.store.dispatch(new UserActions.StopLoading());
                        if (res && res.messageCode) {
                            this.uiService.openSnack(res.messageCode);
                        }
                    }, err => {
                        this.stopEditing();
                        this.store.dispatch(new UserActions.StopLoading());
                        if (err && err.error && err.error.messageCode) {
                            this.translate.get([err.error.messageCode, 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((text) => {
                                    this.uiService.openSnack(text[err.error.messageCode], text.ERROR, 10_000);
                                });
                        } else {
                            this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((translations) => {
                                    this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                                });
                        }
                        console.error(err);
                    });
        });
    }

    getGroupName(userId: string) {
        this.http.get<string[]>(environment.apiUrl + '/applicationuser/group/' + userId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UserActions.SetSelectedMerchGroupNames(res));
                }, (error) => {
                    console.error('error');
                    console.error(error);
                }
            );
    }

    groupGetMembers(groupId: number, loadOptions: LoadOptions) {
        const params = this.getParams(loadOptions);

        this.store.dispatch(new GroupActions.StartLoading());
        this.http.get<UserGroupMembersData>(environment.apiUrl + '/applicationUser/v2/group/' + groupId + '/members', { params })
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedGroupMembers(res));
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedGroupMembers(null));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    resetProjectKindGroupsManagers() {
        this.store.dispatch(new UserActions.SetSelectedProjectKindGroupManagers(null));
    }

    groupGetManagersByProjectKind(projectKindId: number) {
        this.store.dispatch(new GroupActions.StartLoading());
        this.http.get<ProjectKindGroupManagersResource>(environment.apiUrl + '/applicationUser/v2/projectKind/' + projectKindId + '/managers')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedProjectKindGroupManagers(res));
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedProjectKindGroupManagers(null));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    groupGetManagers(groupId: number, loadOptions: LoadOptions) {
        const params = this.getParams(loadOptions);

        this.store.dispatch(new GroupActions.StartLoading());
        this.http.get<UserGroupManagersData>(environment.apiUrl + '/applicationUser/v2/group/' + groupId + '/managers', { params })
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedGroupManagers(res));
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedGroupManagers(null));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    create(user: User, password: string, birthDate: Date,
        startWorkDate: Date, userProjectKinds: number[],
        userAccountType: AccountType, clientAccountType: ClientAccountType, userProjects: number[], userMarkets: number[],
        selectedGroupsWithManagers: GroupManagersBody[], pesel: string, employmentStatus: EmploymentStatus, allowAddToCompany = false) {

        const body: any = {
            user,
            password,
            birthDate: birthDate === undefined ? 0 : birthDate.getTime(),
            startWorkDate: startWorkDate === undefined ? 0 : startWorkDate.getTime(),
            projectKinds: userProjectKinds,
            accountType: userAccountType,
            clientAccountType,
            userProjects,
            userMarkets,
            selectedGroupsWithManagers,
            pesel,
            employmentStatus,
            allowAddToCompany
        };

        return new Promise((r, reject) => {
            this.store.dispatch(new UserActions.StartLoading());
            this.http.post<any>(environment.apiUrl + '/applicationUser', body)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    res => {
                        this.store.dispatch(new UserActions.StopLoading());
                        if (res && res.messageCode) {
                            this.translate.get(res.messageCode)
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((text) => {
                                    this.uiService.openSnack(text);
                                });
                        }
                        if (res && res.exists) {
                            const dialogTextData: YesNoModel = {
                                titleKey: 'This_user_has_already_been_created_before_in_another_company',
                                contentKey: 'Do_you_want_to_add_him_to_the_current_company?'
                            };
                            const ref = this.dialog.open(YesNoDialogComponent, {
                                width: '25%',
                                minWidth: '300px',
                                data: dialogTextData,
                                autoFocus: false
                            });
                            ref.afterClosed()
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((dialogRes) => {
                                    if (dialogRes) {
                                        this.create(user, password, birthDate, startWorkDate, userProjectKinds, userAccountType, clientAccountType, userProjects, userMarkets, selectedGroupsWithManagers, pesel, employmentStatus, true).then(c => r(true));
                                    }
                                });
                        } else {
                            r(true);
                        }
                        // r();
                    }, err => {
                        this.store.dispatch(new UserActions.StopLoading());
                        if (err && err.error && err.error.messageCode) {
                            this.translate.get([err.error.messageCode, 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((text) => {
                                    this.uiService.openSnack(text[err.error.messageCode], text.ERROR, 10_000);
                                });
                        } else {
                            this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((translations) => {
                                    this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                                });
                        }
                        console.error(err);
                    }
                );
        });
    }

    resetPasswordRange(users: User[]) {
        console.log('[User service] Reset user passwords');
        const body: any[] = [];
        for (const u of users) {
            body.push({
                applicationUserId: u.id
            });
        }
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put<ApiResponse<User>>(environment.apiUrl + '/auth/initialPasswords', body)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new UserActions.StopLoading());
                }, (error) => {
                    if (error && error.error && error.error.messageCode) {
                        this.translate.get([error.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text[error.error.messageCode], text.ERROR, 10_000);
                            });
                    } else {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                    }
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error(error);
                }
            );
    }

    resetPassword(userId: string) {
        console.log('[User service] Reset user password');
        const body: any = {
            applicationUserId: userId
        };
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put<ApiResponse<User>>(environment.apiUrl + '/auth/initialPassword', body)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    if (res.messageCode) {
                        this.translate.get(res.messageCode)
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text);
                            });
                    }
                    this.store.dispatch(new UserActions.StopLoading());
                }, (error) => {
                    if (error && error.error && error.error.messageCode) {
                        this.translate.get([error.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text[error.error.messageCode], text.ERROR, 10_000);
                            });
                    } else {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                    }
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error(error);
                }
            );
    }

    disableRange(users: User[]) {
        console.log('[User service] Disable user accounts');
        const body: any[] = [];
        for (const u of users) {
            body.push({
                applicationUserId: u.id,
            });
        }
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put<User>(environment.apiUrl + '/auth/disableUsers', body)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                updatedUser => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new UserActions.StopLoading());
                }, error => {
                    if (error && error.error && error.error.messageCode) {
                        this.translate.get([error.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text[error.error.messageCode], text.ERROR, 10_000);
                            });
                    } else {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                    }
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error(error);
                }
            );
    }

    disable(userId: string) {
        console.log('[User service] Disable user account');
        const body: { applicationUserId: string } = {
            applicationUserId: userId
        };
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put<User>(environment.apiUrl + '/auth/disableUser', body)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                updatedUser => {
                    this.uiService.openSnack('Zakończono pomyślnie');
                    this.store.dispatch(new UserActions.StopLoading());
                }, error => {
                    if (error && error.error && error.error.messageCode) {
                        this.translate.get([error.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text[error.error.messageCode], text.ERROR, 10_000);
                            });
                    } else {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                    }
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error(error);
                }
            );
    }

    getOnline() {
        console.log('[User service] Get online users');
        this.http.get<AppHubConnection[]>(environment.apiUrl + '/connection')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (online) => {
                    // console.log('online', online);
                    this.store.dispatch(new UserActions.SetAvailableOnlineUsers(online));
                }
            );
    }

    getAvatar(user: User) {
        if (user._avatar) {
            return user._avatar;
        }
        if (user._avatarWasLoaded) {
            return null;
        }
        user._avatarWasLoaded = true;
        if (user.hasPhoto) {
            this.loadAvatar(user.id)
                .then(res => user._avatar = res.avatar)
                .catch(err => console.error(err));
        }
        return null;
    }

    getContactAvatar(user: UserContactListItem) {
        if (user._avatar) {
            return user._avatar;
        }
        if (user._avatarWasLoaded) {
            return null;
        }
        if (user.hasPhoto) {
            this.loadAvatar(user.id)
                .then(res => user._avatar = res.avatar)
                .catch(err => console.error(err));
        }
        return null;
    }

    async loadAvatar(userId: string) {
        try {
            const photo = await this.http.get<{ avatar: string }>(environment.apiUrl + '/applicationUser/photo/avatar/' + userId)
                .toPromise();
            return photo;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    uploadPhoto({ croppedPhoto, photo, userId }) {
        console.log('[User service] Upload user photo');
        this.http.post<any>(environment.apiUrl + '/applicationUser/photo', { croppedPhoto, photo, userId })
            .toPromise();
    }

    getCoordinators() {
        console.log('[User service] Get availables coordinators');
        this.http.get<{
            localizationCoordinators: UserBase[],
            managers: UserBase[],
            pmtCoordinators: UserBase[],
            promCoordinators: UserBase[],
            pmtMerchandisers: UserBase[],
            supervisors: UserBase[],
            hostesses: UserBase[]
        }>
            (environment.apiUrl + '/applicationUser/coordinator')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (result) => {
                    this.store.dispatch(new UserActions.SetAvailableManagers(result.managers));
                    this.store.dispatch(new UserActions.SetAvailableLocalizationCoordinators(result.localizationCoordinators));
                    this.store.dispatch(new UserActions.SetAvailablePmtCoordinators(result.pmtCoordinators));
                    this.store.dispatch(new UserActions.SetAvailablePromCoordinators(result.promCoordinators));
                    this.store.dispatch(new UserActions.SetAvailablePmtMerchandisers(result.pmtMerchandisers));
                    this.store.dispatch(new UserActions.SetAvailableHostesses(result.hostesses));
                    this.store.dispatch(new UserActions.SetAvailableSupervisors(result.supervisors));
                }, (error) => {
                    this.store.dispatch(new UserActions.SetAvailableManagers(null));
                    this.store.dispatch(new UserActions.SetAvailableLocalizationCoordinators(null));
                    this.store.dispatch(new UserActions.SetAvailablePmtCoordinators(null));
                    this.store.dispatch(new UserActions.SetAvailablePromCoordinators(null));
                    this.store.dispatch(new UserActions.SetAvailablePmtMerchandisers(null));
                    this.store.dispatch(new UserActions.SetAvailableHostesses(null));
                    this.store.dispatch(new UserActions.SetAvailableSupervisors(null));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    getUsers(loadOptions: LoadOptions) {
        const params = this.getParams(loadOptions);
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<any>(environment.apiUrl + '/applicationUser/v2/users', { params })
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (result) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetAvailableUsers({ data: result.data, totalCount: result.totalCount }));
                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetAvailableUsers({ data: [], totalCount: 0 }));
                    this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                    console.error(error);
                }
            );
    }

    getById(userId: string): Promise<User> {
        console.log('[User service] Get user by id');
        return new Promise((r) => {
            this.http.get<User>(environment.apiUrl + '/applicationUser/administrationUser/' + userId)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    (user) => {
                        r(user);
                    }, (error) => {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                        console.error(error);
                    }
                );
        });
    }

    getUserAdditionalData(userId: string) {
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserAdditionalData>(environment.apiUrl + '/applicationUser/v2/user-additional-data/' + userId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (result) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedUserAdditonalData(result));
                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetSelectedUserAdditonalData(null));
                    this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                    console.error(error);
                }
            );
    }

    deleteUsers(userIds: string[]) {
        this.store.dispatch(new UserActions.StartLoading());
        this.http.put(environment.apiUrl + '/applicationuser/delete', userIds)
            .toPromise()
            .then(
                () => {
                    this.uiService.openSnack('Zakończono pomyślnie');
                    this.store.dispatch(new UserActions.StopLoading());
                })
            .catch(err => {
                if (err && err.error && err.error.messageCode) {
                    this.translate.get([err.error.messageCode, 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text[err.error.messageCode], text.ERROR, 10_000);
                        });
                } else {
                    this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                    this.store.dispatch(new UserActions.StopLoading());
                    console.error(err);
                }
            });
    }

    getDataSourceDefaultValue(): Promise<any> {
        const defaultValue: any = {
            data: [],
            totalCount: 0,
            groupCount: 0,
            summary: [],
        };
        return Promise.resolve(defaultValue);
    }

    loadContactsData(loadOptions: any, groupIds: number[]) {
        if (groupIds === null || groupIds.length === 0) {
            return this.getDataSourceDefaultValue();
        }
        const params = this.getParams(loadOptions);
        return this.http.get<any>(environment.apiUrl + '/applicationuser/v2/contacts?groupIds=' + groupIds.join(','), { params })
            .toPromise();
    }

    getContactDetails(userId: string) {
        this.store.dispatch(new UserActions.StartLoading());
        this.http.get<UserContactDetails>(environment.apiUrl + '/applicationuser/v2/contacts/' + userId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (result) => {

                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetUserContactDetails(result));

                }, (error) => {
                    this.store.dispatch(new UserActions.StopLoading());
                    this.store.dispatch(new UserActions.SetUserContactDetails(null));
                    console.error(error);
                }
            );
    }

    getParams(loadOptions: LoadOptions) {
        function isNotEmpty(value: any): boolean {
            return value !== undefined && value !== null && value !== '';
        }

        let params: HttpParams = new HttpParams();
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary'
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                params = params.set(i, JSON.stringify(loadOptions[i]));
            }
        });
        return params;
    }

    //   update(user) {
    //     console.log('[User service] Update');
    //     let language = localStorage.getItem('language');
    //     if (!language) {
    //         language = 'pl';
    //     }
    //     return new Promise((r) => {
    //         this.store.dispatch(new UserActions.StartLoading());
    //         this.http.put<User>(environment.apiUrl + '/user', { ...user })
    //             .pipe(takeUntil(this._onDestroy))
    //             .subscribe(
    //                 (_) => {
    //                     this.stopEditing();
    //                     this.store.dispatch(new UserActions.StopLoading());
    //                     r();
    //                 }, (err) => {
    //                     this.store.dispatch(new UserActions.StopLoading());
    //                     if (err && err.error && err.error.messageCode) {
    //                         this.uiService.openSnack(err.error.messageCode, 'Błąd', 10_000);
    //                     } else {
    //                         this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
    //                     }
    //                     console.error(err);
    //                 }
    //             );
    //     });
    // }

}
