import { Component, OnInit, OnDestroy } from '@angular/core';
import { DxPieChartModule } from 'devextreme-angular';
import { OosChart } from 'src/app/redux/order-task/order-task.model';
import { OosChartService } from 'src/app/services/oos-chart/oos-chart.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Project } from 'src/app/redux/project/project.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-oos-product-chart-by-localization',
    templateUrl: './oos-product-chart-by-localization.component.html',
    styleUrls: ['./oos-product-chart-by-localization.component.scss']
})

export class OosProductChartByLocalizationComponent implements OnInit, OnDestroy {
    oosProducts: OosChart[];
    selectedProject: Project = {};
    startDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    finishDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    startDateLong: number = this.startDate.getTime();
    finishDateLong: number = this.finishDate.getTime();
    selectedPeriod = 1;
    private _onDestroy = new Subject<void>();

    periods = [{id: 1, name: 'Bieżący miesiąc'}, {id: 2, name: 'Ostatnie 2 miesiące'}, {id: 3, name: 'Ostatnie 3 miesiące'}];

    constructor(private service: OosChartService,
                private store: Store<fromRoot.IRootState>,
                private datePipe: DatePipe) {
    }

    ngOnInit() {

      this.store.select(fromRoot.getChartOosByLocalization)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((prods) => { this.oosProducts = prods; });

      this.store.select(fromRoot.getProjectSelectedForCharts)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((proj) => {
          this.selectedProject = proj;
          this.onSubscribeChanged();
        });
    }

    ngOnDestroy(): void {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    customizeLabel(point) {
      return point.argumentText + ' (' + point.valueText + '%)';
    }

    customizePoint = (pointInfo: any) => {
      if (this.oosProducts) {
        for (let i = 0; i < this.oosProducts.length; i++) {
          if (i === 0 && pointInfo.argument === this.oosProducts[i].parameter) {
            return { color: '#66cc66', hoverStyle: { color: '#66cc66' } };
          }
          if (i === 1 && pointInfo.argument === this.oosProducts[i].parameter) {
            return { color: '#464749', hoverStyle: { color: '#464749' } };
          }
          if (i === 2 && pointInfo.argument === this.oosProducts[i].parameter) {
            return { color: '#ff6635', hoverStyle: { color: '#ff6635' } };
          }
          if (i === 3 && pointInfo.argument === this.oosProducts[i].parameter) {
            return { color: '#0099ca', hoverStyle: { color: '#0099ca' } };
          }
          if (i === 4 && pointInfo.argument === this.oosProducts[i].parameter) {
            return { color: '#ffcc33', hoverStyle: { color: '#ffcc33' } };
          }
          if (i === 5 && pointInfo.argument === this.oosProducts[i].parameter) {
            return { color: '#6666cc', hoverStyle: { color: '#6666cc' } };
          }
        }
      }
    }

    onValueChanged(data) {
      const tmpDate = new Date(this.datePipe.transform(data.value, 'yyyy-MM-dd'));
      this.startDateLong = tmpDate.getTime();
      if (this.selectedProject && this.startDateLong > 0) {
        this.service.getOosProductsByLocalization(this.startDateLong, this.finishDateLong, this.selectedProject.id);
      }
    }

    onValueChanged2(data) {
      const tmpDate = new Date(this.datePipe.transform(data.value, 'yyyy-MM-dd'));
      this.finishDateLong = tmpDate.getTime();
      if (this.selectedProject && this.finishDateLong > 0) {
        this.service.getOosProductsByLocalization(this.startDateLong, this.finishDateLong, this.selectedProject.id);
      }
    }

    onSubscribeChanged() {
      if (this.selectedProject) {
        this.service.getOosProductsByLocalization(this.startDateLong, this.finishDateLong, this.selectedProject.id);
      }
    }
}
