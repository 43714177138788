import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UIService } from 'src/app/shared/ui.service';
import { TranslateService } from '@ngx-translate/core';
import {  Market } from 'src/app/redux/localization/localization.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange, MatDialogRef } from '@angular/material';
import { Product } from 'src/app/redux/project/product.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';


@Component({
  selector: 'app-product-index-market',
  templateUrl: './product-index-market.component.html',
  styleUrls: ['./product-index-market.component.scss']
})
export class ProductIndexMarketComponent implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy = new Subject<void>();
  loading: boolean;
  allMarkets: Market[] = [];
  selectedProduct: Product = {};
  selectedMarket: Market = {};
  productIndex: string;
  isLoadingMarkets: boolean = true;

  searchControl: FormControl = new FormControl();
  filteredMarkets: ReplaySubject<Market[]> = new ReplaySubject<Market[]>(1);
  @ViewChild('marketSelect', { static: false }) selectElem: MatSelect;

  constructor(private router: Router, private http: HttpClient, private ui: UIService,
              private localizationService: LocalizationService,
              private store: Store<fromRoot.IRootState>,
              private translate: TranslateService,
              private dialogRef: MatDialogRef<ProductIndexMarketComponent>,) { }

  ngOnInit() {
    this.store.select(fromRoot.getLocalizationMarketsIsLoading)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((isLoading) => {
      this.isLoadingMarkets = isLoading;
    });
    
    this.store.select(fromRoot.getProductSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        res !== null ? this.selectedProduct = res : this.selectedProduct = {};
      });

    this.store.select(fromRoot.getMarketsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((markets) => {
        if (markets && markets.length > 0) {
          this.allMarkets = markets;
        }
        this.filterMarkets();
      });
  }

  ngAfterViewInit() {
    if (this.allMarkets.length === 0) {
      this.localizationService.getMarkets();
    }

    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
          this.filterMarkets();
      });
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async save() {
      await this.http.put(environment.apiUrl + '/project/product/attachmarketindex',
      {
        productId: this.selectedProduct.id,
        marketId: this.selectedMarket.id,
        productIndex: this.productIndex
      })
      .toPromise()
        .then(async () => {
          this.loading = false;
          this.ui.openSnack('Utworzono pomyślnie', null, 3000);
      })
      .catch(err => {
        this.loading = false;
        this.ui.openSnack('Nieoczekiwany błąd', null, 3000);
        console.log(err); return null;
      });

      this.dialogRef.close();
  }

  protected filterMarkets() {
    if (!this.allMarkets) {
      return;
    }
    let search = this.searchControl.value;
    if (!search) {
      this.filteredMarkets.next(this.allMarkets.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredMarkets.next(
      this.allMarkets.filter(m => m.code.toLowerCase().indexOf(search) > -1)
    );
  }

  marketSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedMarket = e.value;
    }
  }
}
