import { UserBase, UserListItem } from '../user/user.model';
import { Order, OrderForServce, SettlementKind } from '../order/order.model';
import { ProjectForOrder } from '../project/project.model';

export interface Service {
  id?: number;
  orderId?: number;
  order?: OrderForServce;
  settlementMonth?: Date;
  settlementMonthLong?: number;
  settlementStart?: Date;
  settlementStartLong?: number;
  settlementFinish?: Date;
  settlementFinishLong?: number;
  serviceEmployeeId?: string;
  serviceEmployee?: UserBase;
  supervisor?: UserBase;
  managerId?: string;
  manager?: UserBase;
  merchandisingCoordinatorId?: string;
  merchandisingCoordinator?: UserBase;
  settlingPersonId?: string;
  settlingPerson?: UserBase;
  settlementType?: SettlementType;
  settlementTypeIndex?: number;
  settlementTypeCode?: string;
  weekFactor?: number;
  employeeWeekFactor?: number;
  settlementKind?: SettlementKind;
  settlementKindCode?: string;
  settlementKindEmployee?: SettlementKind;
  settlementKindEmployeeCode?: string;
  contractorLumpSum?: number;
  contractorWeekLimit?: number;
  contractorMonthLimit?: number;
  employeeRate?: number;
  employeeRateBudget?: number;
  employeeLumpSum?: number;
  employeeWeekLimit?: number;
  employeeMonthLimit?: number;
  acceptedLimit?: number;
  employeeAcceptedLimit?: number;
  acceptedLimitOriginal?: number;
  employeeAcceptedLimitOriginal?: number;
  settlementLimit?: number;
  transferredHours?: number;
  additionalHours?: number;
  additionalHoursOriginal?: number;
  reclamationHours?: number;
  reclamationHoursOriginal?: number;
  rewardHours?: number;
  rewardAmount?: number;
  cutHours?: number;
  cutAmount?: number;
  cutNotes?: string;
  notes?: string;
  settlementNotes?: string;
  specialistAcceptationTime?: Date;
  supervisorAcceptationTime?: Date;
  settlementToPayAcceptationTime?: Date;
  settlementStatus?: SettlementStatus;
  settlementStatusText?: SettlementStatus;
  createdDate?: Date;
  createdUserId?: string;
  createdUser?: UserBase;
  totalHours?: number;
  totalHoursClient?: number;
  budgetSalary?: number;
  grossSalary?: number;
  netSalary?: number;
  employmentStatus?: EmploymentStatus; //Status zatrudnienia
  employmentFunction?: string; //Funkcja
  EmploymentStatusStr?: string;

  contractorCommissionRateBudget?: number; // Stawka zlecona budżet
  coordinationRate?: number;                // Stawka Koordynacja
  reserveManager?: number;                  // Rezerwa MR
  contractorTotalSum?: number;              // Suma Total
  contractorBonus?: number;                 // Bonus

  kpi?: number;
  employeeCostOfArrival?: number; //Koszt dotarcia
  employeeSaving?: number; //Oszczędność
  employeeNetRate?: number; // Stawka netto
  employeeMinimalNetRate?: number; //Min. Stawka netto
  employeeDeductionAmount?: number; //Potrącenie [kwota]
  employeeReasonForCorrection?: string; //Powód korekty
  employeeDelegation?: number; //Delegacja 
  employeeHoursOrdered?: number; //Liczba godzin zlecona
  employeeHoursWorked?: number; //Liczba godzin wypracowanych
  materialCost?: number; //Koszt rzeczowy
  contractorPersonnelCosts?: number; //Koszty os. klient
  fv?: boolean //Faktura vat
  clientNumberHoursFV?: number //Liczba godzin na FV
  employeeSavingCoordinator?: number  // Oszczędności KR
  usedEmployeeSaving?: number  // Wykorzystane oszczędności
}

export interface ServiceParameters {
  serviceIds: number[];
  params: ServiceParam[];
}

export interface ServiceParam {
  param: string;
  value: any;
}

export interface ServiceDetails {
  id?: number;
  orderId?: number;
  order?: OrderForServce;
  settlementMonth?: Date;
  settlementMonthLong?: number;
  settlementStart?: Date;
  settlementStartLong?: number;
  settlementFinish?: Date;
  settlementFinishLong?: number;
  serviceEmployeeId?: string;
  serviceEmployee?: UserListItem;
  supervisor?: UserListItem;
  managerId?: string;
  manager?: UserListItem;
  merchandisingCoordinatorId?: string;
  merchandisingCoordinator?: UserListItem;
  settlingPersonId?: string;
  settlingPerson?: UserListItem;
  settlementType?: SettlementType;
  settlementTypeIndex?: number;
  settlementTypeCode?: string;
  weekFactor?: number;
  employeeWeekFactor?: number;
  settlementKind?: SettlementKind;
  settlementKindCode?: string;
  settlementKindEmployee?: SettlementKind;
  settlementKindEmployeeCode?: string;
  contractorLumpSum?: number;
  contractorWeekLimit?: number;
  contractorMonthLimit?: number;
  employeeRate?: number;
  employeeRateBudget?: number;
  employeeLumpSum?: number;
  employeeWeekLimit?: number;
  employeeMonthLimit?: number;
  acceptedLimit?: number;
  employeeAcceptedLimit?: number;
  acceptedLimitOriginal?: number;
  employeeAcceptedLimitOriginal?: number;
  settlementLimit?: number;
  transferredHours?: number;
  additionalHours?: number;
  additionalHoursOriginal?: number;
  reclamationHours?: number;
  reclamationHoursOriginal?: number;
  rewardHours?: number;
  rewardAmount?: number;
  cutHours?: number;
  cutAmount?: number;
  cutNotes?: string;
  notes?: string;
  settlementNotes?: string;
  specialistAcceptationTime?: Date;
  supervisorAcceptationTime?: Date;
  settlementToPayAcceptationTime?: Date;
  settlementStatus?: SettlementStatus;
  settlementStatusText?: SettlementStatus;
  createdDate?: Date;
  createdUserId?: string;
  createdUser?: UserListItem;
  totalHours?: number;
  totalHoursClient?: number;
  budgetSalary?: number;
  grossSalary?: number;
  netSalary?: number;
  employmentStatus?: EmploymentStatus; //Status zatrudnienia
  employmentFunction?: string; //Funkcja
  EmploymentStatusStr?: string;

  contractorCommissionRateBudget?: number; // Stawka zlecona budżet
  coordinationRate?: number;                // Stawka Koordynacja
  reserveManager?: number;                  // Rezerwa MR
  contractorTotalSum?: number;              // Suma Total
  contractorBonus?: number;                 // Bonus
  //contractorBudget? : number;               //Budżet dla klienta
  kpi?: number;
  employeeCostOfArrival?: number; //Koszt dotarcia
  employeeSaving?: number; //Oszczędność
  employeeNetRate?: number; // Stawka netto
  employeeMinimalNetRate?: number; //Min. Stawka netto
  employeeDeductionAmount?: number; //Potrącenie [kwota]
  employeeReasonForCorrection?: string; //Powód korekty
  employeeDelegation?: number; //Delegacja 
  employeeHoursOrdered?: number; //Liczba godzin zlecona

  employeeHoursWorked?: number; //Liczba godzin wypracowanych
  materialCost?: number; //Koszt rzeczowy
  contractorPersonnelCosts?: number; //Koszty os. klient
  fv?: boolean //Faktura vat
  clientNumberHoursFV?: number //Liczba godzin na FV
}

export interface UpdateServiceModel {
  settlementStart?: boolean;
  settlementFinish?: boolean;
  manager?: boolean;
  merchandisingCoordinator?: boolean;
  settlementNotes?: boolean;
  settlementKind?: boolean;
  contractorLumpSum?: boolean;
  contractorWeekLimit?: boolean;
  contractorMonthLimit?: boolean;
  materialCost?: boolean;
  contractorBonus?: boolean;
  contractorCommissionRateBudget?: boolean;
  coordinationRate?: boolean;
  reserveManager?: boolean;
  clientNumberHoursFV?: boolean;
  settlementKindEmployee?: boolean;
  employeeHoursOrdered?: boolean;
  kpi?: boolean;
  employeeCostOfArrival?: boolean;
  employeeSaving?: boolean;
  employeeLumpSum?: boolean;
  employeeWeekLimit?: boolean;
  employeeMonthLimit?: boolean;
  employeeRate?: boolean;
  additionalHours?: boolean;
  reclamationHours?: boolean;
  employeeDeductionAmount?: boolean;
  employeeReasonForCorrection?: boolean;
  rewardHours?: boolean;
  cutHours?: boolean;
  cutNotes?: boolean;
  serviceEmployee?: boolean;
  transferredHours?: boolean;
  contractorBudget?: boolean;
}

export enum SettlementType {
  Main = 0,
  Additional = 1,
  MerchChanged = 2
}

export enum SettlementStatus {
  InProgress = 0, // Nowe
  SpecialistAcceptation = 1, // Akceptacja specjalisty
  SupervisorAcceptation = 2, // Akceptacja przełożonego
  PaymentAcceptation = 3, // Akceptacja do wypłaty
  Cancelled = 4
}

export enum EmploymentStatus {
  BezZus = 0,
  Zus = 1,
  Student = 2
}

export interface ServiceDxTable {
  data: Service[];
  totalCount: number;
  groupCount: number;
  summary: number[];
}



