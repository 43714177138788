import { Project, ProjectBudget, ProjectForOrder } from './project.model';
import {
    ProjectActions,
    SELECTED_PROJECT,
    SELECTED_PROJECT_FOR_CHARTS,
    ADD,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_PROJECTS,
    SET_USER_PROJECTS,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING,
    SELECTED_PROJECT_BUDGET,
    SET_RELOAD_DATA_GRID
} from './project.actions';
import { ApplicationData } from 'src/app/auth/user.model';

export interface State {
    projects: Project[];
    userProjects: Project[];
    selectedProject: Project;
    selectedProjectForCharts: Project;
    selectedProjectBudget: ProjectBudget;
    isEditing: boolean;
    isLoading: boolean;
    reloadDataGrid: boolean;
}
const initialState: State = {
    projects: [],
    userProjects: [],
    selectedProject: null,
    selectedProjectForCharts: null,
    selectedProjectBudget: null,
    isEditing: false,
    isLoading: false,
    reloadDataGrid: false,
};

export function projectReducer(state = initialState, action: ProjectActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_PROJECT: {
            return {
                ...state,
                selectedProject: action.payload
            };
        }
        case SELECTED_PROJECT_FOR_CHARTS: {
            return {
                ...state,
                selectedProjectForCharts: action.payload
            };
        }
        case ADD: {
            const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
            return {
                ...state,
                projects: [...state.projects, action.payload],
                userProjects: (action.payload.supervisor.userName === currentUser.userName ||
                    (action.payload.additionalSupervisor && action.payload.additionalSupervisor.userName === currentUser.userName)) ?
                    [...state.userProjects, action.payload] : state.userProjects
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_PROJECTS: {
            return {
                ...state,
                projects: action.payload
            };
        }
        case SET_USER_PROJECTS: {
            return {
                ...state,
                userProjects: action.payload
            };
        }
        case UPDATE: {
            const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));

            const index = state.projects.map(d => d.id).indexOf(action.payload.id);
            const userProjectindex = state.userProjects.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selProject = state.selectedProject;
            if (selProject && selProject.id === action.payload.id) {
                selProject = action.payload;
            }
            const updatedProjects = [
                ...state.projects.slice(0, index),
                Object.assign({}, state.projects[index], action.payload),
                ...state.projects.slice(index + 1)
            ];

            let updatedUserProjects = state.userProjects;
            if (userProjectindex !== -1) {
                updatedUserProjects = [
                    ...state.userProjects.slice(0, userProjectindex),
                    Object.assign({}, state.userProjects[userProjectindex], action.payload),
                    ...state.userProjects.slice(userProjectindex + 1)];

                // delete from userProjects if current supervisor or additional supervisor are not current user
                if (action.payload.supervisor.userName !== currentUser.userName) {
                    if (!action.payload.additionalSupervisor) {
                        updatedUserProjects = state.userProjects.filter(c => c.id !== action.payload.id);
                    } else {
                        if (action.payload.additionalSupervisor.userName !== currentUser.userName) {
                            updatedUserProjects = state.userProjects.filter(c => c.id !== action.payload.id);
                        }
                    }
                }
            } else { // check if we should add new item to user projects
                if (action.payload.supervisor.userName !== currentUser.userName) {
                    if (action.payload.additionalSupervisor && action.payload.additionalSupervisor.userName === currentUser.userName) {
                        updatedUserProjects = [...state.userProjects, action.payload];
                    }
                } else {
                    updatedUserProjects = [...state.userProjects, action.payload];
                }
            }

            return {
                ...state,
                projects: updatedProjects,
                userProjects: updatedUserProjects,
                selectedProject: selProject
            };
        }
        case REMOVE: {
            return {
                ...state,
                projects: state.projects.filter(c => c.id !== action.payload),
                userProjects: state.userProjects.filter(c => c.id !== action.payload)
            };
        }
        case SELECTED_PROJECT_BUDGET: {
            return {
                ...state,
                selectedProjectBudget: action.payload
            };
        }
        case SET_RELOAD_DATA_GRID: {
            return {
                ...state,
                reloadDataGrid: action.payload
            };
        }
        default: {
            return state;
        }        
    }
}

export const projects = (state: State) => state.projects;
export const userProjects = (state: State) => state.userProjects;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedProject = (state: State) => state.selectedProject;
export const selectedProjectForCharts = (state: State) => state.selectedProjectForCharts;
export const selectedProjectBudget = (state: State) => state.selectedProjectBudget;
export const reloadDataGrid = (state: State) => state.reloadDataGrid;