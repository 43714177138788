import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as DictionaryActions from 'src/app/redux/dictionary/dictionary.actions';
import { environment } from 'src/environments/environment';
import { Dictionary, DictionaryElement } from 'src/app/redux/dictionary/dictionary.model';

@Injectable()
export class DictionaryService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();

      this.unSelectDictionary();
  }

  stopEditing() {
    this.store.dispatch(new DictionaryActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new DictionaryActions.StartEditing());
  }

  selectDictionary(dictionary: Dictionary) {
    this.store.dispatch(new DictionaryActions.SetSelectedDictionary(dictionary));
    this.store.dispatch(new DictionaryActions.SetAvailableDictionaryElements(dictionary.elements));
  }

  unSelectDictionary() {
    this.store.dispatch(new DictionaryActions.SetSelectedDictionary(null));
    this.store.dispatch(new DictionaryActions.SetAvailableDictionaryElements(null));
  }

  getDictionaries() {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.get<Dictionary[]>(environment.apiUrl + '/dictionary')
      .toPromise()
      .then(
        (dictionaries) => {
          this.store.dispatch(new DictionaryActions.SetAvailableDictionaries(dictionaries));
          this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.SetAvailableDictionaries(null));
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  getDictionaryByCode(code) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.get<Dictionary>(environment.apiUrl + '/dictionary/' + code)
      .toPromise()
      .then(
        (dictionary) => {
          this.store.dispatch(new DictionaryActions.SetAvailableDictionaryElements(dictionary.elements));
          this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        //this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.SetAvailableDictionaryElements(null));
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  createDictionary(dictionary) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.post<Dictionary>(environment.apiUrl + '/dictionary', dictionary)
      .toPromise()
      .then(
        (model) => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  createElement(element) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.post<DictionaryElement>(environment.apiUrl + '/dictionary/element', element)
      .toPromise()
      .then(
        (model) => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  updateDictionary(dictionary) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.put<Dictionary>(environment.apiUrl + '/dictionary', dictionary)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  updateElement(element) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.put<Dictionary>(environment.apiUrl + '/dictionary/element', element)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  deleteDictionary(id: number) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.delete(environment.apiUrl + '/dictionary/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

  deleteElement(id: number) {
    this.store.dispatch(new DictionaryActions.StartLoading());
    this.http.delete(environment.apiUrl + '/dictionary/element/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new DictionaryActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new DictionaryActions.StopLoading());
        console.error(err);
    });
  }

}
