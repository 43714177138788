import { Market } from '../localization/localization.model';
import { ProjectSimple } from '../project/project.model';
import { UserBase, UserListItem } from '../user/user.model';

export interface  FormDataParamirers {
  key: string;
  value: string;
}

export interface FileInfoResource {
  lastModificationDate: any;
  localization: string;
  waterMark: boolean;
}
export interface FileUploadreData {
  formDataParamiters: FormDataParamirers[];
  apiUrl: string;
  fileInfoResource: FileInfoResource;
}

export const SessionStorageFileUploaderDataKey = 'survayFileUploadreData';

export interface SurveyPattern {
  id?: number;
  title?: string;
  taskTitle?: string;
  marketId?: number;
  market?: Market;
  projectId?: number;
  project?: ProjectSimple;
  viewByGroup?: boolean;
  json?: string;
  productSurvey?: boolean;
  centralPromotion?: boolean;
  supervisorId?: string;
  supervisor?: UserListItem;
  createdDate?: string;
  modifiedDate?: string;
  archival?: boolean;
  reportFields?: number[];
  generateServiceEnabled?: boolean;

  // notifications
  remindServiceEmployeeBeforReportComplete?: boolean;
  notifyContractorAfterReportComplete?: boolean;
  notifyCoordinatorAfterReportComplete?: boolean;
  notifyPmtRegionalManagerAfterReportComplete?: boolean;
  notifySupervisorAfterReportComplete?: boolean;
  notifyCoordinatorAfterSkipReport?: boolean;
  timeForNotifyCoordinatorAfterSkipReport?: number;
  timeForSecondNotifyCoordinatorAfterSkipReport?: number;
  notifyServiceEmployeeAfterSkipReport?: boolean;
  timeForNotifyServiceEmployeeAfterSkipReport?: number;
  timeForSecondNotifyServiceEmployeeAfterSkipReport?: number;
  visualitationParam?: VisualitationParam;
  maxPhoto?: boolean;
  probsIntegrationEnabled?: boolean;
}

export interface ReportField {
  id?: number;
  name?: string;
}

export interface Category {
  name?: string;
  fields?: ReportField[];
}

export interface  TempSurveyResults {
  reports: SurveyReportData[];
  surveySections: string[];
  answers: SurveyAnswer[];
}

export interface  SurveyReportData {
  nazwaProjektu: string;
  tytulZadania: string;
  szablonRaportu: string;
  siec: string;
  nazwaLokalizacji: string;
  adres: string;
  komentarzDoRealizacji: string;
  odpowiedzialny: string;
  dataRealizacji: string;
  dataWykonania: string;
  status: string;
  nrZadania: number;
  format: string;
  agent: string;
  rodzajPOS: string;
  wizualizacja: string;
  kodLokalizacjiKlienta: string;
  region: string;
  podregion: string;
  regionKlienta: string;
  podregionKlienta: string;
  menedzerRegionalnyKlienta: string;
  przedstawicielHandlowy: string;
  menedzer: string;
  koordynatorDsMerchandisingu: string;
}

export interface SurveyAnswer {
  orderTaskId?: number;
  product?: string;
  group?: string;
  sectionName?: string;
  productNumber?: string;
  numberInReport?: number;
  productCategory?: string;
  isProductCategorySet?: boolean;
  productPrice?: string;
  isProductPriceSet?: boolean;
  productAmountInBox?: string;
  isProductAmountInBoxSet?: boolean;
  productInfo?: string;
  isProductInfoSet?: boolean;
  centralPromotionName?: string;
  isCentralPromotionNameSet?: boolean;
  centralPromotionProduct?: string;
  isCentralPromotionProductSet?: boolean;
  centralPromotionDuration?: string;
  isCentralPromotionDurationSet?: boolean;
  centralPromotionNewsletter?: string;
  isCentralPromotionNewsletterSet?: boolean;
  centralPromotionAdditionalExposition?: string;
  isCentralPromotionAdditionalExpositionSet?: boolean;
  centralPromotionArrangements?: string;
  isCentralPromotionArrangementsSet?: boolean;
  isCentralPromotion?: string;
  questionTitle?: string;
  questionAnswer?: string;
  columnIndex?: number;
  productIndex?: number;
  attachmentId?: number;
  attachmentUrl?: string;
}

export interface SurveyConfig {
  orderTaskId?: number;
  product?: string;
  group?: string;
  sectionName?: string;
  productNumber?: string;
  numberInReport?: number;
  questionTitle?: string;
  questionAnswer?: string;
  columnIndex?: number;
  productIndex?: number;
  attachmentId?: number;
}

export interface SurveyResultGrid {
  orderTaskId?: number;
  contractor?: string;
  market?: string;
  street?: string;
  city?: string;
  realizationDate?: Date;
  responsiblePerson?: string;
  surveyPatternId?: number;
  projectId?: number;
  localization?: string;
  manager?: string;
  coordinator?: string;
  localizationCoordinator?: string;
  contractorManager?: string;
  salesRepresentative?: string;
  contractorLocalizationCode?: string;
  contractorArea?: string;
  contractorSubArea?: string;
  orderId?: number;
  products?: SurveyResultGridProduct[];
  standardQuestions?: SurveyResultGridQuestion[];
}

export interface SurveyResultGridProduct {
  name?: string;
  group?: string;
  category?: string;
  number?: string;
  price?: number;
  amountInBox?: number;
  notes?: string;
  mumberInReport?: number;
  questions?: SurveyResultGridQuestion[];
}

export interface SurveyResultGridQuestion {
  sectionName?: string;
  sectionIndex?: number;
  question?: string;
  answer?: string;
  attachmentId?: number;
  attachmentUrl?: string;
}

export enum VisualitationParam {
  noParam = 0,
  oos = 1,
  facy = 2,
  price = 3,
  promotion = 4
}
