import { Component, OnInit, ViewChild, OnDestroy, NgZone, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationData } from 'src/app/auth/user.model';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from '../../app.reducer';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { UIService } from 'src/app/shared/ui.service';
import { OrderTaskService } from 'src/app/services/order-task/order-task.service';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SurveyResultGrid } from 'src/app/redux/survey/survey.model';
import { MatTabGroup } from '@angular/material';
import dxDataGrid from 'devextreme/ui/data_grid';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.scss']
})

export class SurveyResultComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  expanded = false;
  selectedTasksIds: number[] = [];

  surveyResultGrid: SurveyResultGrid[] = []
  dataSource: any[][] = [];
  storageKey: string;
  currentUser: ApplicationUser;
  isLoading: boolean = false;
  taskIds: number[] = [];
  availableSections: string[] = [];

  //additional product features enabled
  prodName: boolean[] = [];
  prodGroup: boolean[] = [];
  prodCategory: boolean[] = [];
  prodNumber: boolean[] = [];
  prodPrice: boolean[] = [];
  prodAmountInBox: boolean[] = [];
  prodNotes: boolean[] = [];

  url = environment.apiUrl + '/ordertask/realization/photo/downloadimage/';

  addtionalQuestions: any[0][0] = [];

  baseFields: string[] =
      ['id','orderTaskId','realizationDate','contractor','market','city',
      'street','responsiblePerson','productName','productGroup','productCategory',
      'productNumber','productAmountInBox','productNotes','productPrice',
      'localization','manager','coordinator','localizationCoordinator','contractorManager',
      'salesRepresentative','contractorLocalizationCode','contractorArea','contractorSubArea','orderId'];

  selectedTabndex;
  @ViewChild('matTabGroup', { static: false }) matTabGroup: MatTabGroup;
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private router: Router,
    private service: OrderTaskService,
    private store: Store<fromRoot.IRootState>,
    private uiService: UIService,
    private route: ActivatedRoute) {

    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser === undefined || currentUser === null) {
      return;
    }
    this.storageKey = 'surveyResult_table_' + currentUser.company.id + currentUser.id;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const idParam = params['tasksIds'];
      this.selectedTasksIds = idParam.split(',');
    });

    this.store.select(fromRoot.getOrderTasksForSurveyResults)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((tasks) => {
        this.taskIds = tasks;
        if(this.taskIds.length > 0) {
          this.getSurveyResults(this.taskIds);
        }
      });
  }

  ngAfterViewInit() {
    this.service.selectTasksForSurveyResults(this.selectedTasksIds);
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.blocked === true) {
      e.rowElement.style.backgroundColor = 'rgba(238, 186, 186, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  close() {
    window.close();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  }

  onRowClick(e) {

  }

  cancel() {

  }

  getSurveyResults(taskIds: number[]) {
    this.uiService.openSnack('Trwa generowanie wyników raportów...', null, null);
    this.http.put<SurveyResultGrid[]>(environment.apiUrl + '/orderTask/surveyresultgrid', taskIds)
      .toPromise()
      .then(
        (results) => {
          this.surveyResultGrid = results;
          this.GetAvailableSections(results);
          this.buildDataGrid();
          this.uiService.openSnack('Zakończono pomyślnie');
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.dataSource = [];
        console.error(err);
    });
  }

  GetAvailableSections(results: SurveyResultGrid[]) {
    let tempCollection: any[] = [];

    for(let item of results) {
      for(let prod of item.products) {
        for(let question of prod.questions) {
          let sectionItem = { sectionIndex: question.sectionIndex, sectionName: question.sectionName };
          if(tempCollection.filter(s => s.sectionIndex == question.sectionIndex).length == 0) {
            tempCollection.push(sectionItem);
          }
        }
      }
    }

    for(let item of results) {
      for(let question of item.standardQuestions) {
        let sectionItem = { sectionIndex: question.sectionIndex, sectionName: question.sectionName };
        if(tempCollection.filter(s => s.sectionIndex == question.sectionIndex).length == 0) {
          tempCollection.push(sectionItem);
        }
      }
    }
    this.availableSections = tempCollection
      .sort((one, two) => one.sectionIndex.toString().localeCompare(two.sectionIndex.toString()))
      .map(s => s.sectionName);
  }

  buildDataGrid() {
    let i = 0;
    let id = 0;
    for(let section of this.availableSections) {
      this.dataSource[i] = [];
      this.prodName[i] = false;
      this.prodNumber[i] = false;
      this.prodGroup[i] = false;
      this.prodCategory[i] = false;
      this.prodPrice[i] = false;
      this.prodAmountInBox[i] = false;
      this.prodNotes[i] = false;
      for(let item of this.surveyResultGrid) {
        for(let prod of item.products) {
          if(prod.questions.filter(s => s.sectionName == section).length > 0) {
            if(prod.name) {
              this.prodName[i] = true;
            }
            if(prod.number) {
              this.prodNumber[i] = true;
            }
            if(prod.group) {
              this.prodGroup[i] = true;
            }
            if(prod.category) {
              this.prodCategory[i] = true;
            }
            if(prod.price) {
              this.prodPrice[i] = true;
            }
            if(prod.amountInBox) {
              this.prodAmountInBox[i] = true;
            }
            if(prod.notes) {
              this.prodNotes[i] = true;
            }
            this.dataSource[i].push( {
              id: id++,
              orderTaskId: item.orderTaskId,
              realizationDate: item.realizationDate,
              contractor: item.contractor,
              market: item.market,
              city: item.city,
              street: item.street,
              responsiblePerson: item.responsiblePerson,
              localization: item.localization,
              manager: item.manager,
              coordinator: item.coordinator,
              localizationCoordinator: item.localizationCoordinator,
              contractorManager: item.contractorManager,
              salesRepresentative: item.salesRepresentative,
              contractorLocalizationCode: item.contractorLocalizationCode,
              contractorArea: item.contractorArea,
              contractorSubArea: item.contractorSubArea,
              orderId: item.orderId,
              productName: prod.name,
              productGroup: prod.group,
              productCategory: prod.category,
              productNumber: prod.number,
              productPrice: prod.price,
              productAmountInBox: prod.amountInBox,
              productNotes: prod.notes
            });

            for(let question of prod.questions)
            {
              this.dataSource[i][this.dataSource[i].length-1][question.question] = question.answer;
            }
          }
        }
      }
      for(let item of this.surveyResultGrid) {
        if(item.standardQuestions.filter(s => s.sectionName == section).length > 0) {
          this.dataSource[i].push( {
            id: id++,
            orderTaskId: item.orderTaskId,
            realizationDate: item.realizationDate,
            contractor: item.contractor,
            market: item.market,
            city: item.city,
            street: item.street,
            responsiblePerson: item.responsiblePerson,
            localization: item.localization,
            manager: item.manager,
            coordinator: item.coordinator,
            localizationCoordinator: item.localizationCoordinator,
            contractorManager: item.contractorManager,
            salesRepresentative: item.salesRepresentative,
            contractorLocalizationCode: item.contractorLocalizationCode,
            contractorArea: item.contractorArea,
            contractorSubArea: item.contractorSubArea,
            orderId: item.orderId
          });
        }
        for(let question of item.standardQuestions) {
          if(question.sectionName === section) {
            this.dataSource[i][this.dataSource[i].length-1][question.question] = question.answer;
          }
        }
      }
      i++;
    }

    let k = 0;
    for(let sec of this.availableSections) {
      this.addtionalQuestions[k] = [];
      for (let i = 0; i < this.dataSource[k].length; i++) {
        for(let key in this.dataSource[k][i]) {
          if(!this.baseFields.includes(key) && !this.addtionalQuestions[k].includes(key))
            this.addtionalQuestions[k].push(key);
        }
      }
      k++;
    }
  }
  selectedTabChange(event) {
    this.selectedTabndex = this.matTabGroup.selectedIndex;

    var element = document.getElementById('gridContainer'+this.selectedTabndex.toString());
    let instance = dxDataGrid.getInstance(element) as dxDataGrid;

    setTimeout(() => {
        instance.repaint();
    }, 500);
  }

  getAttachmentId(base64: string) {
    for(let item of this.surveyResultGrid)
    {
      let attachemnt = item.standardQuestions.filter(s => s.answer === base64);
      if(attachemnt.length > 0) {
        return attachemnt[0].attachmentId;
      }
    }
    return 0;
  }
  getImageData(data:string){
    if(data.includes('urlold:')){
      var arr =  data.replace('urlold:', '').split('|');
      arr[1] = this.url + arr[1]
      return arr;
    }
    if(data.includes('url:')){
      var arr =  data.replace('url:', '').split('|');
      return arr;
    }
  }

  onExporting(e, section) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(section);

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 2, column: 2 },
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if(gridCell.rowType === "data") {
          if(gridCell.value !== undefined && gridCell.value !== null && gridCell.value.toString().includes('url')) {
            excelCell.value = undefined;
            let imageData = this.getImageData(gridCell.value);
            worksheet.name = section;
            worksheet.getRow(excelCell.row).height = 112.5;
            
            const image = workbook.addImage({
              base64: imageData[2],
              extension: 'jpeg',
            });
            

            worksheet.addImage(image, {
              tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
              ext: { width: 150, height: 150 },
              hyperlinks: {
                hyperlink: imageData[1],
                tooltip: 'Pokaż zdjęcie'
              }
            });

            worksheet.columns[excelCell.col-1].width = 22.00;
          }
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Wyniki raportów.xlsx");
      });
    });
    e.cancel = true;
  }
}
