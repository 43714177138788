import { Component, OnInit, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { Localization } from 'src/app/redux/localization/localization.model';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Product, AttachLocalization } from 'src/app/redux/project/product.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { MatDialogRef } from '@angular/material';
import { LocalizationDetailsComponent } from '../localization-details/localization-details.component';
import { Project } from 'src/app/redux/project/project.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';


@Component({
  selector: 'app-localization-select',
  templateUrl: './localization-select.component.html',
  styleUrls: ['./localization-select.component.scss']
})
export class LocalizationSelectComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  selectedProducts: Product[];
  selectedProject: Project = {};
  dataSource: Localization[] = [];
  allLocalizations: Localization[] = [];
  localizationProducts: AttachLocalization[] = [];
  expanded: boolean;
  isLoading: boolean = false;
  public fullScreen: boolean;

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  constructor(private router: Router, private http: HttpClient, private ui: UIService, private store: Store<fromRoot.IRootState>,
    private translate: TranslateService, private auth: AuthService, private service: ProjectService,
    private localizationService: LocalizationService,
    private render: Renderer2, private dialogRef: MatDialogRef<LocalizationDetailsComponent>) { }

  ngOnInit() {
    this.isLoading = true;
    this.store.select(fromRoot.getSelectedProductsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((products) => {
        this.selectedProducts = products;
      });

    this.store.select(fromRoot.getProjectSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((project) => {
        this.selectedProject = project;
      });

    this.store.select(fromRoot.getLocalizationsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((localizations) => {
        this.allLocalizations = localizations;
        if (localizations !== null && localizations !== undefined) {
          this.isLoading = false;
          const dataSourceTemp = localizations.length > 0 ? localizations : this.allLocalizations;
          if (this.selectedProducts.length === 1) {
            dataSourceTemp.forEach(element => {
              const length = this.selectedProducts[0].productLocalizations.filter(p => p.localizationId === element.id).length;
              if (length > 0) {
                element.assignToProduct = true;
              }
            });
          }
          this.dataSource = dataSourceTemp;
        }
      });

    this.store.select(fromRoot.getProjectLocalizationsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((localizations) => {
        if (localizations.length === 0) {
          this.isLoading = true;
          this.localizationService.getAllLocalizations();
          return;
        }
        this.isLoading = false;
        const dataSourceTemp = localizations.length > 0 ? localizations : this.allLocalizations;
        if (this.selectedProducts.length === 1) {
          dataSourceTemp.forEach(element => {
            const length = this.selectedProducts[0].productLocalizations.filter(p => p.localizationId === element.id).length;
            if (length > 0) {
              element.assignToProduct = true;
            }
          });
        }
        this.dataSource = dataSourceTemp;
      });
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel() {

  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectEdit,
        options: {
          icon: 'add',
          text: 'Przypisz do produktu',
          onClick: this.attachLocalization.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectEdit,
        options: {
          icon: 'remove',
          text: 'Odłącz od produktu',
          onClick: this.detachLocalization.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  attachLocalization() {
    this.setOutputCollection();
    this.service.attachLocalizationToProduct(this.localizationProducts);
  }

  detachLocalization() {
    this.setOutputCollection();
    this.service.detachLocalizationFromProduct(this.localizationProducts);
  }

  setOutputCollection() {
    this.localizationProducts.splice(0);
    const rows = this.grid.selectedRowKeys;
    const selectedLocalizations = this.dataSource.filter(localization => rows.includes(localization.id));
    this.selectedProducts.forEach(product => {
      selectedLocalizations.forEach(localization => {
        this.localizationProducts.push({ productId: product.id, localizationId: localization.id });
      });
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.assignToProduct === true) {
      e.rowElement.style.backgroundColor = 'rgba(102, 205, 96, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  }

  public onFullscreenClick() {
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    const content = document.getElementsByClassName('mat-dialog-content');

    if (this.fullScreen) {
      this.render.removeClass(dialog[0], 'full-screen');
      this.render.removeClass(content[0], 'full-screen');
    } else {
      this.render.addClass(dialog[0], 'full-screen');
      this.render.addClass(content[0], 'full-screen');
    }

    this.fullScreen = !this.fullScreen;
  }

  close() {
    this.cancel();
    this.dialogRef.close();
  }
}
