import { Action } from '@ngrx/store';
import { PasswordPolicy } from './security/password-policy-form/password-policy.model';
import { Security } from 'src/app/_models/security.model';
import { UserTableFilterModel } from './company-user/user-table-filter/user-table-filter.model';

export const SET_SECURITY = '[security] SET_SECURITY';
export const SET_PASSWORD_CONFIG = '[security] set password config';

export const HIDE_PARENT_TAB = '[TAB] hide parent tab';
export const SHOW_PARENT_TAB = '[TAB] show parent tab';
export const CAN_HIDE_PARENT_TAB = '[TAB] can hide parent tab';
export const SET_USER_LIST_FILTER = '[Company-Users] Set filter';

export class HideParentTab implements Action {
    readonly type = HIDE_PARENT_TAB;
}
export class ShowParentTab implements Action {
    readonly type = SHOW_PARENT_TAB;
}
export class CanHideParentTab implements Action {
    readonly type = CAN_HIDE_PARENT_TAB;
    constructor(public payload: boolean) { }
}
export class SetSecurity implements Action {
    readonly type = SET_SECURITY;
    constructor(public payload: Security) { }
}
export class SetPasswordConfig implements Action {
    readonly type = SET_PASSWORD_CONFIG;
    constructor(public payload: PasswordPolicy) { }
}
export class SetUserListFilter implements Action {
    readonly type = SET_USER_LIST_FILTER;
    constructor(public payload: UserTableFilterModel) { }
}

export type CompanyActions =
    | SetUserListFilter
    | HideParentTab
    | ShowParentTab
    | SetSecurity
    | SetPasswordConfig
    | CanHideParentTab;
