import { EmployeeForm } from './employee-form.model';
import {
    EmployeeFormActions,
    SELECTED,
    ADD,
    ADD_DOCUMENT,
    UPDATE_DOCUMENT,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_EMPLOYEEFORMS,
    SET_AVAILABLE_EMPLOYEEFORMS_FOR_USER,
    SET_AVAILABLE_DOCUMENTS,
    SET_AVAILABLE_DOCUMENTS_APPROVEMENT,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING
} from './employee-form.actions';
import { ApplicationData } from 'src/app/auth/user.model';
import { ProjectKindAttachment } from '../contractor/project-kind.model';
import { DocumentApprovement } from './document-approvement.model';

export interface State {
    employeeForms: EmployeeForm[];
    employeeFormsForUser: EmployeeForm[];
    allDocuments: ProjectKindAttachment[];
    documentsApprovemnt: DocumentApprovement[];
    selected: EmployeeForm;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    employeeForms: [],
    employeeFormsForUser: [],
    allDocuments: [],
    documentsApprovemnt: [],
    selected: null,
    isEditing: false,
    isLoading: false
};

export function employeeFormReducer(state = initialState, action: EmployeeFormActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED: {
            return {
                ...state,
                selected: action.payload
            };
        }
        case ADD: {
          const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
          return {
                ...state,
                employeeForms: [...state.employeeForms, action.payload],
                employeeFormsForUser: (action.payload.user.id === currentUser.id) ?
                [...state.employeeForms, action.payload] : state.employeeFormsForUser
            };
        }
        case ADD_DOCUMENT: {
          return {
                ...state,
                documentsApprovemnt: [...state.documentsApprovemnt, action.payload]
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_EMPLOYEEFORMS: {
            return {
                ...state,
                employeeForms: action.payload
            };
        }
        case SET_AVAILABLE_EMPLOYEEFORMS_FOR_USER: {
          return {
              ...state,
              employeeFormsForUser: action.payload
          };
        }
        case SET_AVAILABLE_DOCUMENTS: {
          return {
              ...state,
              allDocuments: action.payload
          };
        }
        case SET_AVAILABLE_DOCUMENTS_APPROVEMENT: {
          return {
              ...state,
              documentsApprovemnt: action.payload
          };
        }
        case UPDATE: {
            const index = state.employeeForms.map(d => d.id).indexOf(action.payload.id);
            const index2 = state.employeeFormsForUser.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selEmployeeForm = state.selected;
            if (selEmployeeForm && selEmployeeForm.id === action.payload.id) {
              selEmployeeForm = action.payload;
            }
            const updatedEmployeeForms = [
                  ...state.employeeForms.slice(0, index),
                  Object.assign({}, state.employeeForms[index], action.payload),
                  ...state.employeeForms.slice(index + 1)
                ];
            let updatedEmployeeFormsForUser = state.employeeFormsForUser;
            if (index2 >= 0) {
                updatedEmployeeFormsForUser = [
                  ...state.employeeFormsForUser.slice(0, index2),
                  Object.assign({}, state.employeeFormsForUser[index2], action.payload),
                  ...state.employeeFormsForUser.slice(index2 + 1)
                ];
            }
            return {
                ...state,
                employeeForms: updatedEmployeeForms,
                employeeFormsForUser: updatedEmployeeFormsForUser,
                selected: selEmployeeForm
            };
        }
        case UPDATE_DOCUMENT: {
          const index = state.documentsApprovemnt.map(d => d.id).indexOf(action.payload.id);
          if (index === -1) {
              return state;
          }
          const updatedDocuments = [
              ...state.documentsApprovemnt.slice(0, index),
              Object.assign({}, state.documentsApprovemnt[index], action.payload),
              ...state.documentsApprovemnt.slice(index + 1)
          ];
          return {
              ...state,
              documentsApprovemnt: updatedDocuments,
          };
      }
        case REMOVE: {
            return {
                ...state,
                employeeForms: state.employeeForms.filter(c => c.id !== action.payload),
                employeeFormsForUser: state.employeeFormsForUser.filter(c => c.id !== action.payload)
            };
        }
        default: {
            return state;
        }
    }
}

export const employeeForms = (state: State) => state.employeeForms;
export const employeeFormsForUser = (state: State) => state.employeeFormsForUser;
export const allDocuments = (state: State) => state.allDocuments;
export const documentsApprovemnt = (state: State) => state.documentsApprovemnt;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selected = (state: State) => state.selected;
