import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationData } from 'src/app/auth/user.model';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from '../../app.reducer';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DxButtonComponent } from 'devextreme-angular';
import { UIService } from 'src/app/shared/ui.service';
import { OrderService } from 'src/app/services/order/order.service';
import { Order, OrderImport, ReportConfig, ReportFrequency, SettlementKind } from 'src/app/redux/order/order.model';
import { YesNoModel, YesNoDialogComponent } from 'src/app/shared/yes-no-dialog/yes-no-dialog.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SurveyPattern } from 'src/app/redux/survey/survey.model';

type AOA = any[][];

@Component({
  selector: 'app-report-configuration',
  templateUrl: './report-configuration.component.html',
  styleUrls: ['./report-configuration.component.scss']
})

export class ReportConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() orderId: number;
  private _onDestroy = new Subject<void>();
  expanded = false;

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('editDxButton', { static: false }) editButton: DxButtonComponent;

  dataSource: ReportConfig[] = [];
  allSurveyPatterns: SurveyPattern[] = [];
  selectedOrder: Order;
  loading: boolean;
  reportKinds: any[] = [];
  reportFrequencies: any[] = [];
  responsiblePersons: any[] = [];
  weekDays: any[] = [];
  tempId: number = 0;
  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    private orderService: OrderService,
    private store: Store<fromRoot.IRootState>,
    private uiService: UIService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.store.select(fromRoot.getSurveyPatternsListForProject)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.allSurveyPatterns = res;
      });
    this.store.select(fromRoot.getReportConfigList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res != null) {
          this.dataSource = res;
        }
      });
    this.initCollections();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.blocked === true) {
      e.rowElement.style.backgroundColor = 'rgba(238, 186, 186, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  onToolbarPreparing(e) {
  }

  initReportKinds() {
    this.reportKinds.push({ id: 0, name: 'Raport cykliczny' });
    this.reportKinds.push({ id: 1, name: 'Promocje centralne' });
    this.reportKinds.push({ id: 2, name: 'Ankieta' });
    this.reportKinds.push({ id: 3, name: 'Karta pracy' });
    this.reportKinds.push({ id: 4, name: 'Raport sprzedaży' });
  }

  initReportFrequencies() {
    this.reportFrequencies.push({ id: 0, name: 'Tygodniowy' });
    this.reportFrequencies.push({ id: 1, name: 'Dwutygodniowy' });
    this.reportFrequencies.push({ id: 2, name: 'Miesięczny' });
    this.reportFrequencies.push({ id: 3, name: 'Grafik' });
    this.reportFrequencies.push({ id: 4, name: 'Jednokrotny' });
    this.reportFrequencies.push({ id: 5, name: 'Grafik KR' });
  }

  initResponsiblePersons() {
    this.responsiblePersons.push({ id: 0, name: 'Ankieter' });
    this.responsiblePersons.push({ id: 1, name: 'Merchandiser' });
    this.responsiblePersons.push({ id: 2, name: 'Koordynator lok.' });
    this.responsiblePersons.push({ id: 3, name: 'Koordynator' });
    this.responsiblePersons.push({ id: 4, name: 'Menedżer' });
    this.responsiblePersons.push({ id: 5, name: 'Przedstawiciel handl.' });
    this.responsiblePersons.push({ id: 6, name: 'Inny' });
  }

  initWeekDays() {
    this.weekDays.push({ id: 1, name: 'Poniedziałek' });
    this.weekDays.push({ id: 2, name: 'Wtorek' });
    this.weekDays.push({ id: 3, name: 'Środa' });
    this.weekDays.push({ id: 4, name: 'Czwartek' });
    this.weekDays.push({ id: 5, name: 'Piątek' });
    this.weekDays.push({ id: 6, name: 'Sobota' });
    this.weekDays.push({ id: 0, name: 'Niedziela' });
  }

  initCollections() {
    this.initReportKinds();
    this.initReportFrequencies();
    this.initResponsiblePersons();
    this.initWeekDays();
  }

  deleteSelectedRows() {
    const dialogTextData: YesNoModel = {
      titleKey: 'Usuwanie konfiguracji raportowania',
      contentKey: 'Czy chcesz usunąć wybrane pozycje z konfiguracji raportowania?',
      description: 'Ilość wybranych konfiguracji: ' + this.grid.selectedRowKeys.length
    };
    const ref = this.dialog.open(YesNoDialogComponent, {
      width: '25%',
      minWidth: '300px',
      data: dialogTextData,
      autoFocus: false
    });
    ref.afterClosed()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((dialogRes) => {
        if (dialogRes) {
          const rows = this.grid.selectedRowKeys;
          if (rows.length > 0) {
            this.orderService.deleteReportConfigs(rows);
          } else {
            this.uiService.openSnack('Nie wybrano konfiguracji do usunięcia.');
          }
          this.grid.selectedRowKeys = [];
        }
      });
  }

  onInitNewRow(e) {
    e.data.id = this.tempId - 1;
    this.tempId = e.data.id;
    if (this.orderId) {
      e.data.orderId = this.orderId;
    }
  }

  onRowRemoving(e) {
    if (e.data.id > 0) {
      this.deleteReportConfig(e.data.id);
    }
  }

  onRowUpdated(e) {
    this.updateReportConfig(e.data);
  }

  onRowInserting(e) {
    this.updateReportConfig(e.data);
  }

  updateReportConfig(item) {
    this.orderService.updateReportConfig(item, this.orderId);
  }

  deleteReportConfig(reportConfigId: number) {
    this.orderService.deleteReportConfig(reportConfigId, this.orderId);
  }

  onInitializedEventHandler(e) {
    setTimeout(() => {
      this.grid.instance.repaint();
    }, 0_500);
  }
}