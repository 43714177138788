import { Injectable } from '@angular/core';
import { NavigationState } from 'src/app/store/states/navigation.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NavigatedTo } from '../store/actions/navigation.action';

export enum Page {
  home = 'home',
  contact = 'contact',
  contact_user = 'contact/user',
  gallery = 'order-task-list/gallery',
  generation_plan = 'order-task-list/generation-plan',
  survey_result = 'order-task-list/survey-result',
  survey_designer = 'survey-pattern-list/survey-designer',
  survey_pattern = 'survey-pattern-list'
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  @Select(NavigationState.getNavigatedPage) navigatedPage$: Observable<Page>;

  constructor(private store: Store) { }

  navigatedTo(page: Page) {
    this.store.dispatch(new NavigatedTo(page));
  }
}
