import { Action } from '@ngrx/store';
import { SurveyPattern } from './survey.model';

export const SET_AVAILABLE_SURVEY_PATTERNS = '[SurveyPattern] Set available survey patterns';
export const SET_AVAILABLE_SURVEY_PATTERNS_FOR_PROJECT = '[SurveyPattern] Set available survey patterns for project';
export const SET_AVAILABLE_ORDER_TASKS_FOR_RESULTS = '[SurveyPattern] Set available order tasks for result';
export const START_EDITING = '[SurveyPattern] Start editing';
export const STOP_EDITING = '[SurveyPattern] Stop editing';
export const ADD = '[SurveyPattern] Add new survey pattern';
export const REMOVE = '[SurveyPattern] Remove survey pattern';
export const UPDATE = '[SurveyPattern] Update survey pattern';
export const SELECTED_SURVEY_PATTERN = '[SurveyPattern] Selected survey pattern';
export const START_LOADING = '[SurveyPattern] start loading';
export const STOP_LOADING = '[SurveyPattern] stop loading';

export class SetAvailableSurveyPatterns implements Action {
    readonly type = SET_AVAILABLE_SURVEY_PATTERNS;
    constructor(public payload: SurveyPattern[]) { }
}
export class SetAvailableSurveyPatternsForProject implements Action {
  readonly type = SET_AVAILABLE_SURVEY_PATTERNS_FOR_PROJECT;
  constructor(public payload: SurveyPattern[]) { }
}
export class SetAvailableOrderTasksForResults implements Action {
  readonly type = SET_AVAILABLE_ORDER_TASKS_FOR_RESULTS;
  constructor(public payload: number[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: SurveyPattern) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: SurveyPattern) { }
}
export class SetSelectedSurveyPattern implements Action {
    readonly type = SELECTED_SURVEY_PATTERN;
    constructor(public payload: SurveyPattern) { }
}
export type SurveyActions =
    SetAvailableSurveyPatterns
    | SetAvailableSurveyPatternsForProject
    | SetAvailableOrderTasksForResults
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | Remove
    | Update
    | SetSelectedSurveyPattern;
