import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as root from 'src/app/app.reducer';
import * as RoleActions from 'src/app/redux/role/role.actions';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from 'src/app/shared/ui.service';
import { IRole } from 'src/app/_models/role.model';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { User, UserListItem } from 'src/app/redux/user/user.model';
import { LoadOptions } from 'devextreme/data/load_options';

@Injectable()
export class RoleService {
    private _onDestroy = new Subject<void>();

    constructor(
        private store: Store<root.IRootState>,
        private http: HttpClient,
        private translate: TranslateService,
        private uiService: UIService,
    ) { }

    initService() {
        this.cancelSubscriptions();
        this._onDestroy = new Subject<void>();
    }

    cancelSubscriptions() {
        // console.log('[Role service] Cancel subscriptions');
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    roleStartEdit() {
        this.store.dispatch(new RoleActions.StartEditingRole());
    }

    roleStopEdit() {
        this.store.dispatch(new RoleActions.StopEditingRole());
    }

    roleUserStartEdit() {
        this.store.dispatch(new RoleActions.StartEditingRoleUser());
    }

    roleUserStopEdit() {
        this.store.dispatch(new RoleActions.StopEditingRoleUser());
    }

    delete(id: number) {
        console.log('[Role delete] Role delete');
        this.store.dispatch(new RoleActions.StartLoading());
        this.http.delete<IRole>(environment.apiUrl + '/auth/role/' + id)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (_) => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new RoleActions.StopLoading());
                }, (error) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'Błąd'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], 'Błąd', 10_000);
                        });
                    console.error(error);
                }
            );

    }

    roleUpdate(role: IRole) {
        console.log('[Role service] Role update');
        this.store.dispatch(new RoleActions.StartLoading());
        this.http.put<IRole>(environment.apiUrl + '/auth/role/' + role.id, role)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (updatedRole) => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });

                    // this.hubConnected
                    //     ? this.hubConnection.invoke('SendMessage', Hub.create('role_update', updatedRole))
                    //         .catch(() => this.store.dispatch(new CompanyActions.UpdateRole(updatedRole)))
                    //     : this.store.dispatch(new CompanyActions.UpdateRole(updatedRole));

                    this.store.dispatch(new RoleActions.StopLoading());
                }, (error) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );

    }

    roleCreate(role: { name: string }) {
        console.log('[Role service] Role create');
        this.store.dispatch(new RoleActions.StartLoading());
        this.http.post<IRole>(environment.apiUrl + '/auth/role', role)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (newRole) => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });

                    this.store.dispatch(new RoleActions.SetSelectedRole(newRole));
                    this.store.dispatch(new RoleActions.StopLoading());
                }, (error) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    roleSelect(role: IRole) {
        this.store.dispatch(new RoleActions.SetSelectedRole(role));
    }

    roleSelectUser(user: User) {
        this.store.dispatch(new RoleActions.SetSelectedRoleUser(user));
    }

    roleGetPermissions() {
        console.log('[Role service] Get permissions for role tab');
        this.store.dispatch(new RoleActions.SetAvailablePermissions([]));
        this.store.dispatch(new RoleActions.StartLoading());
        this.http.get<any[]>(environment.apiUrl + `/auth/permissionPerCompany`)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (perms) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.store.dispatch(new RoleActions.SetAvailablePermissions(perms));
                }, (error) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    roleUpdateUserRoles(userId, roles): Promise<User> {
        console.log('[Role service] Update user roles');
        this.store.dispatch(new RoleActions.StartLoading());

        return new Promise((r) => {
            this.http.put<User>(environment.apiUrl + '/applicationUser/role/' + userId, roles)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    (user) => {
                        this.store.dispatch(new RoleActions.StopLoading());
                        this.translate.get('COMMON.SAVE_SUCCESS')
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((text) => {
                                this.uiService.openSnack(text);
                            });
                        r(user);
                    }, (error) => {
                        this.store.dispatch(new RoleActions.StopLoading());
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                            });
                        console.error(error);
                    }
                );
        });


    }

    roleGetAllV2() {    
        console.log('[Role service] Get all roles v2');
        this.store.dispatch(new RoleActions.StartLoading());
        this.http.get<any>(environment.apiUrl + '/applicationUser/v2/roles')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.store.dispatch(new RoleActions.SetAvailableRoles({data : res, totalCount : res.length}));
                }, (error) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.store.dispatch(new RoleActions.SetAvailableRoles({data : [], totalCount : 0}));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    roleGetAll() {
        console.log('[Role service] Get all roles');
        this.store.dispatch(new RoleActions.StartLoading());
        this.http.get<IRole[]>(environment.apiUrl + '/auth/role')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (roles) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.store.dispatch(new RoleActions.SetAvailableRoles({data : roles, totalCount : roles.length}));

                }, (error) => {
                    this.store.dispatch(new RoleActions.StopLoading());
                    this.store.dispatch(new RoleActions.SetAvailableRoles({data : [], totalCount : 0}));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    copyRolesAndPermissions(userFrom: UserListItem, usersTo: UserListItem[]) {
      console.log('[Role service] Copy roles and permissions');

      this.store.dispatch(new RoleActions.StartLoading());

      return new Promise((r) => {
            this.http.put<User>(environment.apiUrl + '/applicationUser/role/copyFrom/' + userFrom.id, usersTo.map(({ id }) => id))
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    (user) => {
                        this.store.dispatch(new RoleActions.StopLoading());
                        this.uiService.openSnack('Kopiowanie uprawnień zakończone sukcesem');
                        r(user);
                    }, (error) => {
                        this.store.dispatch(new RoleActions.StopLoading());
                        this.translate.get(['Nieoczekiwany błąd', 'Błąd'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                            });
                        console.error(error);
                    }
                );
        });

    }
}
