import { Component, Output, Input, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-mat-filter',
    template: `
    <mat-form-field [ngClass]="!filterString ? null : 'filter-green-underline'" width-100>
        <button mat-button matPrefix mat-icon-button>
            <mat-icon>search</mat-icon>
        </button>
        <input autocomplete="off" matInput name="filterString" [(ngModel)]="filter" placeholder="{{ 'FILTER'   }}" (ngModelChange)="onSearchInputChange()"/>
        <button mat-button *ngIf="filterString" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon color-red>close</mat-icon>
        </button>
    </mat-form-field>
    `
})
export class MatFilterComponent {    
    filterString = '';
    @Output() filterChange = new EventEmitter();

    @Input()
    get filter() {
        return this.filterString;
    }

    set filter(value: string) {
        this.filterString = value;
    }

    private filterInputSubject = new Subject<string>();
    searchInput$: Observable<string> = this.filterInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      );
    
      constructor() {}

      ngOnInit() {
        this.filterInputSubject.pipe(debounceTime(300)).subscribe(query => {
          this.filterChange.emit(query);
        });
      }

      clearFilter() {
        this.filter='';
        this.onSearchInputChange();
      }
    
      onSearchInputChange() {
        this.filterInputSubject.next(this.filterString);
      }
}
