import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewChecked, Output, EventEmitter, Input } from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { sortingDataAccessor } from 'src/app/shared/sorting-data-accessor';
import { FormControl } from '@angular/forms';
import { PageData } from 'src/app/_models/page-data.model';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/services/group/group.service';
import { User, UserGroupMember, UserListItem } from 'src/app/redux/user/user.model';
import { Group } from 'src/app/redux/group/group.model';
import { MatDialog } from '@angular/material';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { LoadOptions } from 'devextreme/data/load_options';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user-search-bar',
  templateUrl: './user-search-bar.component.html',
  styleUrls: ['./user-search-bar.component.css']
})
export class UserSearchBarComponent implements OnInit, OnDestroy {
  @Input() isEditing: boolean = true;
  @Input() placeholderText: string = 'Wybierz użytkownika';

  private _onDestroy = new Subject<void>();
  userList: UserListItem[] = [];
  searchQuery: string = '';
  selectedUser: UserListItem = null;
  userSelectOpened = false;
  matSelectFocused = false;
  closedFromInside = false;

  @Output() userSelectionChange = new EventEmitter<UserListItem>();
  selectControl: FormControl = new FormControl();
  searchControl: FormControl = new FormControl();
  constructor(
    private store: Store<fromRoot.IRootState>,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(query => {
      // exception where time delay removes user list - multiple user searches in one window
      if (!this.closedFromInside) {
        this.matSelectFocused = true;
      }
      else
      {
        this.closedFromInside = false;
      }

      this.searchQuery = query;
      this.fetchUserList();
    });



    this.store.select(fromRoot.getSearchUsers)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(users => {
        this.updateUserList(users);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  fetchUserList(): void {
    this.userService.searchUsers(this.searchQuery);
  }

  updateUserList(users: UserListItem[]): void {
    if (this.matSelectFocused) {
      this.userList = users ? users : [];
      if (this.userList.length === 0) {
        this.clear();
      }
    }
  }

  onFocus() {
    this.matSelectFocused = true;
  }

  onClosed() {
    this.closedFromInside = true;
    this.matSelectFocused = false;
    this.searchControl.setValue('');
  }

  clear(): void {
    this.selectedUser = null;
    this.userSelectionChange.emit(null);
  }

  onUserSelectChange(): void {
    this.userSelectionChange.emit(this.selectedUser);
    this.matSelectFocused = false;
  }
}
