import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as OrderActions from 'src/app/redux/order/order.actions';
import * as ParamsAndToolsActions from 'src/app/redux/params-and-tools/params-and-tools.actions';
import * as ExcelExportActions from 'src/app/redux/excel-export/excel-export.actions';
import { environment } from 'src/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { Order, OrderParameters, OrderScheduleHistory, ReportConfig } from 'src/app/redux/order/order.model';
import { takeUntil } from 'rxjs/operators';
import { LoadOptions } from 'devextreme/data/load_options';

@Injectable()
export class OrderService {
  private _onDestroy = new Subject<void>();

  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.unSelectOrder();
  }

  stopEditing() {
    this.store.dispatch(new OrderActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new OrderActions.StartEditing());
  }

  selectOrder(order: Order) {
    this.store.dispatch(new OrderActions.SetSelectedOrder(order));
  }

  unSelectOrder() {
    this.store.dispatch(new OrderActions.SetSelectedOrder(null));
    this.store.dispatch(new OrderActions.SetReportConfigList(null));
  }

  selectOrderSchedule(schedule: OrderScheduleHistory) {
    this.store.dispatch(new OrderActions.SetSelectedOrderSchedule(schedule));
  }

  unSelectOrderSchedule() {
    this.store.dispatch(new OrderActions.SetSelectedOrderSchedule(null));
  }

  unsetMessageFromServer() {
    this.store.dispatch(new OrderActions.SetMessage(null));
  }

  getOrder(id: number) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.get<Order>(environment.apiUrl + '/order/' + id)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.selectOrder(response);
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }

  getUserOrders() {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.get<Order[]>(environment.apiUrl + '/order/currentUser/')
      .toPromise()
      .then(
        (orders) => {
          this.store.dispatch(new OrderActions.SetAvailableOrdersForUser(orders));
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.SetAvailableOrdersForUser(null));
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  createOrderV2(model) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.post<Order>(environment.apiUrl + '/order/v2', model)
      .toPromise()
      .then(
        (order) => {
          this.store.dispatch(new OrderActions.SetSelectedOrder(order));
          this.store.dispatch(new OrderActions.StopLoading());
          this.store.dispatch(new OrderActions.SetMessage("Zakończono pomyślnie"));

        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new OrderActions.StopLoading());
        this.unsetMessageFromServer();
        console.error(err);
      });
  }

  reloadDataGrid() {
    this.store.dispatch(new OrderActions.SetReloadDataGrid(true));
  }

  unsetReloadDataGrid() {
    this.store.dispatch(new OrderActions.SetReloadDataGrid(false));
  }

  uploadOrdersFromFile(file, projectKind: number) {
    var formData = new FormData();
    formData.append("file", file);
    this.store.dispatch(new ParamsAndToolsActions.StartProjectKindLoading());
    this.http.post(environment.apiUrl + '/order/v2/import-from-file?projectKindId=' + projectKind, formData).toPromise()
      .then((result: any) => {
        this.store.dispatch(new ParamsAndToolsActions.StopProjectKindLoading());
        this.store.dispatch(new ExcelExportActions.SetMessage('Poprawnie dodano ' + result.length + ' zleceń'));
      })
      .catch(err => {
        this.store.dispatch(new ParamsAndToolsActions.StopProjectKindLoading());
        this.store.dispatch(new ExcelExportActions.SetMessage('Błąd: ' + err.error.Message));
        console.log(err);
        return null;
      });
  }

  updateOrderV2(model, fromList: boolean = false) {
    var apiUrl = environment.apiUrl + (fromList ? '/order/v2/list' : '/order/v2');
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.put<Order>(apiUrl, model)
      .toPromise()
      .then(
        (updatedModel) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.selectOrder(updatedModel);
          this.store.dispatch(new OrderActions.StopLoading());
          if (fromList) {
            this.reloadDataGrid();
          }
        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          if (err.error.Message.includes('uzupełnij ponownie pola')) {
            this.store.dispatch(new OrderActions.SetMessage('Błąd: ' + err.error.Message));
            this.uiService.openSnack('Błąd', null, 3000);
          }
          else {
            this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
          }
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new OrderActions.StopLoading());
        this.unsetReloadDataGrid();
        console.error(err);
      });
  }

  updateSelectedOrdersV2(orderParameters: OrderParameters) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.put(environment.apiUrl + '/order/v2/updateselected', orderParameters)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          if (err.error.Message.includes('uzupełnij ponownie pola')) {
            this.store.dispatch(new OrderActions.SetMessage('Błąd: ' + err.error.Message));
            this.uiService.openSnack('Błąd', null, 3000);
          }
          else {
            this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
          }
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  setManagerAcceptV2(orderIds: number[]) {
    this.http.put(environment.apiUrl + '/order/v2/manageraccept', orderIds)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  updateServicesViaSelectedOrdersV2(orderIds: number[]) {
    this.http.put<any>(environment.apiUrl + '/service/v2/updateviaorders', orderIds)
      .toPromise()
      .then(
        (res) => {
          if (res && res.messageCode) {
            this.uiService.openSnack(res.messageCode);
          } else {
            this.uiService.openSnack('Zakończono pomyślnie');
          }
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  deleteOrdersV2(ordersIds: number[]) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.put(environment.apiUrl + '/order/v2/delete', ordersIds)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  deleteReportConfigs(repConfigIds: number[]) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.put(environment.apiUrl + '/order/delete/reportConfig', repConfigIds)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  downloadAttachment(attachment, fileName) {
    this.http.get(environment.apiUrl + '/order/attachment/' + attachment.id, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: attachment.type });
        importedSaveAs(blob, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  removeAttachment(attachmentId: number) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.delete(environment.apiUrl + '/order/attachment/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  loadDataV2(loadOptions: any, current: boolean, projectKindId: number, projectId: number, marketId: number) {
    const params = this.getParams(loadOptions, projectId, marketId);
    return this.http.get<any>(environment.apiUrl + '/order/v2/serverside/' + current + '/projectKind/' + projectKindId, { params })
      .toPromise();
  }

  getReportConfig(orderId) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.get<ReportConfig[]>(environment.apiUrl + '/order/reportconfig/' + orderId)
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new OrderActions.SetReportConfigList(res));
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.SetReportConfigList(null));
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  updateReportConfig(item, orderId) {
    this.store.dispatch(new OrderActions.StartLoading());
    item.startDateLong = item.startDate !== undefined ? new Date(item.startDate).getTime() : 0;
    item.finishDateLong = item.finishDate !== undefined ? new Date(item.finishDate).getTime() : 0;
    item.surveyDateLong = item.surveyDate != undefined ? new Date(item.surveyDate).getTime() : 0;

    this.http.put<ReportConfig>(environment.apiUrl + '/order/reportconfig', item)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new OrderActions.StopLoading());
          this.getReportConfig(orderId);
          this.uiService.openSnack('Zakończono pomyślnie');
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  deleteReportConfig(reportConfigId: number, orderId) {
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.delete(environment.apiUrl + '/order/reportconfig/' + reportConfigId)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new OrderActions.StopLoading());
          this.getReportConfig(orderId);
          this.uiService.openSnack('Zakończono pomyślnie');
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });
  }

  getParams(loadOptions: LoadOptions, projectId: number, marketId: number) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
      'projectId',
      'marketId'
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    params = params.set('projectId', projectId.toString());
    params = params.set('marketId', marketId.toString());

    return params;
  }
}
