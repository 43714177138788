import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { LoadOptions } from 'devextreme/data/load_options';
import { ServiceSummaryDxTable } from 'src/app/redux/service-dashboard/service-dashboard.model';

@Injectable()
export class ServiceDashboardService {
  private _onDestroy = new Subject<void>();
  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  getDataSourceDefaultValue(): Promise<any> {
    const defaultValue: ServiceSummaryDxTable = {
      data: [],
      totalCount: 0,
      groupCount: 0,
      summary: [],
    };
    return Promise.resolve(defaultValue);
  }

  loadServiceSummaryData(loadOptions: any, startDate: Date, endDate: Date, summaryKind: string): Promise<any> {
    if (!startDate || !endDate) {
      return this.getDataSourceDefaultValue();
    }

    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime)) {

      return this.getDataSourceDefaultValue();
    }

    const params = this.getParams(loadOptions);
    const apiLink = environment.apiUrl + '/serviceDashboard/summary/' + startTime + '/' + endTime + '/' + summaryKind;
    return this.http.get<any>(apiLink, {params})
      .toPromise();
  }

  getParams(loadOptions: LoadOptions) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }


    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    return params;
  }
}
