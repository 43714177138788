import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { EmployeeRegistrationAccountDataDto, CoordinatorsDataDto, ProjectsDataDto, MarketsDataDto, ProjectKindsDataDto } from 'src/app/redux/employee-records/employee-registration.model';
import * as EmplyeeRecordsActions from 'src/app/redux/employee-records/emplyee-records.actions';

import { catchError, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class EmployeeRecordsService {
  private _onDestroy = new Subject<void>();
  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  getCoordinators() {
    this.store.dispatch(new EmplyeeRecordsActions.StartLoading());
    this.http.get<CoordinatorsDataDto[]>(environment.apiUrl + '/employee-registration/v1/get-cordinators')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (res) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.store.dispatch(new EmplyeeRecordsActions.SetCoordinatorsData(res));

        }, (error) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.store.dispatch(new EmplyeeRecordsActions.SetCoordinatorsData(null));
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
        }
      );
  }

  getCoordinatorsV2(): Observable<CoordinatorsDataDto[]> {
    this.store.dispatch(new EmplyeeRecordsActions.StartLoading());

    return this.http
      .get<CoordinatorsDataDto[]>(environment.apiUrl + '/employee-registration/v1/get-cordinators')
      .pipe(
        tap(() => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
        }),
        catchError((error) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          return of([]);
        })
      );
  }

  getProjectKinds() {
    this.store.dispatch(new EmplyeeRecordsActions.StartLoading());
    this.http.get<ProjectsDataDto[]>(environment.apiUrl + '/employee-registration/v1/get-project-kinds')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (res) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.store.dispatch(new EmplyeeRecordsActions.SetProjectsData(res));

        }, (error) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.store.dispatch(new EmplyeeRecordsActions.SetProjectsData(null));
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
        }
      );
  }

  getProjectKindsV2(): Observable<ProjectKindsDataDto[]> {
    this.store.dispatch(new EmplyeeRecordsActions.StartLoading());

    return this.http
      .get<ProjectKindsDataDto[]>(`${environment.apiUrl}/employee-registration/v1/get-project-kinds`)
      .pipe(
        tap(() => this.store.dispatch(new EmplyeeRecordsActions.StopLoading())),
        catchError((error) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.uiService.openSnack('Błąd podczas pobierania usług', 'Błąd', 10000);
          console.error(error);
          return of([]); 
        })
      );
  }

  getMarkets() {
    this.store.dispatch(new EmplyeeRecordsActions.StartLoading());
    this.http.get<MarketsDataDto[]>(environment.apiUrl + '/employee-registration/v1/get-markets')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (res) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.store.dispatch(new EmplyeeRecordsActions.SetMarketsData(res));

        }, (error) => {
          this.store.dispatch(new EmplyeeRecordsActions.StopLoading());
          this.store.dispatch(new EmplyeeRecordsActions.SetMarketsData(null));
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
        }
      );
  }

  getMarketsV2(): Observable<MarketsDataDto[]> {
    return this.http.get<MarketsDataDto[]>(environment.apiUrl + '/employee-registration/v1/get-markets')
      .pipe(
        catchError((error) => {
          console.error('Błąd podczas pobierania sieci:', error);
          return of([]);
        })
      );
  }

  getProjectsByProjectKindId(projectKindId: number): Observable<ProjectsDataDto[]> {
    return this.http.get<ProjectsDataDto[]>(
      `${environment.apiUrl}/employee-registration/v1/get-projects-by-project-kind-id/${projectKindId}`
    ).pipe(
      catchError((error) => {
        console.error('Błąd podczas pobierania projektów:', error);
        return of([]);
      })
    );
  }  

  postEmployeeRegistrationAccountFormData(formData: FormData): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/employee-registration/v1/create-account`,
      formData
    ).pipe(
      tap(() => {
        this.uiService.openSnack('Poprawnie dodano konto dla pracownika');
      }),
      catchError((error) => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}

