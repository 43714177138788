import { EmployeeRecordsActions, SET_EMPLOYEE_RECORDS, SET_SELECTED_COORDINATORS, SET_SELECTED_PROJECTS,SET_SELECTED_MARKETS, SET_SELECTED_PROJECT_KINDS,EMPLOYEE_REGISTRATION_SUCCESS,EMPLOYEE_REGISTRATION_FAILURE,START_LOADING, STOP_LOADING } from "./emplyee-records.actions";
import { EmployeeRegistrationAccountDataDto, CoordinatorsDataDto,ProjectsDataDto,MarketsDataDto, ProjectKindsDataDto  } from "./employee-registration.model";

export interface State {
    setEmpolyeeRecords: EmployeeRegistrationAccountDataDto;
    selectedCoordinators : CoordinatorsDataDto;
    selectedProjects: ProjectsDataDto;
    selectedMarkets : MarketsDataDto;   
    selectedProjectKinds : ProjectKindsDataDto; 
    registrationSuccess : any;
    registrationFailure: any;
    isLoading: boolean;
}

const initialState: State = {
    setEmpolyeeRecords: null,
    selectedCoordinators: null,
    selectedProjects: null,
    selectedMarkets: null,
    selectedProjectKinds: null,
    registrationSuccess:null,
    registrationFailure:null,
    isLoading: false,
}
export function employeeRecordsReducer(state = initialState, action: EmployeeRecordsActions) {
    switch (action.type) {
        case SET_EMPLOYEE_RECORDS: {
            return {
                ...state,
                setEmpolyeeRecords: action.payload
            };
        }
        case SET_SELECTED_COORDINATORS: {
            return {
                ...state,
                selectedCoordinators: action.payload
            };
        }
        case SET_SELECTED_PROJECTS: {
            return {
                ...state,
                selectedProjects: action.payload
            };
        }
        case SET_SELECTED_MARKETS: {
            return {
                ...state,
                selectedMarkets: action.payload
            };
        }
        case SET_SELECTED_PROJECT_KINDS: {
            return {
                ...state,
                selectedProjectKinds: action.payload
            };
        }
        case EMPLOYEE_REGISTRATION_SUCCESS: {
            return {
                ...state,
                registrationSuccess: action.payload
            };
        }
        case EMPLOYEE_REGISTRATION_FAILURE: {
            return {
                ...state,
                registrationFailure: action.error
            };
        }
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}

export const empolyeeRecords = (state: State) => state.setEmpolyeeRecords;
export const selectedCoordinators = (state: State) => state.selectedCoordinators;
export const selectedProjects = (state: State) => state.selectedProjects;
export const selectedMarkets = (state: State) => state.selectedMarkets;

export const isLoading = (state: State) => state.isLoading;


export const registrationSuccess = (state: State) => state.registrationSuccess;
export const registrationFailure = (state: State) => state.registrationFailure;