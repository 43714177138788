import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetHierarchizedIds } from '../actions/hierarchy.actions';

export class HierarchyStateModel {
    hierarchizedIds: string[];
    hierarchizedDeputyIds: string[];
    userIdsWhereIamIsDeputy: string[];
}

@State<HierarchyStateModel>({
    name: 'hierarchy',
    defaults: {
        hierarchizedIds: [],
        hierarchizedDeputyIds: [],
        userIdsWhereIamIsDeputy: []
    }
})
export class HierarchyState {
    @Selector() static hierarchizedIds(state: HierarchyStateModel) {
        return state.hierarchizedIds;
    }
    @Selector() static hierarchizedDeputyIds(state: HierarchyStateModel) {
        return state.hierarchizedDeputyIds;
    }
    @Selector() static userIdsWhereIamIsDeputy(state: HierarchyStateModel) {
        return state.userIdsWhereIamIsDeputy;
    }
    @Action(SetHierarchizedIds)
    setCurrentWorkTimeDateRange({ patchState }: StateContext<HierarchyStateModel>, { payload }: SetHierarchizedIds) {
        patchState({
            hierarchizedIds: payload.hierarchizedIds,
            hierarchizedDeputyIds: payload.hierarchizedDeputyIds,
            userIdsWhereIamIsDeputy: payload.userIdsWhereIamDeputy
        });
    }
}
