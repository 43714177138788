import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  date = new Date();

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  changeLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

}
