import * as mobileActions from './screen-state.actions';
import { ScreenState } from './screen-state';

const initialState: ScreenState = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
};

export function screenReducer(state: ScreenState = initialState, action: mobileActions.Actions): ScreenState {
    switch (action.type) {
        case mobileActions.ActionTypes.SET_SCREEN:
            return {
                ...action.payload
            };
        default:
            return state;
    }
}

export const isMobile = (state: ScreenState) => state.isMobile;
export const isTablet = (state: ScreenState) => state.isTablet;
export const isDesktop = (state: ScreenState) => state.isDesktop;
