import { Action } from '@ngrx/store';
import { OrderTask, TaskPhoto, ProductBase, TasksGallery, OosChart, WorkTimeChart, SurveyData } from './order-task.model';

export const SET_AVAILABLE_TASKS = '[Task] Set available tasks';
export const SET_MY_TASKS = '[Task] Set my tasks';
export const SET_AVAILABLE_TASK_PHOTOS = '[Task] Set available task photos';
export const SET_AVAILABLE_TASKS_GALLERY = '[Task] Set available tasks gallery';
export const SET_AVAILABLE_TASK_OOS_PRODUCTS = '[Task] Set available oos products';
export const SET_CHART_OOS_PRODUCTS_BY_GROUP = '[Task] Set chart oos products by group';
export const SET_CHART_OOS_PRODUCTS_BY_LOCALIZATION = '[Task] Set chart oos products by localization';
export const SET_CHART_OOS_PRODUCTS_BY_SALESREPRESENTATIVE = '[Task] Set chart oos products by salesrepresentative';
export const SET_CHART_WORKTIME_BY_MARKET = '[Task] Set chart worktime by market';
export const START_EDITING = '[Task] Start editing';
export const STOP_EDITING = '[Task] Stop editing';
export const ADD = '[Task] Add new task';
export const ADD_RANGE = '[Task] Add new tasks';
export const ADD_MY_TASK = '[Task] Add new my task';
export const ADD_PHOTO = '[Task] Add new task photo';
export const REMOVE = '[Task] Remove task';
export const REMOVE_MY_TASK = '[Task] Remove my task';
export const REMOVE_PHOTO = '[Task] Remove task photo';
export const UPDATE = '[Task] Update task';
export const UPDATE_MY_TASK = '[Task] Update my task';
export const SELECTED_TASK = '[Task] Selected task';
export const START_LOADING = '[Task] start loading';
export const STOP_LOADING = '[Task] stop loading';
export const START_GALLERY_LOADING = '[Task] start gallery loading';
export const STOP_GALLERY_LOADING = '[Task] stop gallery loading';
export const SET_SURVEY_DATA = '[Task] set survey data';
export const SET_ERROR = '[Task] set error';
export const SET_TEMP_CONTAINER_REFRESHED = '[Task] set container refreshed';
export const SET_RELOAD_DATA = '[Task] set reload data';

export class SetAvailableTasks implements Action {
  readonly type = SET_AVAILABLE_TASKS;
  constructor(public payload: OrderTask[]) { }
}
export class SetMyTasks implements Action {
  readonly type = SET_MY_TASKS;
  constructor(public payload: OrderTask[]) { }
}
export class SetAvailableTaskPhotos implements Action {
  readonly type = SET_AVAILABLE_TASK_PHOTOS;
  constructor(public payload: TaskPhoto[]) { }
}
export class SetAvailableTasksGallery implements Action {
  readonly type = SET_AVAILABLE_TASKS_GALLERY;
  constructor(public payload: TasksGallery[]) { }
}
export class SetAvailableTaskProducts implements Action {
  readonly type = SET_AVAILABLE_TASK_OOS_PRODUCTS;
  constructor(public payload: ProductBase[]) { }
}
export class SetChartOosProductsByGroup implements Action {
  readonly type = SET_CHART_OOS_PRODUCTS_BY_GROUP;
  constructor(public payload: OosChart[]) { }
}
export class SetChartOosProductsByLocalization implements Action {
  readonly type = SET_CHART_OOS_PRODUCTS_BY_LOCALIZATION;
  constructor(public payload: OosChart[]) { }
}
export class SetChartOosProductsBySalesRepresentative implements Action {
  readonly type = SET_CHART_OOS_PRODUCTS_BY_SALESREPRESENTATIVE;
  constructor(public payload: OosChart[]) { }
}
export class SetChartWorkTimeByMarket implements Action {
  readonly type = SET_CHART_WORKTIME_BY_MARKET;
  constructor(public payload: WorkTimeChart[]) { }
}
export class StartLoading implements Action {
  readonly type = START_LOADING;
}
export class StopLoading implements Action {
  readonly type = STOP_LOADING;
}
export class StartGalleryLoading implements Action {
  readonly type = START_GALLERY_LOADING;
}
export class StopGalleryLoading implements Action {
  readonly type = STOP_GALLERY_LOADING;
}
export class StartEditing implements Action {
  readonly type = START_EDITING;
}
export class StopEditing implements Action {
  readonly type = STOP_EDITING;
}
export class Add implements Action {
  readonly type = ADD;
  constructor(public payload: OrderTask) { }
}
export class AddRange implements Action {
  readonly type = ADD_RANGE;
  constructor(public payload: OrderTask[]) { }
}
export class AddMyTask implements Action {
  readonly type = ADD_MY_TASK;
  constructor(public payload: OrderTask) { }
}
export class AddPhoto implements Action {
  readonly type = ADD_PHOTO;
  constructor(public payload: TaskPhoto) { }
}
export class Remove implements Action {
  readonly type = REMOVE;
  constructor(public payload: number) { }
}
export class RemoveMyTask implements Action {
  readonly type = REMOVE_MY_TASK;
  constructor(public payload: number) { }
}
export class RemovePhoto implements Action {
  readonly type = REMOVE_PHOTO;
  constructor(public payload: number) { }
}
export class Update implements Action {
  readonly type = UPDATE;
  constructor(public payload: OrderTask) { }
}
export class UpdateMyTask implements Action {
  readonly type = UPDATE_MY_TASK;
  constructor(public payload: OrderTask) { }
}
export class SetSelectedTask implements Action {
  readonly type = SELECTED_TASK;
  constructor(public payload: OrderTask) { }
}
export class SetSurveyData implements Action {
  readonly type = SET_SURVEY_DATA;
  constructor(public payload: SurveyData) { }
}
export class SetError implements Action {
  readonly type = SET_ERROR;
  constructor(public payload: boolean) { }
}
export class SetTempContainerRefreshed implements Action {
  readonly type = SET_TEMP_CONTAINER_REFRESHED;
  constructor(public payload: boolean) { }
}
export class SetReloadData implements Action {
  readonly type = SET_RELOAD_DATA;
  constructor(public payload: boolean) { }
}

export type TaskActions =
  SetAvailableTasks
  | SetMyTasks
  | SetAvailableTaskPhotos
  | SetAvailableTasksGallery
  | SetAvailableTaskProducts
  | SetChartOosProductsByGroup
  | SetChartOosProductsByLocalization
  | SetChartOosProductsBySalesRepresentative
  | SetChartWorkTimeByMarket
  | StartLoading
  | StopLoading
  | StartGalleryLoading
  | StopGalleryLoading
  | StartEditing
  | StopEditing
  | Add
  | AddRange
  | AddMyTask
  | AddPhoto
  | Remove
  | RemoveMyTask
  | RemovePhoto
  | Update
  | UpdateMyTask
  | SetSelectedTask
  | SetSurveyData
  | SetError
  | SetTempContainerRefreshed
  | SetReloadData;
