import { Action } from '@ngrx/store';
import { Order, OrderScheduleHistory, ReportConfig } from './order.model';

export const SET_AVAILABLE_ORDERS = '[Order] Set available orders';
export const SET_USER_ORDERS = '[Order] Set user orders';
export const START_EDITING = '[Order] Start editing';
export const STOP_EDITING = '[Order] Stop editing';
export const ADD = '[Order] Add new order';
export const REMOVE = '[Order] Remove order';
export const UPDATE = '[Order] Update order';
export const SELECTED_ORDER = '[Order] Selected order';
export const SELECTED_ORDER_SCHEDULE = '[Order] Selected order schedule';
export const SET_REPORT_CONFIG = '[Order] Selected report config';
export const START_LOADING = '[Order] start loading';
export const STOP_LOADING = '[Order] stop loading';
export const SET_MESSAGE = '[Order] set message';
export const SET_RELOAD_DATA_GRID = '[Order] set reload data grid';

export class SetAvailableOrders implements Action {
    readonly type = SET_AVAILABLE_ORDERS;
    constructor(public payload: Order[]) { }
}
export class SetAvailableOrdersForUser implements Action {
    readonly type = SET_USER_ORDERS;
    constructor(public payload: Order[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Order) { }
}

export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Order) { }
}
export class SetSelectedOrder implements Action {
    readonly type = SELECTED_ORDER;
    constructor(public payload: Order) { }
}
export class SetSelectedOrderSchedule implements Action {
    readonly type = SELECTED_ORDER_SCHEDULE;
    constructor(public payload: OrderScheduleHistory) { }
}

export class SetReportConfigList implements Action {
    readonly type = SET_REPORT_CONFIG;
    constructor(public payload: ReportConfig[]) { }
}

export class SetMessage implements Action {
    readonly type = SET_MESSAGE;
    constructor(public payload: string) { }
}

export class SetReloadDataGrid implements Action {
    readonly type = SET_RELOAD_DATA_GRID;
    constructor(public payload: boolean) { }
}

export type OrderActions =
    SetAvailableOrders
    | SetAvailableOrdersForUser
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | Remove
    | Update
    | SetSelectedOrder
    | SetSelectedOrderSchedule
    | SetReportConfigList
    | SetMessage
    | SetReloadDataGrid;
