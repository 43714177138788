import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as ContractorActions from 'src/app/redux/contractor/contractor.actions';
import { Select } from '@ngxs/store';
import { Contractor } from 'src/app/redux/contractor/contractor.model';
import { environment } from 'src/environments/environment';
import { ContractorProjectKind } from 'src/app/redux/contractor/contractor-project-kind.model';

@Injectable()
export class ContractorService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();

      this.unSelectContractor();
  }

  stopEditing() {
    this.store.dispatch(new ContractorActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new ContractorActions.StartEditing());
  }

  selectContractor(contractor: Contractor) {
    this.store.dispatch(new ContractorActions.SetSelectedContractor(contractor));
  }

  unSelectContractor() {
    this.store.dispatch(new ContractorActions.SetSelectedContractor(null));
  }

  setProjectKindsToContractor(projectKinds: ContractorProjectKind[]) {
    this.store.dispatch(new ContractorActions.SetAvailableContractorProjectKinds(projectKinds));
  }

  addProjectKindToContractor(contractorProjectKind: ContractorProjectKind) {
    this.store.dispatch(new ContractorActions.AddContractorProjectKind(contractorProjectKind));
  }

  createProjectKindContractor(contractorId: number, projectKindContractor: ContractorProjectKind[]) {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.post(environment.apiUrl + '/contractor/contractorProjectKind/' + contractorId, projectKindContractor)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }

  removeProjectKindContractor(contractorId: number, projectKindId: number) {
    console.log('[contractor service] remove contractor project kind');
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.delete<ContractorProjectKind>(environment.apiUrl + '/contractor/contractorProjectKind/' +
    contractorId + '/' + projectKindId)
        .toPromise()
        .then(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new ContractorActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new ContractorActions.StopLoading());
                console.log(error);
            }
        );
  }

  getContractors() {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.get<Contractor[]>(environment.apiUrl + '/contractor')
      .toPromise()
      .then(
        (contractors) => {
          this.store.dispatch(new ContractorActions.SetAvailableContractors(contractors));
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.SetAvailableContractors(null));
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }

  getContractorProjectKinds(contractorId: number) {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.get<ContractorProjectKind[]>(environment.apiUrl + '/contractor/contractorProjectKind/' + contractorId)
      .toPromise()
      .then(
        (contractorProjectinds) => {
          this.store.dispatch(new ContractorActions.SetAvailableContractorProjectKinds(contractorProjectinds));
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.SetAvailableContractorProjectKinds(null));
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }

  blockContractors(contractors: Contractor[]) {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.put<Contractor[]>(environment.apiUrl + '/contractor/block', contractors)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }

  createContractor(contractor: Contractor, contractorProjectKind: ContractorProjectKind[]) {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.post<Contractor>(environment.apiUrl + '/contractor', contractor)
      .toPromise()
      .then(
        (model) => {
          this.http.post(environment.apiUrl + '/contractor/contractorProjectKind/' + model.id, contractorProjectKind)
            .toPromise()
            .then(
            () => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new ContractorActions.StopLoading());
            });
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }

  updateContractor(contractor: Contractor, contractorProjectKind: ContractorProjectKind[]) {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.put<Contractor>(environment.apiUrl + '/contractor', contractor)
      .toPromise()
      .then(
        () => {
          this.http.post(environment.apiUrl + '/contractor/contractorProjectKind/' + contractor.id, contractorProjectKind)
            .toPromise()
            .then(
            () => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new ContractorActions.StopLoading());
            });
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }

  deleteContractor(id: number) {
    this.store.dispatch(new ContractorActions.StartLoading());
    this.http.delete(environment.apiUrl + '/contractor/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ContractorActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ContractorActions.StopLoading());
        console.error(err);
    });
  }
}
