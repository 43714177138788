import { Action } from '@ngrx/store';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { IAuthData } from './auth-data.model';
import { PasswordConfig } from '../_models/password-config.model';
import { ContactModuleModel } from './modules/contact-module.model';
import { HrComponent } from '../_models/hrComponent.model';
import { Company } from '../redux/company/company.model';

export const SET_CONTACT_MODULE = '[Contact-module] set contact module';
export const CONTACT_MODULE_HRCOMPONENT_ADDED = '[Contact-module] CONTACT_MODULE_HRCOMPONENT_ADDED';
export const CONTACT_MODULE_HRCOMPONENT_REMOVED = '[Contact-module] CONTACT_MODULE_HRCOMPONENT_REMOVED';

export const SET_AUTHENTICATED = '[Auth] Set Authenticated';
export const SET_UNAUTHENTICATED = '[Auth] Set Unauthenticated';
export const SET_AVAILABLE_ROLES = '[Auth] Set available roles';
export const SET_CURRENT_USER = '[Auth] Set current user';
export const SET_CURRENT_COMPANY = '[Auth] Set current company';
export const SET_AUTH_DATA = '[Auth] Set auth data';
export const SET_PASSWORD_CONFIG = '[Auth] Set password config';
export const SET_AVATAR_USER = '[Auth] SET_AVATAR_USER';

export const IMPORT_START_GETTING_DATA_FROM_SYMFONIA = '[Import] ImportStartGettingTheDataFromTheSymfonia';
export const IMPORT_STOP_GETTING_DATA_FROM_SYMFONIA = '[Import] IMPORT_STOP_GETTING_DATA_FROM_SYMFONIA';
export const IMPORT_STOP = '[Import] IMPORT_STOP';
export const IMPORT_START_USERS = '[Import] IMPORT_START_USERS';
export const IMPORT_START_ERP = '[Import] IMPORT_START_ERP';
export const IMPORT_START_BUILD_STRUCTURE = '[Import] IMPORT_START_BUILD_STRUCTURE';
export const IMPORT_START_GROUPS = '[Import] IMPORT_START_GROUPS';
export const SET_IMPORT_PROGRESS = '[Import] Set import progress';
export const SET_IMPORT_LOGS = '[Import] Set import logs';
export const ADD_IMPORT_LOG = '[Import] Add import log';
export const ADD_IMPORT_LOGS = '[Import] Add import logs';

export const AUTH_LOGOUT = 'LOGOUT';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';

export const SET_REDIRECT_URL = '[Auth] Set Redirect Url';
export const SET_REDIRECT_PARAMETERS = '[Auth] Set Redirect Parameters';

export class ContactModuleAddHrComponent implements Action {
    readonly type = CONTACT_MODULE_HRCOMPONENT_ADDED;
    constructor(public payload: HrComponent) { }
}
export class SetUserAvatar implements Action {
    readonly type = SET_AVATAR_USER;
    constructor(public payload: { id: string, avatar: string }) { }
}
export class ContactModuleRemoveHrComponent implements Action {
    readonly type = CONTACT_MODULE_HRCOMPONENT_REMOVED;
    constructor(public payload: HrComponent) { }
}
export class SetContactModule implements Action {
    readonly type = SET_CONTACT_MODULE;
    constructor(public payload: ContactModuleModel) { }
}
export class SetAuthenticated implements Action {
    readonly type = SET_AUTHENTICATED;
}
export class SetUnauthenticated implements Action {
    readonly type = SET_UNAUTHENTICATED;
}
export class SetAvailableRoles implements Action {
    readonly type = SET_AVAILABLE_ROLES;

    constructor(public payload: string[]) { }
}
export class SetCurrentUser implements Action {
    readonly type = SET_CURRENT_USER;

    constructor(public payload: ApplicationUser) { }
}
export class SetCurrentCompany implements Action {
    readonly type = SET_CURRENT_COMPANY;

    constructor(public payload: Company) { }
}
export class AuthLogout implements Action {
    readonly type = AUTH_LOGOUT;
}
export class AuthChangeCompany implements Action {
    readonly type = CHANGE_COMPANY;
}
export class SetAuthData implements Action {
    readonly type = SET_AUTH_DATA;
    constructor(public payload: IAuthData) { }
}
export class SetPasswordConfig implements Action {
    readonly type = SET_PASSWORD_CONFIG;
    constructor(public payload: PasswordConfig) { }
}
export class SetImportProgress implements Action {
    readonly type = SET_IMPORT_PROGRESS;
    constructor(public payload: number) { }
}
export class ImportStartGettingTheDataFromTheSymfonia implements Action {
    readonly type = IMPORT_START_GETTING_DATA_FROM_SYMFONIA;
}
export class ImportStopGettingTheDataFromTheSymfonia implements Action {
    readonly type = IMPORT_STOP_GETTING_DATA_FROM_SYMFONIA;
}
export class ImportStartBuildStructure implements Action {
    readonly type = IMPORT_START_BUILD_STRUCTURE;
}
export class ImportStop implements Action {
    readonly type = IMPORT_STOP;
}
export class ImportStartUsers implements Action {
    readonly type = IMPORT_START_USERS;
}
export class ImportStartErp implements Action {
    readonly type = IMPORT_START_ERP;
}
export class ImportStartGroups implements Action {
    readonly type = IMPORT_START_GROUPS;
}
export class SetImportLogs implements Action {
    readonly type = SET_IMPORT_LOGS;
    constructor(public payload: any[]) { }
}
export class AddImportLog implements Action {
    readonly type = ADD_IMPORT_LOG;
    constructor(public payload: any) { }
}
export class AddImportLogs implements Action {
    readonly type = ADD_IMPORT_LOGS;
    constructor(public payload: any[]) { }
}
export class SetRedirectUrl implements Action {
    readonly type = SET_REDIRECT_URL;
    constructor(public payload: string) { }
}
export class SetRedirectParameters implements Action {
    readonly type = SET_REDIRECT_PARAMETERS;
    constructor(public payload: string) { }
}
export type AuthActions =
    | SetAuthenticated
    | SetUserAvatar
    | ContactModuleAddHrComponent
    | ContactModuleRemoveHrComponent
    | SetContactModule
    | AddImportLogs
    | ImportStop
    | ImportStartGettingTheDataFromTheSymfonia
    | ImportStopGettingTheDataFromTheSymfonia
    | ImportStartBuildStructure
    | ImportStartUsers
    | ImportStartErp
    | ImportStartGroups
    | SetUnauthenticated
    | SetAvailableRoles
    | SetCurrentUser
    | AuthChangeCompany
    | SetAuthData
    | AuthLogout
    | SetImportLogs
    | SetImportProgress
    | SetPasswordConfig
    | AddImportLog
    | SetCurrentCompany
    | SetRedirectUrl
    | SetRedirectParameters;
