import { Action } from '@ngrx/store';
import { Localization, LocalizationListItem, Market } from './localization.model';

export const SET_AVAILABLE_LOCALIZATIONS = '[Localization] Set available localizations';
export const SET_AVAILABLE_PROJECT_LOCALIZATIONS = '[Localization] Set available project localizations';
export const SET_AVAILABLE_MARKETS = '[Localization] Set available markets';
export const START_EDITING = '[Localization] Start editing';
export const STOP_EDITING = '[Localization] Stop editing';
export const ADD_LOCALIZATION = '[Localization] Add new localization';
export const ADD_MARKET = '[Localization] Add new market';
export const REMOVE_LOCALIZATION = '[Localization] Remove localization';
export const UPDATE_LOCALIZATION = '[Localization] Update localization';
export const SELECTED_LOCALIZATION = '[Localization] Selected localization';
export const REMOVE_MARKET = '[Localization] Remove market';
export const UPDATE_MARKET = '[Localization] Update market';
export const SELECTED_MARKET = '[Localization] Selected market';
export const START_LOADING = '[Localization] start loading';
export const STOP_LOADING = '[Localization] stop loading';
export const START_LOADING_MARKETS = '[Localization] start loading markets';
export const STOP_LOADING_MARKETS = '[Localization] stop loading markets';
export const SET_SEARCH_LOCALIZATION_LIST = '[Localization] Search localization list';

export class SetAvailableLocalizations implements Action {
    readonly type = SET_AVAILABLE_LOCALIZATIONS;
    constructor(public payload: Localization[]) { }
}
export class SetAvailableProjectLocalizations implements Action {
  readonly type = SET_AVAILABLE_PROJECT_LOCALIZATIONS;
  constructor(public payload: Localization[]) { }
}
export class SetAvailableMarkets implements Action {
  readonly type = SET_AVAILABLE_MARKETS;
  constructor(public payload: Market[]) { }
}
export class StartLoadingMarkets implements Action {
  readonly type = START_LOADING_MARKETS;
}
export class StopLoadingMarkets implements Action {
  readonly type = STOP_LOADING_MARKETS;
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class AddLocalization implements Action {
    readonly type = ADD_LOCALIZATION;
    constructor(public payload: Localization) { }
}
export class AddMarket implements Action {
  readonly type = ADD_MARKET;
  constructor(public payload: Market) { }
}
export class RemoveLocalization implements Action {
    readonly type = REMOVE_LOCALIZATION;
    constructor(public payload: number) { }
}
export class RemoveMarket implements Action {
  readonly type = REMOVE_MARKET;
  constructor(public payload: number) { }
}
export class UpdateLocalization implements Action {
    readonly type = UPDATE_LOCALIZATION;
    constructor(public payload: Localization) { }
}
export class UpdateMarket implements Action {
  readonly type = UPDATE_MARKET;
  constructor(public payload: Market) { }
}
export class SetSearchLocalizationList implements Action {
  readonly type = SET_SEARCH_LOCALIZATION_LIST;
  constructor(public payload: LocalizationListItem[]) { }
}
export class SetSelectedLocalization implements Action {
    readonly type = SELECTED_LOCALIZATION;
    constructor(public payload: Localization) { }
}
export class SetSelectedMarket implements Action {
  readonly type = SELECTED_MARKET;
  constructor(public payload: Market) { }
}

export type LocalizationActions =
    SetAvailableLocalizations
    | SetAvailableProjectLocalizations
    | SetAvailableMarkets
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | AddLocalization
    | AddMarket
    | RemoveLocalization
    | RemoveMarket
    | UpdateLocalization
    | UpdateMarket
    | SetSelectedLocalization
    | SetSelectedMarket
    | SetSearchLocalizationList
    | StartLoadingMarkets
    | StopLoadingMarkets;

