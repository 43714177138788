import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserListItem } from 'src/app/redux/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-user-search-bar-multiple',
  templateUrl: './user-search-bar-multiple.component.html',
  styleUrls: ['./user-search-bar-multiple.component.css']
})
export class UserSearchBarMultipleComponent implements OnInit {
  @Input() isEditing: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private _onDestroy = new Subject<void>();
  userCtrl = new FormControl();
  @ViewChild('userInput', { static: false }) userInput: ElementRef<HTMLInputElement>;
  @Output() usersSelectionChange = new EventEmitter<UserListItem[]>();

  selectedUsers: UserListItem[] = [];
  filteredUsers: UserListItem[] = [];

  constructor(private store: Store<fromRoot.IRootState>,
    private userService: UserService) { }

  ngOnInit(): void {

    this.userCtrl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(query => {
      this.searchUsers(query);
    });

    this.store.select(fromRoot.getSearchUsers)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(users => {
        if (users != null) {
          this.filteredUsers = users;
        }
        else {
          this.filteredUsers = [];
        }
      });
  }

  clear(): void {
    this.userInput.nativeElement.value = '';
    this.selectedUsers = [];
    this.userCtrl.setValue(null);

    this.usersSelectionChange.emit(null);

  }

  remove(user: UserListItem): void {
    const index = this.selectedUsers.indexOf(user);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

  fetchUserList(query: string): void {
    this.userService.searchUsers(query);
  }

  searchUsers(query: string): void {
    if (query && query.length >= 3) {
      this.fetchUserList(query);
    }
  }

  selected(e: MatAutocompleteSelectedEvent): void {
    const selectedUser = e.option.value;
    const elementInSelectedUsers = this.selectedUsers.filter(x => x.id === selectedUser.id);

    if (elementInSelectedUsers === null || elementInSelectedUsers.length === 0) {
      this.selectedUsers.push(selectedUser);
      this.usersSelectionChange.emit(this.selectedUsers);
    }
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);

  }

  add(e: MatChipInputEvent) {
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
  }
}
