import { ProjectKind, ProjectKindAttachment } from './project-kind.model';
import {
    ProjectKindActions,
    SELECTED_PROJECT_KIND,
    ADD,
    ADD_PROJECT_KIND_ROLE,
    ADD_PROJECT_KIND_ATTACHMENT,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_PROJECT_KINDS,
    SET_AVAILABLE_PROJECT_KIND_ROLE,
    SET_AVAILABLE_PROJECT_KIND_ATTACHMENT,
    UPDATE,
    REMOVE,
    REMOVE_PROJECT_KIND_ROLE,
    REMOVE_PROJECT_KIND_ATTACHMENT,
    START_LOADING,
    STOP_LOADING
} from './project-kind.actions';
import { ProjectKindRole } from './project-kind-role.model';

export interface State {
    projectKinds: ProjectKind[];
    projectKindRole: ProjectKindRole[];
    projectKindAttachment: ProjectKindAttachment[];
    selectedProjectKind: ProjectKind;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    projectKinds: [],
    projectKindRole: [],
    projectKindAttachment: [],
    selectedProjectKind: null,
    isEditing: false,
    isLoading: false
};

export function projectKindReducer(state = initialState, action: ProjectKindActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_PROJECT_KIND: {
            return {
                ...state,
                selectedProjectKind: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                projectKinds: [...state.projectKinds, action.payload]
            };
        }
        case ADD_PROJECT_KIND_ROLE: {
          return {
              ...state,
              projectKindRole: [...state.projectKindRole, action.payload]
          };
        }
        case ADD_PROJECT_KIND_ATTACHMENT: {
          return {
              ...state,
              projectKindAttachment: [...state.projectKindAttachment, action.payload]
          };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_PROJECT_KINDS: {
            return {
                ...state,
                projectKinds: action.payload
            };
        }
        case SET_AVAILABLE_PROJECT_KIND_ROLE: {
          return {
              ...state,
              projectKindRole: action.payload
          };
        }
        case SET_AVAILABLE_PROJECT_KIND_ATTACHMENT: {
          return {
              ...state,
              projectKindAttachment: action.payload
          };
        }
        case UPDATE: {
            const index = state.projectKinds.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selProject = state.selectedProjectKind;
            if (selProject && selProject.id === action.payload.id) {
              selProject = action.payload;
            }
            const updatedGroups = [
                ...state.projectKinds.slice(0, index),
                Object.assign({}, state.projectKinds[index], action.payload),
                ...state.projectKinds.slice(index + 1)
            ];
            return {
                ...state,
                projectKinds: updatedGroups,
                selectedProjectKind: selProject
            };
        }
        case REMOVE: {
            return {
                ...state,
                projectKinds: state.projectKinds.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_PROJECT_KIND_ROLE: {
          return {
              ...state,
              projectKindRole: state.projectKindRole.filter(c => !(c.projectKind.id === action.projectKindId
                && c.applicationRole.id === action.applicationRoleId))
          };
        }
        case REMOVE_PROJECT_KIND_ATTACHMENT: {
          return {
              ...state,
              projectKindAttachment: state.projectKindAttachment.filter(c => !(c.id === action.projectKindAttachmentId))
          };
        }
        default: {
            return state;
        }
    }
}

export const projectKinds = (state: State) => state.projectKinds;
export const projectKindRole = (state: State) => state.projectKindRole;
export const projectKindAttachment = (state: State) => state.projectKindAttachment;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedProjectKind = (state: State) => state.selectedProjectKind;
