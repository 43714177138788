export interface HrComponent {
    description: string;
    dictionaryId: number;
    elementId: number;
    id: number;
    name: string;
    printDescription: string;
    selected: boolean;
    sortIndex: number;
    type: HrcomponentType;
    groupId?: number;
    parentId?: number;
    parentName?: string;
    attributes?: HrComponent[];
    translateValues: boolean;
    currency: boolean;
    procent: boolean;
    skipRoles: boolean;
    decimalPipe: string;
    visibleInContactList: boolean;
    visibleInUserDetails: boolean;
    multivalue: boolean;
    sumMultivalueAmounts: boolean;
    valueType: ComponentValueType;
    roleIds: HrComponentModelRoleResource[];
}
export class HrComponentHelper {
    public static canShow(userRoles: any[], component: HrComponent, source: 'contact list' | 'user details'): boolean {
        if (!userRoles || !component) {
            return false;
        }
        if (component.skipRoles) {
          return true;
        }
        switch (component.type) {
            case HrcomponentType.GroupedElement:
                if (!component.attributes) {
                    return false;
                }
                for (const att of component.attributes) {
                    if (this.calculateRoles(att.roleIds, att.parentId, userRoles)) {
                        if (this.getComponentVisibility(source, att)) {
                            return true;
                        }
                    }
                }
                break;

            case HrcomponentType.Attribute:
                if (this.calculateRoles(component.roleIds, component.parentId, userRoles)) {
                    if (this.getComponentVisibility(source, component)) {
                        return true;
                    }
                }
                break;

            case HrcomponentType.Element:
                return component.roleIds.some(c => userRoles.some(ur => ur.id === c.id))
                    && this.getComponentVisibility(source, component);

            default:
                return false;
        }
    }

    private static getComponentVisibility(source: 'contact list' | 'user details', component: HrComponent) {
        let visible = false;

        switch (source) {
            case 'contact list':
                visible = component.visibleInContactList;
                break;
            case 'user details':
                visible = component.visibleInUserDetails;
                break;
            default:
                visible = false;
                break;
        }

        return visible;
    }

    private static calculateRoles(roles: HrComponentModelRoleResource[], parentId: number, userRoles: any[]) {
        const individualRoles = roles.filter(rol => rol.parentId === parentId);
        if (individualRoles.length > 0) {
            for (const role of individualRoles) {
                if (userRoles.some(userRole => userRole.id === role.id && parentId === role.parentId)) {
                    return true;
                }
            }
        } else {
            for (const role of roles.filter(rol => !rol.parentId)) {
                if (userRoles.some(userRole => userRole.id === role.id)) {
                    return true;
                }
            }
        }
    }
}

export interface HrComponentModelRoleResource {
    id: number;
    parentId?: number;
}

export enum ComponentValueType {
    Napis = 0,      // String
    Data = 1,       // Data (tylko data bez czasu)
    Slownik = 2,    // Element wybierany ze słownika
    Ulamek = 3,     // Prezentacja ułamka jako 1/2, 1/4. Ten parametr ma dodatkowy atrybut: prezentuj
    // godziny i minuty. W tej sytuacji wartość ułamkową należy zamimenić na format HH:mm, np. (3 1/2) => 03:30
    UlamekJakoCzas = 4, // Ulamek prezentowany w postaci czasu HH:mm (zgodnie z wyjaśnieniem powyżej)
    LMD = 5,        // prezentacja wartości jako lata miesiące dni; kolejne elementy
    // oddzielone od siebie spacją, np. 1 10 24 (1 rok 10 miesięcy 24 dni)
    liczba = 255,    // dowolna liczba (int/double)
    Email = 6,
    Tel = 7
}

export enum HrcomponentType {
    Element,
    GroupedElement,
    Attribute
}

export enum DefaultHrComponents {
    EmailElement = -1,
    UserElement = -2,
    EmailAttribute = -3,
    UserAttribute = -4
}
