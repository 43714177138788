import {
    CommonActions,
    SET_AVAILABLE_DICTIONARIES,
    UPDATE_DICTIONARY,
    ADD_DICTIONARY,
    REMOVE_DICTIONARY,
    SET_LANGUAGE
} from './common.actions';

export interface EDictionary {
    id: number;
    key: string;
    normalizedKey: string;
    english: string;
    polish: string;
    russian: string;
    ukrainian: string;
    deutsch: string;
    type: EDictionaryType;
}

export enum EDictionaryType {
    administrationPermissions,
    common
}

export interface State {
    dictionaries: EDictionary[];
    updatedTranslation: EDictionary;
    addedTranslation: EDictionary;
    currentLanguage: string;
}

const initialState: State = {
    dictionaries: null,
    updatedTranslation: null,
    addedTranslation: null,
    currentLanguage: null
};

export function commonReducer(state = initialState, action: CommonActions) {
    switch (action.type) {
        case SET_LANGUAGE: {
            return {
                ...state,
                currentLanguage: action.payload
            };
        }
        case ADD_DICTIONARY: {
            if (!state.dictionaries) {
                return state;
            }
            const exists = state.dictionaries.some(c => c.id === action.payload.id);
            return {
                ...state,
                dictionaries: exists ? state.dictionaries : [...state.dictionaries, action.payload],
                addedTranslation: action.payload
            };
        }
        case REMOVE_DICTIONARY: {
            if (!state.dictionaries) {
                return state;
            }
            return {
                ...state,
                dictionaries: state.dictionaries.filter(c => c.id !== action.payload)
            };
        }
        case SET_AVAILABLE_DICTIONARIES: {
            return {
                ...state,
                dictionaries: action.payload
            };
        }
        case UPDATE_DICTIONARY: {
            if (!state.dictionaries) {
                return state;
            }
            const index = state.dictionaries.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            const dictionaries = [
                ...state.dictionaries.slice(0, index),
                Object.assign({}, state.dictionaries[index], action.payload),
                ...state.dictionaries.slice(index + 1)
            ];
            return {
                ...state,
                updatedTranslation: action.payload,
                dictionaries: dictionaries
            };
        }
        default: {
            return state;
        }
    }
}

export const getDictionaries = (state: State) => state.dictionaries;
export const getAddedTranslation = (state: State) => state.addedTranslation;
export const getUpdatedTranslation = (state: State) => state.updatedTranslation;
export const getCurrentLanguage = (state: State) => state.currentLanguage;
