
import {
    ExcelExportActions,
    SET_MESSAGE
} from './excel-export.actions';

export interface State {
    message: string;
}
const initialState: State = {
    message: null,
};

export function excelExportReducer(state = initialState, action: ExcelExportActions) {
    switch (action.type) {
        case SET_MESSAGE: {
            return {
                ...state,
                message: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const message = (state: State) => state.message;