import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface OosConfirmationModel {
    titleKey?: string;
    contentKey?: string;
}

@Component({
    selector: 'app-oos-confirmation-dialog',
    template: `
        <h2 mat-dialog-title *ngIf="model.titleKey">{{ model.titleKey }}</h2>
        <mat-dialog-content *ngIf="model.contentKey">
            <span>{{ model.contentKey   }}</span>
        </mat-dialog-content>
        <mat-dialog-actions float-right margin-top>
            <button mat-button color="primary" (click)="submit()">
                {{ 'Tak' }}
            </button>
            <button mat-button color="primary" (click)="cancel()">
                {{ 'Nie' }}
            </button>
        </mat-dialog-actions>
    `
})
export class OosConfirmationDialogComponent {

    model: OosConfirmationModel;

    constructor(
        public dialogRef: MatDialogRef<OosConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OosConfirmationModel
    ) {
        this.model = data;
    }

    submit() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
