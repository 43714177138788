import {
    AuthActions,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_AVAILABLE_ROLES,
    SET_CURRENT_USER,
    SET_CURRENT_COMPANY,
    AUTH_LOGOUT,
    CHANGE_COMPANY,
    SET_AUTH_DATA,
    SET_PASSWORD_CONFIG,
    SET_IMPORT_PROGRESS,
    SET_IMPORT_LOGS,
    ADD_IMPORT_LOG,
    IMPORT_START_GETTING_DATA_FROM_SYMFONIA,
    IMPORT_START_USERS,
    IMPORT_START_GROUPS,
    IMPORT_STOP,
    IMPORT_STOP_GETTING_DATA_FROM_SYMFONIA,
    IMPORT_START_BUILD_STRUCTURE,
    ADD_IMPORT_LOGS,
    SET_CONTACT_MODULE,
    SET_AVATAR_USER,
    IMPORT_START_ERP,
    SET_REDIRECT_URL,
    SET_REDIRECT_PARAMETERS,
} from './auth.actions';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { IAuthData } from './auth-data.model';
import { PasswordConfig } from '../_models/password-config.model';
import { ContactModuleModel } from './modules/contact-module.model';
import { HrComponent } from '../_models/hrComponent.model';
import { Company } from '../redux/company/company.model';


export interface IAuthState {
    isAuthenticated: boolean;
    importLogs: any[];
    importProgress?: number;
    availableRoles: string[];
    currentUser: ApplicationUser;
    currentCompany: Company;
    authData: IAuthData;
    passwordConfig: PasswordConfig;
    importStatus: string;
    contactModule: ContactModuleModel;
    contactModuleHrComponenetAdded: HrComponent;
    contactModuleHrComponenetRemoved: HrComponent;
    redirectUrl: string;
    redirectParameters: string;
}

const initialState: IAuthState = {
    isAuthenticated: false,
    importLogs: [],
    importProgress: null,
    availableRoles: [],
    passwordConfig: null,
    currentUser: null,
    currentCompany: null,
    authData: {
        username: '',
        password: '',
        provider: 'ePMT'
    },
    importStatus: null,
    contactModule: null,
    contactModuleHrComponenetAdded: null,
    contactModuleHrComponenetRemoved: null,
    redirectUrl: null,
    redirectParameters: null,
};

export function authReducer(state = initialState, action: AuthActions) {
    switch (action.type) {
        case SET_AVATAR_USER: {
            if (!state.currentUser || state.currentUser.id !== action.payload.id) {
                return state;
            }

            const currentUser = state.currentUser;
            currentUser.avatar = action.payload.avatar;
            return {
                ...state,
                currentUser: { ...currentUser }
            };
        }
        case SET_CONTACT_MODULE: {
            return {
                ...state,
                contactModule: action.payload
            };
        }
        case IMPORT_START_GETTING_DATA_FROM_SYMFONIA: {
            return {
                ...state,
                importStatus: 'GET',
                importProgress: null
            };
        }
        case IMPORT_STOP_GETTING_DATA_FROM_SYMFONIA: {
            if (state.importStatus === 'GET') {
                return {
                    ...state,
                    importStatus: null
                };
            }
            return state;
        }
        case IMPORT_START_BUILD_STRUCTURE: {
            return {
                ...state,
                importStatus: 'BUILD',
                importProgress: 0
            };
        }
        case IMPORT_STOP: {
            return {
                ...state,
                importStatus: null
            };
        }
        case IMPORT_START_USERS: {
            return {
                ...state,
                importStatus: 'USERS',
                importProgress: 0
            };
        }
        case IMPORT_START_ERP: {
            return {
                ...state,
                importStatus: 'GET_ERP',
                importProgress: 0
            };
        }
        case IMPORT_START_GROUPS: {
            return {
                ...state,
                importStatus: 'GROUPS',
                importProgress: 0
            };
        }
        case ADD_IMPORT_LOG: {
            return {
                ...state,
                importLogs: [...state.importLogs, action.payload]
            };
        }
        case ADD_IMPORT_LOGS: {
            return {
                ...state,
                importLogs: [...state.importLogs, ...action.payload]
            };
        }
        case SET_IMPORT_LOGS: {
            return {
                ...state,
                importLogs: action.payload
            };
        }
        case SET_IMPORT_PROGRESS: {
            if (!state.importStatus) {
                return state;
            }
            return {
                ...state,
                importProgress: +action.payload
            };
        }
        case SET_PASSWORD_CONFIG: {
            return {
                ...state,
                passwordConfig: action.payload
            };
        }
        case SET_AUTH_DATA: {
            return {
                ...state,
                authData: action.payload
            };
        }
        case CHANGE_COMPANY: {
            return state;
        }
        case AUTH_LOGOUT: {
            return state;
        }
        case SET_CURRENT_COMPANY: {
            return {
                ...state,
                currentCompany: action.payload
            };
        }
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            };
        case SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: true
            };
        case SET_UNAUTHENTICATED:
            return {
                ...state,
                isAuthenticated: false
            };
        case SET_AVAILABLE_ROLES:
            return {
                ...state,
                availableRoles: action.payload
            };
        case SET_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.payload
            };
        case SET_REDIRECT_PARAMETERS:
            return {
                ...state,
                redirectParameters: action.payload
            };
        default: {
            return state;
        }
    }
}

export const getIsAuth = (state: IAuthState) => state.isAuthenticated;
export const getAuthData = (state: IAuthState) => state.authData;
export const getRoles = (state: IAuthState) => state.availableRoles;
export const getCurrentUser = (state: IAuthState) => state.currentUser;
export const getCurrentCompany = (state: IAuthState) => state.currentCompany;
export const getImportLogs = (state: IAuthState) => state.importLogs;
export const getImportProgress = (state: IAuthState) => state.importProgress;
export const getImportStatus = (state: IAuthState) => state.importStatus;
export const passwordConfig = (state: IAuthState) => state.passwordConfig;
export const getContactModule = (state: IAuthState) => state.contactModule;
export const getRedirectUrl = (state: IAuthState) => state.redirectUrl;
export const getRedirectParameters = (state: IAuthState) => state.redirectParameters;