import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { UserBase, UserCompany, UserContactDetails } from 'src/app/redux/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { AppHubConnection } from 'src/app/redux/user/hub_connection.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ContactModuleModel } from 'src/app/auth/modules/contact-module.model';
import {
  HrComponent,
  HrcomponentType,
  DefaultHrComponents,
  ComponentValueType,
  HrComponentHelper
} from 'src/app/_models/hrComponent.model';
import { CreateValueComponent } from '../hr-component/create-value/create-value.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EDictionary } from '../common.reducer';
import { EDictionaryPipe } from '../pipes/eDictionary.pipe';
import { ScreenService } from 'src/app/redux/screen-state/screen.service';
import { AppHub } from 'src/app/hubs/app.hub';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { Page, NavigationService } from 'src/app/services/navigation.service';
import { CurrencyPipe, DecimalPipe, DatePipe } from '@angular/common';
import { ProjectKind } from 'src/app/redux/contractor/project-kind.model';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { Group } from 'src/app/redux/group/group.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { element } from 'protractor';
import { Project } from 'src/app/redux/project/project.model';
import { Market } from 'src/app/redux/localization/localization.model';
import { UIService } from '../ui.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  currentUser: ApplicationUser;
  selectedUser: UserContactDetails;
  userApp: UserContactDetails;
  online: AppHubConnection[] = [];
  tabs = [];
  contactModule: ContactModuleModel;

  @Input() showBackButton = true;
  isMobile: boolean;
  loading = false;
  isedit = false;
  selectedComponent: HrComponent;
  selectedComponentAttributes: any[];
  history: any[];
  eDictionary: EDictionary[];
  translatePipe = new EDictionaryPipe();
  columns: any[];
  translatedStr;
  showElements = false;
  tab: any = {};
  isEditing: boolean;

  allprojectKinds: ProjectKind[] = [];
  projectKinds: ProjectKind[] = [];

  filteredProjectKinds: Observable<ProjectKind[]>;
  filteredProjects: Observable<Project[]>;
  filteredMarkets: Observable<Market[]>;

  projectKindCtrl = new FormControl();
  projectListCtrl = new FormControl();
  marketListCtrl = new FormControl();

  allManagers: UserBase[];
  allGroups: Group[];

  allProjects: Project[] = [];
  allMarkets: Market[] = [];
  marketsByProject: Market[] = [];
  projects: Project[] = [];
  markets: Market[] = [];
  accessAccordingToMarkets = false;

  isLoadingMarkets: boolean = true;

  @ViewChild('projectKindInput', { static: false }) projectKindInput: ElementRef<HTMLInputElement>;
  @ViewChild('projectListInput', { static: false }) projectListInput: ElementRef<HTMLInputElement>;
  @ViewChild('marketListInput', { static: false }) marketListInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.IRootState>,
    public auth: AuthService,
    private screen: ScreenService,
    private dialog: MatDialog,
    private hub: AppHub,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private navService: NavigationService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private uiService: UIService,
    private localizationService: LocalizationService,

  ) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit() {
    this.store.select(fromRoot.getLocalizationMarketsIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((isLoading) => {
        this.isLoadingMarkets = isLoading;
      });
    if (this.showBackButton) {
      this.navService.navigatedTo(Page.contact_user);
    }
    this.store.select(fromRoot.getSelectedContactDetails)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(user => {
        if (user != null) {
          this.selectedUser = { ...user };
          this.selectedUser.employmentStatusText = '';
          if (this.selectedUser.employmentStatus !== null && this.selectedUser.employmentStatus !== undefined) {
            switch (this.selectedUser.employmentStatus) {
              case 0:
                this.selectedUser.employmentStatusText = 'Bez ZUS';
              case 1:
                this.selectedUser.employmentStatusText = 'ZUS';
              case 2:
                this.selectedUser.employmentStatusText = 'Student';
            }
          }
          this.userApp = { ...user };

          if (this.selectedUser.projectKindsIds != null) {
            this.projectKinds = this.allprojectKinds.
              filter(p => this.selectedUser.projectKindsIds.includes(p.id)).slice();
          }
          if (this.selectedUser.projectsIds != null) {
            this.projects = this.allProjects.
              filter(p => this.selectedUser.projectsIds.includes(p.id)).slice();
          }
          if (this.selectedUser.marketsIds != null) {
            this.markets = this.allMarkets.
              filter(p => this.selectedUser.marketsIds.includes(p.id)).slice();
          }

          this.getMarketsByProject(this.projects.map(pr => pr.id));
          this.accessAccordingToMarkets = this.markets.length > 0;
        }
      });
    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.hub.connectionReconected
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.getComponentHistory(this.selectedComponent));
    this.hub.reloadDataTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe((_) => this.getComponentHistory(this.selectedComponent));
    this.screen.mobile$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => this.isMobile = res);
    this.auth.hub.reloadHrHistoryTrigger
      .pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        if (this.selectedComponent && data && data.userId === this.currentUser.id && data.componentId === this.selectedComponent.id) {
          this.getComponentHistory(this.selectedComponent);
        }
      });
    this.store.select(fromRoot.getUserOnline)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(online => this.online = online);
    this.store.select(fromRoot.getContactModule)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(contactModule => {
        if (contactModule) {
          this.contactModule = contactModule;
        }
      });
    this.store.select(fromRoot.getDictionaries)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(dic => this.eDictionary = dic);
    this.translate.get(['Start date', 'End date'])
      .toPromise()
      .then(c => this.translatedStr = c);

    this.store.select(fromRoot.getUserIsediting)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => this.isEditing = res);

    this.store.select(fromRoot.getProjectKindsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projectKinds) => {
        this.allprojectKinds = projectKinds;
      });

    this.store.select(fromRoot.getGroupManagersList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((managers) => {
        this.allManagers = managers;
      });

    this.store.select(fromRoot.getGroupList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((groups) => {
        this.allGroups = groups;
      });

    this.store.select(fromRoot.getProjectsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projects) => {
        this.allProjects = projects;
      });

    this.store.select(fromRoot.getMarketsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((markets) => {
        if (markets && markets.length > 0) {
          this.allMarkets = markets;
        }
      });

    this.filteredProjectKinds = this.projectKindCtrl.valueChanges.pipe(
      startWith(null),
      map((value: ProjectKind | string | null) => value ? this._filter(value) : this.allprojectKinds.slice()));

    this.filteredProjects = this.projectListCtrl.valueChanges.pipe(
      startWith(null),
      map((value: Project | string | null) => value ? this._filter3(value) : this.allProjects.slice()));

    this.filteredMarkets = this.marketListCtrl.valueChanges.pipe(
      startWith(null),
      map((value: Market | string | null) => value ? this._filter4(value) : this.marketsByProject.slice()));


  }

  ngAfterViewInit() {
    if (this.allMarkets.length === 0) {
      this.localizationService.getMarkets();
    }
  }

  ngOnDestroy() {

    this._onDestroy.next();
    this._onDestroy.complete();

    this.cancelForm();
    this.userService.unselectContactUser();
    this.navService.navigatedTo(null);
  }

  canShowOnlineUsersChart() {
    if (this.currentUser && this.currentUser.roles.some(c => c.shortcut === 'kierownik')) {
      return true;
    }
    if (this.auth.appRoles.canAdministration) {
      return true;
    }
    return false;
  }

  openFormWithoutHistory(hrComponent: HrComponent) {
    // if (hrComponent && hrComponent.attributes && hrComponent.attributes.length > 0) {
    if (hrComponent) {
      this.getComponentHistory(hrComponent, true)
        .then(() => {
          this.openForm(
            hrComponent,
            this.selectedComponentAttributes,
            null
          );
        });
    }
  }

  onSelectedComponentRowClick(e) {
    if (e.data && this.selectedComponent && this.selectedComponent.multivalue && this.canEdit(this.selectedComponent)) {
      this.openForm(
        this.selectedComponent,
        this.selectedComponent.attributes && this.selectedComponent.attributes.length > 0
          ? this.getVisibleComponents(this.selectedComponent.attributes)
          : this.selectedComponentAttributes,
        e.data.guid
      );
    }
  }

  canShowHistory() {
    if (!this.selectedUser || !this.currentUser) {
      return false;
    }

    return this.auth.appRoles.canStructureAndContactUserDetailsHistoryManage
      || this.auth.appRoles.canStructureAndContactUserDetailsHistory
      || this.selectedUser.id === this.currentUser.id;
  }

  selectElement(component: HrComponent) {
    if (component.elementId < 0) {
      return;
    }
    if (this.canShowHistory()) {
      this.selectedComponent = { ...component };
      this.loading = true;
      this.getComponentHistory(this.selectedComponent);
    }
  }

  openForm(component: HrComponent, attributes: any[], guid: string = null) {
    if (!this.canEdit(component)) {
      return;
    }

    if (this.isMobile) {
      this.dialog.open(CreateValueComponent, {
        data: {
          selectedComponent: component,
          selectedUser: this.selectedUser,
          attributes: attributes,
          guid: guid
        },
        width: '95vw',
        minWidth: '95vw',
      });
    } else {
      this.dialog.open(CreateValueComponent, {
        data: {
          selectedComponent: component,
          selectedUser: this.selectedUser,
          attributes: attributes,
          guid: guid
        },
        minWidth: '70vw'
      });
    }
  }

  getComponentValue(component: HrComponent) {
    if (component.elementId === DefaultHrComponents.EmailElement || component.elementId === DefaultHrComponents.EmailAttribute) {
      return [this.selectedUser.email];
    }
    if (!this.selectedUser.contactElements) {
      return null;
    }
    // const key = component.elementId ? component.elementId.toString() : component.name;
    const key = component.id.toString();
    const elem = this.selectedUser.contactElements.find(c => c.key === key && c.parentId === component.parentId);
    if (!elem) {
      return null;
    }
    if (!elem.value) {
      return [''];
    }
    const fields = elem.value.split(' | ');
    if (component.valueType === ComponentValueType.liczba && component.sumMultivalueAmounts && component.multivalue) {
      let result = 0;
      fields.forEach(c => +c ? result += +c : result = result);
      if (component.decimalPipe) {
        return [this.decimalPipe.transform(result, component.decimalPipe) + (component.currency ? ' zł' : '')
          + (component.procent ? '%' : '')];
      }
      return [result + (component.currency ? ' zł' : '') + (component.procent ? '%' : '')];
    }

    if (component.valueType === ComponentValueType.Data) {
      return fields.map(c => {
        return this.datePipe.transform(c, 'yyyy-MM-dd');
      });
    }

    if (component.valueType === ComponentValueType.liczba) {
      if (component.decimalPipe) {
        return fields.map(c => {
          return this.decimalPipe.transform(c, component.decimalPipe) + (component.currency ? ' zł' : '')
            + (component.procent ? '%' : '');
        });
      }
      return fields.map(c => {
        return this.decimalPipe.transform(c, '1.0-2') + (component.currency ? ' zł' : '')
          + (component.procent ? '%' : '');
      });
    }


    return fields.map(c => {
      return this.translatePipe.transform(c, this.eDictionary, this.auth.currentLanguage);
    });
  }

  canEdit(component: HrComponent) {
    const editOthers = this.auth.appRoles.canStructureAndContactUserDetailsEditOthers;
    const editOwn = this.auth.appRoles.canStructureAndContactUserDetailsEditOwn
      && this.selectedUser.id === this.currentUser.id;

    return (editOthers || editOwn) && (!component.elementId || component.elementId < 0);
  }

  caneditUserDetails() {
    return true;
  }

  editForm() {
    this.userService.startEditing();
  }


  getComponents(g) {
    const roles = this.filteredUserRoles();
    const result = this.contactModule.hrComponents.filter(c => c.visibleInUserDetails &&
      c.groupId === g.id && c.type !== HrcomponentType.GroupedElement
      && (c.skipRoles || c.roleIds.some(r => roles.some(ur => ur.id === r.id))));

    return result;
  }

  getComponentsGrouped(g) {
    const roles = this.filteredUserRoles();
    const result = this.contactModule.hrComponents.filter(c => c.groupId === g.id && c.visibleInUserDetails
      && c.type === HrcomponentType.GroupedElement && c.attributes && c.attributes.length > 0
      && HrComponentHelper.canShow(roles, c, 'user details'));

    return result;
  }

  getTabs() {
    return this.contactModule.hrComponentGroups;
    // return this.contactModule.hrComponentGroups.filter(c => this.getComponents(c).length > 0 && this.getComponentsGrouped(c).length > 0);
  }

  isOnline(): boolean {
    return this.online.some(c => c.userIdentifier === this.selectedUser.id);
  }

  getFacebookId() {
    if (this.selectedUser === null || this.selectedUser === undefined) {
      return null;
    }
    if (this.selectedUser._avatar || !this.selectedUser.facebookId) {
      return null;
    }
    return this.selectedUser.facebookId;
  }

  getPhoto() {
    if (this.selectedUser === null || this.selectedUser === undefined) {
      return null;
    }
    if (this.selectedUser.hasPhoto) {
      return this.selectedUser._avatar;
    }
    return null;
  }

  close() {
    this.router.navigate(['contact']);
    this.userService.reloadDataGrid();
  }

  getVisibleAttributes(grouped: HrComponent) {
    const result = [];
    if (!grouped.attributes) {
      return result;
    }
    const roles = this.filteredUserRoles();
    for (const att of grouped.attributes) {
      if (att.visibleInUserDetails && HrComponentHelper.canShow(roles, att, 'user details')) {
        result.push(att);
      }
    }
    return result;
  }

  getVisibleComponents(components: HrComponent[]) {
    return components.filter(c => c.visibleInUserDetails
      && (c.skipRoles || c.roleIds.some(r => this.currentUser.roles.some(ur => ur.id === r))));
  }

  private filteredUserRoles() {
    let roles = [... this.currentUser.roles];
    if (this.currentUser.id !== this.selectedUser.id && !roles.some(c => c.shortcut === 'admin')) {
      roles = roles.filter(c => c.shortcut !== 'pracownik');
    }
    return roles;
  }

  private getComponentHistory(selectedComponent: HrComponent, onlyAttributes = false) {
    if (!selectedComponent) {
      return;
    }
    return new Promise((r, e) => {
      const id = selectedComponent.id;
      const parentId = selectedComponent.parentId ? selectedComponent.parentId.toString() : '0';
      this.http.get<any>(environment.apiUrl
        + '/applicationUser/hrComponent/contact/value/' + this.selectedUser.id + '/' + selectedComponent.id
        + '/' + parentId + (onlyAttributes ? '/attributes' : ''))
        .toPromise()
        .then(result => {
          if (selectedComponent && selectedComponent.id === id) {
            const translatedComponentName = this.translatePipe.transform(selectedComponent.name,
              this.eDictionary, this.auth.currentLanguage);
            this.history = [];
            this.columns = [];
            this.selectedComponentAttributes = [];

            if (result) {
              this.selectedComponentAttributes = result;
              this.columns.push({
                key: 'startDate',
                value: this.translatedStr['Start date'],
                width: '130',
                dataType: 'date',
                format: 'yyyy-MM-dd'
              });
              this.columns.push({
                key: 'endDate',
                value: this.translatedStr['End date'],
                width: '130',
                dataType: 'date',
                format: 'yyyy-MM-dd'
              });

              let maxIndex = 0;
              // set max valueIndex
              for (const att of result) {
                for (const attValue of att.values) {
                  if (+attValue.valueIndex > maxIndex) {
                    maxIndex = attValue.valueIndex;
                  }
                }
              }
              if (maxIndex > 0) {
                this.columns.push({
                  key: 'root',
                  value: translatedComponentName,
                  dataType: 'string',
                  groupIndex: '0'
                });
              }


              const guids: string[] = [];
              // fill guids
              for (const att of result) {
                for (const attValue of att.values) {
                  if (!guids.some(c => c === attValue.guid)) {
                    guids.push(attValue.guid);
                  }
                }
                break;
              }
              this.history = [];
              for (let i = 0; i < guids.length; i++) {
                const guid = guids[i];
                const rootName = translatedComponentName + ` (${i + 1})`;

                for (const att of result) {
                  const dataField = `__${att.id}`;

                  // add column
                  if (!this.columns.some(c => c.key === dataField)) {
                    const translated = this.translatePipe.transform(att.name, this.eDictionary, this.auth.currentLanguage);
                    this.columns.push({
                      key: dataField,
                      value: translated,
                      dataType: this.getColumnType(att.valueType),
                      format: att.currency ? { type: 'currency', precision: 2, currency: 'PLN' }
                        : att.valueType === ComponentValueType.Data ? 'yyyy-MM-dd'
                          : att.procent ? { type: 'percent' } : '',
                    });
                  }
                  // get ordered values by guid
                  const values = att.values
                    .filter(c => c.guid === guid)
                    .sort((one, two) => (one.valueIndex < two.valueIndex ? -1 : 1));

                  for (const val of values) {
                    if (!this.history.some(c => c.guid === guid && c.valueIndex === val.valueIndex)) {
                      this.history.push({
                        guid: guid,
                        valueIndex: val.valueIndex,
                        root: rootName,
                        startDate: val.startDate,
                        endDate: val.endDate
                      });
                    }
                    const item = this.history.find(c => c.guid === guid && c.valueIndex === val.valueIndex);


                    if (att.valueType === ComponentValueType.Data && val.value) {
                      item[dataField] = val.value;
                      continue;
                    }

                    if (att.valueType === ComponentValueType.liczba && +val.value) {
                      item[dataField] = +val.value;
                      if (att.procent) {
                        item[dataField] = +val.value / 100;
                      }
                      continue;
                    }

                    if (att.elementId === -3 || att.elementId === -1) {
                      item[dataField] = this.selectedUser.email;
                      continue;
                    }

                    const translatedValue = this.translatePipe.transform(val.value, this.eDictionary, this.auth.currentLanguage);
                    item[dataField] = translatedValue;

                  }
                }
              }
            }
            // this.history.sort((one, two) => (one.startDate > two.startDate ? -1 : 1));
            this.loading = false;
            //r();
          }
        })
        .catch(err => {
          this.loading = false;
          this.history = [];
          this.columns = [];
          this.selectedComponentAttributes = [];
          this.selectedComponent = null;
          console.error(err);
          e();
        });
    });
  }

  private getColumnType(valueType: ComponentValueType) {
    switch (valueType) {
      case ComponentValueType.Data:
        return 'date';
      case ComponentValueType.liczba:
        return 'number';

      default:
        return 'string';
    }
  }

  startEditing(): void {
    this.userService.startEditing();
  }

  stopEditing(): void {
    this.userService.stopEditing();
  }

  cancelForm() {
    this.stopEditing();
    this.selectedUser = { ...this.userApp };

    if (this.selectedUser.projectKindsIds != null) {
      this.projectKinds = this.allprojectKinds.
        filter(p => this.selectedUser.projectKindsIds.includes(p.id)).slice();
    }
    if (this.selectedUser.projectsIds != null) {
      this.projects = this.allProjects.
        filter(p => this.selectedUser.projectsIds.includes(p.id)).slice();
    }
    if (this.selectedUser.marketsIds != null) {
      this.markets = this.allMarkets.
        filter(p => this.selectedUser.marketsIds.includes(p.id)).slice();
    }
  }

  saveForm() {
    this.stopEditing();
    this.selectedUser.projectsIds = this.projects.map(p => p.id);
    this.selectedUser.marketsIds = this.markets.map(p => p.id);
    this.userService.updateV2(this.selectedUser);
    this.userApp = { ...this.selectedUser };
  }

  getProjectKindsWithGroups() {
    return this.projectKinds.filter(g => g.groups !== undefined).slice();
  }

  private _filter(value: ProjectKind | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.allprojectKinds.filter(gr => gr.name.toLowerCase().includes(filterValue));
    }
    return this.allprojectKinds;
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const pk: ProjectKind = event.option.value;
    this.initUserProjectKinds();

    if (pk && !this.projectKinds.includes(pk)) {
      this.projectKinds.push(pk);
      this.selectedUser.projectKindsIds.push(pk.id);
    }
    this.projectKindInput.nativeElement.value = '';
    this.projectKindCtrl.setValue(null);
  }

  add(event: MatChipInputEvent) {
    this.projectKindInput.nativeElement.value = '';
    this.projectKindCtrl.setValue(null);
  }

  remove(projectKind: ProjectKind): void {
    let index = this.projectKinds.indexOf(projectKind);
    if (index >= 0) {
      this.projectKinds.splice(index, 1);
    }
    this.initUserProjectKinds();
    index = this.selectedUser.projectKindsIds.indexOf(projectKind.id);
    if (index >= 0) {
      this.selectedUser.projectKindsIds.splice(index, 1);
    }
  }

  initUserProjectKinds() {
    this.selectedUser.projectKindsIds = Object.assign([], this.selectedUser.projectKindsIds);
  }

  initUserProjects() {
    this.selectedUser.projectsIds = Object.assign([], this.selectedUser.projectsIds);
  }

  initUserMarkets() {
    this.selectedUser.marketsIds = Object.assign([], this.selectedUser.marketsIds);
  }

  private _filter3(value: Project | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.allProjects.filter(p => p.name.toLowerCase().includes(filterValue));
    }
    return this.allProjects;
  }

  private _filter4(value: Market | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.marketsByProject.filter(p => p.code.toLowerCase().includes(filterValue));
    }
    return this.marketsByProject;
  }

  selectedProject(event: MatAutocompleteSelectedEvent) {
    this.initUserProjects();

    const p: Project = event.option.value;
    if (p && !this.projects.includes(p)) {
      this.projects.push(p);
      //this.user.userProjects.push(p.id);
      this.getMarketsByProject(this.projects.map(pr => pr.id));
    }
    this.projectListInput.nativeElement.value = '';
    this.projectListCtrl.setValue(null);
  }

  selectedMarket(event: MatAutocompleteSelectedEvent) {
    this.initUserMarkets();

    const p: Market = event.option.value;
    if (p && !this.markets.includes(p)) {
      this.markets.push(p);
      //this.user.userMarkets.push(p.id);
    }
    this.marketListInput.nativeElement.value = '';
    this.marketListCtrl.setValue(null);
  }

  addProject(event: MatChipInputEvent) {
    this.projectListInput.nativeElement.value = '';
    this.projectListCtrl.setValue(null);
  }

  addMarket(event: MatChipInputEvent) {
    this.marketListInput.nativeElement.value = '';
    this.marketListCtrl.setValue(null);
  }

  removeProject(project: Project): void {
    const index = this.projects.indexOf(project);
    if (index >= 0) {
      this.projects.splice(index, 1);
      this.initUserProjects();
      //this.user.userProjects.splice(index, 1);
      this.getMarketsByProject(this.projects.map(pr => pr.id));
    }
  }

  removeMarket(market: Market): void {
    const index = this.markets.indexOf(market);
    if (index >= 0) {
      this.markets.splice(index, 1);
      this.initUserMarkets();
      //this.user.userMarkets.splice(index, 1);
    }
  }

  getMarketsByProject(projects: number[]) {

    this.http.post<Market[]>(environment.apiUrl + '/localization/marketsbyproject', projects)
      .toPromise()
      .then(
        (markets) => {
          this.marketsByProject = markets;
          const marketsToRemove: Market[] = [];
          this.markets.forEach(m => {
            const list = this.marketsByProject.filter(p => p.id === m.id);
            if (list.length === 0) {
              marketsToRemove.push(m);
            }
          });
          for (let i = 0; i < marketsToRemove.length; i++) {
            const index = this.markets.indexOf(marketsToRemove[i]);
            if (index >= 0) {
              this.markets.splice(index, 1);
            }
          }
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  resetPassword() {
    this.userService.resetPassword(this.selectedUser.id);
  }

  disableUser() {
    this.userService.disable(this.selectedUser.id);
  }

  clearClientAccountType() {
    this.selectedUser.clientAccountType = null;
  }

  getEmploymentStatus() {
    this.selectedUser.employmentStatus = null;
  }

  clearEmploymentStatus() {
    this.selectedUser.employmentStatus = null;
  }
}
