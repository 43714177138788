import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-selection',
  templateUrl: './dropdown-selection.component.html',
  styleUrls: ['./dropdown-selection.component.scss']
})
export class DropdownSelectionComponent<T> implements OnInit, OnDestroy, OnChanges {
  private _onDestroy = new Subject<void>();

  @Input() items: T[] = []; // Generic array for items
  @Input() displayField: keyof T; // Field to display in the dropdown
  @Input() placeholder: string = 'Wybierz';

  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() skipNullValues: boolean = false;

  @Input() control: FormControl;  // Accept the FormControl from the parent
  @Output() selectionChanged = new EventEmitter<T>(); // Emit selected item

  @ViewChild('selectElem', { static: false }) selectElem: MatSelect;

  searchControl: FormControl = new FormControl();
  filteredItems: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  private _filteredItems: T[] = [];
  selectOpened = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        if (this.selectOpened || this._filteredItems.length === 0) {
          this.filterItems();
        }
      });
  }

  compareFn(o1: any, o2: any) {
    if (this.displayField === null || this.displayField === '') {
      return o1 === o2;
    }
    else {
      return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }
  };

  test() {
    const currentSelection = this.control.value;
    this.control.setValue(currentSelection);
    console.log(this.placeholder + ' current selection:');
    console.log(currentSelection);
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      const prevItems = changes.items.previousValue;
      const currentItems = changes.items.currentValue;
      const currentSelection = this.control.value;

      if (currentItems == null && this.skipNullValues) {
        return;
      }

      if (!this.areItemsEqual(prevItems, currentItems)) {
        this.filterItems();
      }
      if (this.items) {
        const foundItem = this.items.find(item =>
          JSON.stringify(item) === JSON.stringify(currentSelection)
        );
        if (foundItem) {
          if (this.displayField === null || this.displayField === '') {
            this.control.setValue(null, { emitEvent: false });

            this.control.setValue(foundItem, { emitEvent: false });
            this.cdr.detectChanges();

          }
          else {
            this.control.setValue(null, { emitEvent: false });

            this.control.setValue(foundItem, { emitEvent: false });
            this.cdr.detectChanges();
          }
        } else {
          this.control.setValue(null, { emitEvent: false });
          this.selectionChanged.emit(null);
        }
      }
      else {
        this.control.setValue(null, { emitEvent: false });
        this.selectionChanged.emit(null);
      }
    }
  }

  areItemsEqual(prevItems: any[], currentItems: any[]): boolean {
    if (!prevItems || !currentItems || prevItems.length !== currentItems.length) {
      return false;
    }

    return prevItems.every((item, index) => JSON.stringify(item) === JSON.stringify(currentItems[index]));
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterItems() {
    const search = this.searchControl.value ? this.searchControl.value.toLowerCase() : '';
    if (search) {
      if (this.displayField === null || this.displayField === '') {
        this._filteredItems = this.items.filter(item =>
          (item as unknown as string).toLowerCase().includes(search));
        this.filteredItems.next(this._filteredItems);

      }
      else {
        this._filteredItems = this.items.filter(item =>
          (item[this.displayField] as unknown as string).toLowerCase().includes(search)
        );
        this.filteredItems.next(this._filteredItems);

      }
    } else {
      this._filteredItems = this.items;
      this.filteredItems.next(this.items);
    }
  }

  onSelectionChange(event: MatSelectChange) {
    this.selectionChanged.emit(event.value);
  }

  onClosed() {
    this.searchControl.setValue('');
    this.selectOpened = false;
  }

  onOpened() {
    this.selectOpened = true;
  }
}