import { HierarchyState } from '../store/states/hierarchy.state';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SetHierarchizedIds } from '../store/actions/hierarchy.actions';
import { UIService } from '../shared/ui.service';

@Injectable({
    providedIn: 'root'
})
export class HierarchyService {
    @Select(HierarchyState.hierarchizedIds) hierarchizedIds$: Observable<string[]>;
    @Select(HierarchyState.hierarchizedDeputyIds) hierarchizedDeputyIds$: Observable<string[]>;
    @Select(HierarchyState.userIdsWhereIamIsDeputy) userIdsWhereIamIsDeputy$: Observable<string[]>;

    constructor(private store: Store, private http: HttpClient, private uiService: UIService
    ) { }

    assingManager(userId: string, groupId: number, manager1Id: string, manager2Id: string, manager3Id, manager4Id: string, manager5Id: string, manager6Id: string) {
        const body =
        {
            userId: userId,
            groupId: groupId,
            manager1Id: manager1Id,
            manager2Id: manager2Id,
            manager3Id: manager3Id,
            manager4Id: manager4Id,
            manager5Id: manager5Id,
            manager6Id: manager6Id
        }

        return this.http.put<any>(environment.apiUrl + '/hierarchy/assign-manager', body)
            .toPromise()
            .then(result => {
                return true;
            })
            .catch(err => {
                console.error(err);
                this.uiService.openSnack('Błąd podczas aktualizacji menadżerów', null, 3000);
                return false;
            });
    }

    getHierarchizedIds() {
        this.http.get<any>(environment.apiUrl + `/hierarchy`)
            .toPromise()
            .then(result => {
                this.store.dispatch(new SetHierarchizedIds(result));
            })
            .catch(err => {
                this.store.dispatch(new SetHierarchizedIds({
                    hierarchizedDeputyIds: [],
                    hierarchizedIds: [],
                    userIdsWhereIamDeputy: []
                }));
                console.error(err);
            });
    }

    getHierarchyByUserId(userId: string) {
        return this.http.get<string[]>(environment.apiUrl + `/hierarchy/` + userId)
            .toPromise()
            .then(result => {
                return result;
            })
            .catch(err => {
                console.error(err);
                return null;
            });
    }

    destroy() {
        this.store.dispatch(new SetHierarchizedIds({
            hierarchizedDeputyIds: [],
            hierarchizedIds: [],
            userIdsWhereIamDeputy: []
        }));
    }
}
