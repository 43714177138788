import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-password-change-form-dialog',
    templateUrl: './password-change-form-dialog.component.html',
})
export class PasswordChangeFormDialogComponent {

    logoutButton = false;

    constructor(
        public dialogRef: MatDialogRef<PasswordChangeFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: boolean) {
        this.logoutButton = data;
    }

    close() {
        this.dialogRef.close({ close: true, logout: false });
    }

    logout() {
        this.dialogRef.close({ close: true, logout: true });
    }
}
