import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as AppInfoActions from 'src/app/redux/app-information/app-information.actions';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppInformationService {
  private _onDestroy = new Subject<void>();

  constructor(    
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService
    ) { }
  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }
  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getVersion() {
    this.http.get<any>(environment.apiUrl + '/appinformation/info')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new AppInfoActions.SetVersion(response.version));
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd w pobieraniu wersji projektu', 'Błąd', 10_000);
          //console.error('błąd');
          console.error(error);
        }
      );
  }
}
