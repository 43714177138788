import { Action } from '@ngrx/store';
import { Dictionary, DictionaryElement } from './dictionary.model';

export const SET_AVAILABLE_DICTIONARIES = '[Dictionary] Set available dictionaries';
export const SET_AVAILABLE_DICTIONARY_ELEMENTS = '[Dictionary] Set available dictionary elements';
export const START_EDITING = '[Dictioanry] Start editing';
export const STOP_EDITING = '[Dictionary] Stop editing';
export const ADD = '[Dictionary] Add new dictionary';
export const ADD_ELEMENT = '[Dictionary] Add new dictionary element';
export const REMOVE = '[Dictionary] Remove dictionary';
export const REMOVE_ELEMENT = '[Dictionary] Remove dictionary element';
export const UPDATE = '[Dictionary] Update dictionary';
export const UPDATE_ELEMENT = '[Dictionary] Update dictionary element';
export const SELECTED_DICTIONARY = '[Dictionary] Selected dictionary';
export const START_LOADING = '[Dictionary] start loading';
export const STOP_LOADING = '[Dictionary] stop loading';

export class SetAvailableDictionaries implements Action {
    readonly type = SET_AVAILABLE_DICTIONARIES;
    constructor(public payload: Dictionary[]) { }
}
export class SetAvailableDictionaryElements implements Action {
  readonly type = SET_AVAILABLE_DICTIONARY_ELEMENTS;
  constructor(public payload: DictionaryElement[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Dictionary) { }
}
export class AddElement implements Action {
  readonly type = ADD_ELEMENT;
  constructor(public payload: DictionaryElement) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class RemoveElement implements Action {
  readonly type = REMOVE_ELEMENT;
  constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Dictionary) { }
}
export class UpdateElement implements Action {
  readonly type = UPDATE_ELEMENT;
  constructor(public payload: DictionaryElement) { }
}
export class SetSelectedDictionary implements Action {
    readonly type = SELECTED_DICTIONARY;
    constructor(public payload: Dictionary) { }
}

export type DictionaryActions =
    SetAvailableDictionaries
    | SetAvailableDictionaryElements
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | AddElement
    | Remove
    | RemoveElement
    | Update
    | UpdateElement
    | SetSelectedDictionary;
