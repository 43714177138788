import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HrComponent, ComponentValueType } from 'src/app/_models/hrComponent.model';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/redux/user/user.model';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EDictionary } from '../../common.reducer';
import { AuthService } from 'src/app/auth/auth.service';
import { AppDateAdapter } from 'src/app/directives/app-date-adapter.adapter';
import { APP_DATE_FORMATS } from '../../material.module';


@Component({
  selector: 'app-create-value',
  templateUrl: './create-value.component.html',
  styleUrls: ['./create-value.component.scss'],
})
export class CreateValueComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();

  eDictionary: EDictionary[] = [];
  loading = false;
  minDate = new Date();
  value = '';
  model: any = {};
  hasEndDate: boolean;
  hasStartDate: boolean;
  endDate: Date;
  startDate: Date;
  selectedComponent: HrComponent;
  selectedUser: User;
  attributes: any[];
  parentGuid: string;

  constructor(
    public dialogRef: MatDialogRef<CreateValueComponent>,
    @Inject(MAT_DIALOG_DATA) data: { selectedComponent: HrComponent, selectedUser: User, attributes: any[], guid?: string },
    private adapter: DateAdapter<any>,
    private http: HttpClient,
    private store: Store<fromRoot.IRootState>,
    public auth: AuthService) {
    this.selectedComponent = data.selectedComponent;
    this.selectedUser = data.selectedUser;
    if (data.attributes) {
      this.attributes = [...data.attributes];
    }
    this.parentGuid = data.guid;

    if (this.attributes) {
      for (let index = 0; index < this.attributes.length; index++) {
        const attribute = this.attributes[index];
        if (attribute.hrComponentDictionaryId && attribute.valueType === ComponentValueType.Slownik) {
          this.getDictionaryValues(attribute);
        }
      }
    }
  }

  ngOnInit() {
    let value = localStorage.getItem('language');
    if (value) {
      if (value === 'ua') {
        value = 'uk';
      }
      this.adapter.setLocale(value);
    }

    this.store.select(fromRoot.getDictionaries)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(dic => this.eDictionary = dic);
  }

  isCurrency(att: HrComponent) {
    if (!att) {
      return false;
    }
    if (att.valueType === ComponentValueType.liczba && att.currency) {
      return true;
    }

    return false;
  }

  isNumber(att: HrComponent) {
    if (!att) {
      return false;
    }
    if (att.valueType === ComponentValueType.liczba) {
      return true;
    }

    return false;
  }

  isDecimalPipe(att: HrComponent) {
    if (!att) {
      return false;
    }
    if (att.decimalPipe) {
      return true;
    }

    return false;
  }

  getInputType(att) {
    if (!att) {
      return 'text';
    }
    switch (att.valueType) {

      case ComponentValueType.liczba:
      case ComponentValueType.Ulamek:
        return 'number';

      case ComponentValueType.Data:
        return 'date';

      default:
        return 'text';
    }
  }

  getAttributes() {
    if (!this.attributes) {
      return [];
    }
    return this.attributes.filter(c => !c.elementId || c.elementId > 0);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  deleteItemFormArray(array: any[], index: number) {
    delete array[index];
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.endDate = new Date(event.value);
    } else {
      this.endDate = null;
    }
  }

  setStartDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.startDate = new Date(event.value);
    } else {
      this.startDate = null;
    }
  }

  submit() {
    this.loading = true;
    let language = localStorage.getItem('language');
    if (!language) {
      language = 'pl';
    }
    this.http.post<any>(environment.apiUrl + '/applicationUser/hrComponent/contact/value', {
      hrComponentId: this.selectedComponent.id,
      // hrComponentModelParentId: this.selectedComponent.parentId,
      values: this.getAttributes().map(att => {
        return { value: this.model[att.id.toString()] ? this.model[att.id.toString()] : '', id: att.id };
      }),
      startDate: this.startDate ? this.startDate.getTime() : null,
      endDate: this.endDate ? this.endDate.getTime() : null,
      userId: this.selectedUser.id,
      fromLanguage: language,
      guid: this.parentGuid
    })
      .toPromise()
      .then(() => {
        this.dialogRef.close();
      })
      .catch(err => {
        this.loading = false;
        console.error(err);
      });
  }

  getValues(attribute) {
    let value = this.model[attribute.id.toString()];
    if (!value) {
      return attribute.__dictionary;
    }
    value = value.toLowerCase();
    return attribute.__dictionary.filter(dic => dic.value.toLowerCase().indexOf(value) === 0);
  }

  private getDictionaryValues(attribute: any) {
    this.http.get<string[]>(environment.apiUrl + `/hrComponent/dictionary/${attribute.hrComponentDictionaryId}`)
      .toPromise()
      .then(values => {
        console.log(values);
        attribute.__dictionary = values;
      })
      .catch(err => console.error(err));
  }
}
