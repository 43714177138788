import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';

@Component({
  selector: 'app-password-reminder-from',
  templateUrl: './password-reminder-from.component.html',
  styleUrls: ['./password-reminder-from.component.scss']
})
export class PasswordReminderFormComponent implements OnInit {

  isLoading$: Observable<boolean>;
  token: string;
  model: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private store: Store<fromRoot.IRootState>
  ) { }

  ngOnInit() {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.route.params.subscribe(p => {
      this.token = p['token'];
    });
  }

  navigateToLoginPage() {
    this.router.navigate(['login']);
  }

  onSubmit() {
    this.authService.resetPassword({
      password: this.model.password1,
      language: localStorage.getItem('language'),
      token: this.token
    });
  }

}
