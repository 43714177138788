import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Renderer2, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Store } from '@ngrx/store';
import { MatSelectChange, MatSelect, MatDatepickerInputEvent, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDatepicker, MatInput } from '@angular/material';
import * as fromRoot from '../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { User, USER_LOC_COORDINATOR_ROLENAME, USER_MANAGER_ROLENAME, USER_PMT_COORDINATOR_ROLENAME, USER_SUPERVISOR_ROLENAME, UserBase, UserListItem } from 'src/app/redux/user/user.model';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { ProjectDetailsComponent } from 'src/app/project/project-details/project-details.component';
import { Order, SettlementKind } from 'src/app/redux/order/order.model';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DictionaryService } from 'src/app/services/dictionary/dictionary.service';
import { EmploymentStatus, Service, ServiceDetails, ServiceParameters, SettlementStatus, SettlementType, UpdateServiceModel } from 'src/app/redux/service/service.model';
import { ServiceService } from 'src/app/services/service/service.service';
import { UserSearchBarWithRolesComponent } from 'src/app/shared/user-search-bar-with-roles/user-search-bar-with-roles.component';
import { UserService } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    //dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ServiceDetailsComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  date = new FormControl(moment());
  private _onDestroy = new Subject<void>();
  isLoading = false;
  model: ServiceDetails = {};
  updateServiceModel: UpdateServiceModel = {};

  isEditing = false;
  serviceApp: ServiceDetails;
  selectedMerch: UserListItem;
  selectedCoordinator: UserListItem;
  selectedManager: UserListItem;
  allMerches: UserListItem[] = [];
  allManagers: UserListItem[] = [];
  allMerchandisingCoordinators: UserListItem[] = [];
  allLocalizationCoordinators: UserListItem[] = [];
  public fullScreen: boolean;
  placeholder = 'Rozliczany miesiąc';
  allUsers: User[] = [];
  rewardAmount: 0;
  totalHours: 0;
  netSalary: 0;
  grosSalary: 0;
  budgetSalary: 0;
  selectedOrder: Order = {};
  extraUsersForSearch: UserListItem[] = [];
  selectedServiceId: number;
  selectedServicesIds: number[];

  error = false;

  isMerchView: boolean = false;
  isOutsView: boolean = false;
  isLogisticView: boolean = false;
  isPromotionView: boolean = false;

  isMultipleEdit = false;

  //selectBox
  private _step = 630;
  RELOAD_TOP_SCROLL_POSITION = this._step;

  merchSelectControl = new FormControl('');

  searchControl2: FormControl = new FormControl();
  filteredMerchandisingCoordinators: ReplaySubject<UserListItem[]> = new ReplaySubject<UserListItem[]>(1);
  @ViewChild('coordinatorSelect', { static: false }) selectElem2: MatSelect;

  //selectBox manager
  searchControlManager: FormControl = new FormControl();
  private _filteredManagers: UserListItem[] = [];
  filteredManagers: ReplaySubject<UserListItem[]> = new ReplaySubject<UserListItem[]>(1);
  managerSelectOpened = false;

  @ViewChild('managerSelect', { static: false }) selectElem5: MatSelect;
  @ViewChild('input1', { static: false }) input1: MatInput;

  @ViewChild('merchSelect', { static: false }) set merchInput(
    input: UserSearchBarWithRolesComponent
  ) {
    if (input) {
      this._merchSelectInput = input;
      this.applyMerchValue();
    }
  }

  applyMerchValue() {
    if (this._merchSelectInput) {
      this._merchSelectInput.applyDefaultValue(this.model.serviceEmployee);
    }
  }

  private _merchSelectInput: UserSearchBarWithRolesComponent;

  constructor(
    public auth: AuthService,
    private service: ServiceService,
    private store: Store<fromRoot.IRootState>,
    private render: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const idParam = params['id'];
      this.selectedServiceId = idParam;

      const idsSplit = idParam.split(',');
      if (idsSplit.length > 1) {
        this.isMultipleEdit = true;
        this.selectedServicesIds = idsSplit;
        this.startEditing();
      }
      else {
        this.selectedServiceId = idParam;
      }
    });

    this.store.select(fromRoot.getSelectedServiceProjectKindId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(projectKindId => {
        if (projectKindId !== null && projectKindId !== 0) {
          switch (projectKindId) {
            case 8:
              this.isPromotionView = true;
              break;
            case 12:
            case 11:
              this.isLogisticView = true;
              break;
            case 7:
              this.isOutsView = true;
            default:
              this.isMerchView = true;
              break;
          }
        }
      });

    this.store.select(fromRoot.getSelectedUserAdditionalData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        if (res !== null) {
          this.model.employmentStatus = res.employmentStatus;
        }
      }
      );

    this.store.select(fromRoot.getServiceError)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(error => {
        this.error = error
      });

    this.store.select(fromRoot.getServiceIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.isLoading = res
      });

    this.store.select(fromRoot.getLocalizationCoordinatorsListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result) => {
        this.allLocalizationCoordinators = result;
      });

    this.store.select(fromRoot.getServiceIsEditing)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.isEditing = res;
        this.OnIsEditChanged();
      });

    this.store.select(fromRoot.getPmtCoordinatorsListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result) => {
        this.allMerchandisingCoordinators = result;
        this.filterCoordinators();
      });

    this.store.select(fromRoot.getManagersListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result) => {
        this.allManagers = result;
        this.filterManagers();
      });
    this.initModel();
  }

  ngAfterViewInit() {
    if (this.selectedServiceId !== null && this.selectedServiceId !== undefined && this.selectedServiceId != 0 && !this.isMultipleEdit) {
      this.service.getService(this.selectedServiceId);
    }
    else if (this.isMultipleEdit) {
      this.service.getServiceProjectKind(this.selectedServicesIds[0]);
    }

    this.userService.getUsersByRoleName(USER_PMT_COORDINATOR_ROLENAME);
    this.userService.getUsersByRoleName(USER_SUPERVISOR_ROLENAME);
    this.userService.getUsersByRoleName(USER_LOC_COORDINATOR_ROLENAME);
    this.userService.getUsersByRoleName(USER_MANAGER_ROLENAME);

    this.store.select(fromRoot.getServiceSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        res !== null ? this.serviceApp = res : this.serviceApp = {};
        this.model = { ...this.serviceApp };

        if (res !== null && res !== undefined) {
          switch (res.order.project.projectKind) {
            case 'Promocje':
              this.isPromotionView = true;
              break;
            case 'Logistyka':
            case 'Badania':
              this.isLogisticView = true;
              break;
            case 'Outsourcing':
              this.isOutsView = true;
            default:
              this.isMerchView = true;
              break;
          }

          this.selectedManager = res.manager;
          this.reloadExtraUsersForSearch();
          if (this.model.employeeLumpSum) {
            this.model.employeeLumpSum = Number(this.model.employeeLumpSum.toFixed(2));
          }
          if (this.model.employeeRate) {
            this.model.employeeRate = Number(this.model.employeeRate.toFixed(2));
          }
          if (this.model.employeeRateBudget) {
            this.model.employeeRateBudget = Number(this.model.employeeRateBudget.toFixed(2));
          }
        }
      });

    this.store.select(fromRoot.getOrderSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.selectedOrder = res;
      });

    this.searchControlManager.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.managerSelectOpened || this._filteredManagers.length === 0) {
          this.filterManagers();
        }
      });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  reloadExtraUsersForSearch() {
    this.extraUsersForSearch = [];
    if (this.model.supervisor !== null && this.model.supervisor !== undefined) {
      this.extraUsersForSearch.push(this.model.supervisor);
    }
    if (this.model.manager !== null && this.model.manager !== undefined) {
      this.extraUsersForSearch.push(this.model.manager);
    }
    if (this.model.merchandisingCoordinator !== null && this.model.merchandisingCoordinator !== undefined) {
      this.extraUsersForSearch.push(this.model.merchandisingCoordinator);
    }
  }

  cancel(): void {
    this.service.stopEditing();
    this.model = { ...this.serviceApp };
  }

  initModel() {
    this.serviceApp = {};
    this.serviceApp.id = 0;
    this.serviceApp.order = this.selectedOrder;
    this.serviceApp.orderId = this.selectedOrder.id;
    this.serviceApp.contractorLumpSum = this.selectedOrder.contractorLumpSum;
    this.serviceApp.contractorMonthLimit = this.selectedOrder.contractorMonthLimit;
    this.serviceApp.contractorWeekLimit = this.selectedOrder.contractorWeekLimit;
    this.serviceApp.settlementKind = this.selectedOrder.settlementKind;
    this.serviceApp.settlementKindEmployee = this.selectedOrder.settlementKindEmployee;
    this.serviceApp.employeeLumpSum = this.selectedOrder.employeeLumpSum;
    this.serviceApp.employeeMonthLimit = this.selectedOrder.employeeMonthLimit;
    this.serviceApp.employeeWeekLimit = this.selectedOrder.employeeWeekLimit;
    this.serviceApp.additionalHours = this.selectedOrder.employeeAdditionalHours;
    this.serviceApp.manager = this.selectedOrder.manager;
    if (this.serviceApp.manager) {
      this.serviceApp.managerId = this.serviceApp.manager.id;
    }
    this.serviceApp.merchandisingCoordinator = this.selectedOrder.merchandisingCoordinator;
    if (this.serviceApp.merchandisingCoordinator) {
      this.serviceApp.merchandisingCoordinatorId = this.serviceApp.merchandisingCoordinator.id;
    }
    this.serviceApp.settlementMonth = this.selectedOrder.settlementMonth;
    this.serviceApp.settlingPerson = {};
    this.serviceApp.createdUser = {};
    this.serviceApp.serviceEmployee = {};
    this.serviceApp.settlingPerson = {};

    this.serviceApp.settlementFinishLong = 0;
    this.serviceApp.settlementType = SettlementType.Main;
    this.serviceApp.order = {};
    this.serviceApp.order.project = {};
    this.serviceApp.order.localization = {};
    this.serviceApp.order.localization.market = {};


    this.model = { ...this.serviceApp };
  }

  OnIsEditChanged() {
  }

  startEditing() {
    this.service.startEditing();
  }

  navigateBackToList() {
    this.router.navigate(['service-list']);
  }

  navigateBack() {
    window.close();
  }

  save() {
    console.log(this.model);
    this.service.stopEditing();

    if (!this.model.id || this.model.id === 0) {
      if (this.model.settlementMonth) {
        this.model.settlementMonth = new Date(this.model.settlementMonth.toString());
        this.model.settlementMonthLong = this.model.settlementMonth.getTime();
      }
      this.service.createServiceV2(this.model);
    } else {

      this.service.updateServiceV2(this.model, true, undefined, undefined, undefined, false);
      this.serviceApp = { ...this.model };
    }
  }

  filterCoordinators() {

    if (!this.allMerchandisingCoordinators) {
      return;
    }
    // get the search keyword
    let search = this.searchControl2.value;
    if (!search) {
      this.filteredMerchandisingCoordinators.next(this.allMerchandisingCoordinators.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the settling persons
    this.filteredMerchandisingCoordinators.next(
      this.allMerchandisingCoordinators.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  filterManagers() {

    if (!this.allManagers) {
      return;
    }
    // get the search keyword
    let search = this.searchControlManager.value;
    if (!search) {
      this.filteredManagers.next(this.allManagers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the settling persons
    this.filteredManagers.next(
      this.allManagers.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  compareFn(merch1: UserListItem, merch2: UserListItem) {
    return merch1 && merch2 ? merch1.id === merch2.id : false;
  }

  merchSelectionChange(user: UserListItem) {
    if (user !== null && user !== undefined) {
      this.selectedMerch = user;
      this.model.serviceEmployee = user;
      this.model.serviceEmployeeId = user.id;
      this.userService.getUserAdditionalData(user.id);
    }
  }

  convertUserToListItem(user: UserBase) {
    if (user === null) {
      return null;
    }
    if (Object.keys(user).length === 0) {
      return null;
    }
    const userItem: UserListItem =
    {
      firstName: user.firstName,
      id: user.id,
      lastName: user.lastName,
      userName: user.userName
    }
    return userItem
  }

  coordinatorChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedCoordinator = e.value;
      this.model.merchandisingCoordinator = e.value;
      this.model.merchandisingCoordinatorId = e.value.id;
      this.reloadExtraUsersForSearch();
    }
  }

  managerChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedManager = e.value;
      this.model.manager = e.value;
      this.model.managerId = e.value.id;
      this.reloadExtraUsersForSearch();
    }
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.model.settlementStart = new Date(event.value);
      this.model.settlementStartLong = this.model.settlementStart.getTime();
    } else {
      this.model.settlementStart = null;
      this.model.settlementStartLong = 0;
    }
  }

  setDate2(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.model.settlementFinish = new Date(event.value);
      this.model.settlementFinishLong = this.model.settlementFinish.getTime();
    } else {
      this.model.settlementFinish = null;
      this.model.settlementFinishLong = 0;
    }
  }

  setDate3(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.input1.placeholder = '';
      this.model.settlementMonth = new Date(event.value);
      this.model.settlementMonthLong = this.model.settlementMonth.getTime();
    } else {
      this.input1.placeholder = 'Rozliczany miesiąc';
      this.model.settlementMonth = null;
      this.model.settlementMonthLong = 0;
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.model.settlementMonth = this.date.value.toDate();
  }

  settlementGenerate() {

  }

  checkSpecialistPermission() {
    return this.auth.appRoles.s39;
  }

  onAcceptedLimitChange(e) {
    if (this.checkSpecialistPermission() && this.model.settlementType === SettlementType.Main) {
      this.model.acceptedLimitOriginal = this.model.acceptedLimit;
      this.model.employeeAcceptedLimitOriginal = this.model.acceptedLimit;
    }
    this.model.employeeAcceptedLimit = this.model.acceptedLimit;
  }

  onEmployeeAcceptedLimitChange(e) {
    if (this.checkSpecialistPermission() && this.model.settlementType === SettlementType.Main) {
      this.model.employeeAcceptedLimitOriginal = this.model.employeeAcceptedLimit;
    }

  }

  onEmployeeRateChange(e) {

  }

  onEmployeeBudgetRateChange(e) {
  }

  ontransferredHoursChange(e) {
  }

  onAdditionalHoursChange(e) {
    if (this.checkSpecialistPermission() && this.model.settlementType === SettlementType.Main) {
      this.model.additionalHoursOriginal = this.model.additionalHours;
    }
  }

  onReclamationHoursChange(e) {
    if (this.checkSpecialistPermission() && this.model.settlementType === SettlementType.Main) {
      this.model.reclamationHoursOriginal = this.model.reclamationHours;
    }
  }

  employeeSettlementKindChange(e) {

    if (this.model.settlementKindEmployee === SettlementKind.LimitTygodniowy) {
      this.model.employeeMonthLimit = 0;
      this.model.employeeLumpSum = 0;
    }
    if (this.model.settlementKindEmployee === SettlementKind.LimitMiesieczny) {
      this.model.employeeWeekLimit = 0;
      this.model.employeeLumpSum = 0;
    }
    if (this.model.settlementKindEmployee === SettlementKind.Ryczalt) {
      this.model.employeeWeekLimit = 0;
      this.model.employeeMonthLimit = 0;
    }
  }

  onCutHoursChange(e) {
  }

  onCalculateTotalBudget(e) {
  }

  onEmployeeLumpSumChanged(e) {
    if (!this.isMultipleEdit) {
      if (this.model.settlementKindEmployee === SettlementKind.Ryczalt) {
        this.model.employeeRate = this.model.employeeLumpSum;
      }
    }
  }

  supervisorAccept() {
    this.service.setServiceStatus(SettlementStatus.SupervisorAcceptation, this.model.id);
  }

  specialistAccept() {
    this.service.setServiceStatus(SettlementStatus.SpecialistAcceptation, this.model.id);
  }

  paymentAccept() {
    this.service.setServiceStatus(SettlementStatus.PaymentAcceptation, this.model.id);
  }

  cancelSettlement() {
    this.service.setServiceStatus(SettlementStatus.Cancelled, this.model.id);
  }

  resetStatus() {
    this.service.setServiceStatus(SettlementStatus.SpecialistAcceptation, this.model.id);
  }

  updateSelectedServices() {
    const serviceParams: ServiceParameters = { serviceIds: this.selectedServicesIds, params: [] };

    if (this.updateServiceModel.settlementStart) {
      serviceParams.params.push({ param: 'settlementStart', value: this.model.settlementStart ? this.model.settlementStart.getTime() : 0 });
    }
    if (this.updateServiceModel.settlementFinish) {
      serviceParams.params.push({ param: 'settlementFinish', value: this.model.settlementFinish ? this.model.settlementFinish.getTime() : 0 });
    }
    if (this.updateServiceModel.manager) {
      serviceParams.params.push({ param: 'manager', value: this.model.manager ? this.model.manager.id : '' });
    }
    if (this.updateServiceModel.merchandisingCoordinator) {
      serviceParams.params.push({ param: 'merchandisingCoordinator', value: this.model.merchandisingCoordinator ? this.model.merchandisingCoordinator.id : '' });
    }
    if (this.updateServiceModel.serviceEmployee) {
      serviceParams.params.push({ param: 'serviceEmployee', value: this.model.serviceEmployee ? this.model.serviceEmployee.id : '' });
    }

    const propertyNames = [
      'settlementNotes',
      'settlementKind',
      'contractorLumpSum',
      'contractorWeekLimit',
      'contractorMonthLimit',
      'materialCost',
      'contractorBonus',
      'contractorCommissionRateBudget',
      'coordinationRate',
      'reserveManager',
      'clientNumberHoursFV',
      'settlementKindEmployee',
      'employeeHoursOrdered',
      'kpi',
      'employeeCostOfArrival',
      'employeeSaving',
      'employeeLumpSum',
      'employeeWeekLimit',
      'employeeMonthLimit',
      'employeeRate',
      'additionalHours',
      'reclamationHours',
      'employeeDeductionAmount',
      'employeeReasonForCorrection',
      'rewardHours',
      'cutHours',
      'cutNotes',
      'transferredHours',
      'contractorBudget'
    ];
    propertyNames.forEach(propertyName => {
      if (this.updateServiceModel[propertyName]) {
        serviceParams.params.push({ param: propertyName, value: this.model[propertyName] });
      }
    });
    this.service.updateSelectedServices(serviceParams);
    this.service.stopEditing();
  }

  clearServiceEmployee() {
    this._merchSelectInput.clear();
    this.model.serviceEmployee = null;
    this.model.serviceEmployeeId = null;
    this.model.employmentStatus = null;
  }

  clientSettlementKindChange() {
    if (this.model.settlementKind === SettlementKind.LimitTygodniowy) {
      this.model.contractorMonthLimit = 0;
      this.model.contractorLumpSum = 0;
    }
    if (this.model.settlementKind === SettlementKind.LimitMiesieczny) {
      this.model.contractorWeekLimit = 0;
      this.model.contractorLumpSum = 0;
    }
    if (this.model.settlementKind === SettlementKind.Ryczalt) {
      this.model.contractorWeekLimit = 0;
      this.model.contractorMonthLimit = 0;
    }
    //this.setWeekFactorForCurrentDay();
  }
}
