import { Action } from '@ngrx/store';
import { EDictionary } from './common.reducer';

export const SET_AVAILABLE_DICTIONARIES = '[common] Set available dictionaries';
export const ADD_DICTIONARY = '[common] add dictionary';
export const REMOVE_DICTIONARY = '[common] remove dictionary';
export const UPDATE_DICTIONARY = '[common] Update dictionary';
export const SET_LANGUAGE = '[common] set language';

export class SetAvailableDictionaries implements Action {
    readonly type = SET_AVAILABLE_DICTIONARIES;
    constructor(public payload: EDictionary[]) { }
}
export class AddDictionary implements Action {
    readonly type = ADD_DICTIONARY;
    constructor(public payload: EDictionary) { }
}
export class RemoveDictionay implements Action {
    readonly type = REMOVE_DICTIONARY;
    constructor(public payload: number) { }
}
export class SetLanguage implements Action {
    readonly type = SET_LANGUAGE;
    constructor(public payload: string) { }
}
export class UpdateDictionary implements Action {
    readonly type = UPDATE_DICTIONARY;

    constructor(public payload: EDictionary) { }
}

export type CommonActions =
    SetAvailableDictionaries
    | AddDictionary
    | SetLanguage
    | RemoveDictionay
    | UpdateDictionary;
