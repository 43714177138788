import { Service, ServiceDetails } from './service.model';
import {
    ServiceActions,
    SELECTED_SERVICE,
    ADD,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_SERVICES,
    SET_USER_SERVICES,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING,
    SET_RELOAD_DATA_GRID,
    SET_ERROR,
    SET_TOTAL_SAVINGS,
    SELECTED_SERVICE_PROJECT_KIND,
    STOP_SM_LOADING,
    START_SM_LOADING
} from './service.actions';
import { ApplicationData } from 'src/app/auth/user.model';

export interface State {
    services: Service[];
    userServices: Service[];
    selectedService: ServiceDetails;
    isEditing: boolean;
    isLoading: boolean;
    isLoadingSM: boolean;
    reloadDataGrid: boolean;
    error : boolean;
    totalSavings: number;
    selectedServiceProjectKindId: number;
}
const initialState: State = {
    services: [],
    userServices: [],
    selectedService: null,
    isEditing: false,
    isLoading: false,
    isLoadingSM: false,
    reloadDataGrid: false,
    error: false,
    totalSavings: 0,
    selectedServiceProjectKindId: 0,
};

export function serviceReducer(state = initialState, action: ServiceActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case START_SM_LOADING: {
            return {
                ...state,
                isLoadingSM: true
            };
        }
        case STOP_SM_LOADING: {
            return {
                ...state,
                isLoadingSM: false
            };
        }
        case SELECTED_SERVICE: {
            return {
                ...state,
                selectedService: action.payload
            };
        }
        case ADD: {
            const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
            const checkUser = action.payload.serviceEmployee && action.payload.serviceEmployee.userName === currentUser.userName;

            return {
                ...state,
                services: [...state.services, action.payload],
                userServices: checkUser ? [...state.services, action.payload] : state.userServices
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_SERVICES: {
            return {
                ...state,
                services: action.payload
            };
        }
        case SET_USER_SERVICES: {
          return {
              ...state,
              userServices: action.payload
          };
      }
        case UPDATE: {
            const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));

            const index = state.services.map(d => d.id).indexOf(action.payload.id);
            const userProjectindex = state.userServices.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selProject = state.selectedService;
            if (selProject && selProject.id === action.payload.id) {
              selProject = action.payload;
            }
            const updatedProjects = [
                ...state.services.slice(0, index),
                Object.assign({}, state.services[index], action.payload),
                ...state.services.slice(index + 1)
            ];

            let updatedUserProjects = state.userServices;
            if (userProjectindex !== -1) {
              updatedUserProjects = [
              ...state.userServices.slice(0, userProjectindex),
              Object.assign({}, state.userServices[userProjectindex], action.payload),
              ...state.userServices.slice(userProjectindex + 1)];

              // delete from userOrders if current user or replacement user are not current user
              if (action.payload.serviceEmployee &&
                action.payload.serviceEmployee.userName !== currentUser.userName ) {
                  updatedUserProjects = state.userServices.filter(c => c.id !== action.payload.id);
              }

              // add to user orders if they do not exist before
              if (action.payload.serviceEmployee &&
                action.payload.serviceEmployee.userName === currentUser.userName ) {
                  if (state.services.map(d => d.serviceEmployee.id).indexOf(action.payload.serviceEmployee.id) < 0) {
                    updatedUserProjects = [...state.userServices, action.payload];
                  }
              }
            }

            return {
                ...state,
                services: updatedProjects,
                userServices: updatedUserProjects,
                selectedService: selProject
            };
        }
        case REMOVE: {
            return {
                ...state,
                services: state.services.filter(c => c.id !== action.payload),
                userServices: state.userServices.filter(c => c.id !== action.payload)
            };
        }
        case SET_RELOAD_DATA_GRID: {
            return {
                ...state,
                reloadDataGrid: action.payload
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case SET_TOTAL_SAVINGS: {
            return {
                ...state,
                totalSavings: action.payload
            };
        }
        case SELECTED_SERVICE_PROJECT_KIND: {
            return {
                ...state,
                selectedServiceProjectKindId: action.payload
            };
        }

        
        default: {
            return state;
        }
    }
}

export const services = (state: State) => state.services;
export const userServices = (state: State) => state.userServices;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const isLoadingSM = (state: State) => state.isLoadingSM;
export const selectedService = (state: State) => state.selectedService;
export const reloadDataGrid = (state: State) => state.reloadDataGrid;
export const error = (state: State) => state.error;
export const totalSavings = (state: State) => state.totalSavings;
export const selectedServiceProjectKindId = (state: State) => state.selectedServiceProjectKindId;