import { Action } from '@ngrx/store';
import { ProjectSimple } from '../project/project.model';
import { ProjectKindSimple } from '../contractor/project-kind.model';
import { WorkScheduleEmployee } from '../work-schedule/work-schedule.model';

export const START_LOADING = '[WorkLog] start loading';
export const STOP_LOADING = '[WorkLog] stop loading';
export const SET_PROJECT_KIND_LIST = '[WorkLog] set project kind list';
export const SET_PROJECT_LIST = '[WorkLog] set project list';
export const SET_EMPLOYEE_LIST = '[WorkLog] set coordinator employee list';

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export class SetProjectKindList implements Action {
    readonly type = SET_PROJECT_KIND_LIST;
    constructor(public payload: ProjectKindSimple[]) { }
}

export class SetProjectList implements Action {
    readonly type = SET_PROJECT_LIST;
    constructor(public payload: ProjectSimple[]) { }
}

export class SetEmployeeList implements Action {
    readonly type = SET_EMPLOYEE_LIST;
    constructor(public payload: WorkScheduleEmployee[]) { }
}

export type WorkLogActions =
    StartLoading
    | StopLoading
    | SetProjectKindList
    | SetProjectList
    | SetEmployeeList;
