import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { OrderTask, OosChart, WorkTimeChart } from 'src/app/redux/order-task/order-task.model';
import * as OrderTaskActions from 'src/app/redux/order-task/order-task.actions';

@Injectable()
export class OosChartService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();
  }

  getOosProductsByProductGroup(startDate: number, finishDate: number, projectId: number) {
    this.http.get<OosChart[]>(environment.apiUrl + '/ordertask/chart/oosbyproductgroup/' + startDate + '/' + finishDate + '/' + projectId)
      .toPromise()
      .then(
        (products) => {
          this.store.dispatch(new OrderTaskActions.SetChartOosProductsByGroup(products));
         })
    .catch(err => {
        this.store.dispatch(new OrderTaskActions.SetChartOosProductsByGroup(null));
        console.error(err);
    });
  }

  getOosProductsBySalesRepresentative(startDate: number, finishDate: number, projectId: number) {
    this.http.get<OosChart[]>(environment.apiUrl + '/ordertask/chart/oosbysalesrepresentative/' + startDate + '/' + finishDate + '/' + projectId)
      .toPromise()
      .then(
        (products) => {
          this.store.dispatch(new OrderTaskActions.SetChartOosProductsBySalesRepresentative(products));
         })
    .catch(err => {
        this.store.dispatch(new OrderTaskActions.SetChartOosProductsBySalesRepresentative(null));
        console.error(err);
    });
  }

  getOosProductsByLocalization(startDate: number, finishDate: number, projectId: number) {
    this.http.get<OosChart[]>(environment.apiUrl + '/ordertask/chart/oosbymarket/' + startDate + '/' + finishDate + '/' + projectId)
      .toPromise()
      .then(
        (products) => {
          this.store.dispatch(new OrderTaskActions.SetChartOosProductsByLocalization(products));
         })
    .catch(err => {
        this.store.dispatch(new OrderTaskActions.SetChartOosProductsByLocalization(null));
        console.error(err);
    });
  }

  getWorktimeByMarket(startDate: number, projectId: number) {
    this.http.get<WorkTimeChart[]>(environment.apiUrl + '/worktime/chartbymarket/' + startDate + '/' + projectId)
      .toPromise()
      .then(
        (workTime) => {
          this.store.dispatch(new OrderTaskActions.SetChartWorkTimeByMarket(workTime));
         })
    .catch(err => {
        this.store.dispatch(new OrderTaskActions.SetChartWorkTimeByMarket(null));
        console.error(err);
    });
  }
}
