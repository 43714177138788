import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { PasswordConfig } from 'src/app/_models/password-config.model';
import { User } from 'src/app/redux/user/user.model';

@Component({
    selector: 'app-password-change-form',
    templateUrl: './password-change-form.component.html',
    styleUrls: ['./password-change-form.component.scss']
})
export class PasswordChangeFormComponent implements OnInit, OnDestroy {
    private _onDestroy = new Subject<void>();
    passwordConfig: PasswordConfig;
    isLoading$: Observable<boolean>;
    model: any = {};

    @Input() user: User;

    @Input() onlyValidation: boolean;
    @Input() set newPassword(value: string) {
        this.model.password1 = value;
        this.validate();
    }
    @Input() set newPasswordRepeat(value: string) {
        this.model.password2 = value;
        this.validate();
    }

    @Output() validated = new EventEmitter<boolean>();

    @Output() passwordchanged = new EventEmitter();
    @Output() changed = new EventEmitter<{ user: User, password: string }>();

    specialCharacter = false;
    capitalLetter = false;
    empty = false;
    digit = false;
    lengthOfPassword = false;
    similar = false;

    constructor(private auth: AuthService, private store: Store<fromRoot.IRootState>) { }

    ngOnInit() {
        this.isLoading$ = this.store.select(fromRoot.getIsLoading);
        this.store.select(fromRoot.getCurrentCompany)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((company) => {
                if (company) {
                    this.model.companyId = company.id;
                }
            });
        this.store.select(fromRoot.getCurrentUser)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((user) => {
                if (user) {
                    this.model.applicationUserId = user.id;
                }
            });
        this.store.select(fromRoot.passwordConfig)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((config) => {
                if (config) {
                    this.passwordConfig = config;
                }
            });

        setTimeout(() => {
            this.auth.getPasswordConfig();
        }, 100);
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onSubmit() {
        if (!this.user) {
            const post = {
                oldPassword: this.model.oldPassword,
                newPassword: this.model.password1,
                applicationUserId: this.model.applicationUserId,
                companyId: this.model.companyId
            };
            this.auth.changePassword(post)
                .then(() => {
                    this.passwordchanged.next();
                });
        }
        this.changed.next({ user: this.user, password: this.model.password1 });
    }

    canSubmit(): boolean {
        return this.specialCharacter
            && this.capitalLetter
            && this.empty
            && this.digit
            && this.lengthOfPassword
            && this.similar;
    }

    validate() {
        if (!this.passwordConfig) {
            return;
        }

        this.model.password1 === this.model.password2 && this.model.password1
            ? this.similar = true
            : this.similar = false;

        if (!this.user && !this.onlyValidation) {
            this.model.password1 && this.model.password2 && this.model.oldPassword
                ? this.empty = true
                : this.empty = false;
        } else {
            this.empty = true;
        }

        if (this.passwordConfig.capitalLetter) {
            if (!this.model.password1) {
                this.capitalLetter = false;
            } else {
                const password: string = this.model.password1;
                this.capitalLetter = password.toLocaleLowerCase() !== password;
            }
        } else {
            this.capitalLetter = true;
        }

        if (this.passwordConfig.specialCharacter) {
            if (!this.model.password1) {
                this.specialCharacter = false;
            } else {
                const password: string = this.model.password1;
                const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
                this.specialCharacter = format.test(password);
            }
        } else {
            this.specialCharacter = true;
        }

        if (this.passwordConfig.digit) {
            if (!this.model.password1) {
                this.digit = false;
            } else {
                const password: string = this.model.password1;
                const format = /[1234567890]/;
                this.digit = format.test(password);
            }
        } else {
            this.digit = true;
        }

        if (!this.model.password1) {
            this.lengthOfPassword = false;
        } else {
            const password: string = this.model.password1;
            this.lengthOfPassword = password.length >= this.passwordConfig.lengthOfPassword;
        }

        this.validated.next(this.canSubmit());
    }

}
