import { Action } from '@ngrx/store';
import { AccessConfig } from './access-config.model';

export const SET_AVAILABLE_ACCESS_CONFIGS = '[Access config] Set available access configs';
export const START_EDITING = '[Access config] Start editing';
export const STOP_EDITING = '[Access config] Stop editing';
export const ADD = '[Access config] Add new access config';
export const REMOVE = '[Access config] Remove access config';
export const UPDATE = '[Access config] Update access config';
export const START_LOADING = '[Access config] start loading';
export const STOP_LOADING = '[Access config] stop loading';

export class SetAvailableAccessConfigs implements Action {
    readonly type = SET_AVAILABLE_ACCESS_CONFIGS;
    constructor(public payload: AccessConfig[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: AccessConfig) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: AccessConfig) { }
}
export type AccessConfigActions =
    SetAvailableAccessConfigs
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | Remove
    | Update;
