import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as ProjectActions from 'src/app/redux/project/project.actions';
import * as ParamsAndToolsActions from 'src/app/redux/params-and-tools/params-and-tools.actions';
import { environment } from 'src/environments/environment';
import { Project, ProjectBudget, ProjectForOrder } from 'src/app/redux/project/project.model';
import { saveAs as importedSaveAs } from 'file-saver';
import { Product, ProductPhoto, AttachLocalization, LocalizationProduct } from 'src/app/redux/project/product.model';
import * as ProductActions from 'src/app/redux/project/product.actions';
import { ProjectKindSimple } from 'src/app/redux/contractor/project-kind.model';

@Injectable()
export class ProjectService {
  private _onDestroy = new Subject<void>();

  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.unSelectProject();
  }

  stopEditing() {
    this.store.dispatch(new ProjectActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new ProjectActions.StartEditing());
  }

  selectProject(project: Project) {
    this.store.dispatch(new ProjectActions.SetSelectedProject(project));
  }

  selectProjectForCharts(project: Project) {
    this.store.dispatch(new ProjectActions.SetSelectedProjectForCharts(project));
  }

  selectProduct(product: Product) {
    this.store.dispatch(new ProductActions.SetSelectedProduct(product));
  }

  selectProjectBudget(projectBudget: ProjectBudget) {
    this.store.dispatch(new ProjectActions.SetSelectedProjectBudget(projectBudget));
  }

  unSelectProject() {
    this.store.dispatch(new ProjectActions.SetSelectedProject(null));
  }

  unSelectProduct() {
    this.store.dispatch(new ProductActions.SetSelectedProduct(null));
  }

  unSelectProjectList() {
    this.store.dispatch(new ParamsAndToolsActions.SetProjectList(null));
  }

  unSelectProjectBudget() {
    this.store.dispatch(new ProjectActions.SetSelectedProjectBudget(null));
  }

  stopProductEditing() {
    this.store.dispatch(new ProductActions.StopEditing());
  }

  startProductEditing() {
    this.store.dispatch(new ProductActions.StartEditing());
  }

  setSelectedProducts(products: Product[]) {
    this.store.dispatch(new ProductActions.SetSelectedProducts(products));
  }

  clearProductPhotos() {
    this.store.dispatch(new ProductActions.SetAvailableProductPhotos(null));
  }

  getProjects() {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.get<Project[]>(environment.apiUrl + '/project')
      .toPromise()
      .then(
        (projects) => {
          this.store.dispatch(new ProjectActions.SetAvailableProjects(projects));
          this.store.dispatch(new ProjectActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.SetAvailableProjects(null));
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  getProjectKindList() {
    this.store.dispatch(new ParamsAndToolsActions.StartProjectKindLoading());
    this.http.get<ProjectKindSimple[]>(environment.apiUrl + '/project/v2/projectKinds')
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new ParamsAndToolsActions.SetProjectKindList(res));
          this.store.dispatch(new ParamsAndToolsActions.StopProjectKindLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetProjectKindList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopProjectKindLoading());
        console.error(err);
      });
  }

  getProjectListForOrderTasks(startTime: number, endTime: number, projectKind: number) {
    this.store.dispatch(new ParamsAndToolsActions.StartProjectLoading());
    this.http.get<ProjectForOrder[]>(environment.apiUrl + '/project/v2/project-list-for-ordertasks?start=' + startTime.toString() + '&finish=' + endTime.toString() + '&projectKindId=' + projectKind)
      .toPromise()
      .then(
        (projects) => {
          this.store.dispatch(new ParamsAndToolsActions.SetProjectList(projects));
          this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetProjectList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        console.error(err);
      });
  }

  getProjectListForServices(startTime: number, endTime: number, projectKind: number) {
    this.store.dispatch(new ParamsAndToolsActions.StartProjectLoading());
    this.http.get<ProjectForOrder[]>(environment.apiUrl + '/project/v2/project-list-for-services?projectKindId=' + projectKind + '&start=' + startTime.toString() + '&finish=' + endTime.toString())
      .toPromise()
      .then(
        (projects) => {
          this.store.dispatch(new ParamsAndToolsActions.SetProjectList(projects));
          this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetProjectList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        console.error(err);
      });
  }

  getProjectListForOrders(projectKind: number, current: boolean) {
    this.store.dispatch(new ParamsAndToolsActions.StartProjectLoading());
    this.http.get<ProjectForOrder[]>(environment.apiUrl + '/project/v2/project-list-for-orders?projectKindId=' + projectKind + '&current=' + current)
      .toPromise()
      .then(
        (projects) => {
          this.store.dispatch(new ParamsAndToolsActions.SetProjectList(projects));
          this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetProjectList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        console.error(err);
      });
  }
  getProjectListForWorktime(startTime: number, endTime: number) {
    this.store.dispatch(new ParamsAndToolsActions.StartProjectLoading());
    this.http.get<ProjectForOrder[]>(environment.apiUrl + '/project/v2/project-list-for-worktime?start=' + startTime.toString() + '&finish=' + endTime.toString())
      .toPromise()
      .then(
        (projects) => {
          this.store.dispatch(new ParamsAndToolsActions.SetProjectList(projects));
          this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetProjectList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        console.error(err);
      });
  }

  getUserProjects() {
    this.store.dispatch(new ParamsAndToolsActions.StartProjectLoading());
    this.http.get<Project[]>(environment.apiUrl + '/project/currentUser/')
      .toPromise()
      .then(
        (projects) => {
          this.store.dispatch(new ProjectActions.SetAvailableProjectsForUser(projects));
          this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.SetAvailableProjectsForUser(null));
        this.store.dispatch(new ParamsAndToolsActions.StopProjectLoading());
        console.error(err);
      });
  }

  loadDataProjectsBudget(loadOptions: any, projectId: number) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });
    return this.http.get<any>(environment.apiUrl + '/project/' + projectId + '/budget-list', { params })
      .toPromise();
  }

  createProjectBudget(budget: ProjectBudget, projectId: number) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.post(environment.apiUrl + '/project/' + projectId + '/budget', budget)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProjectActions.StopLoading());
          this.store.dispatch(new ProjectActions.SetReloadDataGrid(true));
        })
      .catch(err => {
        console.log(err);
        if (err.error && err.error.messages) {
          this.uiService.openSnack(err.error.messages[0], 'Błąd', 10_000);
        } else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }

        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  unsetReloadDataGrid()
  {
    this.store.dispatch(new ProjectActions.SetReloadDataGrid(false));
  }

  updateProjectBudget(budget: ProjectBudget) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.put(environment.apiUrl + '/project/' + budget.projectId + '/budget', budget)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProjectActions.StopLoading());
          this.store.dispatch(new ProjectActions.SetReloadDataGrid(true));
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  blockProjects(projects: Project[]) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.put<Project[]>(environment.apiUrl + '/project/block', projects)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ProjectActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  createProject(formData) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.post<Project>(environment.apiUrl + '/project', formData)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProjectActions.StopLoading());
        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  updateProject(formData) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.put<Project>(environment.apiUrl + '/project', formData)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProjectActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  deleteProject(id: number) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.delete(environment.apiUrl + '/project/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ProjectActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  downloadAttachment(attachmentId, attachmentType, fileName) {
    this.http.get(environment.apiUrl + '/project/attachment/' + attachmentId, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: attachmentType });
        importedSaveAs(blob, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  removeAttachment(attachmentId: number) {
    this.store.dispatch(new ProjectActions.StartLoading());
    this.http.delete(environment.apiUrl + '/project/attachment/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProjectActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProjectActions.StopLoading());
        console.error(err);
      });
  }

  getProjectProducts(projectId: number) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.get<Product[]>(environment.apiUrl + '/project/product/' + projectId)
      .toPromise()
      .then(
        (products) => {
          this.store.dispatch(new ProductActions.SetAvailableProducts(products));
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.SetAvailableProducts(null));
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  getProductPhotos(productId: number) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.get<ProductPhoto[]>(environment.apiUrl + '/project/product/photo/' + productId)
      .toPromise()
      .then(
        (photos) => {
          this.store.dispatch(new ProductActions.SetAvailableProductPhotos(photos));
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.SetAvailableProductPhotos(null));
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  deleteProduct(id: number) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.delete(environment.apiUrl + '/project/product/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  updateProduct(product: Product) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.put<Product>(environment.apiUrl + '/project/product', product)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  createProduct(product: Product) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.post<Product>(environment.apiUrl + '/project/product', product)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  removeProductPhoto(attachmentId: number) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.delete(environment.apiUrl + '/project/product/photo/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  downloadProductPhoto(attachment: ProductPhoto) {
    this.http.get(environment.apiUrl + '/project/product/photo/download/' + attachment.attachemntId, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: attachment.fileType });
        importedSaveAs(blob, attachment.fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  attachLocalizationToProduct(attachLocalizations: AttachLocalization[]) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.put<Product>(environment.apiUrl + '/project/product/attachlocalization', attachLocalizations)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  detachLocalizationFromProduct(detachLocalizations: AttachLocalization[]) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.put<Product>(environment.apiUrl + '/project/product/detachlocalization', detachLocalizations)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  async detachMarketIndex(productId: number, marketId: number) {
    this.http.delete(environment.apiUrl + '/project/product/detachmarketindex/' + productId + '/' + marketId)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ProductActions.StopLoading());
          this.uiService.openSnack('Zakończono pomyślnie');
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }

  getLocalizationProductsForReport(orderId: number) {
    this.store.dispatch(new ProductActions.StartLoading());
    this.http.get<Product[]>(environment.apiUrl + '/project/product/order/' + orderId)
      .toPromise()
      .then(
        (products) => {
          this.store.dispatch(new ProductActions.SetAvailableLocalizationProducts(products));
          this.store.dispatch(new ProductActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.SetAvailableLocalizationProducts(null));
        this.store.dispatch(new ProductActions.StopLoading());
        console.error(err);
      });
  }
}
