import { Action } from '@ngrx/store';
import { EmployeeForm } from './employee-form.model';
import { ProjectKindAttachment } from '../contractor/project-kind.model';
import { DocumentApprovement } from './document-approvement.model';

export const SET_AVAILABLE_EMPLOYEEFORMS = '[EmployeeForms] Set available employee forms';
export const SET_AVAILABLE_EMPLOYEEFORMS_FOR_USER = '[EmployeeForms] Set available employee forms for user';
export const SET_AVAILABLE_DOCUMENTS = '[EmployeeForms] Set available documents for approvement';
export const SET_AVAILABLE_DOCUMENTS_APPROVEMENT = '[EmployeeForms] Set available documents approvement';
export const START_EDITING = '[EmployeeForms] Start editing';
export const STOP_EDITING = '[EmployeeForms] Stop editing';
export const ADD = '[EmployeeForms] Add new employee form';
export const ADD_DOCUMENT = '[EmployeeForms] Add new approvement document ';
export const UPDATE_DOCUMENT = '[EmployeeForms] update approvement document ';
export const REMOVE = '[EmployeeForm] Remove employee form';
export const UPDATE = '[EmployeeForms] Update employee form';
export const SELECTED = '[EmployeeForms] Selected employee form';
export const START_LOADING = '[EmployeeForms] start loading';
export const STOP_LOADING = '[EmployeeForms] stop loading';

export class SetAvailableEmployeeForms implements Action {
    readonly type = SET_AVAILABLE_EMPLOYEEFORMS;
    constructor(public payload: EmployeeForm[]) { }
}
export class SetAvailableEmployeeFormsForUser implements Action {
  readonly type = SET_AVAILABLE_EMPLOYEEFORMS_FOR_USER;
  constructor(public payload: EmployeeForm[]) { }
}
export class SetAvailableDocuments implements Action {
  readonly type = SET_AVAILABLE_DOCUMENTS;
  constructor(public payload: ProjectKindAttachment[]) { }
}
export class SetAvailableDocumentApprovement implements Action {
  readonly type = SET_AVAILABLE_DOCUMENTS_APPROVEMENT;
  constructor(public payload: DocumentApprovement[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: EmployeeForm) { }
}
export class AddDocument implements Action {
  readonly type = ADD_DOCUMENT;
  constructor(public payload: DocumentApprovement) { }
}
export class UpdateDocument implements Action {
  readonly type = UPDATE_DOCUMENT;
  constructor(public payload: DocumentApprovement) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: EmployeeForm) { }
}
export class SetSelected implements Action {
    readonly type = SELECTED;
    constructor(public payload: EmployeeForm) { }
}

export type EmployeeFormActions =
    SetAvailableEmployeeForms
    | SetAvailableEmployeeFormsForUser
    | SetAvailableDocuments
    | SetAvailableDocumentApprovement
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | AddDocument
    | UpdateDocument
    | Remove
    | Update
    | SetSelected;
