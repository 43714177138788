
import { ClientTicketDetails, ClientTicketFormDetails, ClientTicketListItem, ClientTicketMessage } from './client-ticket.model';
import { TicketsActions, SET_CLIENT_TICKET_LIST, START_LOADING, STOP_LOADING, SET_CLIENT_TICKET_DETAILS, SET_MESSAGE, SET_CLIENT_TICKET_FORM_DETAILS } from './client-ticket.actions';

export interface State {
    isLoading: boolean,
    clientTicketList: ClientTicketListItem[];
    clientTicketDetails: ClientTicketDetails;
    clientTicketFormDetails: ClientTicketFormDetails;
    message: ClientTicketMessage;
}

const initialState: State = {
    isLoading: false,
    clientTicketList: null,
    clientTicketDetails: null,
    clientTicketFormDetails: null,
    message: null,
};

export function clientTicketReducer(state = initialState, action: TicketsActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_CLIENT_TICKET_LIST: {
            return {
                ...state,
                clientTicketList: action.payload
            };
        }
        case SET_CLIENT_TICKET_DETAILS: {
            return {
                ...state,
                clientTicketDetails: action.payload
            };
        }        
        case SET_CLIENT_TICKET_FORM_DETAILS: {
            return {
                ...state,
                clientTicketFormDetails: action.payload
            };
        }
        case SET_CLIENT_TICKET_FORM_DETAILS: {
            return {
                ...state,
                clientTicketFormDetails: action.payload
            };
        }
        case SET_MESSAGE: {
            return {
                ...state,
                message: action.payload
            };
        }
        default: {
            return state;
        }

    }
}

export const isLoading = (state: State) => state.isLoading;
export const clientTicketList = (state: State) => state.clientTicketList;
export const clientTicketDetails = (state: State) => state.clientTicketDetails;
export const clientTicketFormDetails = (state: State) => state.clientTicketFormDetails;
export const message = (state: State) => state.message;



