import { CentralPromotion } from './central-promotion.model';
import {
    SELECTED_CENTRAL_PROMOTION,
    ADD,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_CENTRAL_PROMOTIONS,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING,
    CentralPromotionActions,
} from './central-promotion.actions';

export interface State {
    centralPromotions: CentralPromotion[];
    selectedCentralPromotion: CentralPromotion;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    centralPromotions: [],
    selectedCentralPromotion: null,
    isEditing: false,
    isLoading: false
};

export function centralPromotionReducer(state = initialState, action: CentralPromotionActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_CENTRAL_PROMOTION: {
            return {
                ...state,
                selectedCentralPromotion: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                centralPromotions: [...state.centralPromotions, action.payload],
                selectedCentralPromotion: action.payload
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_CENTRAL_PROMOTIONS: {
            return {
                ...state,
                centralPromotions: action.payload
            };
        }
        case UPDATE: {
            const index = state.centralPromotions.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selCentralPromotion = state.selectedCentralPromotion;
            if (selCentralPromotion && selCentralPromotion.id === action.payload.id) {
              selCentralPromotion = action.payload;
            }
            const updatedCentralPromotions = [
                ...state.centralPromotions.slice(0, index),
                Object.assign({}, state.centralPromotions[index], action.payload),
                ...state.centralPromotions.slice(index + 1)
            ];
            return {
                ...state,
                centralPromotions: updatedCentralPromotions,
                selectedCentralPromotion: selCentralPromotion
            };
        }
        case REMOVE: {
            return {
                ...state,
                centralPromotions: state.centralPromotions.filter(c => c.id !== action.payload)
            };
        }
      default: {
          return state;
      }
    }
}

export const centralPromotions = (state: State) => state.centralPromotions;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedCentralPromotion = (state: State) => state.selectedCentralPromotion;
