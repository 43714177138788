import { Action } from '@ngrx/store';
import { WorkScheduleByRequiredDate, WorkScheduleEmployee, WorkScheduleFilterForm } from './work-schedule.model';
import { ProjectSimple } from '../project/project.model';
import { Market } from '../localization/localization.model';

export const START_LOADING = '[Workschedule] start loading';
export const STOP_LOADING = '[Workschedule] stop loading';
export const SET_DEFINITION = '[Workschedule] set definition';

export const SET_PROJECT_LIST = '[Workschedule] set project list';
export const SET_MARKET_LIST = '[Workschedule] set market list';
export const SET_CITY_LIST = '[Workschedule] set cities list';
export const SET_STREET_LIST = '[Workschedule] set street list';
export const SET_MANAGER_LIST = '[Workschedule] set manager list';
export const SET_COORDINATOR_LIST = '[Workschedule] set coordinator list';
export const SET_COORDINATOR_EMPLOYEE_LIST = '[Workschedule] set coordinator employee list';
export const SET_WORK_SCHEDULE = '[Workschedule] set work schedule';

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export class SetDefinition implements Action {
    readonly type = SET_DEFINITION;
    constructor(public payload: WorkScheduleFilterForm) { }
}

export class SetProjectList implements Action {
    readonly type = SET_PROJECT_LIST;
    constructor(public payload: ProjectSimple[]) { }
}

export class SetMarketList implements Action {
    readonly type = SET_MARKET_LIST;
    constructor(public payload: Market[]) { }
}

export class SetCityList implements Action {
    readonly type = SET_CITY_LIST;
    constructor(public payload: string[]) { }
}

export class SetStreetList implements Action {
    readonly type = SET_STREET_LIST;
    constructor(public payload: string[]) { }
}

export class SetManagerList implements Action {
    readonly type = SET_MANAGER_LIST;
    constructor(public payload: WorkScheduleEmployee[]) { }
}

export class SetCoordinatorList implements Action {
    readonly type = SET_COORDINATOR_LIST;
    constructor(public payload: WorkScheduleEmployee[]) { }
}

export class SetCoordinatorEmployeeList implements Action {
    readonly type = SET_COORDINATOR_EMPLOYEE_LIST;
    constructor(public payload: WorkScheduleEmployee[]) { }
}

export class SetWorkSchedule implements Action {
    readonly type = SET_WORK_SCHEDULE;
    constructor(public payload: WorkScheduleByRequiredDate[]) { }
}

export type WorkScheduleActions =
    StartLoading
    | StopLoading
    | SetDefinition
    | SetProjectList
    | SetMarketList
    | SetCityList
    | SetStreetList
    | SetManagerList
    | SetCoordinatorList
    | SetCoordinatorEmployeeList
    | SetWorkSchedule;
