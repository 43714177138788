import { Component, OnInit, ViewChild, OnDestroy, NgZone, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationData } from 'src/app/auth/user.model';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from '../../app.reducer';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DxButtonComponent } from 'devextreme-angular';
import { UIService } from 'src/app/shared/ui.service';
import { OrderTaskService } from 'src/app/services/order-task/order-task.service';
import { YesNoDialogComponent, YesNoModel } from 'src/app/shared/yes-no-dialog/yes-no-dialog.component';
import { OrderTask, OrderTaskDxTable } from 'src/app/redux/order-task/order-task.model';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { DatePipe } from '@angular/common';
import { AppHub } from 'src/app/hubs/app.hub';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-mytasks-list',
  templateUrl: './mytasks-list.component.html',
  styleUrls: ['./mytasks-list.component.scss']
})

export class MyTasksListComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private _onDestroy = new Subject<void>();
  expanded = false;
  myTasks = true;
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('editDxButton', { static: false }) editButton: DxButtonComponent;

  dataSource: any;
  storageKey: string;
  currentUser: ApplicationUser;
  isLoading: boolean = false;
  currentWeek = '2020-09-07 - 2020-09-13';
  firstWeekDay: string;
  lastWeekDay: string;
  selectedTask: number;
  items: OrderTask[] = [];

  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    private router: Router,
    private orderTaskService: OrderTaskService,
    private store: Store<fromRoot.IRootState>,
    private uiService: UIService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    public hub: AppHub) {

    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser === undefined || currentUser === null) {
      return;
    }
    this.storageKey = 'mytasks_list_table_' + currentUser.company.id + currentUser.id;
  }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));

    this.store.select(fromRoot.getTaskIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => this.isLoading = isLoading);

    this.store.select(fromRoot.getTaskSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        if (res !== null) {
          this.selectedTask = res.id;
        } else {
          this.selectedTask = undefined;
        }
      });

    this.hub.orderTasksUpdated
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.buildDateSourceStore();
      });

  }

  ngAfterViewInit() {
    if (this.grid) {
      this.grid.instance.repaint();
      this.buildDateSourceStore();
    }
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  handleVisibilityChange(): void {
    if (document.visibilityState === 'visible') {      
      this.buildDateSourceStore();
    }
  }

  private buildDateSourceStore(autoupdate: boolean = true): void {
    this.isLoading = true;
    if (autoupdate) {
      setTimeout(() => {
        this.dataSource = new CustomStore({
          key: 'id',
          load: (loadOptions) => this.orderTaskService.loadMyTasksData(loadOptions)
            .then(async (data: OrderTaskDxTable) => {
              this.isLoading = false;
              this.items = data.data;
              //update data in selected task
              if (this.selectedTask !== undefined) {
                let selTask = this.items.filter(t => t.id === this.selectedTask);
                if (selTask.length > 0) {
                  //this.getSurveyData(selTask[0]); //get survey data and update selected task
                } else { //jeśli dane zadanie zniknęło z listy i trzeba pobrać dane z serwera, żeby zaktualizować widok w popupie szczegółów zadania
                  this.getSelectedTask(this.selectedTask);
                }
              }
              return data;
            })
            .catch(error => { this.isLoading = false; console.error('err', error); throw new Error('Błąd ładowania danych'); }),
          update: async () => { }
        });
      }, 200);
    }
  }


  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.blocked === true) {
      e.rowElement.style.backgroundColor = 'rgba(238, 186, 186, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canOrderEdit === true,
        options: {
          icon: 'fieldchooser',
          text: 'Zmiana masowa',
          onClick: this.updateSelectedTasks.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canTaskStatusCanceledAction === true,
        options: {
          icon: 'close',
          text: 'Anuluj',
          onClick: this.cancelSelectedRows.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canTaskDelete === true,
        options: {
          icon: 'trash',
          text: 'Usuń',
          onClick: this.deleteSelectedRows.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'export',
          hint: 'Eksportuj',
          onClick: this.exportRealizations.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  editTask(e) {
    if (this.auth.appRoles.canTaskDetails) {
      if (e.data !== undefined && e.data.id) {
        const id = e.data.id;
        const url = `${window.location.origin}/#/order-task-list/details;id=${id}`;
        window.open(url, '_blank');
      }
    } else {
      this.uiService.openSnack('Nie masz uprawnień do podglądu zadania.');
    }
  }

  deleteSelectedRows() {
    const dialogTextData: YesNoModel = {
      titleKey: 'Usuwanie zadań',
      contentKey: 'Czy chcesz usunąć wybrane zadania?',
      description: 'Ilość wybranych zadań: ' + this.grid.selectedRowKeys.length
    };
    const ref = this.dialog.open(YesNoDialogComponent, {
      width: '25%',
      minWidth: '300px',
      data: dialogTextData,
      autoFocus: false
    });
    ref.afterClosed()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((dialogRes) => {
        if (dialogRes) {
          const rows = this.grid.selectedRowKeys;
          if (rows.length > 0) {
            this.orderTaskService.deleteTasks(rows);
          } else {
            this.uiService.openSnack('Nie wybrano zadań do usunięcia.');
          }
          this.grid.selectedRowKeys = [];
        }
      });
  }

  cancelSelectedRows() {
    const dialogTextData: YesNoModel = {
      titleKey: 'Anulowanie zadań',
      contentKey: 'Czy chcesz anulować wybrane zadania?',
      description: 'Ilość wybranych zadań: ' + this.grid.selectedRowKeys.length
    };
    const ref = this.dialog.open(YesNoDialogComponent, {
      width: '25%',
      minWidth: '300px',
      data: dialogTextData,
      autoFocus: false
    });
    ref.afterClosed()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((dialogRes) => {
        if (dialogRes) {
          const rows = this.grid.selectedRowKeys;
          if (rows.length > 0) {
            this.orderTaskService.cancelTasks(rows);
          } else {
            this.uiService.openSnack('Nie wybrano zadań do anulowania.');
          }
          this.grid.selectedRowKeys = [];
        }
      });
  }

  updateSelectedTasks() {
    this.orderTaskService.unSelectTask();
    const idsString = this.grid.selectedRowKeys.join(",");

    const url = `${window.location.origin}/#/order-task-list/details;id=${idsString}`;
    window.open(url, '_blank');
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  }

  onRowClick(e) {
    this.editTask(e);
    this.grid.instance.repaint();
  }

  cancel() {

  }

  exportRealizations() {
    const currenttime = this.datePipe.transform(Date.now(), 'yyyy_MM_dd HH_mm_ss');
    const fileName = 'Raporty ' + currenttime;
    const rows = this.grid.selectedRowKeys;
    const selectedTasks = this.items.filter(task => rows.includes(task.id));
    this.excelService.exportReports(selectedTasks, fileName);
  }

  getSelectedTask(id: number) {
    this.http.get<any>(environment.apiUrl + '/orderTask/' + id)
      .toPromise()
      .then(
        (task) => {
          //this.getSurveyData(task);
        })
      .catch(err => {
        console.error(err);
      });
  }

  statusLabel(cellInfo) {
    const labels = {
      0: 'Czeka',
      1: 'W trakcie',
      2: 'Zrobione',
      3: 'Anulowane',
      4: 'Niewykonane',
    };
    return labels[cellInfo.value];
  }

  onInitializedEventHandler(e) {
    setTimeout(() => {
      this.grid.instance.repaint();
    }, 0_500);
  }
}
