import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DxChartModule } from 'devextreme-angular';
import { WorkTimeChart } from 'src/app/redux/order-task/order-task.model';
import { OosChartService } from 'src/app/services/oos-chart/oos-chart.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Project } from 'src/app/redux/project/project.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-work-time-chart-by-localization',
    templateUrl: './work-time-chart-by-localization.component.html',
    styleUrls: ['./work-time-chart-by-localization.component.scss']
})

export class WorkTimeChartByLocalizationComponent implements OnInit, OnDestroy, AfterViewInit {
    markerPath: any;
    selectedProject: Project = {};
    currentDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    startDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    startDateLong: number = this.startDate.getTime();
    workTime: WorkTimeChart[] = [
      // {
      //   parameter: 'Makro', afterVisit: 2, inProgressVisit: 3, noVisit: 1
      // },
      // {
      //   parameter: 'Selgros', afterVisit: 1, inProgressVisit: 1, noVisit: 5
      // },
      // {
      //   parameter: 'Kaufland', afterVisit: 4, inProgressVisit: 0, noVisit: 2
      // }
    ];

    private _onDestroy = new Subject<void>();

    constructor(private service: OosChartService,
                private store: Store<fromRoot.IRootState>,
                private datePipe: DatePipe) {
      this.markerPath = {
        'Zrealizowane': 'M 0 8 C 2 4 7 4 9.5 8 C 11 12 16 12 18 8 L 18 10 C 16 14 11 14 8.5 10 C 7 6 2 6 0 10 Z',
        'W trakcie': 'M 18 8 L 12 12 L 7 3 L 0 7.4 L 0 10 L 6 6 L 11 15 L 18 10.6 Z',
        'Oczekujące': 'M 18 8 L 12 12 L 7 3 L 0 7.4 L 0 10 L 6 6 L 11 15 L 18 10.6 Z'
    };
    }

    ngOnInit() {
      this.store.select(fromRoot.getChartWorkTimeByMarket)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((work) => { this.workTime = work; });

      this.store.select(fromRoot.getProjectSelectedForCharts)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((proj) => {
          this.selectedProject = proj;
          this.onSubscribeChanged();
        });
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    customizeLabel(arg) {
        return arg.percentText + ' - ' + arg.valueText;
    }

    customizeTooltip(arg: any) {
      return {
          text: arg.percentText + ' - ' + arg.valueText
      };
    }

    getMarkerPath(name) {
      return this.markerPath[name];
  }

  getMarkerColor(item) {
    if (item === 'Zrealizowane') {
      return '#66cc66';
    }
    if (item === 'W trakcie') {
      return '#0099ca';
    }
    if (item === 'Oczekujące') {
      return '#ff6635';
    }
  }

  onLegendClick(e) {
      e.target.isVisible() ? e.target.hide() : e.target.show();
  }

    customizePoint = (pointInfo: any) => {
      if (pointInfo.seriesName === 'Zrealizowane') {
        return { color: '#66cc66', hoverStyle: { color: '#66cc66' } };
      }
      if (pointInfo.seriesName === 'W trakcie') {
        return { color: '#0099ca', hoverStyle: { color: '#0099ca' } };
      }
      if (pointInfo.seriesName === 'Oczekujące') {
        return { color: '#ff6635', hoverStyle: { color: '#ff6635' } };
      }
    }

    onValueChanged(data) {
      const tmpDate = new Date(this.datePipe.transform(data.value, 'yyyy-MM-dd'));
      this.startDateLong = tmpDate.getTime();
      if (this.selectedProject && this.startDateLong > 0) {
        this.service.getWorktimeByMarket(this.startDateLong, this.selectedProject.id);
      }
    }

    onSubscribeChanged() {
      if (this.selectedProject) {
        this.service.getWorktimeByMarket(this.startDateLong, this.selectedProject.id);
      }
    }
}
