import { Action } from '@ngrx/store';

const typeCache: { [label: string]: boolean } = {};
function type<T>(label: T | ''): T {
  if (typeCache[ <string>label ]) {
    throw new Error(`Action type '${label}' is not unqiue'`);
  }

  typeCache[ <string>label ] = true;

  return <T>label;
}

export const ActionTypes = {
  SET_SCREEN: type<'SET_SCREEN'>('SET_SCREEN'),
};
const MOBILE_MAX_WIDTH = 425;  // Adjust as needed
const TABLET_MAX_WIDTH = 1024; // Adjust as needed

// Action type for screen
export class SetScreen implements Action {
  type = ActionTypes.SET_SCREEN;
  payload: Readonly<{
    isMobile: boolean,
    isTablet: boolean,
    isDesktop: boolean
  }>;

  public constructor(width: number) {
    const isMobile = width <= MOBILE_MAX_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH;
    this.payload = {
      isMobile,
      isTablet,
      isDesktop: !isMobile && !isTablet,
    };
  }
}

export type Actions = SetScreen;
