import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppInformationService } from '../services/app-information/app-information.service';
import { AppVersion } from 'src/version';

@Component({
  selector: 'app-version-footer',
  templateUrl: './version-footer.component.html',
  styleUrls: ['./version-footer.component.scss']
})

export class VersionFooter implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy = new Subject<void>();
  version = '';

  constructor(
    private store: Store<fromRoot.IRootState>,
    private service: AppInformationService
  )
  {}

  ngOnInit() {
    this.store.select(fromRoot.getAppInfoVersion)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((result) => {
            this.version = result;
    });
  }

  ngAfterViewInit(): void {
    this.service.getVersion();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getWebVersionNumber() {
    return AppVersion.number;
  }

  getWebVersionDate() {
    return AppVersion.buildDate;
  }

}
