import { Action } from '@ngrx/store';
import { Contractor } from './contractor.model';
import { ContractorProjectKind } from './contractor-project-kind.model';

export const SET_AVAILABLE_CONTRACTORS = '[Contractor] Set available contractors';
export const SET_AVAILABLE_CONTRACTOR_PROJECT_KINDS = '[Contractor] Set available contractor project kind';
export const START_EDITING = '[Contractor] Start editing';
export const STOP_EDITING = '[Contractor] Stop editing';
export const ADD = '[Contractor] Add new contractor';
export const ADD_CONTRACTOR_PROJECT_KIND = '[Contractor] Add new contractor project kind';
export const REMOVE = '[Contractor] Remove contractor';
export const REMOVE_CONTRACTOR_PROJECT_KIND = '[Contractor] Remove contractor project kind';
export const UPDATE = '[Contractor] Update contractor';
export const SELECTED_CONTRACTOR = '[Contractor] Selected contractor';
export const START_LOADING = '[Contractor] start loading';
export const STOP_LOADING = '[Contractor] stop loading';

export class SetAvailableContractors implements Action {
    readonly type = SET_AVAILABLE_CONTRACTORS;
    constructor(public payload: Contractor[]) { }
}
export class SetAvailableContractorProjectKinds implements Action {
  readonly type = SET_AVAILABLE_CONTRACTOR_PROJECT_KINDS;
  constructor(public payload: ContractorProjectKind[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Contractor) { }
}
export class AddContractorProjectKind implements Action {
  readonly type = ADD_CONTRACTOR_PROJECT_KIND;
  constructor(public payload: ContractorProjectKind) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class RemoveContractorProjectKind implements Action {
  readonly type = REMOVE_CONTRACTOR_PROJECT_KIND;
  constructor(public contractorId: number, public projectKindId: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Contractor) { }
}
export class SetSelectedContractor implements Action {
    readonly type = SELECTED_CONTRACTOR;
    constructor(public payload: Contractor) { }
}

export type ContractorActions =
    SetAvailableContractors
    | SetAvailableContractorProjectKinds
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | AddContractorProjectKind
    | Remove
    | RemoveContractorProjectKind
    | Update
    | SetSelectedContractor;
