import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserListItem } from 'src/app/redux/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-user-search-bar-with-roles',
  templateUrl: './user-search-bar-with-roles.component.html',
  styleUrls: ['./user-search-bar-with-roles.component.css']
})
export class UserSearchBarWithRolesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() underline: boolean = false;
  @Input() isEditing: boolean = true;

  @Input() placeholderText: string = 'Wybierz użytkownika';
  @Input() roleName: string = null;
  @Input() principalId: string = null;
  @Input() selectControl: FormControl;

  @Input() getUsersRootFunction: Observable<UserListItem[]>;

  // Users like manager, specialist etc.
  @Input() extraUsers: UserListItem[] = null;


  private _onDestroy = new Subject<void>();
  userList: UserListItem[] = [];
  searchQuery: string = '';
  selectedUser: UserListItem = null;
  userSelectOpened = false;
  matSelectFocused = false;
  closedFromInside = false;

  @Output() userSelectionChange = new EventEmitter<UserListItem>();
  searchControl: FormControl = new FormControl();

  constructor(private store: Store<fromRoot.IRootState>, private userService: UserService) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    this.searchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(query => {
      // exception where time delay removes user list - multiple user searches in one window
      if (!this.closedFromInside) {
        this.matSelectFocused = true;
      }
      else {
        this.closedFromInside = false;
      }
      this.searchQuery = query;
      this.fetchUserList();
    });

    switch (this.roleName) {
      case 'Koordynator lokalizacji':
        this.store.select(fromRoot.getLocalizationCoordinatorsListV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
      case 'Menedżer Regionalny':
        this.store.select(fromRoot.getManagersListV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
      case 'Koordynator ds. Merchandisingu':
        this.store.select(fromRoot.getPmtCoordinatorsListV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
      case 'Koordynator ds. Promocji':
        this.store.select(fromRoot.getPromCoordinatorsListV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
      case 'Merchandiser':
        this.store.select(fromRoot.getPmtMerchandisersListV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
      case 'Hostessa':
        this.store.select(fromRoot.getHostessesListV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
      case 'Specjalista':
        this.store.select(fromRoot.getSupervisorsV2)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(users => {
            this.updateUserList(users);
          });
        break;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  applyDefaultValue(value: UserListItem) {
    if (value === null) {
      this.selectedUser = null;
      return;
    }
    if (value === undefined) {
      this.selectedUser = null;
      return;
    }
    if (value.id === null) {
      return;
    }
    if (Object.keys(value).length === 0) {
      return;
    }
    if (this.selectedUser && this.selectedUser.userName === value.userName) {
      return;
    }
    this.userList.push(value);
    this.selectedUser = value;
  }

  updateUserList(users: UserListItem[]): void {
    if (this.matSelectFocused) {
      this.userList = users ? users : [];
      if (this.extraUsers !== null && this.extraUsers.length > 0) {
        const filteredExtraUsers = this.checkExtraUsers();
        if (filteredExtraUsers != null && filteredExtraUsers.length > 0) {
          const nonDuplicateExtraUsers = filteredExtraUsers.filter(user => !this.userList.some(u => u.id === user.id));
          this.userList = this.userList.concat(nonDuplicateExtraUsers);
        }
      }

      if (this.userList.length === 0) {
        this.clear();
      }
    }
  }

  checkExtraUsers() {
    const lowerCaseSearchQuery = this.searchQuery.toLowerCase();
    return this.extraUsers.filter(item => item.firstName.toLowerCase().includes(lowerCaseSearchQuery) || item.lastName.toLowerCase().includes(lowerCaseSearchQuery));
  }

  onFocus() {
    this.matSelectFocused = true;
  }

  onClosed() {
    this.closedFromInside = true;
    this.matSelectFocused = false;
    this.searchControl.setValue('');
  }

  fetchUserList(): void {
    if (this.principalId === null) {
      this.userService.searchUsersByRoleName(this.roleName, this.searchQuery);
    }
    else {
      this.userService.searchUsersByPrincipalAndRoleName(this.principalId, this.roleName, this.searchQuery);
    }
  }

  clear(): void {
    this.selectedUser = null;
    this.userSelectionChange.emit(null);
  }

  onUserSelectChange(): void {
    this.userSelectionChange.emit(this.selectedUser);
  }

}
