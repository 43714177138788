import { Contact } from 'src/app/contact/contact.component';
import { User } from 'src/app/redux/user/user.model';

export class SetDataSouce {
    static readonly type = '[Contact] set data source';
    constructor(public payload: Contact[]) { }
}

export class UpdateDataSource {
    static readonly type = '[Contact] update data source';
    constructor(public payload: User[]) { }
}

export class UpdateUser {
    static readonly type = '[Contact] update user';
    constructor(public payload: User) { }
}
