import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Store } from '@ngrx/store';
import { MatDialogRef, MatDialog } from '@angular/material';
import * as fromRoot from '../../../app.reducer';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { takeUntil } from 'rxjs/operators';
import { Contractor} from 'src/app/redux/contractor/contractor.model';
import { Product, AttachLocalization} from 'src/app/redux/project/product.model';
import { DxDataGridComponent, DxButtonComponent } from 'devextreme-angular';
import { ProjectService } from 'src/app/services/project/project.service';
import { Project } from 'src/app/redux/project/project.model';
import { LocalizationSelectComponent } from 'src/app/localization/localization-select/localization-select.component';
import { ExcelService } from 'src/app/services/excel/excel.service';
import DataGrid from 'devextreme/ui/data_grid';
import { ApplicationData } from 'src/app/auth/user.model';

(DataGrid as any).registerModule('columnChooserSorting', {
  extenders: {
      controllers: {
          columns: {
              getChooserColumns(loadAllColumns) {
                  var result = this.callBase(loadAllColumns);
                  return result.sort(function(column1, column2) {
                        return column1.caption.localeCompare(column2.caption);
                  });
              }
          }
      }
  }
});

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  entryComponents: [ LocalizationSelectComponent ]
})
export class ProductListComponent implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy = new Subject<void>();
  selectedProject: Project;
  allProducts: Product[];
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('detailsGrid', { static: false }) detailsGrid: DxDataGridComponent;
  @ViewChild('editDxButton', { static: false }) editButton: DxButtonComponent;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  expanded = false;
  dataSource: Product[];
  storageKey: string;
  isLoading: boolean;

  constructor(private dialog: MatDialog,
              public auth: AuthService,
              private service: ProjectService,
              private store: Store<fromRoot.IRootState>,
              private dialogRef: MatDialogRef<ProductDetailsComponent>,
              private excelService: ExcelService) {

                const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
                if (currentUser === undefined || currentUser === null) {
                  return;
                }
                this.storageKey = 'products_list_table_' + currentUser.company.id + currentUser.id;
               }

  ngOnInit() {
    this.store.select(fromRoot.getProductIsLoadingList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => this.isLoading = isLoading);

    this.store.select(fromRoot.getProjectSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        res => {
          if (res !== null) {
            this.selectedProject = res;
          } else {
            this.selectedProject = {};
          }
      });

    this.store.select(fromRoot.getProductList)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((products) => {
      this.allProducts = products;
      this.dataSource = products;
    });

    this.service.getProjectProducts(this.selectedProject.id);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel() {

  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectEdit === true,
        options: {
          icon: 'decreaseindent',
          text: 'Przypisz lokalizacje',
          onClick: this.assignLocalization.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectCreate ===  true,
        options: {
          icon: 'add',
          hint: 'Dodaj',
          onClick: this.createProduct.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectDelete === true,
        options: {
          icon: 'remove',
          hint: 'Usuń',
          onClick: this.deleteSelectedRows.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectDetailsProductDetails ===  true,
        options: {
            icon: 'export',
            hint: 'Eksportuj',
            onClick: this.export.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectCreate ===  true,
        options: {
            icon: 'download',
            hint: 'Importuj',
            onClick: this.import.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          hint: 'Zwiń/rozwiń',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  }

  deleteSelectedRows() {
    const rows = this.grid.selectedRowKeys;
    const selectedProducts = this.dataSource.filter(product => rows.includes(product.id));
    selectedProducts.forEach(element => {
      this.service.deleteProduct(element.id);
      element.archival = true;
    });
    this.grid.selectedRowKeys = [];
  }

  onRowClick(e) {
    if (e.rowType === 'data' && e.handled !== true) {
      this.editProduct(e);
    }
    this.grid.instance.repaint();
  }

  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.blocked === true) {
      e.rowElement.style.backgroundColor = 'rgba(238, 186, 186, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  createProduct(e) {
    if (this.auth.appRoles.canProjectDetailsProductDetails) {
        this.service.startEditing();
        this.service.unSelectProduct();
        this.dialog.open(ProductDetailsComponent, {
          width: '95%'
      });
    }
  }

  editProduct(e) {
    if (e.data.id && this.auth.appRoles.canProjectDetailsProductDetails) {
      this.service.selectProduct(e.data);
      this.dialog.open(ProductDetailsComponent, {
        width: '95%'
      });
    }
  }

  addToMarket() {

  }

  addToLocalization(detailViewProduct: Product)  {
    const detailViewProducts: Product[] = [ detailViewProduct ];
    this.service.setSelectedProducts(detailViewProducts);
    this.dialog.open(LocalizationSelectComponent, {
      width: '95%'
    });
  }

  assignLocalization() {
    const rows = this.grid.selectedRowKeys;
    const selectedProducts = this.dataSource.filter(product => rows.includes(product.id));
    this.service.setSelectedProducts(selectedProducts);
    this.dialog.open(LocalizationSelectComponent, {
      width: '95%'
    });
    this.grid.selectedRowKeys = [];
  }

  removeLocalizations(detailViewProduct: Product) {
    const rows = this.detailsGrid.selectedRowKeys;
    const selectedLocalizations = detailViewProduct.productLocalizations
    .filter(localization => rows.includes(localization.localizationId));

    const resultList: AttachLocalization[] = [];

    selectedLocalizations.forEach( element => {
      resultList.push({ localizationId: element.localizationId, productId: element.productId });
    });
    this.service.detachLocalizationFromProduct(resultList);
  }

  export() {
    const fileName = 'Produkty ' + this.selectedProject.name + ' [' + this.selectedProject.contractor.shortcut + ']';
    this.excelService.exportProducts2Excel(this.dataSource, fileName);
  }

  import() {
    if (this.auth.appRoles.canProjectCreate) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileChange(ev) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      this.excelService.ReadProductsFromExcel(data, this.selectedProject.id);
    };
    reader.readAsBinaryString(file);
  }

  getRowNumber(grid, data) {
    const missedRowsNumber = this.getGroupRowsNumbers(data.row.rowIndex);
    return data.row.rowIndex + 1 - missedRowsNumber;
  }

  getGroupRowsNumbers(rowIndex: number) {

    const visibleRows = this.grid.instance.getVisibleRows();
    let counter = 0;
    for (let i = rowIndex; i >= 0; i--) {
      if (visibleRows[i] !== undefined && visibleRows[i].rowType === 'group'){
        counter++;
      }
    }
    return counter;
  }

  onInitializedEventHandler(e) {
    setTimeout(() => {
      this.grid.instance.repaint();
    }, 0_500);
  }
}
