import { Dictionary, DictionaryElement } from './dictionary.model';
import {
    DictionaryActions,
    SELECTED_DICTIONARY,
    ADD,
    ADD_ELEMENT,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_DICTIONARIES,
    SET_AVAILABLE_DICTIONARY_ELEMENTS,
    UPDATE,
    UPDATE_ELEMENT,
    REMOVE,
    REMOVE_ELEMENT,
    START_LOADING,
    STOP_LOADING
} from './dictionary.actions';

export interface State {
    dictionaries: Dictionary[];
    selectedDictionary: Dictionary;
    elements: DictionaryElement[];
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    dictionaries: [],
    elements: [],
    selectedDictionary: null,
    isEditing: false,
    isLoading: false
};

export function dictionaryReducer(state = initialState, action: DictionaryActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_DICTIONARY: {
            return {
                ...state,
                selectedDictionary: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                dictionaries: [...state.dictionaries, action.payload]
            };
        }
        case ADD_ELEMENT: {
          return {
              ...state,
              elements: [...state.elements, action.payload]
          };
      }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_DICTIONARIES: {
            return {
                ...state,
                dictionaries: action.payload
            };
        }
        case SET_AVAILABLE_DICTIONARY_ELEMENTS: {
          return {
              ...state,
              elements: action.payload
          };
        }
        case UPDATE: {
            const index = state.dictionaries.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selDictionary = state.selectedDictionary;
            if (selDictionary && selDictionary.id === action.payload.id) {
              selDictionary = action.payload;
            }
            const updatedDictionaries = [
                ...state.dictionaries.slice(0, index),
                Object.assign({}, state.dictionaries[index], action.payload),
                ...state.dictionaries.slice(index + 1)
            ];

            return {
                ...state,
                dictionaries: updatedDictionaries,
                selectedOrder: selDictionary
            };
        }
        case UPDATE_ELEMENT: {
          const index = state.elements.map(d => d.id).indexOf(action.payload.id);
          if (index === -1) {
              return state;
          }
          const updatedElements = [
              ...state.elements.slice(0, index),
              Object.assign({}, state.elements[index], action.payload),
              ...state.elements.slice(index + 1)
          ];

          return {
              ...state,
              elements: updatedElements
          };
        }
        case REMOVE: {
            return {
                ...state,
                dictionaries: state.dictionaries.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_ELEMENT: {
          return {
              ...state,
              elements: state.elements.filter(c => c.id !== action.payload)
          };
      }
        default: {
            return state;
        }
    }
}

export const dictionaries = (state: State) => state.dictionaries;
export const elements = (state: State) => state.elements;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedDictionary = (state: State) => state.selectedDictionary;
