import { Action } from '@ngrx/store';
import { ProjectKind, ProjectKindAttachment } from './project-kind.model';
import { ProjectKindRole } from './project-kind-role.model';

export const SET_AVAILABLE_PROJECT_KINDS = '[Project kind] Set available project kinds';
export const SET_AVAILABLE_PROJECT_KIND_ROLE = '[Project kind role] Set available project kind roles';
export const SET_AVAILABLE_PROJECT_KIND_ATTACHMENT = '[Project kind attachment] Set available project kind attachments';
export const START_EDITING = '[Project kind] Start editing';
export const STOP_EDITING = '[Project kind] Stop editing';
export const ADD = '[Project kind] Add new project kind';
export const ADD_PROJECT_KIND_ROLE = '[Project kind role] Add new project kind role';
export const ADD_PROJECT_KIND_ATTACHMENT = '[Project kind attachment] Add new project kind document';
export const REMOVE = '[Project kind] Remove project kinds';
export const REMOVE_PROJECT_KIND_ROLE = '[Project kind role] Remove project kind role';
export const REMOVE_PROJECT_KIND_ATTACHMENT = '[Project kind attachment] Remove project kind document';
export const UPDATE = '[Project kind] Update project kinds';
export const SELECTED_PROJECT_KIND = '[Project kind] Selected project kinds';
export const START_LOADING = '[Project kind] start loading';
export const STOP_LOADING = '[Project kind] stop loading';

export class SetAvailableProjectKinds implements Action {
    readonly type = SET_AVAILABLE_PROJECT_KINDS;
    constructor(public payload: ProjectKind[]) { }
}
export class SetAvailableProjectKindRole implements Action {
  readonly type = SET_AVAILABLE_PROJECT_KIND_ROLE;
  constructor(public payload: ProjectKindRole[]) { }
}
export class SetAvailableProjectKindAttachments implements Action {
  readonly type = SET_AVAILABLE_PROJECT_KIND_ATTACHMENT;
  constructor(public payload: ProjectKindAttachment[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: ProjectKind) { }
}
export class AddProjectKindRole implements Action {
  readonly type = ADD_PROJECT_KIND_ROLE;
  constructor(public payload: ProjectKindRole) { }
}
export class AddProjectKindAttachment implements Action {
  readonly type = ADD_PROJECT_KIND_ATTACHMENT;
  constructor(public payload: ProjectKindAttachment) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class RemoveProjectKindRole implements Action {
  readonly type = REMOVE_PROJECT_KIND_ROLE;
  constructor(public projectKindId: number, public applicationRoleId: number) { }
}
export class RemoveProjectKindAttachment implements Action {
  readonly type = REMOVE_PROJECT_KIND_ATTACHMENT;
  constructor(public projectKindAttachmentId: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: ProjectKind) { }
}
export class SetSelectedProjectKind implements Action {
    readonly type = SELECTED_PROJECT_KIND;
    constructor(public payload: ProjectKind) { }
}

export type ProjectKindActions =
    SetAvailableProjectKinds
    | SetAvailableProjectKindRole
    | SetAvailableProjectKindAttachments
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | AddProjectKindRole
    | AddProjectKindAttachment
    | Remove
    | RemoveProjectKindRole
    | RemoveProjectKindAttachment
    | Update
    | SetSelectedProjectKind;
