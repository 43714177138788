import { ProjectKind } from '../contractor/project-kind.model';
import { Contractor } from '../contractor/contractor.model';
import { User, UserBase, UserListItem } from '../user/user.model';
import { Project, ProjectAttachment, ProjectForOrder } from '../project/project.model';
import { Localization } from '../localization/localization.model';
import { WeekDay } from '@angular/common';
import { DictionaryElement } from '../dictionary/dictionary.model';
import { EmploymentStatus } from '../service/service.model';
import { SurveyPattern } from "src/app/redux/survey/survey.model";

export interface Order {
  id?: number;
  projectId?: number;
  project?: ProjectForOrder;
  localizationId?: number;
  localization?: Localization;
  notes?: string;
  serviceStartDate?: Date;
  serviceStartDateLong?: number;
  serviceFinishDate?: Date;
  serviceFinishDateLong?: number;
  contractorArea?: string;
  contractorSubArea?: string;
  contractorLocalizationCode?: string;
  contractorManagerId?: string;
  contractorManager?: UserListItem;
  contractorManagerPhone?: string;
  serviceEmployeeId?: string;
  serviceEmployee?: UserBase;
  serviceEmployeeReplacementId?: string;
  serviceEmployeeReplacement?: UserListItem;

  salesRepresentativeId?: string;
  salesRepresentative?: UserListItem;
  salesRepresentativePhone?: string;
  reportResponsiblePersonId?: string;
  reportResponsiblePerson?: UserListItem;
  settlementKind?: SettlementKind;
  settlementKindEmployee?: SettlementKind;
  contractorLumpSum?: number;
  contractorWeekLimit?: number;
  contractorMonthLimit?: number;
  copyToSettlement?: boolean;
  employeeRate?: number;
  employeeRateBudget?: number;
  employeeLumpSum?: number;
  employeeWeekLimit?: number;
  employeeMonthLimit?: number;
  employeeAdditionalHours?: number;
  countOfVisitsInMonth?: number;
  startLimitDate?: Date;
  startLimitDateLong?: number;
  settlementBlocked?: boolean;
  settlementMonth?: Date;
  settlementMonthLong?: number;

  outOfStockReporting?: boolean;
  startReportingDate?: Date;
  startReportingDateLong?: number;
  finishReportingDate?: Date;
  finishReportingDateLong?: number;
  nextReportingDate?: Date;
  nextReportingDateLong?: number;
  reportingFrequency?: ReportFrequency;
  reportDayOfWeek?: WeekDay;
  reportDayOfMonth?: number;
  shiftReportRealization?: string;
  reportLackCutting?: boolean;
  reportDescription?: string;
  enableRemainder?: boolean;
  dayNumbersBeforeReminder?: number;
  notifyContractorAfterReportComplete?: boolean;
  notifyPmtCoordinatorAfterReportComplete?: boolean;
  remindPmtCoordinatorBeforReportComplete?: boolean;
  remindServiceEmployeeBeforReportComplete?: boolean;
  notifyPmtCoordinatorAfterSkipReport?: boolean;
  timeForNotifyPmtCoordinatorAfterSkipReport?: number;
  timeForSecondNotifyPmtCoordinatorAfterSkipReport?: number;
  notifyServiceEmployeeAfterSkipReport?: boolean;
  timeForNotifyServiceEmployeeAfterSkipReport?: number;
  timeForSecondNotifyServiceEmployeeAfterSkipReport?: number;
  reportGenarateDaysBefore?: number;

  checkInWithPhoto?: boolean;
  photosNumberInReport?: number;
  startReportingDate2?: Date;
  startReportingDateLong2?: number;
  finishReportingDate2?: Date;
  finishReportingDateLong2?: number;
  reportDescription2?: string;
  nextReportingDate2?: Date;
  nextReportingDateLong2?: number;
  reportingFrequency2?: ReportFrequency;
  reportDayOfWeek2?: WeekDay;
  reportDayOfMonth2?: number;
  enableRemainder2?: boolean;
  dayNumbersBeforeReminder2?: number;
  notifyContractorAfterReportComplete2?: boolean;
  notifyPmtCoordinatorAfterReportComplete2?: boolean;
  notifyPmtCoordinatorAfterSkipReport2?: boolean;
  timeForNotifyPmtCoordinatorAfterSkipReport2?: number;
  timeForSecondNotifyPmtCoordinatorAfterSkipReport2?: number;
  notifyServiceEmployeeAfterSkipReport2?: boolean;
  timeForNotifyServiceEmployeeAfterSkipReport2?: number;
  timeForSecondNotifyServiceEmployeeAfterSkipReport2?: number;
  remindPmtCoordinatorBeforReportComplete2?: boolean;
  remindServiceEmployeeBeforReportComplete2?: boolean;
  reportGenarateDaysBefore2?: number;

  orderScheduleHistory?: OrderScheduleHistory;

  createdDate?: Date;
  createdUserId?: string;
  createdUser?: User;
  modifedDate?: Date;
  modifiedUserId?: string;
  modifiedUser?: User;

  departmentManager?: string;
  departmentManagerPhone?: string;

  manager?: UserBase;
  managerId?: string;
  localizationCoordinator?: UserBase;
  localizationCoordinatorId?: string;
  merchandisingCoordinator?: UserBase;
  merchandisingCoordinatorId?: string;
  supervisor?: UserBase;
  supervisorId?: string;
  additionalSupervisor?: UserBase;
  additionalSupervisorId?: string;
  exportedToZeus?: boolean;
  exportDateStr?: string;
  exportDate?: Date;
  exportDayOfMonth?: number;
  startServiceDateForEmployee?: Date;
  startServiceDateForEmployeeLong?: number;
  blockSettlementGenarationDayOfMonth?: number;
  files?: ProjectAttachment[];
  employmentStatus?: EmploymentStatus; //Status zatrudnienia
  employmentFunction?: string; //Funkcja

  //Nowe pola
  materialCost?: number //Koszt rzeczowy
  reclamationHours?: number //Reklamacje

  budgetSalary?: number //Wynagrodzenie Budżet Pracownik
  grossSalary?: number //Wynagrodzenie Brutto Pracownik
  netSalary?: number //Wynagrodzenie Netto Pracownik

  contractorBudget?: number //Budżet dla klienta
  contractorCommissionRateBudget?: number //Stawka zlecona budżet
  coordinationRate?: number //Stawka Koordynacja 
  reserveManager?: number //Rezerwa MR
  contractorTotalSum?: number //Suma Total
  contractorBonus?: number; // Bonus

  kpi?: number // KPI 
  employeeCostOfArrival?: number // Koszt dotarcia
  employeeSaving?: number // Oszczędność

  employeeNetRate?: number // Stawka netto
  employeeMinimalNetRate?: number //Min. Stawka netto
  fvEmployee?: number  // FV
  employeeDelegation?: number //Delegacja 

  clientNumberHoursFV?: number //Liczba godzin na FV

  employeeHoursOrdered?: number //Liczba godzin zlecona
  employeeDeductionAmount?: number // Potrącenia
  employeeHoursWorked?: number  // Liczba godzin wypracowanych
  employeeSavingCoordinator?: number  // Oszczędności KR
}

export interface ReportConfigImportResponse {
  addedCount: number;
  updatedCount: number;
  removedCount: number;
}

export enum SettlementKind {
  LimitMiesieczny = 0,
  LimitTygodniowy = 1,
  Ryczalt = 2,
  Budzet = 3,
  Godzinowy = 4,
  Nieznany = 99
}

export enum ReportFrequency {
  Week = 0,
  TwoWeeks = 1,
  Month = 2,
  Schedule = 3
}

export interface OrderScheduleHistory {
  id?: number;
  orderId?: number;
  startDate?: Date;
  startDateLong?: number;
  finishDate?: Date;
  finishDateLong?: number;
  monday?: DictionaryElement;
  tuesday?: DictionaryElement;
  wednesday?: DictionaryElement;
  thursday?: DictionaryElement;
  friday?: DictionaryElement;
  saturday?: DictionaryElement;
  sunday?: DictionaryElement;

  mondayId?: number;
  tuesdayId?: number;
  wednesdayId?: number;
  thursdayId?: number;
  fridayId?: number;
  saturdayId?: number;
  sundayId?: number;

  schduleNotes?: string;
  createdDate?: Date;
  createdUser?: UserBase;
  modifiedDate?: Date;
  modifiedUser?: UserBase;
}

export interface OrderScheduleUpdate {
  orderSheduleHistory?: OrderScheduleHistory[];
  orderIds?: number[];
}

export interface OrderImport {
  id?: number;
  project?: string;
  localization?: string;
  notes?: string;
  serviceStartDateLong?: number;
  serviceFinishDateLong?: number;
  contractorArea?: string;
  contractorSubArea?: string;
  contractorLocalizationCode?: string;
  contractorManager?: string;
  contractorManagerPhone?: string;
  contractorManagerMail?: string;
  //serviceEmployee?: string;
  serviceEmployeeFirstName?: string;
  serviceEmployeeLastName?: string;
  serviceEmployeeUserName?: string;
  //serviceEmployeeReplacement?: string;
  serviceEmployeeReplacementFirstName?: string;
  serviceEmployeeReplacementLastName?: string;
  serviceEmployeeReplacementUserName?: string;
  supervisor?: string;
  additionalSupervisor?: string;
  salesRepresentative?: string;
  salesRepresentativePhone?: string;
  salesRepresentativeMail?: string;
  reportResponsiblePerson?: string;
  settlementKind?: string;
  settlementKindEmployee?: string;
  contractorLumpSum?: number;
  contractorWeekLimit?: number;
  contractorMonthLimit?: number;
  copyToSettlement?: number;
  employeeRate?: number;
  employeeRateBudget?: number;
  employeeLumpSum?: number;
  employeeWeekLimit?: number;
  employeeMonthLimit?: number;
  employeeAdditionalHours?: number;
  countOfVisitsInMonth?: number;
  startLimitDateLong?: number;
  settlementBlocked?: number;
  settlementMonthLong?: number;

  outOfStockReporting?: number;
  startReportingDateLong?: number;
  finishReportingDateLong?: number;
  nextReportingDateLong?: number;
  reportingFrequency?: string;
  reportDayOfWeek?: string;
  reportDayOfMonth?: number;
  shiftReportRealization?: string;
  reportLackCutting?: number;
  reportDescription?: string;
  enableRemainder?: number;
  dayNumbersBeforeReminder?: number;
  notifyContractorAfterReportComplete?: number;
  notifyPmtCoordinatorAfterReportComplete?: number;
  remindPmtCoordinatorBeforReportComplete?: number;
  remindServiceEmployeeBeforReportComplete?: number;
  notifyPmtCoordinatorAfterSkipReport?: number;
  timeForNotifyPmtCoordinatorAfterSkipReport?: number;
  timeForSecondNotifyPmtCoordinatorAfterSkipReport?: number;
  notifyServiceEmployeeAfterSkipReport?: number;
  timeForNotifyServiceEmployeeAfterSkipReport?: number;
  timeForSecondNotifyServiceEmployeeAfterSkipReport?: number;
  reportGenarateDaysBefore?: number;

  checkInWithPhoto?: number;
  photosNumberInReport?: number;
  startReportingDateLong2?: number;
  finishReportingDateLong2?: number;
  reportDescription2?: string;
  nextReportingDateLong2?: number;
  reportingFrequency2?: string;
  reportDayOfWeek2?: string;
  reportDayOfMonth2?: number;
  enableRemainder2?: number;
  dayNumbersBeforeReminder2?: number;
  notifyContractorAfterReportComplete2?: number;
  notifyPmtCoordinatorAfterReportComplete2?: number;
  notifyPmtCoordinatorAfterSkipReport2?: number;
  timeForNotifyPmtCoordinatorAfterSkipReport2?: number;
  timeForSecondNotifyPmtCoordinatorAfterSkipReport2?: number;
  notifyServiceEmployeeAfterSkipReport2?: number;
  timeForNotifyServiceEmployeeAfterSkipReport2?: number;
  timeForSecondNotifyServiceEmployeeAfterSkipReport2?: number;
  remindPmtCoordinatorBeforReportComplete2?: number;
  remindServiceEmployeeBeforReportComplete2?: number;
  reportGenarateDaysBefore2?: number;

  orderScheduleMonday?: string;
  orderScheduleTuesday?: string;
  orderScheduleWednesday?: string;
  orderScheduleThursday?: string;
  orderScheduleFriday?: string;
  orderScheduleSaturday?: string;
  orderScheduleSunday?: string;
  orderScheduleNotes?: string;

  manager?: string;
  localizationCoordinator?: string;
  merchandisingCoordinator?: string;
  departmentManager?: string;
  departmentManagerPhone?: string;
  exportDayOfMonth?: number;
  employmentFunction?: string;
}

export interface UpdateOrderModel {
  startServiceDateForEmployee?: boolean;
  project?: boolean;
  startDate?: boolean;
  finishDate?: boolean;
  localization?: boolean;
  contractorLocalizationCode?: boolean;
  contractorArea?: boolean;
  contractorSubArea?: boolean;
  notes?: boolean;
  supervisor?: boolean;
  additionalSupervisor?: boolean;
  manager?: boolean;
  merchandisingCoordinator?: boolean;
  localizationCoordinator?: boolean;
  merchandiser?: boolean;
  employmentStatus?: boolean;
  employmentFunction?: boolean;
  merchandiserReplacement?: boolean;
  reportResponsiblePerson?: boolean;
  contractorManager?: boolean;
  salesRepresentative?: boolean;
  departmentManager?: boolean;
  departmentManagerPhone?: boolean;
  contractorSettlementKind?: boolean;
  contractorLumpSum?: boolean;
  contractorMonthLimit?: boolean;
  contractorWeekLimit?: boolean;
  employeeSettlementKind?: boolean;
  employeeLumpSum?: boolean;
  employeeMonthLimit?: boolean;
  employeeWeekLimit?: boolean;
  employeeRateBudget?: boolean;
  employeeRate?: boolean;
  countOfVisitsInMonth?: boolean;
  additionalHours?: boolean;
  startLimitDate?: boolean;
  settlementBlocked?: boolean;
  settlementMonth?: boolean;
  outOfStockReporting?: boolean;
  nextReportingDate?: boolean;
  startReportingDate?: boolean;
  finishReportingDate?: boolean;
  reportGenarateDaysBefore?: boolean;
  reportingFrequency?: boolean;
  reportDayOfWeek?: boolean;
  reportDayOfMonth?: boolean;
  remindServiceEmployeeBeforReportComplete?: boolean;
  dayNumbersBeforeReminder?: boolean;
  notifyContractorAfterReportComplete?: boolean;
  notifyPmtCoordinatorAfterReportComplete?: boolean;
  notifyPmtCoordinatorAfterSkipReport?: boolean;
  timeForNotifyPmtCoordinatorAfterSkipReport?: boolean;
  timeForSecondNotifyPmtCoordinatorAfterSkipReport?: boolean;
  notifyServiceEmployeeAfterSkipReport?: boolean;
  timeForNotifyServiceEmployeeAfterSkipReport?: boolean;
  timeForSecondNotifyServiceEmployeeAfterSkipReport?: boolean;
  reportDescription?: boolean;
  checkInWithPhoto?: boolean;
  photosNumberInReport?: boolean;
  nextReportingDate2?: boolean;
  startReportingDate2?: boolean;
  finishReportingDate2?: boolean;
  reportGenarateDaysBefore2?: boolean;
  reportingFrequency2?: boolean;
  reportDayOfWeek2?: boolean;
  reportDayOfMonth2?: boolean;
  remindServiceEmployeeBeforReportComplete2?: boolean;
  dayNumbersBeforeReminder2?: boolean;
  notifyContractorAfterReportComplete2?: boolean;
  notifyPmtCoordinatorAfterReportComplete2?: boolean;
  notifyPmtCoordinatorAfterSkipReport2?: boolean;
  timeForNotifyPmtCoordinatorAfterSkipReport2?: boolean;
  timeForSecondNotifyPmtCoordinatorAfterSkipReport2?: boolean;
  notifyServiceEmployeeAfterSkipReport2?: boolean;
  timeForNotifyServiceEmployeeAfterSkipReport2?: boolean;
  timeForSecondNotifyServiceEmployeeAfterSkipReport2?: boolean;
  reportDescription2?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  scheduleDescription?: boolean;
  exportDayOfMonth?: number;
  blockSettlementGenarationDayOfMonth?: number;

  //Nowe pola
  materialCost?: boolean;
  reclamationHours?: boolean;
  contractorBudget?: boolean;
  contractorCommissionRateBudget?: boolean;
  coordinationRate?: boolean;
  reserveManager?: boolean;
  contractorBonus?: boolean;
  kpi?: boolean;
  employeeCostOfArrival?: boolean;
  employeeSaving?: boolean;
  clientNumberHoursFV?: boolean;
  employeeHoursOrdered?: boolean;
  employeeDeductionAmount?: boolean;
}

export interface OrderParameters {
  orderIds: number[];
  params: OrderParam[];
}

export interface OrderParam {
  param: string;
  value: any;
}

export interface ReportConfigImport {
  orderId?: number;
  defId?: number;
  project?: string;
  market?: string;
  city?: string;
  street?: string;
  localization?: string;
  reportKind?: string;
  reportFrequency?: string;
  startDateLong?: number;
  startDate?: string;
  finishDateLong?: number;
  finishDate?: string;
  realizationDateLong?: number;
  realizationDate?: string;
  dayOfWeek?: string;
  dayOfMonth?: number;
  userRole?: string;
  surveyTemplate?: string;
  numberDaysBefore?: number;
  nextReportDateLong?: number;
  nextReportDate?: string;
  toRemove?: number;
}

export interface OrderForServce {
  localization?: Localization,
  additionalType?: boolean,
  project?: ProjectForOrder,
  supervisorId?: string
}

export interface OrderDxTable {
  data: Order[];
  totalCount: number;
  groupCount: number;
  summary: number[];
}

export interface ReportConfig {
  id?: number;
  orderId?: number;
  orderPromotionId?: number;
  reportKind?: ReportKind;
  reportFrequency?: ReportFrequency;
  reportDayOfWeek?: WeekDay
  reportDayOfMonth?: number;
  startDate?: Date;
  finishDate?: Date;
  surveyDate?: Date;
  startDateLong?: number;
  finishDateLong?: number;
  suerveyDateLong?: number;
  surveyPatternId?: number;
  surveyPattern?: SurveyPattern;
  daysToSurveyGenerate?: number;
  reportResponsiblePerson?: ReportResponsiblePerson;
  nextReportDate?: Date;
}

export enum ReportKind {
  RecurringReport = 0,
  CentralPromotion = 1,
  Survey = 2,
  WorkCard = 3,
  SalesReport = 4
}

export enum ReportResponsiblePerson {
  Pollster = 0,
  Merchandiser = 1,
  LocalizationCoordinator = 2,
  Coordinator = 3,
  Manager = 4,
  SalesRepresentative = 5,
  Others = 6
}
