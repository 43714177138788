import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Renderer2, Inject, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Store } from '@ngrx/store';
import { MatDialogRef, MatSelectChange, MatSelect, MatDialog, MatDatepickerInputEvent, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatTabGroup } from '@angular/material';
import * as fromRoot from '../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ProjectDetailsComponent } from 'src/app/project/project-details/project-details.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { OrderTask, OrderTaskStatus, TaskPhoto, ProductBase, TaskKind, OrderTaskParameters, UpdateOrderTaskModel, Prob } from 'src/app/redux/order-task/order-task.model';
import { OrderTaskService } from 'src/app/services/order-task/order-task.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ApplicationData } from 'src/app/auth/user.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { ProductSelectComponent } from 'src/app/contractor/product/product-select/product-select.component';
import { USER_LOC_COORDINATOR_ROLENAME, USER_MANAGER_ROLENAME, USER_PMT_COORDINATOR_ROLENAME, USER_SUPERVISOR_ROLENAME, User, UserBase, UserListItem } from 'src/app/redux/user/user.model';
import { HttpClient } from '@angular/common/http';
import { OosConfirmationDialogComponent, OosConfirmationModel } from 'src/app/shared/oos-confirmation-dialog/oos-confirmation-dialog.component';
import { UIService } from 'src/app/shared/ui.service';
import { SurveyPattern, FileUploadreData, SessionStorageFileUploaderDataKey, FileInfoResource } from 'src/app/redux/survey/survey.model';
import { SurveyPage } from 'src/app/survey/survey-page/survey.page';
import * as SurveyPDF from 'survey-pdf';
import * as Survey from 'survey-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { UserSearchBarWithRolesComponent } from 'src/app/shared/user-search-bar-with-roles/user-search-bar-with-roles.component';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    // dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-order-task-details',
  templateUrl: './order-task-details.component.html',
  styleUrls: ['./order-task-details.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class OrderTaskDetailsComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  date = new FormControl(moment());
  private _onDestroy = new Subject<void>();
  model: OrderTask = null;
  updateOrderTaskModel: UpdateOrderTaskModel = {};

  isMultipleEdit = false;
  isEditing = false;
  isLoading = false;
  error = false;

  orderTasksToSend: OrderTask[] = [];
  orderTaskApp: OrderTask;
  taskPhotos: TaskPhoto[] = [];
  taskProducts: ProductBase[] = [];
  taskProbs: Prob[] = [];
  public fullScreen: boolean;
  apiUrl = environment.apiUrl;
  value: any[] = [];
  headers: any;
  taskPhotoAction = false;
  selectedMerch: UserListItem;
  selectedMerch1: UserListItem;
  selectedMerch2: UserListItem;
  selectedManager: UserListItem;
  selectedMerchandisingCoordinator: UserListItem;
  selectedLocalizationCoordinator: UserListItem;
  selectedSupervisor: UserListItem;
  selectedAdditionalSupervisor: UserListItem;
  allUsers: User[] = [];
  allManagers: UserListItem[] = [];
  allLocalizationCoordinators: UserListItem[] = [];
  allPmtCoordinators: UserListItem[] = [];
  allSupervisors: UserListItem[] = [];
  allSurveyPatterns: SurveyPattern[] = [];
  surveyResultsLoaded = false;
  selectedTabndex = 0;
  oosReason: string[] = [
    'Brak produktu (wyprzedał się)',
    'Produkt zablokowany',
    'Produkt usunięty z półki (przeterminowany)',
    'Produkt usunięty z półki (uszkodzony, niepełnowartościowy)',
    'Stan wirtualny (brak produktu na magazynie/półce)',
    'Zapas produktów nie wystarcza od dostawy do dostawy'
  ];
  surveyJson = '';
  selectedTaskId: number;
  selectedTasksIds: number[] = null;
  extraUsersForSearch: UserListItem[] = [];

  isMerchView: boolean = false;
  isOutsView: boolean = false;
  isLogisticView: boolean = false;
  isPromotionView: boolean = false;

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('matTabGroup', { static: false }) matTabGroup: MatTabGroup;

  @ViewChild('serviceEmployeeSearch1', { static: false }) set serviceEmployeeInput1(
    input: UserSearchBarWithRolesComponent
  ) {
    if (input) {
      this._serviceEmployeeInput1 = input;
      this.applyMerch1Value();
    }
  }
  private _serviceEmployeeInput1: UserSearchBarWithRolesComponent;

  @ViewChild('serviceEmployeeSearch2', { static: false }) set serviceEmployeeInput2(
    input: UserSearchBarWithRolesComponent
  ) {
    if (input) {
      this._serviceEmployeeInput2 = input;
      this.applyMerch2Value();
    }
  }
  private _serviceEmployeeInput2: UserSearchBarWithRolesComponent;

  @ViewChild('serviceEmployeeSearch3', { static: false }) set serviceEmployeeInput3(
    input: UserSearchBarWithRolesComponent
  ) {
    if (input) {
      this._serviceEmployeeInput3 = input;
      this.applyMerch3Value();
    }
  }
  private _serviceEmployeeInput3: UserSearchBarWithRolesComponent;

  // selectBox manager

  searchControl5: FormControl = new FormControl();
  private _filteredManagers: UserBase[] = [];
  filteredManagers: ReplaySubject<UserBase[]> = new ReplaySubject<UserBase[]>(1);
  managerSelectOpened = false;
  @ViewChild('managerSelect', { static: false }) selectElem5: MatSelect;

  // selectBox localization coordynator

  searchControl6: FormControl = new FormControl();
  private _filteredLocalizationCoordinators: UserBase[] = [];
  filteredLocalizationCoordinators: ReplaySubject<UserBase[]> = new ReplaySubject<UserBase[]>(1);
  localizationcoordinatorSelectOpened = false;
  @ViewChild('localizationCoordinatorSelect', { static: false }) selectElem6: MatSelect;

  // selectBox pmt coordynator

  searchControl7: FormControl = new FormControl();
  private _filteredPmtCoordinators: UserBase[] = [];
  filteredPmtCoordinators: ReplaySubject<UserBase[]> = new ReplaySubject<UserBase[]>(1);
  pmtCoordinatorSelectOpened = false;
  @ViewChild('pmtCoordinatorSelect', { static: false }) selectElem7: MatSelect;

  // selectBox supervisor

  searchControl8: FormControl = new FormControl();
  private _filteredSupervisors: UserBase[] = [];
  filteredSupervisors: ReplaySubject<UserBase[]> = new ReplaySubject<UserBase[]>(1);
  supervisorSelectOpened = false;
  @ViewChild('supervisorSelect', { static: false }) selectElem8: MatSelect;

  // selectBox additional supervisor

  searchControl9: FormControl = new FormControl();
  @ViewChild('additionalSupervisorSelect', { static: false }) selectElem9: MatSelect;

  // selectBox survey pattern

  searchControl10: FormControl = new FormControl();
  private _filteredSurveyPatterns: UserBase[] = [];
  filteredSurveyPatterns: ReplaySubject<SurveyPattern[]> = new ReplaySubject<SurveyPattern[]>(1);
  surveyPatternSelectOpened = false;
  @ViewChild('surveyPatternSelect', { static: false }) selectElem10: MatSelect;

  serviceEmployeeControl1 = new FormControl('');
  serviceEmployeeControl2 = new FormControl('');
  serviceEmployeeControl3 = new FormControl('');

  currentUser: ApplicationUser;

  constructor(
    public auth: AuthService,
    private service: OrderTaskService,
    private store: Store<fromRoot.IRootState>,
    private dialog: MatDialog,
    private render: Renderer2,
    private datePipe: DatePipe,
    private http: HttpClient,
    private uiService: UIService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService

  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const idParam = params['id'];
      const idsSplit = idParam.split(',');
      if (idsSplit.length > 1) {
        this.isMultipleEdit = true;
        this.selectedTasksIds = idsSplit;
        this.startEditing();
      }
      else {
        this.selectedTaskId = idParam;
      }
    });

    this.store.select(fromRoot.getTaskReloadData)
    .pipe(takeUntil(this._onDestroy))
    .subscribe(res => {
      if (res === true) {
        this.loadTaskModel();
        this.service.unsetReloadData();
      }
    });

    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(user => {
        this.currentUser = user;
      }
      );
      
    this.store.select(fromRoot.getTaskIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => {
        this.isLoading = isLoading
      });

    this.store.select(fromRoot.getTaskError)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(error => {
        this.error = error
      });

    this.store.select(fromRoot.getManagersListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((managers) => {
        this.allManagers = managers;
        this.filterManagers();
      });

    this.store.select(fromRoot.getLocalizationCoordinatorsListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((managers) => {
        this.allLocalizationCoordinators = managers;
        this.filterLocalizationCoordinators();
      });

    this.store.select(fromRoot.getPmtCoordinatorsListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((managers) => {
        this.allPmtCoordinators = managers;
        this.filterPmtCoordinators();
      });

    this.store.select(fromRoot.getSupervisorsV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((users) => {
        this.allSupervisors = users;
        this.filterSupervisors();
        this.filterAdditionalSupervisors();
      });

    this.store.select(fromRoot.getTaskIsEditing)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.isEditing = res;
      });

    this.store.select(fromRoot.getSurveyPatternsListForProject)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((patterns) => {
        this.allSurveyPatterns = patterns;
        this.filterSurveyPatterns();
      });

    this.store.select(fromRoot.getTempContainerRefreshed)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res === true) {
          const currentUrl = this.router.url;
          //this.router.navigate([currentUrl]);
          
        }
      });

    const currentUserAppData: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = { Authorization: `Bearer ${currentUserAppData.token}` };
  }

  ngAfterViewInit() {
    this.store.select(fromRoot.getTaskSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        res !== null ? this.orderTaskApp = res : this.orderTaskApp = {};
        this.model = { ...this.orderTaskApp };

        if (res !== null && res !== undefined) {
          switch (res.projectKindId) {
              case 8:
                this.isPromotionView = true;
                break;
              case 11:
              case 12:
                this.isLogisticView = true;
                break;
            default:
              this.isMerchView = true;
              break;
          }
        }

        this.model.createdDateText = this.datePipe.transform(this.model.createdDate, 'yyyy-MM-dd HH:mm:ss');
        this.model.requiredDateText = this.datePipe.transform(this.model.requiredDate, 'yyyy-MM-dd');
        this.model.realizationDateText = this.datePipe.transform(this.model.realizationDate, 'yyyy-MM-dd HH:mm:ss');
        this.model.surveyRealizationDateText = this.datePipe.transform(this.model.surveyRealizationDate, 'yyyy-MM-dd HH:mm:ss');
        if (this.model.id === undefined || this.model.id === 0) {
          this.model.responsiblePerson = {};
        }
        if (res !== null && res !== undefined) {
          this.service.getSurveyDataByOrderId(res.id);
          this.reloadExtraUsersForSearch();
        }
      });

    this.store.select(fromRoot.getSurveyData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        surveyData => {
          if (surveyData === null || surveyData === undefined) {
            return;
          }

          this.model.surveyPattern = surveyData.surveyPattern;
          this.model.surveyRealizationJson = surveyData.surveyRealizationJson;
          this.model.surveyPatternIndividual = surveyData.surveyPatternIndividual;
          if (this.model.surveyPatternIndividual) {
            this.surveyJson = this.model.surveyPatternIndividual;
          } else {
            this.surveyJson = this.model.surveyPattern ? this.model.surveyPattern.json : '';
          }
          this.surveyResultsLoaded = true;
          const timeOffsetInMS: number = new Date().getTimezoneOffset() * 60000;
          const localDate = new Date().getTime() - timeOffsetInMS;
          const fileUploaderData = {
            apiUrl: environment.apiUrl + '/attachment/addimage/',
            formDataParamiters: [],
            fileInfoResource: {
              localization: this.model.market + ',' + this.model.localizationCity + ',' + this.model.localizationStreet,
              waterMark: true,
              lastModificationDate: localDate
            }
          } as FileUploadreData;

          fileUploaderData.formDataParamiters.push({ key: 'maxWidthHeight', value: '1400' });
          sessionStorage.setItem(SessionStorageFileUploaderDataKey, JSON.stringify(fileUploaderData));
        }
      );
      
    this.userService.getUsersByRoleName(USER_PMT_COORDINATOR_ROLENAME);
    this.userService.getUsersByRoleName(USER_SUPERVISOR_ROLENAME);
    this.userService.getUsersByRoleName(USER_LOC_COORDINATOR_ROLENAME);
    this.userService.getUsersByRoleName(USER_MANAGER_ROLENAME);

    this.service.resetError();
    this.service.resetTempContainerRefreshed();
    this.loadTaskModel();

    this.searchControl5.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.managerSelectOpened || this._filteredManagers.length === 0) {
          this.filterManagers();
        }
      });

    this.searchControl6.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.localizationcoordinatorSelectOpened || this._filteredLocalizationCoordinators.length === 0) {
          this.filterLocalizationCoordinators();
        }
      });

    this.searchControl7.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.pmtCoordinatorSelectOpened || this._filteredPmtCoordinators.length === 0) {
          this.filterPmtCoordinators();
        }
      });

    this.searchControl8.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.supervisorSelectOpened || this._filteredSupervisors.length === 0) {
          this.filterSupervisors();
        }
      });

    this.searchControl9.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.supervisorSelectOpened || this._filteredSupervisors.length === 0) {
          this.filterAdditionalSupervisors();
        }
      });

    this.searchControl10.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.surveyPatternSelectOpened || this._filteredSurveyPatterns.length === 0) {
          this.filterSurveyPatterns();
        }
      });
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  loadTaskModel()
  {
    if (this.selectedTaskId !== null && this.selectedTaskId !== undefined) {
      this.service.getTaskById(this.selectedTaskId);
    }
  }

  isCancelButtonVisible() {
    if (!this.isMultipleEdit) {
      if (this.isEditing) {
        return true;
      }
    }
    return false;
  }

  applyMerch1Value() {
    if (this._serviceEmployeeInput1) {
      this._serviceEmployeeInput1.applyDefaultValue(this.model.responsiblePerson);
    }
  }

  applyMerch2Value() {
    if (this._serviceEmployeeInput2) {
      this._serviceEmployeeInput2.applyDefaultValue(this.model.serviceEmployee);
    }
  }

  applyMerch3Value() {
    if (this._serviceEmployeeInput3) {
      this._serviceEmployeeInput3.applyDefaultValue(this.model.responsiblePerson);
    }
  }

  reloadSurvey() {
    this.surveyResultsLoaded = false;
    setTimeout(() => {
      this.surveyResultsLoaded = true;
    }, 100);
  }
  
  reloadExtraUsersForSearch() {
    this.extraUsersForSearch = [];
    if (this.model.supervisor !== null && this.model.supervisor !== undefined) {
      this.extraUsersForSearch.push(this.model.supervisor);
    }
    if (this.model.manager !== null && this.model.manager !== undefined) {
      this.extraUsersForSearch.push(this.model.manager);
    }
    if (this.model.merchandisingCoordinator !== null && this.model.merchandisingCoordinator !== undefined) {
      this.extraUsersForSearch.push(this.model.merchandisingCoordinator);
    }
    if (this.model.additionalSupervisor !== null && this.model.additionalSupervisor !== undefined) {
      this.extraUsersForSearch.push(this.model.additionalSupervisor);
    }
  }

  prepareTaskInfo() {
    if (this.model.id) {
      if (this.model.projectId) {
        this.service.getSurveyPatternsForProject(this.model.projectId, false);
      }
      this.service.getSurveyDataByOrderId(this.model.id);
      this.getTaskProbs(this.model.id); // TODO: warunkowo jeśli użytkownik ma uprawnienia do zakładki Pro.Display
      if (!this.model.surveyPatternId) {
        this.getTaskPhotos(this.model.id);
        this.getTaskProducts(this.model.id);
      }
    }
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  navigateBack() {
    window.close();
    /*
    this.cancel();
    this.service.clearTaskProducts();
    this.service.clearTaskPhotos();
    this.service.unSelectTask();
    this.router.navigate(['order-task-list']);*/
  }

  cancel(): void {
    this.service.stopEditing();
    if (this.isMultipleEdit) {
      this.clearToogles();
      //this.model = { ...this.orderTaskApp };

    }
    else {
      this.model = { ...this.orderTaskApp };
    }


    //this.service.clearTaskProducts();
    //this.service.clearTaskPhotos();
    //this.service.unSelectTask();
  }

  startEditing() {
    this.service.startEditing();
  }

  save() {
    this.service.stopEditing();
    if (!this.model.id || this.model.id === 0) {
      this.service.createTask(this.model);
    } else {
      this.model.products = this.taskProducts;

      this.service.updateTask(this.model);
      this.orderTaskApp = { ...this.model };
    }
  }

  removePhoto(id: number) {
    this.taskPhotoAction = true;
    this.deletePhoto(id);
  }

  downloadPhoto(photo: TaskPhoto) {
    this.taskPhotoAction = true;
    this.service.downloadPhoto(photo.attachemntId, photo.fileName, photo.fileType);
  }

  getRowNumber(grid, data) {
    const missedRowsNumber = this.getGroupRowsNumbers(data.row.rowIndex);
    return data.row.rowIndex + 1 - missedRowsNumber;
  }

  getGroupRowsNumbers(rowIndex: number) {
    const visibleRows = this.grid.instance.getVisibleRows();
    let counter = 0;
    for (let i = rowIndex; i >= 0; i--) {
      if (visibleRows[i] !== undefined && visibleRows[i].rowType === 'group') {
        counter++;
      }
    }
    return counter;
  }

  deleteSelectedRows() {
    const rows = this.grid.selectedRowKeys;
    this.service.removeProductsFromReport(rows, this.model.id);
    this.grid.selectedRowKeys = [];
  }

  addProducts() {
    this.dialog.open(ProductSelectComponent, {
      width: '95%'
    });
  }

  reportCompleted() {
    if (!this.oosValidation()) {
      this.uiService.openSnack('Nie wszystkie brakujące produkty mają wypełniny powód braku!', null, 2000);
      return;
    }

    const oosCount = this.taskProducts.filter(p => p.oos === true).length;
    if (oosCount === 0 && this.model.taskKind === TaskKind.OutOfStockReport) {
      const dialogTextData: OosConfirmationModel = {
        titleKey: 'Braki produktów',
        contentKey: 'Potwierdzam, że w lokalizacji są obecne wszystkie produkty wskazane w raporcie.'
      };
      const ref = this.dialog.open(OosConfirmationDialogComponent, {
        width: '25%',
        minWidth: '300px',
        data: dialogTextData,
        autoFocus: false
      });
      ref.afterClosed()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((dialogRes) => {
          if (dialogRes) {
            this.service.setReportStatus(OrderTaskStatus.Finished, this.model.id, this.model.taskRealization.realizationNote);
          }
        });
    } else {
      this.service.setReportStatus(OrderTaskStatus.Finished, this.model.id, this.model.taskRealization.realizationNote);
    }
  }

  reportNotCompleted() {
    this.service.setReportStatus(OrderTaskStatus.NotDone, this.model.id, '');
  }

  reportCanceled() {
    this.service.setReportStatus(OrderTaskStatus.Canceled, this.model.id, '');
  }

  returnFromCanceled() {
    this.service.setReportStatus(OrderTaskStatus.New, this.model.id, '');
  }

  onPhotoClick(event: any) {
    if (!this.taskPhotoAction) {
      const photo = this.taskPhotos[event.itemIndex];
      const image = new Image();
      image.src = 'data:image/jpg;base64,' + photo.photoBase64;
      const w = window.open('');
      w.document.write(image.outerHTML);
    } else {
      this.taskPhotoAction = false;
    }
  }

  merchSelectionChange(user: UserListItem) {
    if (user !== null && user !== undefined) {
      this.selectedMerch = user;
      this.model.responsiblePerson = user;
      this.model.responsiblePersonId = user.id;     
    }
  }

  merchSelectionChange1(user: UserListItem) {
    if (user !== null && user !== undefined) {
      this.selectedMerch1 = user;
      this.model.responsiblePerson = user;
      this.model.responsiblePersonId = user.id;     
    }
  }

  merchSelectionChange2(user: UserListItem) {
    if (user !== null && user !== undefined) {
      this.selectedMerch2 = user;
      this.model.serviceEmployee = user;
      this.model.serviceEmployeeId = user.id;
    }
  }

  surveyPatternSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.model.surveyPattern = e.value;
      this.model.surveyPatternId = e.value.id;
    }
  }

  compareFn3(merch1: UserBase, merch2: UserBase) {
    return merch1 && merch2 ? merch1.id === merch2.id : false;
  }

  compareFn4(survey1: SurveyPattern, survey2: SurveyPattern) {
    return survey1 && survey2 ? survey1.id === survey2.id : false;
  }

  managerSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedManager = e.value;
      this.model.manager = e.value;
      this.model.managerId = e.value.id;
      this.reloadExtraUsersForSearch();      
    }
  }

  merchandisingCoordinatorSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedMerchandisingCoordinator = e.value;
      this.model.merchandisingCoordinator = e.value;
      this.model.merchandisingCoordinatorId = e.value.id;
      this.reloadExtraUsersForSearch();
    }
  }

  localizationCoordinatorSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedLocalizationCoordinator = e.value;
      this.model.localizationCoordinator = e.value;
      this.model.localizationCoordinatorId = e.value.id;
    }
  }

  supervisorSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedSupervisor = e.value;
      this.model.supervisor = e.value;
      this.model.supervisorId = e.value.id;
      this.reloadExtraUsersForSearch();
    }
  }

  additionalSupervisorSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedAdditionalSupervisor = e.value;
      this.model.additionalSupervisor = e.value;
      this.model.additionalSupervisorId = e.value.id;
      this.reloadExtraUsersForSearch();
    }
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.model.requiredDate = new Date(event.value);
      this.model.requiredDateLong = this.model.requiredDate.getTime();
    } else {
      this.model.requiredDate = null;
      this.model.requiredDateLong = 0;
    }
  }

  setDate2(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      if (this.model.taskRealization) {
        this.model.taskRealization.realizationDate = new Date(event.value);
        this.model.taskRealization.realizationDateLong = this.model.taskRealization.realizationDate.getTime();
      }
    } else {
      this.model.taskRealization.realizationDate = null;
      this.model.taskRealization.realizationDateLong = 0;
    }
  }

  filterManagers() {

    if (!this.allManagers) {
      return;
    }
    // get the search keyword
    let search = this.searchControl5.value;
    if (!search) {
      this.filteredManagers.next(this.allManagers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the managers
    this.filteredManagers.next(
      this.allManagers.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  filterLocalizationCoordinators() {

    if (!this.allLocalizationCoordinators) {
      return;
    }
    // get the search keyword
    let search = this.searchControl6.value;
    if (!search) {
      this.filteredLocalizationCoordinators.next(this.allLocalizationCoordinators.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the managers
    this.filteredLocalizationCoordinators.next(
      this.allLocalizationCoordinators.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  filterPmtCoordinators() {

    if (!this.allPmtCoordinators) {
      return;
    }
    // get the search keyword
    let search = this.searchControl7.value;
    if (!search) {
      this.filteredPmtCoordinators.next(this.allPmtCoordinators.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the managers
    this.filteredPmtCoordinators.next(
      this.allPmtCoordinators.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  filterSurveyPatterns() {

    if (!this.allSurveyPatterns) {
      return;
    }
    // get the search keyword
    let search = this.searchControl10.value;
    if (!search) {
      this.filteredSurveyPatterns.next(this.allSurveyPatterns.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the managers
    this.filteredSurveyPatterns.next(
      this.allSurveyPatterns.filter(s => s.title.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterSupervisors() {
    if (!this.allSupervisors) {
      return;
    }
    // get the search keyword
    let search = this.searchControl8.value;
    if (!search) {
      this.filteredSupervisors.next(this.allSupervisors.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the supervisors
    this.filteredSupervisors.next(
      this.allSupervisors.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterAdditionalSupervisors() {
    if (!this.allSupervisors) {
      return; 
    }
    // get the search keyword
    let search = this.searchControl9.value;
    if (!search) {
      this.filteredSupervisors.next(this.allSupervisors.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the supervisors
    this.filteredSupervisors.next(
      this.allSupervisors.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
        || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  getTaskPhotos(taskId: number) {
    this.http.get<TaskPhoto[]>(environment.apiUrl + '/orderTask/realization/photo/' + taskId)
      .toPromise()
      .then((photos) => { console.log('photos found'); console.log(photos); this.taskPhotos = photos; })
      .catch(err => {
        this.taskPhotos = [];
        console.error(err);
      });
  }

  getTaskProducts(taskId: number) {
    this.http.get<ProductBase[]>(environment.apiUrl + '/orderTask/realization/product/' + taskId)
      .toPromise()
      .then((products) => { this.taskProducts = products; })
      .catch(err => {
        this.taskProducts = [];
        console.error(err);
      });
  }

  getTaskProbs(taskId: number) {
    this.http.get<Prob[]>(`${environment.apiUrl}/probs/order-task/${taskId}`)
      .toPromise()
      .then((probs) => { this.taskProbs = probs; })
      .catch(err => {
        this.taskProbs = [];
        console.error(err);
      });
  }

  // raport cykliczny zamienia sie w zdjeciowy jakos??

  deletePhoto(attachmentId: number) {
    this.http.delete(environment.apiUrl + '/ordertask/realization/photo/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.getTaskPhotos(this.model.id);
        })
      .catch(err => {
        console.error(err);
      });
  }

  setDateTimeformat(date: number) {
    if (date !== undefined && date !== 0) {
      return (new Date(date)).toISOString().
        replace(/T/, ' ').
        replace(/\..+/, '');
    }
  }

  uploadedFile(event) {
    this.service.updatePhotoCreationTime(this.model.id, event.file.lastModified, event.file.name);
    this.getTaskPhotos(this.model.id);

    const index = this.value.indexOf(event.file);
    if (index > -1) {
      this.value.splice(index, 1);
    }
  }

  updateSelectedTasks() {
    const taskParams: OrderTaskParameters = { taskIds: this.selectedTasksIds, params: [] };

    if (this.updateOrderTaskModel.title) {
      taskParams.params.push({ param: 'title', value: this.model.title });
    }
    if (this.updateOrderTaskModel.requiredDate) {
      taskParams.params.push({ param: 'requiredDate', value: this.model.requiredDate ? this.model.requiredDate.getTime() : 0 });
    }
    if (this.updateOrderTaskModel.responsiblePerson) {
      taskParams.params.push({ param: 'responsiblePerson', value: this.model.responsiblePerson ? this.model.responsiblePerson.id : '' });
    }
    if (this.updateOrderTaskModel.taskDescription) {
      taskParams.params.push({ param: 'taskDescription', value: this.model.taskdescription });
    }
    if (this.updateOrderTaskModel.approvedByOffice) {
      taskParams.params.push({ param: 'approvedByOffice', value: this.model.approvedByOffice });
    }
    if (this.updateOrderTaskModel.supervisor) {
      taskParams.params.push({ param: 'supervisor', value: this.model.supervisor ? this.model.supervisor.id : '' });
    }
    if (this.updateOrderTaskModel.additionalSupervisor) {
      taskParams.params.push({ param: 'additionalSupervisor', value: this.model.additionalSupervisor ? this.model.additionalSupervisor.id : '' });
    }
    if (this.updateOrderTaskModel.manager) {
      taskParams.params.push({ param: 'manager', value: this.model.manager ? this.model.manager.id : '' });
    }
    if (this.updateOrderTaskModel.merchandisingCoordinator) {
      taskParams.params.push({ param: 'merchandisingCoordinator', value: this.model.merchandisingCoordinator ? this.model.merchandisingCoordinator.id : '' });
    }
    if (this.updateOrderTaskModel.localizationCoordinator) {
      taskParams.params.push({ param: 'localizationCoordinator', value: this.model.localizationCoordinator ? this.model.localizationCoordinator.id : '' });
    }
    if (this.updateOrderTaskModel.serviceEmployee) {
      taskParams.params.push({ param: 'serviceEmployee', value: this.model.serviceEmployee ? this.model.serviceEmployee.id : '' });
    }
    if (this.updateOrderTaskModel.contractorManager) {
      taskParams.params.push({ param: 'contractorManager', value: this.model.contractorManager });
    }
    if (this.updateOrderTaskModel.salesRepresentative) {
      taskParams.params.push({ param: 'salesRepresentative', value: this.model.salesRepresentative });
    }
    if (this.updateOrderTaskModel.contractorLocalizationCode) {
      taskParams.params.push({ param: 'contractorLocalizationCode', value: this.model.contractorLocalizationCode });
    }
    if (this.updateOrderTaskModel.contractorArea) {
      taskParams.params.push({ param: 'contractorArea', value: this.model.contractorArea });
    }
    if (this.updateOrderTaskModel.contractorSubArea) {
      taskParams.params.push({ param: 'contractorSubArea', value: this.model.contractorSubArea });
    }
    if (this.updateOrderTaskModel.realizedBy) {
      taskParams.params.push({ param: 'realizedBy', value: this.model.realizedBy ? this.model.realizedBy.id : '' });
    }
    if (this.model.orderTaskSettlementStatus >= 0) {
      taskParams.params.push({ param: 'orderTaskSettlementStatus', value: this.model.orderTaskSettlementStatus });
    }
    if (this.model.status >= 0) {
      taskParams.params.push({ param: 'orderTaskStatus', value: this.model.status });
    }     
    
    this.service.updateSelectedTasks(taskParams);
    this.service.stopEditing();
    this.cancel();
    this.uiService.openSnack('Aktualizacja zadań...', null, null);
  }

  clearToogles() {
    this.updateOrderTaskModel.title = false;
    this.updateOrderTaskModel.title = false;
    this.updateOrderTaskModel.requiredDate = false;
    this.updateOrderTaskModel.responsiblePerson = false;
    this.updateOrderTaskModel.taskDescription = false;
    this.updateOrderTaskModel.approvedByOffice = false;
    this.updateOrderTaskModel.supervisor = false;
    this.updateOrderTaskModel.additionalSupervisor = false;
    this.updateOrderTaskModel.manager = false;
    this.updateOrderTaskModel.merchandisingCoordinator = false;
    this.updateOrderTaskModel.localizationCoordinator = false;
    this.updateOrderTaskModel.serviceEmployee = false;
    this.updateOrderTaskModel.contractorManager = false;
    this.updateOrderTaskModel.salesRepresentative = false;
    this.updateOrderTaskModel.contractorLocalizationCode = false;
    this.updateOrderTaskModel.contractorArea = false;
    this.updateOrderTaskModel.contractorSubArea = false;
    this.updateOrderTaskModel.realizedBy = false;
    this.updateOrderTaskModel.orderTaskSettlementStatus = false;
    this.updateOrderTaskModel.orderTaskStatus = false;
  }

  oosValidation() {
    let result = true;
    this.taskProducts.forEach(item => {
      if (item.oos === true && (item.description === '' || item.description === undefined)) {
        result = false;
      }
    });
    return result;
  }

  runSurvey() {
    this.dialog.open(SurveyPage, {
      width: '90%'
    });
  }

  saveData(result) {
    this.surveyResultsLoaded = false;
    const json = JSON.stringify(result.res);
    this.service.saveSurveyResult(this.model.id, json, result.isCompleteOnTrigger);
    this.model.surveyRealizationJson = json;
  }

  savePdfSurvey() {
    const json = this.model.surveyPatternIndividual ? this.model.surveyPatternIndividual : this.model.surveyPattern.json;
    const surveyModel = new Survey.Model(json);
    surveyModel.data = this.model.surveyRealizationJson;
    surveyModel.onTextMarkdown.add((survey, options) => { options.html = options.text; });

    const options = {
      haveCommercialLicense: true,
      fontSize: 14,
      margins: {
        left: 10,
        right: 10,
        top: 18,
        bot: 10
      }
    };

    const surveyPDF = new SurveyPDF.SurveyPDF(json, options);
    surveyPDF.data = JSON.parse(this.model.surveyRealizationJson);
    surveyPDF.mode = 'display';

    surveyPDF
      .onRenderQuestion
      .add((survey, options) => {
        // console.log(options.question.getType() );
        // console.log(options.question.name);
        // console.log(options.question);
        if (options.question.name !== 'pdf_adorners_changesize') {
          return;
        }
        const flatMatrix = options.repository.create(survey, options.question, options.controller, 'matrix');
        const oldFontSize = options.controller.fontSize;
        options.controller.fontSize = oldFontSize / 2.0;
        return new Promise((resolve) => {
          flatMatrix
            .generateFlats(options.point)
            .then((matrixBricks) => {
              options.controller.fontSize = oldFontSize;
              options.bricks = matrixBricks;
              resolve(1);
            });
        });
      });
    // surveyPDF.onTextMarkdown
    // .add(function (survey, options) {
    //     options.html = options.text;
    // });
    surveyPDF.save();
  }

  selectedTabChange(event) {
    this.selectedTabndex = this.matTabGroup.selectedIndex;
  }

  canRefreshTempContainer() {
    if (!this.isMultipleEdit && !this.isEditing && this.auth.appRoles.canTaskEdit && this.model.isCheckInActive) {
      return true;
    }
    return false;
  }

  refreshTempContainer() {
    this.service.refreshTempContainer(this.selectedTaskId);
  }
}
