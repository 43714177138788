import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Product, ProductPhoto } from 'src/app/redux/project/product.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as ProductActions from 'src/app/redux/project/product.actions';
import * as OrderActions from 'src/app/redux/order/order.actions';
import * as ExcelExportActions from 'src/app/redux/excel-export/excel-export.actions';
import { Store } from '@ngrx/store';
import * as root from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { OrderTask, OrderTaskStatus, ProductBase, TaskKind, TaskPhoto } from 'src/app/redux/order-task/order-task.model';
import { Order2Excel, RepConfig2Excel } from 'src/app/_models/order-excel.model';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import { ContactImport, UserListItem } from 'src/app/redux/user/user.model';
import { SurveyAnswer, SurveyReportData, TempSurveyResults } from 'src/app/redux/survey/survey.model';
import { TranslateService } from '@ngx-translate/core';
import { ReportConfigImport } from 'src/app/redux/order/order.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ControlScaleLineComponent } from 'ngx-openlayers';
import { id } from '@swimlane/ngx-charts/release/utils';

@Injectable()
export class ExcelService {
  private _onDestroy = new Subject<void>();
  constructor(private http: HttpClient,
    private store: Store<root.IRootState>,
    private uiService: UIService,
    private datePipe: DatePipe,
    public auth: AuthService,
    private translate: TranslateService,) { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  wsPhotoRep: XLSX.WorkSheet;

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  unsetMessageFromServer() {
    this.store.dispatch(new ExcelExportActions.SetMessage(null));
  }

  public exportProducts2Excel(jsonData: Product[], fileName: string): void {

    const localizations = [].concat(...jsonData.map(x => x.productLocalizations.map(c => [c, x.name])))
      .map(r => {
        const c = r[0]; c.name = r[1]; return {
          siec: c.market,
          miasto: c.city,
          ulica: c.street,
          lokalizacja: c.localizationCode,
          grupa: c.group,
          nazwa: c.name,
          numer_produktu: c.code
        };
      });

    const productIndexMarkets = [].concat(...jsonData.map(x => x.productIndexMarkets.map(c => [c, x.name])))
      .map(r => { const c = r[0]; c.name = r[1]; return { nazwa: c.name, siec: c.marketName, index: c.productIndex }; });

    const products = jsonData.map(x => {
      const c = x[0]; return {
        nazwa: x.name,
        numer_produktu: x.code,
        grupa: x.group,
        kategoria: x.category,
        rodzaj: x.kind,
        model: x.model,
        marka: x.mark,
        waznosc: x.priority === 0 ? 'Must to have' : 'Nice to have',
        ilosc_szt_w_opak_zbiorczym: x.piecesNumber,
        cena: x.price,
        cechy_szczegolne: x.importantFeatures,
        archiwalny: x.archival,
        informacje_o_produkcie: x.notes,
        raport_tygodniowy: x.weeklyReport,
        raport_dwutygodniowy: x.biWeeklyReport,
        raport_miesieczny: x.monthlyReport,
        raport_oos: x.oosReport,
        raport_wg_grafiku: x.scheduleReport,
        ankieta: x.inquiryReport,
        numer_w_raporcie: x.numberInReport,
        produkt_konkurencji: x.competitionProduct,

      };
    });

    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(products);
    const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(localizations);
    const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(productIndexMarkets);
    const wb: XLSX.WorkBook = {
      Sheets: { Produkty: ws1, Lokalizacje: ws2, Indeksy: ws3 },
      SheetNames: ['Produkty', 'Lokalizacje', 'Indeksy']
    };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  public ReadProductsFromExcel(data: any, projectId: number) {
    let workBook = null;
    let jsonData = null;
    workBook = XLSX.read(data, { type: 'binary' });
    jsonData = workBook.SheetNames.reduce((initial, name) => {
      const sheet = workBook.Sheets[name];
      initial[name] = XLSX.utils.sheet_to_json(sheet);
      return initial;
    }, {});

    const products = jsonData.Produkty;
    const localizations = jsonData.Lokalizacje.map((row: any) => {
      return {
        nazwa: row.nazwa,
        siec: row.siec,
        lokalizacja: row.lokalizacja
      };
    });
    const productIndexMarkets = jsonData.Indeksy;

    this.store.dispatch(new ProductActions.StartLoadingList());
    this.http.put(environment.apiUrl + '/project/product/import',
      {
        projectId,
        products,
        localizations,
        productIndexMarkets
      }).toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ProductActions.StopLoadingList());
        })
      .catch(err => {
        //this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ProductActions.StopLoadingList());
        console.error(err);
      });

  }

  public async exportReports(jsonData: OrderTask[], fileName: string) {

    this.wsPhotoRep = {};

    const url = 'https://systemapi.e-pmt.pl/api/ordertask/realization/photo/downloadimage/';
    const jsonDataPhotos = jsonData.filter(r => r.taskKind === TaskKind.PhotosReport);
    const photoReports: { [k: string]: any }[] = jsonDataPhotos.map(x => {
      const c = x[0]; return {
        Nazwa_projektu: x.project,
        Siec: x.market,
        Nazwa_lokalizacji: x.localizationCode,
        Adres: x.localizationCity + ', ' + x.localizationStreet,
        Komentarz_do_realizacji: x.taskRealization !== undefined ? x.taskRealization.realizationNote : '',
        Odpowiedzialny: this.getFullName(x.responsiblePerson),
        Data_realizacji: this.datePipe.transform(x.requiredDate, 'yyyy-MM-dd'),
        Data_wykonania: this.auth.appRoles.canTaskDetailsRealizationPage ? this.datePipe.transform(x.realizationDate, 'yyyy-MM-dd') : this.datePipe.transform(x.requiredDate, 'yyyy-MM-dd'),
        Status: this.getOrderTaskStatus(x.status),
        Nr_zadania: x.id,
        Przedstawiciel_handlowy: x.salesRepresentative,
        Menedzer: x.manager !== undefined ? this.getFullName(x.manager) : '',
        Koordynator_ds_merchandisingu: x.merchandisingCoordinator !== undefined ? this.getFullName(x.merchandisingCoordinator) : ''
      };
    });

    await this.doWork(jsonData, fileName, photoReports, url);
  }

  getFullName(person: UserListItem) {
    if (person === null || person === undefined) {
      return '';
    }
    return person.firstName + '' + person.lastName;
  }

  async setPhotosParameters(orderTasks: OrderTask[], photoReports: any, url: string) {
    let maxPhotosNumber = 0;
    let i = 0;
    const photoOrderTasks = orderTasks.filter(t => t.taskKind === TaskKind.PhotosReport);
    photoOrderTasks.forEach(element => {
      let j = 0;
      if (element.taskRealization !== undefined) {
        element.taskRealization.photos.forEach(photo => {
          photoReports[i]['Zdjecie' + (j + 1).toString()] = photo.fileName;
          if (j + 1 > maxPhotosNumber) {
            maxPhotosNumber = j + 1;
          }
          j = j + 1;
        });
      }
      i = i + 1;
    });

    this.wsPhotoRep = XLSX.utils.json_to_sheet(photoReports);

    if (maxPhotosNumber > 0) {
      for (let R = 1; R <= photoReports.length; R++) {
        for (let C = 13; C <= (13 + maxPhotosNumber - 1); C++) {
          const cellAddress: XLSX.CellAddress = { c: C, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);

          if (photoReports[R - 1]['Zdjecie' + (C - 13 + 1).toString()] !== undefined) {
            this.wsPhotoRep[cellRef].l = { Target: url + photoOrderTasks[R - 1].taskRealization.photos[C - 13].attachemntId, Tooltip: photoReports[R - 1]['Zdjecie' + (maxPhotosNumber - C + 1).toString()] };
          }
        }
      }
    }
  }

  async getPhotos(elementTasks: OrderTask[]) {
    elementTasks.forEach(elementTask => {
      this.http.get<TaskPhoto[]>(environment.apiUrl + '/orderTask/realization/photoforreport/' + elementTask.id)
        .toPromise()
        .then(
          (photos) => {
            elementTask.taskRealization.photos = photos;
          })
        .catch(err => {
          console.error(err);
        });
    });
  }

  async getProducts(elementTasks: OrderTask[]) {
    elementTasks.forEach(elementTask => {
      this.http.get<ProductBase[]>(environment.apiUrl + '/orderTask/realization/product/' + elementTask.id)
        .toPromise()
        .then(
          (products) => {
            elementTask.products = products;
          })
        .catch(err => {
          console.error(err);
        });
    });
  }  

  getOrderTaskStatus(status: number) {
    const labels = {
      0: 'Czeka',
      1: 'W trakcie',
      2: 'Zrobione',
      3: 'Anulowane',
      4: 'Niewykonane',
    };
    return labels[status];
  }

  saveDataToExcel(orderTasks: OrderTask[], fileName: string) {
    const oosReports: any[] = [];
    const jsonDataOos = orderTasks.filter(r => r.taskKind === TaskKind.OutOfStockReport);
    jsonDataOos.forEach(elementTask => {
      elementTask.products.forEach(elementProduct => {
        oosReports.push({
          Nazwa_projektu: elementTask.project,
          Siec: elementTask.market,
          Nazwa_lokalizacji: elementTask.localizationCode,
          Adres: elementTask.localizationCity + ', ' + elementTask.localizationStreet,
          Komentarz_do_realizacji: elementTask.taskRealization !== undefined ? elementTask.taskRealization.realizationNote : '',
          Odpowiedzialny: elementTask.responsiblePerson.firstName + '' + elementTask.responsiblePerson.lastName,
          Data_realizacji: this.datePipe.transform(elementTask.requiredDate, 'yyyy-MM-dd'),
          Data_wykonania: this.auth.appRoles.canTaskDetailsRealizationPage ? this.datePipe.transform(elementTask.realizationDate, 'yyyy-MM-dd') : this.datePipe.transform(elementTask.requiredDate, 'yyyy-MM-dd'),
          Status: this.getOrderTaskStatus(elementTask.status),
          Nr_zadania: elementTask.id,
          Przedstawiciel_handlowy: elementTask.salesRepresentative,
          Menedzer: elementTask.manager !== undefined ? this.getFullName(elementTask.manager) : '',
          Koordynator_ds_merchandisingu: elementTask.merchandisingCoordinator !== undefined ? this.getFullName(elementTask.merchandisingCoordinator) : '',
          Grupa: elementProduct.group,
          Kategoria: elementProduct.category,
          Nazwa: elementProduct.name,
          Numer: elementProduct.code,
          OOS: elementTask.status === OrderTaskStatus.New ? '' : (elementProduct.oos ? 0 : 1),
          Powod_braku: elementProduct.description
        });
      });
    });

    const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(oosReports);

    const wb: XLSX.WorkBook = {
      Sheets: { RaportZdjeciowy: this.wsPhotoRep, RaportBrakow: ws2 },
      SheetNames: ['RaportZdjeciowy', 'RaportBrakow']
    };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  prepareSurveyData(orderTasks: OrderTask[], reports: any[], surveySections: string[]) {

    const jsonPatternReportData = orderTasks.filter(r => r.surveyPatternId !== undefined);
    for (const elementTask of jsonPatternReportData) {
      if (elementTask.surveyAnswers) {
        let surveyAnswers = elementTask.surveyAnswers.map(p => p.product);
        let products = surveyAnswers.filter((v, i, a) => a.indexOf(v) === i);

        let sections = elementTask.surveyAnswers.map(p => p.sectionName);
        let temp = sections.filter((v, i, a) => a.indexOf(v) === i);

        for (const sec of temp) {
          if (!surveySections.includes(sec)) {
            surveySections.push(sec);
          }
        }

        for (const elementAnswer of products) {
          reports.push({
            Nazwa_projektu: elementTask.project,
            Tytul_zadania: elementTask.title,
            Szablon_raportu: elementTask.surveyPatternTitle,
            Siec: elementTask.market,
            Nazwa_lokalizacji: elementTask.localizationCode,
            Adres: elementTask.localizationCity + ', ' + elementTask.localizationStreet,
            Komentarz_do_realizacji: elementTask.taskRealization !== undefined ? elementTask.taskRealization.realizationNote : '',
            Odpowiedzialny: elementTask.responsiblePerson.firstName + ' ' + elementTask.responsiblePerson.lastName,
            Data_realizacji: this.datePipe.transform(elementTask.requiredDate, 'yyyy-MM-dd'),
            Data_wykonania: this.auth.appRoles.canTaskDetailsRealizationPage ? this.datePipe.transform(elementTask.realizationDate, 'yyyy-MM-dd') : this.datePipe.transform(elementTask.requiredDate, 'yyyy-MM-dd'),
            Status: this.getOrderTaskStatus(elementTask.status),
            Nr_zadania: elementTask.id,
            Format: elementTask.localizationFormat,
            Agent: elementTask.localizationAgent,
            Rodzaj_POS: elementTask.localizationKindOfPos,
            Wizualizacja: elementTask.localizationVisualization,
            Kod_lokalizacji_klienta: elementTask.contractorLocalizationCode,
            Region: elementTask.localizationVoivodeship,
            Podregion: elementTask.localizationSubArea,
            Region_klienta: elementTask.contractorArea,
            Podregion_klienta: elementTask.contractorSubArea,
            Menedzer_regionalny_klienta: elementTask.contractorManager,
            Przedstawiciel_handlowy: elementTask.salesRepresentative,
            Menedzer: elementTask.manager !== undefined ? this.getFullName(elementTask.manager) : '',
            Koordynator_ds_merchandisingu: elementTask.merchandisingCoordinator !== undefined ? this.getFullName(elementTask.merchandisingCoordinator) : ''
          });
        };
      }
    };
  }

  setPhotosParametersAsync(orderTasks: OrderTask[], photosReport: any, url: string) {
    return new Promise((resolve, reject) => {
      this.setPhotosParameters(orderTasks, photosReport, url);
      setTimeout(() => {
        resolve('setPhotosParametersAsync');
      }, 100);
    });
  }

  getPhotosFromDbAsync(orderTasks: OrderTask[]) {
    return new Promise((resolve, reject) => {
      this.getPhotos(orderTasks);
      setTimeout(() => {
        resolve('getPhotosFromDbAsync');
      }, 100);
    });
  }

  getProductsFromDbAsync(orderTasks: OrderTask[]) {
    return new Promise((resolve, reject) => {
      this.getProducts(orderTasks);
      setTimeout(() => {
        resolve('getProductsFromDbAsync');
      }, 100);
    });
  }

  SaveDataToExcelAsync(orderTasks: OrderTask[], fileName: string) {
    return new Promise(resolve => {
      this.saveDataToExcel(orderTasks, fileName);
      setTimeout(() => {
        resolve('readDataFromExcelAsync');
      }, 100);
    });
  }

  async prepareSurveyDataAsync(orderTasks: OrderTask[], reports: any[], surveySections: string[]) {
    return new Promise((resolve, reject) => {
      this.prepareSurveyData(orderTasks, reports, surveySections);
      setTimeout(() => {
        resolve('prepareSurveyDataAsync');
      }, 100);
    });
  }

  async doWork(orderTasks: OrderTask[], fileName: string, photosReport: any, url: string) {
    await this.getPhotosFromDbAsync(orderTasks).then(result => { });
    await this.setPhotosParametersAsync(orderTasks, photosReport, url);
    await this.getProductsFromDbAsync(orderTasks).then(result => { });
    await this.SaveDataToExcelAsync(orderTasks, fileName).then(result => { });
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  exportOrdersV2(projectKindId: number,ids: number[]) {
    
    var body;
    if(projectKindId == 1 || projectKindId == 7){     //Merchandising, Outsourcing
      body= {
        ids: ids,
        type: "ORDER" 
      };
    }else if(projectKindId == 11 || projectKindId == 12){ //Badania || Logistyka
      body= {
        ids: ids,
        type: "ORDER_RL"
      };
    }else if(projectKindId == 8){ //Promocje
      body= {
        ids: ids,
        type: "ORDER_P"
      };
    }
        
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.post<any>(environment.apiUrl + '/excelgenerator/order', body)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.uiService.openSnack('Sukces', '', 10_000);
          this.store.dispatch(new ExcelExportActions.SetMessage(response.message));
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }


  exportAllFilteredOrdersV2(projectKindId: number, projectId: number, marketId: number, filters: any) {
    var exportType = '';
    if(projectKindId == 1 || projectKindId == 7) {exportType = 'ORDER';} //Merchandising || Outsourcing
    else if(projectKindId == 11 || projectKindId == 12) { exportType = 'ORDER_RL'; } //Badania || Logistyka
    else if(projectKindId == 8) { exportType = 'ORDER_P'; } //Promocje

    var params = new HttpParams({
      fromObject: 
      {                
        'Type' : exportType,
        'projectKindId' : projectKindId.toString(),
        'projectId' : projectId.toString(),
        'marketId' : marketId.toString(),
        'filter': JSON.stringify(filters)
      }});
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.post<any>(environment.apiUrl + '/excelgenerator/allOrders', params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.uiService.openSnack('Sukces', '', 10_000);
          this.store.dispatch(new ExcelExportActions.SetMessage(response.message));
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }

  exportOrderTasksV2(projectKindId: number,ids: number[]) {    
    
    var body;
    if(projectKindId == 1 || projectKindId == 7){  //Merchandising, Outsourcing
      body= {
        ids: ids,
        type: "ORDER_TASK"
      };
    }else if(projectKindId == 11 || projectKindId == 12){  //Badania || Logistyka
      body= {
        ids: ids,
        type: "ORDER_TASK_RL"
      };
    }else if(projectKindId == 8){   //Promocje
      body= {
        ids: ids,
        type: "ORDER_TASK_P"
      };
    }
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.post<any>(environment.apiUrl + '/excelgenerator/order', body)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          //this.uiService.openSnack('', 'Sukces', 10_000);
          this.store.dispatch(new ExcelExportActions.SetMessage(response.message));
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }

  exportOrderTasksDefinitionsV2(projectKindId: number,ids: number[]) {

    var body;
    if(projectKindId == 1 || projectKindId == 7){   //Merchandising, Outsourcing
      body = {
        ids: ids,
        type: "ORDER_TASK_WITH_RESULT" 
      };
    }if(projectKindId == 11 || projectKindId == 12){  //Badania || Logistyka
      body = {
        ids: ids,
        type: "ORDER_TASK_RL_WITH_RESULT"
      };
    }if(projectKindId == 8){    //Promocje
      body = {
        ids: ids,
        type: "ORDER_TASK_PROMO_WITH_RESULT"
      };
    }
   
    this.store.dispatch(new OrderActions.StartLoading());
    this.http.post<any>(environment.apiUrl + '/excelgenerator/order', body)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          //this.uiService.openSnack('', 'Sukces', 10_000);
          this.store.dispatch(new ExcelExportActions.SetMessage(response.message));
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }

  exportAllFilteredOrderTasksV2(startDate: Date, endDate: Date, projectKindId, projectId: number, marketId: number, filters: any, withResults: boolean = false) {
    if (!startDate || !endDate || projectKindId === -1) {
      return;
    }
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime)) {
      return;
    }

    this.store.dispatch(new OrderActions.StartLoading());

    const apiLink = environment.apiUrl + '/ordertask/v2/serverside/to-excel/' + startTime + '/' + endTime + '/projectKind/' + projectKindId + '?requireTotalCount=true&projectId=' + projectId + '&marketId=' + marketId + '&preSelect=["id"]&select=["id"]&withResults=' + withResults + '&filter=' + JSON.stringify(filters);
    this.http.get<any>(apiLink)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new ExcelExportActions.SetMessage(response.message));
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }

  exportUsersV2(ids: string[]) {  
    
      const body = {
        ids: ids,
        type: "User"
      };
    
    this.store.dispatch(new OrderActions.StartLoading());        
    this.http.post<any>(environment.apiUrl + '/excelgenerator/order', body)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          //this.uiService.openSnack('', 'Sukces', 10_000);
          this.store.dispatch(new ExcelExportActions.SetMessage(response.message));
          this.store.dispatch(new OrderActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderActions.StopLoading());
        }
      );
  }  

  exportOrders(orders: Order2Excel[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(orders);

    const wb: XLSX.WorkBook = {
      Sheets: { Zlecenia: ws },
      SheetNames: ['Zlecenia']
    };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  exportReportDefinitions(repConfigs: RepConfig2Excel[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(repConfigs);

    const wb: XLSX.WorkBook = {
      Sheets: { Definicje_raportow: ws },
      SheetNames: ['Definicje_raportow']
    };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  prepareReportConfigsDefiniton(reportConfigs: ReportConfigImport[]) {
    const repConfigs: RepConfig2Excel[] = [];

    for (let rep of reportConfigs) {
      repConfigs.push({
        id: rep.orderId,
        id_def: rep.defId,
        projekt_nazwa: rep.project,
        siec: rep.market,
        miasto: rep.city,
        ulica: rep.street,
        lokalizacja: rep.localization,
        rodzaj: rep.reportKind,
        poczatek: rep.startDate,
        koniec: rep.finishDate,
        data_realiz: rep.realizationDate,
        cyklicznosc: rep.reportFrequency,
        dzien_tyg: rep.dayOfWeek,
        dzien_mies: rep.dayOfMonth,
        rola: rep.userRole,
        szablon: rep.surveyTemplate,
        ilosc_dni_przed: rep.numberDaysBefore,
        nastepny: rep.nextReportDate,
        do_usuniecia: rep.toRemove
      });
    }

    const currenttime = this.datePipe.transform(Date.now(), 'yyyy_MM_dd HH_mm_ss');
    const fileName = 'Definicje raportow ' + currenttime;
    this.exportReportDefinitions(repConfigs, fileName);
  }

  public ReadOrdersFromExcel(data: any) {
    let workBook = null;
    let jsonData = null;
    workBook = XLSX.read(data, { type: 'binary' });
    jsonData = workBook.SheetNames.reduce((initial, name) => {
      const sheet = workBook.Sheets[name];
      initial[name] = XLSX.utils.sheet_to_json(sheet);
      return initial;
    }, {});

    const orders = jsonData.Zlecenia;

    this.store.dispatch(new OrderActions.StartLoading());
    this.http.put(environment.apiUrl + '/project/product/import',
      {
        orders
      }).toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderActions.StopLoading());
        console.error(err);
      });

  }

  exportContacts(orders: ContactImport[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(orders);

    const wb: XLSX.WorkBook = {
      Sheets: { Kontakty: ws },
      SheetNames: ['Kontakty']
    };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  getReportConfigs(orderIds: number[]) {
    this.http.post<ReportConfigImport[]>(environment.apiUrl + '/order/reportconfigforexcel/', orderIds)
      .toPromise()
      .then(
        (rep) => {
          this.prepareReportConfigsDefiniton(rep);
        })
      .catch(err => {
        console.error(err);
      });
  }
}
