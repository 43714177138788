import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { AccessConfig } from 'src/app/redux/access-config/access-config.model';
import * as AccessConfigActions from 'src/app/redux/access-config/access-config.actions';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import * as UI from '../../shared/ui.actions';

@Injectable()
export class AccessConfigService {
    private _onDestroy = new Subject<void>();

    constructor(
        private store: Store<root.IRootState>,
        private http: HttpClient,
        private uiService: UIService,
    ) { }

    initService() {
      this.cancelSubscriptions();
      this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();
  }

  startEdit() {
    this.store.dispatch(new AccessConfigActions.StartEditing());
  }

  stopEdit() {
    this.store.dispatch(new AccessConfigActions.StopEditing());
  }

  setProjectKindsList(accessConfigs: AccessConfig[]) {
    this.store.dispatch(new AccessConfigActions.SetAvailableAccessConfigs(accessConfigs));
  }

  getAll() {
    this.store.dispatch(new UI.StartLoading());
    this.http.get<AccessConfig[]>(environment.apiUrl + '/accessConfiguration')
        .subscribe(
            (accessConfigs) => {
                this.store.dispatch(new AccessConfigActions.SetAvailableAccessConfigs(accessConfigs));
                this.store.dispatch(new UI.StopLoading());
            }, (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
                this.store.dispatch(new AccessConfigActions.SetAvailableAccessConfigs(null));
                this.store.dispatch(new UI.StopLoading());
            }
        );
  }

  remove(accessConfigId: number) {
    console.log('[Project kind service] remove');
    this.store.dispatch(new AccessConfigActions.StartLoading());
    this.http.delete<AccessConfig>(environment.apiUrl + '/accessConfiguration/' + accessConfigId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new AccessConfigActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new AccessConfigActions.StopLoading());
                console.log(error);
            }
        );
  }

create(accessConfig: AccessConfig) {
    console.log('[Project kind service] create');
    this.store.dispatch(new AccessConfigActions.StartLoading());
    this.http.put<AccessConfig>(environment.apiUrl + '/accessConfiguration', accessConfig)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.store.dispatch(new AccessConfigActions.StopLoading());
            }, (error) => {
                this.store.dispatch(new AccessConfigActions.StopLoading());
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
            }
        );
  }
}

export const AccessDescription = {
  contractorMainData: '[Kontrahent].[Dane firmowe]',
  contractorAddressData: '[Kontrahent].[Dane adresowe]'
};

