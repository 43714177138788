import { User } from 'src/app/redux/user/user.model';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SelectUser, UnselectUser, ClearUserState } from '../actions/user.action';

export class UserStateModel {
    selectedUser: User;
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        selectedUser: null
    }
})
export class UserState {
    @Selector()
    static getSelectedUser(state: UserStateModel) {
        return state.selectedUser;
    }
    @Action(SelectUser)
    selectUser({ patchState }: StateContext<UserStateModel>, { payload }: SelectUser) {
        patchState({
            selectedUser: { ...payload }
        });
    }
    @Action(UnselectUser)
    unselectUser({ patchState }: StateContext<UserStateModel>) {
        patchState({
            selectedUser: null
        });
    }
    @Action(ClearUserState)
    clearUserState({ patchState }: StateContext<UserStateModel>) {
        patchState({
            selectedUser: null
        });
    }
}
