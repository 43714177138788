export const DevExtreme = {
    pl: {
        'Yes': 'Tak',
        'No': 'Nie',
        'Cancel': 'Anuluj',
        'Clear': 'Wyczyść',
        'Done': 'Zrobione',
        'Loading': 'Ładowanie...',
        'Select': 'Wybierz...',
        'Search': 'Wyszukaj',
        'Back': 'Powrót',
        'OK': 'OK',
        // 'dxList-pullingDownText': 'Pull down to refresh...',
        // 'dxList-pulledDownText': 'Release to refresh...',

        'validation-required': 'Wymagany',
        // 'validation-required-formatted': '{0} is required',
        'validation-required-formatted': 'Wartość jest wymagana',

        'dxForm-optionalMark': 'Opcjonalny',
        // 'dxForm-requiredMessage': '{0} is required',
        'dxForm-requiredMessage': 'Wartość jest wymagana',

        'dxList-refreshingText': 'Odświeżanie...',
        'dxList-pageLoadingText': 'Ładowanie...',
        'dxList-nextButtonText': 'Więcej',
        'dxList-selectAll': 'Zaznacz wszystko',
        'dxListEditDecorator-delete': 'Usunąć',
        'dxListEditDecorator-more': 'Więcej',
        'dxDataGrid-columnChooserTitle': 'Wybór kolumn',
        'dxDataGrid-columnChooserEmptyText': 'Przeciągnij tutaj kolumny, aby je ukryć',
        'dxDataGrid-groupContinuesMessage': 'Ciąg dalszy na następnej stronie',
        'dxDataGrid-groupContinuedMessage': 'Kontynuacja z poprzedniej strony',
        'dxDataGrid-groupHeaderText': 'Grupuj po tej kolumnie',
        'dxDataGrid-ungroupHeaderText': 'Usuń grupę',
        'dxDataGrid-ungroupAllText': 'Usuń wszystkie grupy',
        'dxDataGrid-editingEditRow': 'Zmień',
        'dxDataGrid-editingSaveRowChanges': 'Zapisz',
        'dxDataGrid-editingCancelRowChanges': 'Anuluj',
        'dxDataGrid-editingDeleteRow': 'Usuń',
        'dxDataGrid-editingUndeleteRow': 'Przywróć',
        'dxDataGrid-editingConfirmDeleteMessage': 'Czy na pewno chcesz usunąć ten rekord?',
        'dxDataGrid-validationCancelChanges': 'Anuluj zmiany',
        'dxDataGrid-groupPanelEmptyText': 'Przeciągnij kolumnę aby pogrupować',
        'dxDataGrid-noDataText': 'Brak danych',
        'dxDataGrid-searchPanelPlaceholder': 'Szukaj...',
        'dxDataGrid-filterRowShowAllText': '(Wszystko)',
        'dxDataGrid-filterRowResetOperationText': 'Powrót',
        'dxDataGrid-filterRowOperationEquals': 'jest równe',
        'dxDataGrid-filterRowOperationNotEquals': 'nierówne',
        'dxDataGrid-filterRowOperationLess': 'mniejszy niż',
        'dxDataGrid-filterRowOperationLessOrEquals': 'mniejszy lub równy',
        'dxDataGrid-filterRowOperationGreater': 'większy niż',
        'dxDataGrid-filterRowOperationGreaterOrEquals': 'większy lub równy',
        'dxDataGrid-filterRowOperationStartsWith': 'zaczyna się od',
        'dxDataGrid-filterRowOperationContains': 'zawiera',
        'dxDataGrid-filterRowOperationNotContains': 'nie zawiera',
        'dxDataGrid-filterRowOperationEndsWith': 'kończy się na',
        'dxDataGrid-filterRowOperationBetween': 'między',
        'dxDataGrid-filterRowOperationBetweenStartText': 'początek',
        'dxDataGrid-filterRowOperationBetweenEndText': 'koniec',
        'dxDataGrid-applyFilterText': 'Zastosuj filtr',
        'dxDataGrid-trueText': 'prawda',
        'dxDataGrid-falseText': 'fałsz',
        'dxDataGrid-sortingAscendingText': 'Sortuj rosnąco',
        'dxDataGrid-sortingDescendingText': 'Sortuj malejąco',
        'dxDataGrid-sortingClearText': 'Wyczyść sortowanie',
        'dxDataGrid-editingSaveAllChanges': 'Zapisz zmiany',
        'dxDataGrid-editingCancelAllChanges': 'Anuluj zmiany',
        'dxDataGrid-editingAddRow': 'Dodaj wiersz',
        'dxDataGrid-summaryMin': 'Min: {0}',
        'dxDataGrid-summaryMinOtherColumn': 'Minimum z {1} wynosi {0}',
        'dxDataGrid-summaryMax': 'Max: {0}',
        'dxDataGrid-summaryMaxOtherColumn': 'Maksimum z {1} wynosi {0}',
        'dxDataGrid-summaryAvg': 'Ø: {0}',
        'dxDataGrid-summaryAvgOtherColumn': 'Średnia z {1} wynosi {0}',
        'dxDataGrid-summarySum': 'Suma: {0}',
        'dxDataGrid-summarySumOtherColumn': 'Suma z {1} wynosi {0}',
        'dxDataGrid-summaryCount': 'Ilość: {0}',
        'dxDataGrid-columnFixingFix': 'Przymocuj',
        'dxDataGrid-columnFixingUnfix': 'Zwolnij',
        'dxDataGrid-columnFixingLeftPosition': 'Do lewej',
        'dxDataGrid-columnFixingRightPosition': 'Do prawej',
        'dxDataGrid-exportTo': 'Eksportuj',
        'dxDataGrid-exportToExcel': 'Eksportuj do pliku Excela',
        'dxDataGrid-excelFormat': 'PLik Excela',
        'dxDataGrid-selectedRows': 'Zaznaczone wiersze',
        'dxDataGrid-exportAll': 'Eksportuj wszystko',
        'dxDataGrid-exportSelectedRows': 'Eksportuj zaznaczone',
        'dxDataGrid-headerFilterEmptyValue': '(Puste)',
        'dxDataGrid-headerFilterOK': 'OK',
        'dxDataGrid-headerFilterCancel': 'Anuluj',
        'dxDataGrid-ariaColumn': 'Kolumna',
        'dxDataGrid-ariaValue': 'Wartość',
        'dxDataGrid-ariaFilterCell': 'Filtruj komórkę',
        'dxDataGrid-ariaCollapse': 'Zwiń',
        'dxDataGrid-ariaExpand': 'Rozwiń',
        'dxDataGrid-ariaDataGrid': 'Tabela danych',
        'dxDataGrid-ariaSearchInGrid': 'Szukaj w tabeli',
        'dxDataGrid-ariaSelectAll': 'Zaznacz wszysko',
        'dxDataGrid-ariaSelectRow': 'Zaznacz wiersz',
        'dxPager-infoText': 'Strona {0} z {1} ({2} elementów)',
        'dxPager-pagesCountText': 'od',
        'dxPivotGrid-grandTotal': 'Razem',
        'dxPivotGrid-total': '{0} razem',
        'dxPivotGrid-fieldChooserTitle': 'Wybór pola',
        'dxPivotGrid-showFieldChooser': 'Pokaż wybór pól',
        'dxPivotGrid-expandAll': 'Rozwiń wszystko',
        'dxPivotGrid-collapseAll': 'Zwiń wszystko',
        'dxPivotGrid-sortColumnBySummary': '"{0}" sortuj wg tej kolumny',
        'dxPivotGrid-sortRowBySummary': '"{0}" sortuj wg tego wiersza',
        'dxPivotGrid-removeAllSorting': 'usuń sortowanie',
        'dxPivotGrid-rowFields': 'Pola wierszy',
        'dxPivotGrid-columnFields': 'Pola kolumn',
        'dxPivotGrid-dataFields': 'Pola danych',
        'dxPivotGrid-filterFields': 'Filtruj pola',
        'dxPivotGrid-allFields': 'Wszystkie pola',
        'dxPivotGrid-columnFieldArea': 'Przeciągnij tutaj pola kolumn',
        'dxPivotGrid-dataFieldArea': 'Przeciągnij tutaj pola danych',
        'dxPivotGrid-rowFieldArea': 'Przeciągnij tutaj pola wierszy',
        'dxPivotGrid-filterFieldArea': 'Przeciągnij tutaj pola filtrowania',
        'dxScheduler-editorLabelTitle': 'Wzgląd',
        'dxScheduler-editorLabelStartDate': 'Od daty',
        'dxScheduler-editorLabelEndDate': 'Do daty',
        'dxScheduler-editorLabelDescription': 'Opis',
        'dxScheduler-editorLabelRecurrence': 'Powtarzanie',
        'dxScheduler-openAppointment': 'Otwórz termin',
        'dxScheduler-recurrenceNever': 'Nigdy',
        'dxScheduler-recurrenceDaily': 'Co dzień',
        'dxScheduler-recurrenceWeekly': 'Co tydzień',
        'dxScheduler-recurrenceMonthly': 'Co miesiąc',
        'dxScheduler-recurrenceYearly': 'Co rok',
        'dxScheduler-recurrenceEvery': 'Co',
        'dxScheduler-recurrenceEnd': 'Koniec powtarzania',
        'dxScheduler-recurrenceAfter': 'Nach',
        'dxScheduler-recurrenceOn': 'Am',
        'dxScheduler-recurrenceRepeatDaily': 'Codziennie(e)',
        'dxScheduler-recurrenceRepeatWeekly': 'Co tydzień(-dnie)',
        'dxScheduler-recurrenceRepeatMonthly': 'Co miesiąc(-e)',
        'dxScheduler-recurrenceRepeatYearly': 'Co rok(lat)',
        'dxScheduler-switcherDay': 'Dzień',
        'dxScheduler-switcherWeek': 'Tydzień',
        'dxScheduler-switcherWorkWeek': 'Tydzień roboczy',
        'dxScheduler-switcherMonth': 'Miesiąc',
        'dxScheduler-switcherTimelineDay': 'Dzienna oś czasu',
        'dxScheduler-switcherTimelineWeek': 'Tygodniowa oś czasu',
        'dxScheduler-switcherTimelineWorkWeek': 'Oś czasu tygodnia roboczego',
        'dxScheduler-switcherTimelineMonth': 'Miesięczna oś czasu',
        'dxScheduler-switcherAgenda': 'Agenda',
        'dxScheduler-recurrenceRepeatOnDate': 'dnia',
        'dxScheduler-recurrenceRepeatCount': 'Zdarzenia',
        'dxScheduler-allDay': 'Całodzienne',
        'dxScheduler-confirmRecurrenceEditMessage': 'Czy chcesz zmienić tylko to wydarzenie czy całą serię?',
        'dxScheduler-confirmRecurrenceDeleteMessage': 'Czy chcesz usunąć tylko to wydarzenie lub całą serię?',
        'dxScheduler-confirmRecurrenceEditSeries': 'Edycja serii',
        'dxScheduler-confirmRecurrenceDeleteSeries': 'Usunięcie serii',
        'dxScheduler-confirmRecurrenceEditOccurrence': 'Zmiana terminu',
        'dxScheduler-confirmRecurrenceDeleteOccurrence': 'Usunięcie terminu',
        'dxScheduler-noTimezoneTitle': 'Brak strefy czasowej',
        'dxCalendar-todayButtonText': 'Dziś',
        'dxCalendar-ariaWidgetName': 'Kalendarz',
        'dxColorView-ariaRed': 'Czerwony',
        'dxColorView-ariaGreen': 'Zielony',
        'dxColorView-ariaBlue': 'Niebieski',
        'dxColorView-ariaAlpha': 'Przezro',
        'dxColorView-ariaHex': 'Hex',
        'vizExport-printingButtonText': 'Drukuj',
        'vizExport-titleMenuText': 'Eksport/Wydruk',
        'vizExport-exportButtonText': '{0}-plików'
    }
};
