import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-start-end-date-picker',
  templateUrl: './start-end-date-picker.component.html',
  styleUrls: ['./start-end-date-picker.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StartEndDatePickerComponent implements OnInit {
  @Input() fg: FormGroup;
  @Input() isLoading: boolean;
  @Input() maxDaysRange = 31;
  @Input() setEndDateAutomatically: boolean = false;
  @Input() initDates: boolean = true;

  @Output() startDateEvent = new EventEmitter<Date>();
  @Output() endDateEvent = new EventEmitter<Date>();
  @Output() isDateCorrectEvent = new EventEmitter<boolean>();

  startDate: Date = null;
  endDate: Date = null;

  constructor() { }

  ngOnInit() {
    if (!this.initDates) {
      return;
    }

    const currentDate = new Date();
    let startDate, endDate;

    if (this.maxDaysRange === 7) {
      // Calculate the closest past Monday to the current date
      const daysFromLastMonday = (currentDate.getDay() === 0) ? 6 : (currentDate.getDay() - 1);
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - daysFromLastMonday);

      // Calculate the closest upcoming Sunday after the above calculated Monday
      endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
    } else {
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      if (this.maxDaysRange === 31) {
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      } else {
        endDate = new Date(currentDate.getFullYear(), startDate.getMonth(), this.maxDaysRange);
      }
    }

    this.fg.controls.formStartDate.setValue(startDate);
    this.fg.controls.formEndDate.setValue(endDate);
    this.startDate = startDate;
    this.endDate = endDate;
    this.checkDates();
  }

  calculateDateDifference(startTime: number, endTime: number): number {
    let days = Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24);
    return days;
  }

  getErrorMessage(controlName: string) {
    var form = this.fg.controls.formStartDate;
    switch (controlName) {
      case 'formStartDate':
        form = this.fg.controls.formStartDate;
        break;
      case 'formEndDate':
        form = this.fg.controls.formEndDate;
        break;
    }

    if (form.hasError('required')) {
      return 'Pole wymagane';
    }
    else if (form.hasError('badStart')) {
      return 'Zła data startowa';
    }
    else if (form.hasError('badEnd')) {
      return this.maxDaysRange === 31 ? 'Maks 1 miesiąc' : 'Maks ' + this.maxDaysRange + ' dni';
    }
  }

  checkDates() {
    if (this.startDate !== null && this.endDate !== null) {
      const startTime = this.startDate.getTime();
      const endTime = this.endDate.getTime();

      if (startTime <= endTime) {
        const difference = this.calculateDateDifference(startTime, endTime);
        if (difference <= this.maxDaysRange) {
          this.fg.controls.formStartDate.setErrors(null);
          this.fg.controls.formEndDate.setErrors(null);
          this.startDateEvent.emit(this.startDate);
          this.endDateEvent.emit(this.endDate);
          this.isDateCorrectEvent.emit(true);
          return;
        }
        else {
          this.fg.controls.formEndDate.setErrors({ 'badEnd': true });
        }
      }
      else {
        this.fg.controls.formStartDate.setErrors({ 'badStart': true });
      }
    }
    this.isDateCorrectEvent.emit(false);
  }

  setStartDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.startDate = new Date(event.value);
      if (this.setEndDateAutomatically) {
        if (this.maxDaysRange === 31) {
          this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        }
        else {
          this.endDate = new Date(event.value);
          this.endDate.setDate(this.endDate.getDate() + (this.maxDaysRange - 1));
        }
        this.fg.controls.formEndDate.setValue(this.endDate);
      }
      this.checkDates();
    } else {
      this.startDate = null;
    }
  }

  setEndDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.endDate = new Date(event.value);
      this.checkDates();
    } else {
      this.endDate = null;
    }
  }
}
