import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private store: Store<fromRoot.IRootState>,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromRoot.getIsAuth).pipe(take(1));
    }

    canLoad(route: Route, segments: UrlSegment[]) {
        if (localStorage.getItem('currentUser')) {
            return true;
        }
        const url = '/' + segments.map(s => s.path).join('/');

        let matrixParams = {};
        let paramsString = '';

        segments.forEach(segment => {
            if (segment.parameters) {
                matrixParams = { ...matrixParams, ...segment.parameters };
                paramsString += segment.parameters.name;
            }
        });

        const matrixParamsAsString = this.objToQueryString(matrixParams);
        this.router.navigate(['/auth/login', { url: url, params: matrixParamsAsString }]);
        return false;
    }

    objToQueryString(obj) {
        return Object.keys(obj).map(key => `${key}=${obj[key]}`).join(';');
    }
}
