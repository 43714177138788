import { HierarchyState } from '../store/states/hierarchy.state';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SetHierarchizedIds } from '../store/actions/hierarchy.actions';

@Injectable({
    providedIn: 'root'
})
export class HierarchyService {
    @Select(HierarchyState.hierarchizedIds) hierarchizedIds$: Observable<string[]>;
    @Select(HierarchyState.hierarchizedDeputyIds) hierarchizedDeputyIds$: Observable<string[]>;
    @Select(HierarchyState.userIdsWhereIamIsDeputy) userIdsWhereIamIsDeputy$: Observable<string[]>;

    constructor(private store: Store, private http: HttpClient) { }

    assingManager(userId, groupId, managerId, manager2Id, manager3Id) {
      if (managerId === '' || managerId === undefined) {
        managerId = '0';
      }

      if (manager2Id === '' || manager2Id === undefined) {
        manager2Id = '0';
      }

      if (manager3Id === '' || manager3Id === undefined) {
        manager3Id = '0';
      }

      return this.http.get<any>(environment.apiUrl + `/hierarchy/assignManager/${userId}/${groupId}/${managerId}/${manager2Id}/${manager3Id}`)
            .toPromise()
            .then(result => {
                return true;
            })
            .catch(err => {
                console.error(err);
                return false;
            });
    }

    getHierarchizedIds() {
        this.http.get<any>(environment.apiUrl + `/hierarchy`)
            .toPromise()
            .then(result => {
                this.store.dispatch(new SetHierarchizedIds(result));
            })
            .catch(err => {
                this.store.dispatch(new SetHierarchizedIds({
                    hierarchizedDeputyIds: [],
                    hierarchizedIds: [],
                    userIdsWhereIamDeputy: []
                }));
                console.error(err);
            });
    }

    getHierarchyByUserId(userId: string) {
        return this.http.get<string[]>(environment.apiUrl + `/hierarchy/` + userId)
            .toPromise()
            .then(result => {
                return result;
            })
            .catch(err => {
                console.error(err);
                return null;
            });
    }

    destroy() {
        this.store.dispatch(new SetHierarchizedIds({
            hierarchizedDeputyIds: [],
            hierarchizedIds: [],
            userIdsWhereIamDeputy: []
        }));
    }
}
