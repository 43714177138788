import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as CentralPromotionActions from 'src/app/redux/central-promotion/central-promotion.actions';
import { Select } from '@ngxs/store';
import { Contractor } from 'src/app/redux/contractor/contractor.model';
import { environment } from 'src/environments/environment';
import { ContractorProjectKind } from 'src/app/redux/contractor/contractor-project-kind.model';
import { CentralPromotion } from 'src/app/redux/central-promotion/central-promotion.model';
import { centralPromotions } from 'src/app/redux/central-promotion/central-promotion.reducer';

@Injectable()
export class CentralPromotionService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();

      this.unSelectCentralPromotion();
  }

  stopEditing() {
    this.store.dispatch(new CentralPromotionActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new CentralPromotionActions.StartEditing());
  }

  selectCentralPromotion(centralPromotion: CentralPromotion) {
    this.store.dispatch(new CentralPromotionActions.SetSelectedCentralPromotion(centralPromotion));
  }

  unSelectCentralPromotion() {
    this.store.dispatch(new CentralPromotionActions.SetSelectedCentralPromotion(null));
  }

  getCentralPromotions() {
    this.store.dispatch(new CentralPromotionActions.StartLoading());
    this.http.get<CentralPromotion[]>(environment.apiUrl + '/centralPromotion')
      .toPromise()
      .then(
        (centralPromotions) => {
          this.store.dispatch(new CentralPromotionActions.SetAvailableCentralPromotions(centralPromotions));
          this.store.dispatch(new CentralPromotionActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new CentralPromotionActions.SetAvailableCentralPromotions(null));
        this.store.dispatch(new CentralPromotionActions.StopLoading());
        console.error(err);
    });
  }

  createCentralPromotion(centralPromotion: CentralPromotion) {
    this.store.dispatch(new CentralPromotionActions.StartLoading());
    this.http.post<CentralPromotion>(environment.apiUrl + '/centralPromotion', centralPromotion)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new CentralPromotionActions.StopLoading());
          this.store.dispatch(new CentralPromotionActions.StopLoading());
         })
    .catch(err => {
        //this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new CentralPromotionActions.StopLoading());
        console.error(err);
    });
  }

  updateCentralPromotion(centralPromotion: CentralPromotion) {
    this.store.dispatch(new CentralPromotionActions.StartLoading());
    this.http.put<CentralPromotion>(environment.apiUrl + '/centralPromotion', centralPromotion)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new CentralPromotionActions.StopLoading());
         })
    .catch(err => {
        //this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new CentralPromotionActions.StopLoading());
        console.error(err);
    });
  }

  deleteCentralPromotion(id: number) {
    this.store.dispatch(new CentralPromotionActions.StartLoading());
    this.http.delete(environment.apiUrl + '/centralPromotion/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new CentralPromotionActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new CentralPromotionActions.StopLoading());
        console.error(err);
    });
  }
}
