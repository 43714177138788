import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OrderTask } from "src/app/redux/order-task/order-task.model";
import { OrderTaskService } from "src/app/services/order-task/order-task.service";
import * as fromRoot from '../../app.reducer';

@Component({
  selector: "survey-page",
  templateUrl: "./survey.page.html",
})
export class SurveyPage implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  title = "Raport";
  json: string;
  orderTask: OrderTask;
  public fullScreen: boolean;

  constructor(
      private store: Store<fromRoot.IRootState>,
      private render: Renderer2,
      private dialogRef: MatDialogRef<SurveyPage>,
      private orderTaskService: OrderTaskService
    ) {}

  ngOnInit() {
    this.store.select(fromRoot.getTaskSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        res !== null ? this.orderTask = res : this.orderTask = {};
      });
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel() {

  }

  saveData(result) {
    let json = JSON.stringify(result.res);
    this.orderTaskService.saveSurveyResult(this.orderTask.id, json, result.isCompleteOnTrigger);
    this.orderTask.surveyRealizationJson = json;
  }

  public onFullscreenClick() {
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    const content = document.getElementsByClassName('mat-dialog-content');

    if (this.fullScreen) {
      this.render.removeClass(dialog[1], 'full-screen');
      this.render.removeClass(content[1], 'full-screen');
    } else {
      this.render.addClass(dialog[1], 'full-screen');
      this.render.addClass(content[1], 'full-screen');
    }

    this.fullScreen = !this.fullScreen;
  }

  close() {
    this.cancel();
    this.dialogRef.close();
  }
}
