import { ProjectKindSimple } from "../contractor/project-kind.model";
import { ProjectSimple } from "../project/project.model";
import { WorkScheduleEmployee } from "../work-schedule/work-schedule.model";
import { SET_EMPLOYEE_LIST, SET_PROJECT_KIND_LIST, SET_PROJECT_LIST, START_LOADING, STOP_LOADING, WorkLogActions } from "./work-log.actions";

export interface State {
    isLoading: boolean;    
    projectKindList: ProjectKindSimple[],
    projectList: ProjectSimple[];    
    employeeList: WorkScheduleEmployee[];
}

const initialState: State = {
    isLoading: false,
    projectKindList: null,
    projectList: null,
    employeeList: null,
};

export function workLogReducer(state = initialState, action: WorkLogActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_PROJECT_KIND_LIST: {
            return {
                ...state,
                projectKindList: action.payload
            }
        }
        case SET_PROJECT_LIST: {
            return {
                ...state,
                projectList: action.payload
            }
        }
        case SET_EMPLOYEE_LIST: {
            return {
                ...state,
                employeeList: action.payload
            }
        }             
        default: {
            return state;
        }
    }
}

export const isLoading = (state: State) => state.isLoading;
export const projectKindList = (state: State) => state.projectKindList;
export const projectList = (state: State) => state.projectList;
export const employeeList = (state: State) => state.employeeList;
