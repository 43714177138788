import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { User, UserBase, UserListItem } from 'src/app/redux/user/user.model';
import { IRole } from 'src/app/_models/role.model';
import { ApplicationData } from 'src/app/auth/user.model';
import { ServiceService } from 'src/app/services/service/service.service';
import { Order } from 'src/app/redux/order/order.model';
import { FormControl } from '@angular/forms';

export interface SelectMerchDialogModel {
  services?: number[]
}

@Component({
  selector: 'app-select-merch-dialog',
  templateUrl: './select-merch-dialog.component.html',
  styleUrls: ['./select-merch-dialog.component.scss']
})

export class SelectMerchDialogComponent implements OnInit, OnDestroy {
  model: SelectMerchDialogModel;
  private _onDestroy = new Subject<void>();

  hierarhizedIds: string[];
  selectedUser: UserListItem;
  loading = false;
  currentUserId = '';
  merchandiserControl = new FormControl('');

  constructor(private hierarchy: HierarchyService,
    private service: ServiceService,
    @Inject(MAT_DIALOG_DATA) public data: SelectMerchDialogModel,
    private store: Store<fromRoot.IRootState>,
    private dialogRef: MatDialogRef<SelectMerchDialogComponent> ) {
      this.model = data;
      const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUserId = currentUser.id
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onUserSelectionChange(user: UserListItem) {
    if (user !== -1) {
      this.merchandiserControl.setErrors(null);
      this.selectedUser = user;
    
    }
    else {
      this.merchandiserControl.setErrors({ 'badUser': true });
    }

  }

  async submit() {
    this.loading = true;
    this.service.createAdditionalServiceV2(this.model.services, this.selectedUser.id);
    this.dialogRef.close();
    this.loading = false;
  }
}
