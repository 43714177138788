import { Action } from '@ngrx/store';
import { ClientTicketListItem, ClientTicketDetails, ClientTicketFormDetails, ClientTicketMessage } from './client-ticket.model';

export const SET_CLIENT_TICKET_LIST = '[Tickets] set client tickets list';
export const SET_CLIENT_TICKET_DETAILS = '[Tickets] set client ticket details';
export const SET_CLIENT_TICKET_FORM_DETAILS = '[Tickets] set client ticket form details';

export const START_LOADING = '[Tickets] start loading';
export const STOP_LOADING = '[Tickets] stop loading';
export const SET_MESSAGE = '[Tickets] set message';

export class SetClientTicketList implements Action {
    readonly type = SET_CLIENT_TICKET_LIST;
    constructor(public payload: ClientTicketListItem[]) { }
}

export class SetClientTicketDetails implements Action {
    readonly type = SET_CLIENT_TICKET_DETAILS;
    constructor(public payload: ClientTicketDetails) { }
}

export class SetClientTicketFormDetails implements Action {
    readonly type = SET_CLIENT_TICKET_FORM_DETAILS;
    constructor(public payload: ClientTicketFormDetails) { }
}

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export class SetMessage implements Action {
    readonly type = SET_MESSAGE;
    constructor(public payload: ClientTicketMessage) { }
}

export type TicketsActions =
    SetClientTicketList
    | SetClientTicketDetails
    | SetClientTicketFormDetails
    | StartLoading
    | StopLoading
    | SetMessage;