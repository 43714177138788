import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Product } from 'src/app/redux/project/product.model';
import { Contractor } from 'src/app/redux/contractor/contractor.model';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectKind } from 'src/app/redux/contractor/project-kind.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { ContractorProjectKind } from 'src/app/redux/contractor/contractor-project-kind.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-contractor-details',
  templateUrl: './contractor-details.component.html',
  styleUrls: ['./contractor-details.component.css']
})
export class ContractorDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  allprojectKinds: ProjectKind[] = [];
  projectKindsToUse: ProjectKind[] = [];
  allContractorProjectKinds: ContractorProjectKind[] = [];
  allContractorProducts: Product[] = [];
  model: Contractor = {};
  isEditing = false;
  contractorApp: Contractor;
  contractorsToSend: Contractor[] = [];
  projectKindCtrl = new FormControl();
  filteredProjectKinds: Observable<ProjectKind[]>;

  @ViewChild('projectKindInput', { static: false }) projectKindInput: ElementRef<HTMLInputElement>;

  constructor(
    public auth: AuthService,
    private service: ContractorService,
    private store: Store<fromRoot.IRootState>,
    private dialogRef: MatDialogRef<ContractorDetailsComponent>) {
      this.projectKindCtrl.disable();
     }

  ngOnInit() {

    this.store.select(fromRoot.getContractorSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        res => {
          if (res !== null) {
            this.contractorApp = res;
            this.model = { ...this.contractorApp };
          } else {
            this.contractorApp = {};
            this.model = {};
          }
      });

    this.store.select(fromRoot.getContractorIsEditing)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(res => this.isEditing = res);

    this.store.select(fromRoot.getProjectKindsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projectKinds) => {
        this.allprojectKinds = projectKinds;
      });

    this.store.select(fromRoot.getContractorProjectKindsList)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((contractorProjectKinds) => {
      this.allContractorProjectKinds = contractorProjectKinds;
      this.projectKindsToUse = this.getNotUsedprojectKinds();
      this.filteredProjectKinds = this.projectKindCtrl.valueChanges.pipe(
        startWith(null),
        map((value: ProjectKind | string | null) => value ? this._filter(value) : this.projectKindsToUse));
    });

    this.service.getContractorProjectKinds(this.model.id);
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel(): void {
    this.service.stopEditing();
    this.service.setProjectKindsToContractor([]);
    this.model = { ...this.contractorApp };
  }

  getNotUsedprojectKinds() {

    const currentProjectKinds: ProjectKind[] = this.allprojectKinds.map(x => Object.assign({}, x));
    this.allContractorProjectKinds.forEach(element => {
      const index = currentProjectKinds.map( function myFunc(x) { return x.id; } ).indexOf(element.projectKind.id);
      currentProjectKinds.splice(index, 1);
    });

    return currentProjectKinds;
  }

  save() {
    this.service.stopEditing();
    if (!this.model.id || this.model.id === 0 ) {
      this.service.createContractor(this.model, this.allContractorProjectKinds);
    } else {
      this.service.updateContractor(this.model, this.allContractorProjectKinds);
      this.contractorApp = { ...this.model };
    }
    this.dialogRef.close();
  }

  startEditing() {
    this.service.startEditing();
  }

  setActive() {
    this.model.blocked = false;
    this.contractorsToSend.push(this.model);
    this.service.blockContractors(this.contractorsToSend);
    this.contractorsToSend = [];
  }

  private _filter(value: ProjectKind | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.projectKindsToUse.filter(p => p.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.projectKindsToUse;
  }

  getFilteredProjectKinds() {
    return this.allContractorProjectKinds.slice();
  }

  remove(contractorprojectKind: ContractorProjectKind) {
    this.service.setProjectKindsToContractor(this.allContractorProjectKinds.
      filter(c => c.projectKind.id !== contractorprojectKind.projectKindId));
  }

  add(e) {
    if (!e || !e.value) {
      return;
    }
    this.projectKindInput.nativeElement.value = '';
    this.projectKindCtrl.setValue(null);
  }

  selectedProjectKind(event: MatAutocompleteSelectedEvent) {
    const projectKind: ProjectKind = event.option.value;
    const newContractorProjectKind: ContractorProjectKind = {
      contractorId: this.model.id,
      contractor: this.model,
      projectKindId: projectKind.id,
      projectKind
    };
    if (projectKind && !this.allContractorProjectKinds.
      includes(newContractorProjectKind)) {
      //this.service.createProjectKindContractor(newContractorProjectKind);
      this.service.addProjectKindToContractor(newContractorProjectKind);
    }
    this.projectKindInput.nativeElement.value = '';
    this.projectKindCtrl.setValue(null);
  }
}
