import { NgModule } from '@angular/core';
import {
    DxDataGridModule, DxFileUploaderModule, DxTileViewModule, DxLoadPanelModule, DxDropDownButtonModule
} from 'devextreme-angular';

@NgModule({
    imports: [
        DxDataGridModule,
        DxFileUploaderModule,
        DxTileViewModule,
        DxLoadPanelModule,
        DxDropDownButtonModule
    ],
    exports: [
        DxDataGridModule,
        DxFileUploaderModule,
        DxTileViewModule,
        DxLoadPanelModule,
        DxDropDownButtonModule
    ],
})
export class DXModule { }
