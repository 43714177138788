import { ProjectKindSimple } from '../contractor/project-kind.model';
import { Market } from '../localization/localization.model';
import { ProjectForOrder } from '../project/project.model';
import {
    ParamsAndToolsActions,
    SET_PROJECT_LIST,
    START_MARKET_LOADING,
    STOP_MARKET_LOADING,
    START_PROJECT_LOADING,
    STOP_PROJECT_LOADING,
    SET_MARKET_LIST,
    SET_PROJECT_KIND_LIST,
    START_PROJECT_KIND_LOADING,
    STOP_PROJECT_KIND_LOADING
} from './params-and-tools.actions';

export interface State {
    isLoadingProjects: boolean;
    isLoadingProjectKinds: boolean;
    isLoadingMarkets: boolean;
    projectList: ProjectForOrder[];
    projectKindList: ProjectKindSimple[];
    marketList: Market[];
}

const initialState: State = {
    isLoadingProjects: false,
    isLoadingProjectKinds: false,
    isLoadingMarkets: false,
    projectList: [],
    projectKindList: [],
    marketList: [],
};

export function paramsAndToolsReducer(state = initialState, action: ParamsAndToolsActions) {
    switch (action.type) {
        case START_MARKET_LOADING: {
            return {
                ...state,
                isLoadingProjects: true
            };
        }
        case STOP_MARKET_LOADING: {
            return {
                ...state,
                isLoadingProjects: false
            };
        }
        case START_PROJECT_LOADING: {
            return {
                ...state,
                isLoadingMarkets: true
            };
        }
        case STOP_PROJECT_LOADING: {
            return {
                ...state,
                isLoadingMarkets: false
            };
        }
        case START_PROJECT_KIND_LOADING: {
            return {
                ...state,
                isLoadingProjectKinds: true
            };
        }
        case STOP_PROJECT_KIND_LOADING: {
            return {
                ...state,
                isLoadingProjectKinds: false
            };
        }
        case SET_PROJECT_LIST: {
            return {
                ...state,
                projectList: action.payload
            };
        }
        case SET_PROJECT_KIND_LIST: {
            return {
                ...state,
                projectKindList: action.payload
            };
        }
        case SET_MARKET_LIST: {
            return {
                ...state,
                marketList: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const isLoadingProjects = (state: State) => state.isLoadingProjects;
export const isLoadingProjectKinds = (state: State) => state.isLoadingProjectKinds;
export const isLoadingMarkets = (state: State) => state.isLoadingMarkets;

export const projectList = (state: State) => state.projectList;
export const projectKindList = (state: State) => state.projectKindList;
export const marketList = (state: State) => state.marketList;