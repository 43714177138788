import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { reducers } from './app.reducer';
import { StoreModule } from '@ngrx/store';
import { AuthService } from './auth/auth.service';
import { AppRoutingModule } from './app.routing.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIService } from './shared/ui.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MaterialModule } from './shared/material.module';
import { JwtInterceptor } from './jwt.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocationStrategy, HashLocationStrategy, DecimalPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { CommonService } from './shared/common.service';
import { EDictionaryPipe } from './shared/pipes/eDictionary.pipe';
// for Router import LoadingBarRouterModule:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { AvatarModule } from 'ngx-avatar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavNavListItemComponent } from './navigation/sidenav/sidenav-nav-list-item/sidenav-nav-list-item.component';
import { ToolbarComponent } from './navigation/sidenav/toolbar/toolbar.component';
import { MatIconRegistry } from '@angular/material/icon';
import { AppErrorHandler } from 'src/app.error-handler';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CompanyService } from './services/company/company.service';
import { AppHub } from './hubs/app.hub';
import { GroupService } from './services/group/group.service';
import { UserService } from './services/user/user.service';
import { RoleService } from './services/role/role.service';
import { ProjectKindService } from './services/contractor/project-kind.service';
import { ContractorService } from './services/contractor/contractor.service';
import { ProjectService } from './services/project/project.service';
import { OrderService } from './services/order/order.service';
import { ServiceService } from './services/service/service.service';
import { ServiceDashboardService } from './services/service-dashboard/service-dashboard.service';
import { AuditService } from './services/audit/audit.service';
import { OrderTaskService } from './services/order-task/order-task.service';
import { EmployeeFormService } from './services/employee-form/employee-form.service';
import { CustomFieldService } from './services/custom-fields/custom-field.service';
import { AccessConfigService } from './services/access-config/access-config.service';
import { DXModule } from './shared/dx.module';
import { AppEnvironmentService } from './services/app-environment.service';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { CompanySecurityService } from './administration/companies/company.serivce';
import { ExcelService } from './services/excel/excel.service';
import { UserReportsService } from './services/user-reports/user-reports.service';
import { ClientTicketService } from './services/client-ticket/client-ticket.service';
import { VersionFooter } from './version-footer/version-footer.component';


/** Import Alyle UI */
import {
  LY_THEME
} from '@alyle/ui';
/** Import themes */
import { MinimaLight } from '@alyle/ui/themes/minima';
import { ScreenService } from './redux/screen-state/screen.service';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { UserState } from './store/states/user.state';
import { NavigationState } from './store/states/navigation.state';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import { OnlineUsersChartComponent } from './components/online-users-chart/online-users-chart.component';
import { ContactState } from './store/states/contact.state';
import { companyReducer } from '../app/administration/companies/company.reducer';
import { LocalizationService } from './services/localization/localization.service';
import { DictionaryService } from './services/dictionary/dictionary.service';
import { OosChartService } from './services/oos-chart/oos-chart.service';
import { NgxGalleryModule } from 'ngx-gallery';
import { DxChartModule } from 'devextreme-angular';
import { WorkTimeService } from './services/work-time/work-time.service';
import { CentralPromotionService } from './services/central-promotion/central-promotion.service';
import { EmployeeRecordsService } from './services/employee-records/employee-records.service';
//import { SurveyCreatorComponent } from './survey/survey-component/survey.creator.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
export function clearState(reducer) {
  return function (state, action) {
    if (action.type === 'LOGOUT') {
      state = undefined;
    }
    return reducer(state, action);
  };
}
export function init_app(appEnvironmentService: AppEnvironmentService) {
  return () => appEnvironmentService.init();
}

// social
export let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('')
  }
]);

export function provideConfig() {
  return config;
}

registerLocaleData(localePl, 'pl', localePlExtra);

const states = [
  UserState,
  NavigationState,
  ContactState
];

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    VersionFooter,
    SidenavNavListItemComponent,
    ToolbarComponent,
    PrivacyComponent
    //SurveyCreatorComponent
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    StoreModule.forFeature('company', companyReducer),
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    DXModule,
    DxChartModule,
    LoadingBarRouterModule,
    AvatarModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    NgxChartsModule,
    SocialLoginModule,
    NgxsModule.forRoot(states, { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pl' },
    ScreenService,
    AppEnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppEnvironmentService],
      multi: true
    },
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    EDictionaryPipe,
    GroupService,
    UserService,
    RoleService,
    ProjectKindService,
    ContractorService,
    ProjectService,
    OrderService,
    ServiceService,
    ServiceDashboardService,
    AuditService,
    OrderTaskService,
    WorkTimeService,
    EmployeeFormService,
    LocalizationService,
    DictionaryService,
    CustomFieldService,
    AccessConfigService,
    CompanyService,
    AuthService,
    UIService,
    AppHub,
    CompanySecurityService,
    ExcelService,
    OosChartService,
    CommonService,
    CentralPromotionService,
    EmployeeRecordsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: AuthServiceConfig, useFactory: provideConfig },
    {
      provide: LY_THEME,
      useClass: MinimaLight
    },
    UserReportsService,
    ClientTicketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    matIconRegistry.addSvgIcon('egoogle', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/egoogle.svg'));
    matIconRegistry.addSvgIcon('efacebook', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/efacebook.svg'));
    matIconRegistry.addSvgIcon('desktop_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/dashboard.svg'));
    matIconRegistry.addSvgIcon('contractors_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/contractors.svg'));
    matIconRegistry.addSvgIcon('contact_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/contact.svg'));
    matIconRegistry.addSvgIcon('structure_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/structure.svg'));
    matIconRegistry.addSvgIcon('settings_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/settings.svg'));
    matIconRegistry.addSvgIcon('company_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/company.svg'));
    matIconRegistry.addSvgIcon('projects_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/projects.svg'));
    matIconRegistry.addSvgIcon('localizations_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/localizations.svg'));
    matIconRegistry.addSvgIcon('employeeForms_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/employeeForms.svg'));
    matIconRegistry.addSvgIcon('orders_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/orders.svg'));
    matIconRegistry.addSvgIcon('tasks_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/tasks.svg'));
    matIconRegistry.addSvgIcon('reports_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/reports.svg'));
    matIconRegistry.addSvgIcon('services_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/services.svg'));
    matIconRegistry.addSvgIcon('work_time_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/work_time.svg'));
    matIconRegistry.addSvgIcon('current_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/current.svg'));
    matIconRegistry.addSvgIcon('archival_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/archival.svg'));
    matIconRegistry.addSvgIcon('mytasks_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/mytasks.svg'));
    matIconRegistry.addSvgIcon('survey_designer_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/survey_designer.svg'));
    matIconRegistry.addSvgIcon('filter_remove_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/filter_remove.svg'));
    matIconRegistry.addSvgIcon('central_promotion_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/discount.svg'));
    matIconRegistry.addSvgIcon('notifications_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/notifications.svg'));
    matIconRegistry.addSvgIcon('calendar_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/calendar.svg'));
    matIconRegistry.addSvgIcon('client_ticket_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/client_ticket.svg'));
    matIconRegistry.addSvgIcon('service_dashboard_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/service_dashboard.svg'));
    matIconRegistry.addSvgIcon('audits_icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/audits.svg'));
  }
}
