import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as ServiceActions from 'src/app/redux/service/service.actions';
import { environment } from 'src/environments/environment';
import { Service, ServiceDetails, ServiceDxTable, ServiceParameters } from 'src/app/redux/service/service.model';
import { LoadOptions } from 'devextreme/data/load_options';

@Injectable()
export class ServiceService {
  private _onDestroy = new Subject<void>();

  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.unSelectService();
  }

  stopEditing() {
    this.store.dispatch(new ServiceActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new ServiceActions.StartEditing());
  }

  selectService(service: ServiceDetails) {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.store.dispatch(new ServiceActions.SetSelectedService(service));
    this.store.dispatch(new ServiceActions.StopLoading());
  }

  unSelectService() {
    this.store.dispatch(new ServiceActions.SetSelectedService(null));
  }

  getTotalSavings(loadOptions: any, startDate: Date, endDate: Date, serviceForCurrentUser: boolean, projectKindId: number, projectId: number, marketId: number) {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime)) {
      return;
    }

    const params = this.getParams(loadOptions, serviceForCurrentUser, projectId, marketId, null, true);

    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.get<number>(environment.apiUrl + '/service/totalEmployeeSaving/' + startTime + '/' + endTime + '/projectKind/' + projectKindId, { params }).toPromise()
      .then(
        (result) => {
          this.store.dispatch(new ServiceActions.SetTotalSavings(result));
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.SetTotalSavings(0));
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }

  getService(id: number) {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.get<Service>(environment.apiUrl + '/service/' + id)
      .toPromise()
      .then(
        (service) => {
          this.store.dispatch(new ServiceActions.SetSelectedService(service));
          this.store.dispatch(new ServiceActions.StopLoading());
          if (service == null) {
            this.store.dispatch(new ServiceActions.SetError(true));
          }
          else {
            this.store.dispatch(new ServiceActions.SetError(false));
          }
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.SetSelectedService(null));
        this.store.dispatch(new ServiceActions.StopLoading());
        this.store.dispatch(new ServiceActions.SetError(true));
        console.error(err);
      });
  }

  getServiceProjectKind(id: number) {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.get<number>(environment.apiUrl + '/service/projectKind/' + id)
      .toPromise()
      .then(
        (projectKind) => {
          this.store.dispatch(new ServiceActions.SetSelectedServiceProjectKind(projectKind));
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.SetSelectedServiceProjectKind(null));
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }

  getServices() {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.get<Service[]>(environment.apiUrl + '/service')
      .toPromise()
      .then(
        (services) => {
          this.store.dispatch(new ServiceActions.SetAvailableServices(services));
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.SetAvailableServices(null));
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }

  getUserServices() {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.get<Service[]>(environment.apiUrl + '/service/currentUser/')
      .toPromise()
      .then(
        (services) => {
          this.store.dispatch(new ServiceActions.SetAvailableServicesForUser(services));
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.SetAvailableServicesForUser(null));
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }


  getDataSourceDefaultValue(): Promise<any> {
    const defaultValue: ServiceDxTable = {
      data: [],
      totalCount: 0,
      groupCount: 0,
      summary: [],
    };
    return Promise.resolve(defaultValue);
  }  

  loadData(loadOptions: any, startDate: Date, endDate: Date, serviceForCurrentUser: boolean, savingsManagementEnabled: boolean, reloadDataOnNextCall: boolean, items: Service[], projectKindId: number, projectId: number, marketId: number): Promise<any> {
    if (!startDate || !endDate || projectKindId === -1) {
      return this.getDataSourceDefaultValue();
    }

    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime)) {

      return this.getDataSourceDefaultValue();
    }

    if (!reloadDataOnNextCall) {
      return new Promise<any>((resolve) => {
        resolve({ data: items, totalCount: items.length, groupCount: 0, summary: [] });
      });
    }

    const params = this.getParams(loadOptions, serviceForCurrentUser, projectId, marketId, null, savingsManagementEnabled);
    const apiLink = environment.apiUrl + '/service/v2/' + startTime + '/' + endTime + '/projectKind/' + projectKindId;
    return this.http.get<any>(apiLink, { params })
      .toPromise();
  }


  createServiceV2(model) {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.post<Service>(environment.apiUrl + '/service/v2', model)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }

  unsetReloadDataGrid() {
    this.store.dispatch(new ServiceActions.SetReloadDataGrid(false));
  }

  updateServiceViaSavingsManagement(loadOptions: any, startDate: Date, endDate: Date, serviceForCurrentUser: boolean, projectKindId: number, projectId: number, marketId: number, model) {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    const params = this.getParams(loadOptions, serviceForCurrentUser, projectId, marketId, null, true);
    let apiLink = environment.apiUrl + '/service/updateViaSavingsManagement/' + startTime + '/' + endTime + '/projectKind/' + projectKindId;


    this.store.dispatch(new ServiceActions.StartSavingsManagmentLoading());
    this.http.put<Service>(apiLink, model, { params })
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie', 'Info', 1_000);
          this.store.dispatch(new ServiceActions.StopSavingsManagmentLoading());
          this.reloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
        this.store.dispatch(new ServiceActions.StopSavingsManagmentLoading());
        this.unsetReloadDataGrid();
        console.error(err);
      });
  }

  updateSelectedServices(serviceParameters: ServiceParameters) {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.put(environment.apiUrl + '/service/updateselected', serviceParameters)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }

  updateServiceV2(model, showMessage, tempReclamationService, tempAdditionalService, tempEmployeeAcceptedLimitService, fromList) {
    var apiUrl = environment.apiUrl + (fromList ? '/service/v2/list' : '/service/v2');
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.put<Service>(apiUrl, model)
      .toPromise()
      .then(
        (res) => {
          if (showMessage) {
            this.uiService.openSnack('Zakończono pomyślnie', 'Info', 1_000);
          }
          this.selectService(res);
          this.store.dispatch(new ServiceActions.StopLoading());
          if (tempReclamationService !== undefined) {
            tempReclamationService.splice(0);
          }
          if (tempAdditionalService !== undefined) {
            tempAdditionalService.splice(0);
          }
          if (tempEmployeeAcceptedLimitService !== undefined) {
            tempEmployeeAcceptedLimitService.splice(0);
          }
          this.reloadDataGrid();
        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new ServiceActions.StopLoading());
        this.unsetReloadDataGrid();
        console.error(err);
      });
  }

  deleteServiceV2(id: number) {
    this.store.dispatch(new ServiceActions.StartLoading());
    this.http.delete(environment.apiUrl + '/service/v2/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new ServiceActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ServiceActions.StopLoading());
        console.error(err);
      });
  }

  reloadDataGrid() {
    this.store.dispatch(new ServiceActions.SetReloadDataGrid(true));
  }

  setServiceStatus(status: number, serviceId: number) {
    this.http.put<any>(environment.apiUrl + '/service/status', { status, serviceId })
      .toPromise()
      .then(
        (res) => {
          if (res && res.messageCode) {
            this.uiService.openSnack(res.messageCode);
          } else {
            this.uiService.openSnack('Zakończono pomyślnie');
          }
          this.reloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  //Merchandising
  setAcceptV2(serviceIds: number[]) {
    this.http.put<any>(environment.apiUrl + '/service/v2/accept', serviceIds)
      .toPromise()
      .then(
        (res) => {
          if (res && res.messageCode) {
            this.uiService.openSnack(res.messageCode);
          }
          this.reloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
        this.unsetReloadDataGrid();
      });
  }

  //Badania Logistyka
  setResearchLogisticAccept(serviceIds: number[]) {
    this.http.put<any>(environment.apiUrl + '/service/research-and-logistic/accept', serviceIds)
      .toPromise()
      .then(
        (res) => {
          if (res && res.messageCode) {
            this.uiService.openSnack(res.messageCode);
          }
          this.reloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
        this.unsetReloadDataGrid();
      });
  }

  //Akceptacja serwisów z Promocji
  setPromotionAccept(serviceIds: number[]) {
    this.http.put<any>(environment.apiUrl + '/service/promotion/accept', serviceIds)
      .toPromise()
      .then(
        (res) => {
          if (res && res.messageCode) {
            this.uiService.openSnack(res.messageCode);
          }
          this.reloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
        this.unsetReloadDataGrid();
      });
  }

  setCancelV2(serviceIds: number[]) {
    this.http.put(environment.apiUrl + '/service/v2/cancel', serviceIds)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.reloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
        this.unsetReloadDataGrid();
      });
  }

  async createAdditionalServiceV2(service: number[], userId: string) {
    this.http.post(environment.apiUrl + '/service/v2/additionalService', { service, userId })
      .toPromise()
      .then(
        (result) => {
          if (!result) {
            this.uiService.openSnack('Zakończono pomyślnie');
          } else {
            this.uiService.openSnack(result.toString(), 'Otrzeżenie', 10_000);
          }
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  async createAdditionalService(service: number[], userId: string) {
    this.http.post(environment.apiUrl + '/service/additionalService', { service, userId })
      .toPromise()
      .then(
        (result) => {
          if (!result) {
            this.uiService.openSnack('Zakończono pomyślnie');
          } else {
            this.uiService.openSnack(result.toString(), 'Otrzeżenie', 10_000);
          }
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  getParams(loadOptions: LoadOptions, serviceForCurrentUser: boolean, projectId: number, marketId: number, summarySelector: string, savingsManagementEnabled: boolean) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }

    if (summarySelector !== null) {
      loadOptions.totalSummary = [{ selector: summarySelector, summaryType: 'sum' }]
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
      'projectId',
      'marketId',
      'onlyMy',
      'forSavingsManagement'
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    params = params.set('projectId', projectId.toString());
    params = params.set('marketId', marketId.toString());
    params = params.set('onlyMy', serviceForCurrentUser.toString());
    params = params.set('forSavingsManagement', savingsManagementEnabled.toString());


    return params;
  }
}
