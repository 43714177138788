import { Action } from '@ngrx/store';
import { ProjectForOrder } from '../project/project.model';
import { Market } from '../localization/localization.model';
import { ProjectKindSimple } from '../contractor/project-kind.model';

export const START_PROJECT_LOADING = '[Params And Tools] start project loading';
export const STOP_PROJECT_LOADING = '[Params And Tools] stop project loading';
export const START_MARKET_LOADING = '[Params And Tools] start market loading';
export const STOP_MARKET_LOADING = '[Params And Tools] stop market loading';
export const START_PROJECT_KIND_LOADING = '[Params And Tools] start projectKind loading';
export const STOP_PROJECT_KIND_LOADING = '[Params And Tools] stop projectKind loading';

export const SET_PROJECT_LIST = '[Params And Tools] Set project list';
export const SET_PROJECT_KIND_LIST = '[Params And Tools] Set projectKind list';
export const SET_MARKET_LIST = '[Params And Tools] Set market list';

export class StartProjectLoading implements Action {
    readonly type = START_PROJECT_LOADING;
}
export class StopProjectLoading implements Action {
    readonly type = STOP_PROJECT_LOADING;
}
export class StartProjectKindLoading implements Action {
    readonly type = START_PROJECT_KIND_LOADING;
}
export class StopProjectKindLoading implements Action {
    readonly type = STOP_PROJECT_KIND_LOADING;
}
export class StartMarketLoading implements Action {
    readonly type = START_MARKET_LOADING;
}
export class StopMarketLoading implements Action {
    readonly type = STOP_MARKET_LOADING;
}
export class SetProjectList implements Action {
    readonly type = SET_PROJECT_LIST;
    constructor(public payload: ProjectForOrder[]) { }
}
export class SetProjectKindList implements Action {
    readonly type = SET_PROJECT_KIND_LIST;
    constructor(public payload: ProjectKindSimple[]) { }
}
export class SetMarketList implements Action {
    readonly type = SET_MARKET_LIST;
    constructor(public payload: Market[]) { }
}

export type ParamsAndToolsActions =
    StartProjectLoading
    | StopProjectLoading
    | StartProjectKindLoading
    | StopProjectKindLoading
    | StartMarketLoading
    | StopMarketLoading
    | SetProjectList
    | SetMarketList
    | SetProjectKindList;