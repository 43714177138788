import {EmployeeRegistrationAccountDataDto, CoordinatorsDataDto, ProjectsDataDto, MarketsDataDto, ProjectKindsDataDto} from 'src/app/redux/employee-records/employee-registration.model';
import { Action } from '@ngrx/store';

export const SET_EMPLOYEE_RECORDS = '[EmployeeRecord] Set selected employee record';
export const SET_SELECTED_COORDINATORS = '[EmployeeRecord] Set selected coordinators';
export const SET_SELECTED_PROJECTS = '[EmployeeRecord] Set selected projects';
export const SET_SELECTED_MARKETS = '[EmployeeRecord] Set selected markets';
export const SET_SELECTED_PROJECT_KINDS = '[EmployeeRecord] Set selected project kinds';
export const EMPLOYEE_REGISTRATION_SUCCESS = '[Employee Records] Registration Success';
export const EMPLOYEE_REGISTRATION_FAILURE = '[Employee Records] Registration Failure';

//export const SET_OBJECT_TYPES = '[EmployeeRecord] Set object types';
export const START_LOADING = '[EmployeeRecord] start loading';
export const STOP_LOADING = '[EmployeeRecord] stop loading';

export class SetEmplyeeRecords implements Action {
    readonly type = SET_EMPLOYEE_RECORDS;
    constructor(public payload: EmployeeRegistrationAccountDataDto) { }
}
export class SetCoordinatorsData implements Action {
    readonly type = SET_SELECTED_COORDINATORS;
    constructor(public payload: CoordinatorsDataDto[]) { }
}
export class SetProjectsData implements Action {
    readonly type = SET_SELECTED_PROJECTS;
    constructor(public payload: ProjectsDataDto[]) { }
}
export class SetMarketsData implements Action {
    readonly type = SET_SELECTED_MARKETS;
    constructor(public payload: MarketsDataDto[]) { }
}
export class SetProjectKindsData implements Action {
    readonly type = SET_SELECTED_PROJECT_KINDS;
    constructor(public payload: ProjectKindsDataDto[]) { }
}
export class EmployeeRegistrationSuccess {
    readonly type = EMPLOYEE_REGISTRATION_SUCCESS;
    constructor(public payload: any) {} 
}
  export class EmployeeRegistrationFailure {
    readonly type = EMPLOYEE_REGISTRATION_FAILURE;
    constructor(public error: any) {}
}

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export type EmployeeRecordsActions =
    SetEmplyeeRecords
    |SetCoordinatorsData
    |SetProjectsData
    |SetMarketsData
    |SetProjectKindsData
    |EmployeeRegistrationSuccess
    |EmployeeRegistrationFailure
    | StopLoading
    | StartLoading
    ;