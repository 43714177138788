import { LoginComponent } from 'src/app/auth/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PasswordReminderComponent } from './password-reminder/password-reminder.component';
import { PasswordReminderFormComponent } from './password-reminder-from/password-reminder-from.component';
import { AuthGuard } from './auth.guard';
import { PasswordChangeFormComponent } from '../shared/password-change-form/password-change-form.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'login/reminder', component: PasswordReminderComponent },
    { path: 'login/reminder/form/:token', component: PasswordReminderFormComponent },
    // { path: 'login/password', component: PasswordChangeFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
