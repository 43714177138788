import { SurveyAnswer, SurveyConfig, SurveyPattern } from '../survey/survey.model';
import { UserBase, UserListItem } from '../user/user.model';

export interface SurveyData {
  surveyPattern?: SurveyPattern;
  surveyPatternIndividual?: string;
  surveyRealizationJson?: string;
}

export interface OrderTask {
  id?: number;
  isCheckInActive?: boolean;
  orderId?: number;
  contractorName?: string;
  market?: string;
  localizationName?: string;
  localizationCode?: string;
  localizationCity?: string;
  localizationStreet?: string;
  localizationFormat?: string;
  localizationAgent?: string;
  localizationVoivodeship?: string;
  localizationSubArea?: string;
  localizationKindOfPos?: string;
  localizationVisualization?: string;
  taskKindDesc?: string;
  taskKind?: TaskKind;
  photosNumber?: number;
  requiredDate?: Date;
  requiredDateText?: string;
  requiredDateLong?: number;
  realizationDate?: Date;
  realizationDateText?: string;
  responsiblePerson?: UserListItem;
  responsiblePersonId?: string;
  taskdescription?: string;
  taskStartDate?: Date;
  taskFinishDate?: Date;
  createdDate?: Date;
  createdDateText?: string;
  enableRemainder?: boolean;
  dayNumbersBeforeReminder?: number;
  remainderDate?: Date;
  notifyContractorAfterReportComplete?: boolean;
  notifyPmtCoordinatorAfterReportComplete?: boolean;
  remindPmtCoordinatorBeforReportComplete?: boolean;
  remindServiceEmployeeBeforReportComplete?: boolean;
  notifyPmtCoordinatorAfterSkipReport?: boolean;
  notifyServiceEmployeeAfterSkipReport?: boolean;
  status?: OrderTaskStatus;
  taskRealization?: TaskRealization;

  project?: string;
  projectId?: number;
  title?: string;
  surveyTitle?: string;
  approvedByOffice?: boolean;
  manager?: UserListItem;
  managerId?: string;
  localizationCoordinator?: UserListItem;
  localizationCoordinatorId?: string;
  merchandisingCoordinator?: UserListItem;
  merchandisingCoordinatorId?: string;
  supervisor?: UserListItem;
  supervisorId?: string;
  additionalSupervisor?: UserListItem;
  additionalSupervisorId?: string;
  serviceEmployee?: UserListItem;
  serviceEmployeeId?: string;
  contractorManager?: string;
  salesRepresentative?: string;
  contractorLocalizationCode?: string;
  contractorArea?: string;
  contractorSubArea?: string;
  orderTaskSettlementStatus?: OrderTaskSettlementStatus;
  products?: ProductBase[];
  realizedBy?: UserListItem;
  surveyPattern?: SurveyPattern;
  surveyPatternId?: number;
  surveyPatternTitle?: string;
  surveyRealizationDate?: Date;
  surveyRealizationDateText?: string;
  surveyRealizationJson?: string;
  surveyPatternIndividual?: string;
  surveyAnswers?: SurveyAnswer[];
  centralPromotionId?: number;
  reportConfigId?: number;
  generateServiceEnabled? : boolean;

  probsIntegrationEnabled?: boolean;
  probsIntegrationStatus?: ProbsIntegrationStatus;
  probsLastIntegrationAt?: Date;

  //Badania:
  contractorCommissionRateBudget?: number; // Stawka zlecona budżet
  coordinationRate?: number;                // Stawka Koordynacja
  reserveManager?: number;                  // Rezerwa MR
  contractorTotalSum?: number;              // Suma Total
  contractorBonus?: number;                 // Bonus

  kpi?: number;
  employeeCostOfArrival?: number; //Koszt dotarcia
  employeeSaving?: number; //Oszczędność
  employeeMinimalNetRate?: number; //Min. Stawka netto
  employeeDeductionAmount?: number; //Potrącenie [kwota]
  employeeReasonForCorrection?: string; //Powód korekty
  employeeDelegation?: number; //Delegacja 
  fvEmployee?: boolean //Faktura vat

  budgetSalary?: number //Wynagrodzenie Budżet Pracownik
  grossSalary?: number //Wynagrodzenie Brutto Pracownik
  netSalary?: number //Wynagrodzenie Netto Pracownik

  employeeRateBudgetSettlements?: number; //Stawka przyznana budżet 
  employeeRateSettlements?: number;//Stawka przyznana brutto 
  employeeNetRate?: number; // Stawka netto
  
  projectKindId?: number;
  clientNumberHoursFV?: number //Liczba godzin na FV
  employeeHoursOrdered?: number; //Liczba godzin zlecona
  employeeHoursWorked?: number; //Liczba godzin wypracowanych
  employeeSavingCoordinator?: number  // Oszczędności KR
}

export interface ProductBase {
  id?: number;
  name?: string;
  group?: string;
  category?: string;
  code?: string;
  oos?: boolean;
  description?: string;
}

export interface Prob {
  id?: number;
  orderId?: number;
  orderTaskId?: number;
  attachmentId?: number;
  fileName?: string;
  photoUrl?: string;
  categoryIdentifierCollection?: string;
  shopIdentifier?: number;
  userIdentifier?: number;
  responsiblePersonId?: number;
  photoCreatedAt?: string;
  packageCategoryIdCollection?: number;
  probsOrderJson?: string;
  probsOrderStatus?: string;
  sendTryCount?: number;
  integrationStage?: string;
  integrationStatus?: string;
  logs?: string;
  sentAt?: string;
  updatedAt?: string;
  statusCheckedAt?: string;
  createdAt?: string;
}

export enum TaskKind {
  OutOfStockReport = 0,
  PhotosReport = 1
}

export enum OrderTaskStatus {
  New = 0, //Czeka
  InProgress = 1, //W trakcie
  Finished = 2, //Zrobione
  Canceled = 3, //Anulowane
  NotDone = 4 //Niewykonane
}

export enum ProbsIntegrationStatus {
  New = 0,
  Waiting = 1,
  Finished = 2,
}

export enum OrderTaskSettlementStatus {
  Ok = 0, // OK
  Late = 1, // spóźnione
  AfterRequiredTime = 2, // Po czasie
  NotDone = 3 // Brak
}

export interface TaskPhoto {
  attachemntId: number;
  taskId: number;
  photoBase64: string;
  fileName: string;
  fileType: string;
  description?: string;
  market?: string;
  localization?: string;
}

export interface TasksGallery {
  galleryDescription?: string;
  taskPhotos: TaskPhoto[];
}

export interface TaskRealization {
  realizationDate?: Date;
  realizationDateLong?: number  ;
  realizationDateStr?: string;
  realizationNote?: string;
  createUser?: UserBase;
  oosProducts?: ProductBase[];
  photos?: TaskPhoto[];
}

export interface OosChart {
  parameter: string;
  value: number;
  totalValue: number;
}

export interface WorkTimeChart {
  parameter: string;
  afterVisit: number;
  inProgressVisit: number;
  noVisit: number;
}

export interface OrderTaskParameters {
  taskIds: number[];
  params: OrderTaskParam[];
}

export interface OrderTaskParam {
  param: string;
  value: any;
}

export interface UpdateOrderTaskModel {
  title?: boolean;
  requiredDate?: boolean;
  responsiblePerson?: boolean;
  taskDescription?: boolean;
  approvedByOffice?: boolean;
  supervisor?: boolean;
  additionalSupervisor?: boolean;
  manager?: boolean;
  merchandisingCoordinator?: boolean;
  localizationCoordinator?: boolean;
  serviceEmployee?: boolean;
  contractorManager?: boolean;
  salesRepresentative?: boolean;
  contractorLocalizationCode?: boolean;
  contractorArea?: boolean;
  contractorSubArea?: boolean;
  realizedBy?: boolean;
  orderTaskSettlementStatus?: boolean;
  orderTaskStatus?: boolean;
  generateServiceEnabled? : boolean;
}

export interface GenerationPlan {
    id?: number;
    project?: string;
    localization?: string;
    market?: string;
    city?: string;
    street?: string;
    reportTitle?: string;
    surveyPatternTitle?: string;
    responsiblePerson?: string;
    realizationDate?: Date;
    coordinator?: string;
    manager?: string;
    contractorManager?: string;
    salesRepresentative?: string;
}

export interface OrderTaskDxTable {
  data: OrderTask[];
  totalCount: number;
  groupCount: number;
  summary: number[];
}

