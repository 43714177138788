import { AuditActions, SET_SELECTED_AUDIT, START_LOADING, STOP_LOADING } from "./audit.actions";
import { Audit } from "./audit.model";

export interface State {
    selectedAudit: Audit;
    isLoading: boolean;
}

const initialState: State = {
    selectedAudit: null,
    isLoading: false,
}
export function auditReducer(state = initialState, action: AuditActions) {
    switch (action.type) {
        case SET_SELECTED_AUDIT: {
            return {
                ...state,
                selectedAudit: action.payload
            };
        }
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}

export const isLoading = (state: State) => state.isLoading;
export const selectedAudit = (state: State) => state.selectedAudit;
