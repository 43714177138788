import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as fromRoot from '../../../app.reducer';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeFormDialogComponent } from 'src/app/shared/password-change-form/password-change-form-dialog.component';
import { AppHubConnection } from 'src/app/redux/user/hub_connection.model';
import { Company } from 'src/app/redux/company/company.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  @Output() sidenavToggle = new EventEmitter<void>();

  company: Company;
  user: ApplicationUser;
  language = 'ru';
  importStatus: string;
  importProgress = 0;
  online: AppHubConnection[] = [];

  constructor(
    private store: Store<fromRoot.IRootState>,
    public auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService) { }

  ngOnInit() {
    this.store.select(fromRoot.getCurrentCompany)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(company => this.company = company);
    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(user => { this.user = user; });
    this.store.select(fromRoot.importStatus)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(importStatus => { this.importStatus = importStatus; });
    this.store.select(fromRoot.importProggress)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(importProgress => this.importProgress = importProgress);
    this.store.select(fromRoot.getUserOnline)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((online) => {
        this.online = online;
      });

    // set language
    const value = localStorage.getItem('language');
    switch (value) {
      case 'en': {
        this.language = 'en';
        break;
      }
      case 'ru': {
        this.language = 'ru';
        break;
      }
      case 'de': {
        this.language = 'de';
        break;
      }
      case 'ua': {
        this.language = 'ua';
        break;
      }
      default: {
        this.language = 'pl';
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  isOnline(id: string): boolean {
    return this.online.some(c => c.userIdentifier === id);
  }

  getFacebookId(user) {
    if (!user.facebookId || user.avatar) {
      return null;
    }
    return user.facebookId;
  }

  getPhoto(user) {
    if (user.avatar) {
      return user.avatar;
    }
  }

  changeLanguage(lang) {
    this.language = lang;
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

  doImport() {
    this.auth.doImport();
  }

  toggle() {
    this.sidenavToggle.emit();
  }

  get companyList() {
    if (!this.user || !this.user.applicationUserCompany) {
      return [];
    }
    const companies = this.user.applicationUserCompany.filter(c => c.companyId !== this.company.id && c.company.isActive
      && !c.disabled
    );
    return companies.sort((one, two) => one.company.shortcut > two.company.shortcut ? 1 : -1);
  }

  navigationTo(path) {
    this.router.navigate([path]);
  }

  changeCompany(company) {
    this.auth.changeCompany(company);
  }

  changePassword() {
    this.dialog.open(PasswordChangeFormDialogComponent);
  }

  logout() {
    this.auth.logout();
  }
}
