import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Store } from "@ngrx/store";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { Category, SurveyPattern } from "src/app/redux/survey/survey.model";
import { Page } from "src/app/services/navigation.service";
import { OrderTaskService } from "src/app/services/order-task/order-task.service";
import { environment } from "src/environments/environment";
import * as fromRoot from '../app.reducer';
import { Market } from "src/app/redux/localization/localization.model";
import { MatAccordion, MatOption, MatSelect, MatSelectChange } from "@angular/material";
import { Project, ProjectSimple } from "src/app/redux/project/project.model";
import { User, UserBase, UserListItem } from "src/app/redux/user/user.model";
import { UIService } from "src/app/shared/ui.service";
import { NotificationRoles } from "./notification.model";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy = new Subject<void>();

  allMarkets: Market[] = [];
  selectedMarkets: number[];
  allProjects: Project[] = [];
  allProjectsSimple: ProjectSimple[] = [];
  selectedProject: number;
  availableUsers: UserListItem[] = [];
  selectedUsers: UserListItem[] = [];
  selectedRoles: string[] = [];

  notificationRoles: NotificationRoles;
  valueContent: string;
  isMultiline: boolean = true;
  mail: boolean = true;
  apka: boolean = false;
  mailTitle: string;
  files = [];

  fg: FormGroup;

  // selectBox Market
  searchControl: FormControl = new FormControl('');
  private _filteredMarkets: Market[] = [];
  filteredMarkets: ReplaySubject<Market[]> = new ReplaySubject<Market[]>(1);
  marketSelectOpened = false;
  @ViewChild('marketSelect', { static: false }) selectElem: MatSelect;

  // selectBox Project
  searchControl2: FormControl = new FormControl();
  private _filteredProjects: ProjectSimple[] = [];
  filteredProjects: ReplaySubject<ProjectSimple[]> = new ReplaySubject<ProjectSimple[]>(1);
  @ViewChild('projectSelect', { static: false }) selectElem2: MatSelect;
  projectSelectOpened = false;

  //selectBox supervisor

  searchControl3: FormControl = new FormControl();
  private _filteredUsers: UserListItem[] = [];
  filteredUsers: ReplaySubject<UserListItem[]> = new ReplaySubject<UserListItem[]>(1);
  userSelectOpened = false;
  @ViewChild('userSelect', { static: false }) selectElem3: MatSelect;

  @ViewChild('f', { static: false }) f: NgForm;

  @ViewChild('allMarketsSelected', { static: false }) private allMarketsSelected: MatOption;
  @ViewChild('allProjectsSelected', { static: false }) private allProjectsSelected: MatOption;
  @ViewChild('allUsersSelected', { static: false }) private allUsersSelected: MatOption;
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  groupControl = new FormControl();

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private store: Store<fromRoot.IRootState>,
    private http: HttpClient,
    private uiService: UIService) { }

  ngOnInit() {
    this.fg = this.fb.group({
      marketSelection: new FormControl(''),
      projectSelection: new FormControl(''),
      userSelection: new FormControl('')
    });


    this.store.select(fromRoot.getMarketsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((markets) => {
        this.allMarkets = markets;
        this.filterMarkets();
      });

      this.store.select(fromRoot.getProjectsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projects) => {
        this.allProjects = projects;
        this.allProjectsSimple.splice(0);
        projects.forEach(element => {
          this.allProjectsSimple.push({
            id: element.id,
            name: element.name,
            projectKind: element.projectKind,
            contractor: element.contractor });
        })
        this.filterProjects();
      });
  }

  ngAfterViewInit() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      this.files = [];
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push(file);
      }
    };

    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.marketSelectOpened || this._filteredMarkets.length === 0) {
          this.filterMarkets();
        }
      });

    this.searchControl2.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.projectSelectOpened || this._filteredProjects.length === 0) {
          this.filterProjects();
        }
      });

    this.searchControl3.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.userSelectOpened || this._filteredUsers.length === 0) {
          this.filterUsers();
        }
      });
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterMarkets() {
    if (!this.allMarkets) {
      return;
    }
    // get the search keyword
    let search = this.searchControl.value;
    if (!search) {
      this.filteredMarkets.next(this.allMarkets.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the markets
    this.filteredMarkets.next(
      this.allMarkets.filter(m => m.code.toLowerCase().indexOf(search) > -1)
    );

  }

  filterProjects() {

    if (!this.allProjectsSimple) {
      return;
    }
    // get the search keyword
    let search = this.searchControl2.value;
    if (!search) {
      this.filteredProjects.next(this.allProjectsSimple.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the projects
    this.filteredProjects.next(
      this.allProjectsSimple.filter(l => l.name.toLowerCase().indexOf(search) > -1
      || l.contractor.shortcut.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterUsers() {
    if (!this.availableUsers) {
      return;
    }
    // get the search keyword
    let search = this.searchControl3.value;
    if (!search) {
      this.filteredUsers.next(this.availableUsers);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredUsers.next(
      this.availableUsers.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
      || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  cancel() {

  }


  compareFn(market1: Market, market2: Market) {
    return market1 && market2 ? market1.id === market2.id : market1 === market2;
  }

  compareFn2(project1: ProjectSimple, project2: ProjectSimple) {
    return project1 && project2 ? project1.id === project2.id : false;
  }

  compareFn3(merch1: UserListItem, merch2: UserListItem) {
    return merch1 && merch2 ? merch1.id === merch2.id : false;
  }

  marketSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedMarkets = e.value;
      this.getUsers(this.selectedProject, this.selectedMarkets, this.selectedRoles);
    }
  }

  projectSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedProject = e.value;
      this.getUsers(this.selectedProject, this.selectedMarkets, this.selectedRoles);
    }
  }

  roleSelectionChange(e: MatSelectChange) {
    if (e.value) {

      this.selectedRoles.splice(0);

      let roles: number[] = e.value;
      roles.forEach(r =>
        {
          if(r == 0) {
            this.selectedRoles.push('Merchandiser');
          }
          if(r == 1) {
            this.selectedRoles.push('Koordynator lokalizacji');
          }
          if(r == 2) {
            this.selectedRoles.push('Koordynator');
          }
          if(r == 3) {
            this.selectedRoles.push('Menedżer');
          }
        })
        this.getUsers(this.selectedProject, this.selectedMarkets, this.selectedRoles);
    }
  }

  userSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedUsers = e.value;
    }
  }

  toggleAllMarketsSelection() {
    if (this.allMarketsSelected.selected) {
      this.fg.controls.marketSelection
        .patchValue([0, ...this.allMarkets.map(item => item.id).slice()]);
        this.selectedMarkets = this.allMarkets.map(item => item.id).slice();
    } else {
      this.fg.controls.marketSelection.patchValue([]);
      this.selectedMarkets = [];
    }
  }

  tosslePerOneMarket(all){
    if (this.allMarketsSelected.selected) {
     this.allMarketsSelected.deselect();
     return false;
    }
      if(this.fg.controls.marketSelection.value.length === this.allMarkets.length)
        this.allMarketsSelected.select();
  }

  toggleAllProjectsSelection() {
    if (this.allProjectsSelected.selected) {
      this.fg.controls.projectSelection
        .patchValue([0, ...this.allProjects.map(item => item.id).slice()]);
    } else {
      this.fg.controls.projectSelection.patchValue([]);
    }
  }

  tosslePerOneProject(all){
    if (this.allProjectsSelected.selected) {
     this.allProjectsSelected.deselect();
     return false;
    }
      if(this.fg.controls.projectSelection.value.length === this.allProjects.length)
        this.allProjectsSelected.select();
  }

  toggleAllUsersSelection() {
    if (this.allUsersSelected.selected) {
      this.fg.controls.userSelection
        .patchValue([0, ...this.availableUsers]);
        this.selectedUsers = this.availableUsers;
    } else {
      this.fg.controls.userSelection.patchValue([]);
      this.selectedUsers = [];
    }
  }

  tosslePerOneUser(all){
    if (this.allUsersSelected.selected) {
     this.allUsersSelected.deselect();
     return false;
    }
      if(this.fg.controls.userSelection.value.length === this.availableUsers.length)
        this.allUsersSelected.select();
  }

  getUsers(selectedProject, selectedMarkets, selectedRoles) {
    this.http.put<any>(environment.apiUrl + '/notification/',
    {
      projectId: selectedProject,
      markets: selectedMarkets,
      roles: selectedRoles
    })
      .toPromise()
      .then(
        (users) => {
          this.availableUsers = users;
          this.filterUsers();
         })
    .catch(err => {
        console.error(err);
    });
  }

  sendNotification(title, body, users, files) {
    this.uiService.openSnack('Trwa wysyłka powiadomienia...', null, null);
    const formData = new FormData();

    for (const file of files) {
      formData.append('files', file);
    }

    for (const user of this.selectedUsers) {
      formData.append('users', user.id);
    }
    formData.append('title', title);
    formData.append('body', body);

    this.http.post<any>(environment.apiUrl + '/notification/message', formData)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
        })
    .catch(err => {
      this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
    });
  }

  send() {
    console.log(this.valueContent);
    this.sendNotification(this.mailTitle, this.valueContent, this.selectedUsers, this.files);
  }

}
