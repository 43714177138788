import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationData } from './auth/user.model';
import * as fromRoot from 'src/app/app.reducer';
import 'devextreme-intl';
import { loadMessages, locale } from 'devextreme/localization';
// the content of these you have to load in your ngx-translate loader from the webservice
import * as deMessages from 'devextreme/localization/messages/de.json';
// import * as enMessages from 'devextreme/localization/messages/en.json';
import * as ruMessages from 'devextreme/localization/messages/ru.json';
import { DevExtreme } from './devextreme-translations';
import { Store } from '@ngrx/store';
import { Store as NgxsStore } from '@ngxs/store';
import { ScreenService } from './redux/screen-state/screen.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Group } from './redux/group/group.model';
import { User } from './redux/user/user.model';
import { EDictionary } from './shared/common.reducer';
import { ApplicationUser } from './_models/application-user.model';
import { ContactModuleModel } from './auth/modules/contact-module.model';
import { UpdateDataSource } from './redux/user/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  private _allGroups: Group[];
  private _eDictionary: EDictionary[];
  private _currentUser: ApplicationUser;
  private _contactModule: ContactModuleModel;

  isAuth: boolean;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private store: Store<fromRoot.IRootState>,
    private screen: ScreenService,
    private nStore: NgxsStore
  ) { }

  ngOnInit() {

    this.store.select(fromRoot.getIsAuth)
      .subscribe(res => this.isAuth = res);

    this.initTranslate();

    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token && currentUser.company) {
      this.spinner.show();
    }

    this.authService.initAuth();

    this.translate.onLangChange
      .subscribe(c => {
        this.authService.setLanguage(c.lang);
        locale(c.lang);
      });

    this.screen.setWindowWidth(window.innerWidth);

    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((user) => {
        this._currentUser = user;
        //this.buildDataSource();
      });
    this.store.select(fromRoot.getGroupList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((groups) => {
        this._allGroups = groups;
        //this.buildDataSource();
      });
    this.store.select(fromRoot.getDictionaries)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((dic) => {
        this._eDictionary = dic;
        //this.buildDataSource();
      });
    this.store.select(fromRoot.getContactModule)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((contactModule) => {
        this._contactModule = contactModule;
        //this.buildDataSource();
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.screen.setWindowWidth(width);
  }

  initTranslate() {
    this.translate.addLangs(['pl', 'en', 'ru', 'de', 'ua']);
    this.translate.setDefaultLang('pl');

    loadMessages(ruMessages.default);
    loadMessages(deMessages.default);
    loadMessages(DevExtreme);

    const value = localStorage.getItem('language');
    if (value) {
      locale(value);
      this.translate.use(value);
    } else {
      locale('pl');
      this.translate.use('pl');
      localStorage.setItem('language', 'pl');
    }
  }

  toggle() {
    this.authService.toggle();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
