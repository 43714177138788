import { Action } from '@ngrx/store';
import { CustomField, CustomPage } from './custom-filed.model';

export const SET_AVAILABLE_CUSTOM_FIELDS = '[Custom field] Set available custom fields';
export const SET_AVAILABLE_CUSTOM_PAGES = '[Custom field] Set available custom pages';
export const START_EDITING = '[Custom field] Start editing';
export const STOP_EDITING = '[Custom field] Stop editing';
export const ADD_FIELD = '[Custom field] Add new custom field';
export const ADD_PAGE = '[Custom field] Add new custom page';
export const REMOVE_FIELD = '[Custom field] Remove custom field';
export const REMOVE_PAGE = '[Custom field] Remove custom page';
export const UPDATE_FIELD = '[Custom field] Update custom field';
export const UPDATE_PAGE = '[Custom field] Update custom paga';
export const SELECTED_CUSTOM_FIELD = '[Custom field] Selected custom field';
export const SELECTED_CUSTOM_PAGE = '[Custom field] Selected custom page';
export const START_LOADING = '[Custom field] start loading';
export const STOP_LOADING = '[Custom field] stop loading';

export class SetAvailableCustomFields implements Action {
    readonly type = SET_AVAILABLE_CUSTOM_FIELDS;
    constructor(public payload: CustomField[]) { }
}
export class SetAvailableCustomPages implements Action {
  readonly type = SET_AVAILABLE_CUSTOM_PAGES;
  constructor(public payload: CustomPage[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class AddField implements Action {
    readonly type = ADD_FIELD;
    constructor(public payload: CustomField) { }
}
export class AddPage implements Action {
  readonly type = ADD_PAGE;
  constructor(public payload: CustomPage) { }
}

export class RemoveField implements Action {
    readonly type = REMOVE_FIELD;
    constructor(public payload: number) { }
}

export class RemovePage implements Action {
  readonly type = REMOVE_PAGE;
  constructor(public payload: number) { }
}

export class UpdateField implements Action {
    readonly type = UPDATE_FIELD;
    constructor(public payload: CustomField) { }
}

export class UpdatePage implements Action {
  readonly type = UPDATE_PAGE;
  constructor(public payload: CustomPage) { }
}
export class SetSelectedCustomField implements Action {
    readonly type = SELECTED_CUSTOM_FIELD;
    constructor(public payload: CustomField) { }
}
export class SetSelectedCustomPage implements Action {
  readonly type = SELECTED_CUSTOM_PAGE;
  constructor(public payload: CustomPage) { }
}

export type CustomFieldActions =
    SetAvailableCustomFields
    | SetAvailableCustomPages
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | AddField
    | AddPage
    | RemoveField
    | RemovePage
    | UpdateField
    | UpdatePage
    | SetSelectedCustomField;
