import { Action } from '@ngrx/store';
import { IRole } from 'src/app/_models/role.model';
import { User } from '../user/user.model';

export const SET_AVAILABLE_ROLES = '[Perm] Set available Roles';
export const ADD_NEW_ROLE = '[Perm] Add new role';
export const UPDATE_ROLE = '[Perm] Update role';
export const REMOVE_ROLE = '[Perm] Remove role';
export const START_EDITING_ROLE = '[Perm] Start editing';
export const STOP_EDITING_ROLE = '[Perm] Stop editing';
export const START_EDITING_ROLE_USER = '[Perm] Start editing role user';
export const STOP_EDITING_ROLE_USER = '[Perm] Stop editing role user';
export const SET_AVAILABLE_PERMISSIONS = '[Perm] set available permissions';
export const ROLE_SELECTED = '[Perm] Selected role';
export const ROLE_SELECTED_USER = '[Perm] Selected role user';
export const START_LOADING = '[Perm] start loading';
export const STOP_LOADING = '[Perm] stop loading';

export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class SetAvailableRoles implements Action {
    readonly type = SET_AVAILABLE_ROLES;
    constructor(public payload: { data: IRole[], totalCount: number }) { }
}
export class AddNewRole implements Action {
    readonly type = ADD_NEW_ROLE;
    constructor(public payload: IRole) { }
}
export class RemoveRole implements Action {
    readonly type = REMOVE_ROLE;
    constructor(public payload: number) { }
}
export class UpdateRole implements Action {
    readonly type = UPDATE_ROLE;
    constructor(public payload: IRole) { }
}
export class StartEditingRole implements Action {
    readonly type = START_EDITING_ROLE;
}
export class StopEditingRole implements Action {
    readonly type = STOP_EDITING_ROLE;
}
export class StartEditingRoleUser implements Action {
    readonly type = START_EDITING_ROLE_USER;
}
export class StopEditingRoleUser implements Action {
    readonly type = STOP_EDITING_ROLE_USER;
}
export class SetAvailablePermissions implements Action {
    readonly type = SET_AVAILABLE_PERMISSIONS;
    constructor(public payload: any[]) { }
}

export class SetSelectedRole implements Action {
    readonly type = ROLE_SELECTED;
    constructor(public payload: IRole) { }
}

export class SetSelectedRoleUser implements Action {
    readonly type = ROLE_SELECTED_USER;
    constructor(public payload: User) { }
}

export type RoleActions =
    | StartEditingRoleUser
    | StopEditingRoleUser
    | SetSelectedRoleUser
    | StartLoading
    | StopLoading
    | SetSelectedRole
    | SetAvailablePermissions
    | SetAvailableRoles
    | AddNewRole
    | RemoveRole
    | UpdateRole
    | StartEditingRole
    | StopEditingRole;
