import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// #2975ff
@Component({
    selector: 'app-sidenav-nav-list-item',
    template: `
    <mat-list-item class="router-item" (click)="onClick()"
     routerLink="{{link}}" routerLinkActive="active" matTooltipPosition="after" matTooltip="{{ text   }}"
        [matTooltipShowDelay]="showDelay.value">
        <div class="active-line"></div>
        <mat-icon *ngIf="icon" class="nav-list-item-icon" color-gray>{{icon}}</mat-icon>
        <mat-icon *ngIf="svgIcon" class="nav-list-item-icon" color-gray svgIcon="{{svgIcon}}"></mat-icon>
        <div fxFlex="10"></div>
        <div *ngIf="sidenavWidth > 6">
            <div class="action-title mat-typography">{{ text   }}</div>
        </div>
    </mat-list-item>
    `,
    styleUrls: ['./sidenav-nav-list-item.component.scss']
})
export class SidenavNavListItemComponent {
    @Output() onclick = new EventEmitter<void>();
    @Input() text: string;
    @Input() icon: string;
    @Input() svgIcon: string;
    @Input() sidenavWidth: number;
    @Input() link: string;
    showDelay = new FormControl(500);

    constructor(private router: Router) { }

    navigate() {
        this.router.navigate([this.link]);
    }

    onClick() {
        this.onclick.emit();
    }
}
