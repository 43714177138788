import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn
} from '@angular/forms';
import {Directive} from '@angular/core';

export function phoneNumber(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
        isValidPhoneNumber(control.value) ? null : {wrongPhoneNumber: 'Wprowadź poprawny numer telefonu'};
}

export function isValidPhoneNumber(input: string) {
  if(!input) {
    return true;
  }
  return input.trim().replace(' ','').replace('-','').length === 9;
}

@Directive({
  selector: '[phone-number]',
  providers: [{
      provide: NG_VALIDATORS,
      useExisting: PhoneNumberValidatorDirective,
      multi: true
  }]
})

export class PhoneNumberValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
      return phoneNumber()(control);
  }
}
