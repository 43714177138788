import { Action } from '@ngrx/store';
import { Product, ProductPhoto } from './product.model';

export const SET_AVAILABLE_PRODUCTS = '[Product] Set available products';
export const SET_AVAILABLE_PRODUCT_PHOTOS = '[Product] Set available product photos';
export const SET_AVAILABLE_LOCALIZATION_PRODUCTS = '[Product] Set available localization products';
export const SET_SELECTED_PRODUCTS = '[Product] Set selected products';
export const START_EDITING = '[Product] Start editing';
export const STOP_EDITING = '[Product] Stop editing';
export const ADD = '[Product] Add new product';
export const ADD_PHOTO = '[Product] Add new photo';
export const REMOVE = '[Product] Remove product';
export const REMOVE_PHOTO = '[Product] Remove photo';
export const UPDATE = '[Product] Update product';
export const SELECTED = '[Product] Selected product';
export const START_LOADING = '[Product] start loading';
export const STOP_LOADING = '[Product] stop loading';
export const START_LOADING_LIST = '[Project] start loading list';
export const STOP_LOADING_LIST = '[Project] stop loading list';

export class SetAvailableProducts implements Action {
    readonly type = SET_AVAILABLE_PRODUCTS;
    constructor(public payload: Product[]) { }
}
export class SetAvailableProductPhotos implements Action {
  readonly type = SET_AVAILABLE_PRODUCT_PHOTOS;
  constructor(public payload: ProductPhoto[]) { }
}
export class SetAvailableLocalizationProducts implements Action {
  readonly type = SET_AVAILABLE_LOCALIZATION_PRODUCTS;
  constructor(public payload: Product[]) { }
}
export class SetSelectedProducts implements Action {
  readonly type = SET_SELECTED_PRODUCTS;
  constructor(public payload: Product[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartLoadingList implements Action {
  readonly type = START_LOADING_LIST;
}
export class StopLoadingList implements Action {
  readonly type = STOP_LOADING_LIST;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Product) { }
}
export class AddPhoto implements Action {
  readonly type = ADD_PHOTO;
  constructor(public payload: ProductPhoto) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class RemovePhoto implements Action {
  readonly type = REMOVE_PHOTO;
  constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Product) { }
}
export class SetSelectedProduct implements Action {
  readonly type = SELECTED;
  constructor(public payload: Product) { }
}

export type ProductActions =
    SetAvailableProducts
    | SetAvailableProductPhotos
    | SetAvailableLocalizationProducts
    | SetSelectedProducts
    | StartLoading
    | StopLoading
    | StartLoadingList
    | StopLoadingList
    | StartEditing
    | StopEditing
    | Add
    | AddPhoto
    | Remove
    | RemovePhoto
    | Update
    | SetSelectedProduct;
