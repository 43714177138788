import { Action } from '@ngrx/store';
import { UserContactsData, User, UserBase, UserCommonRoles, UserGroupManagersData, UserGroupMembersData, UserListItem, UserContactDetails, UserAdditionalData, GroupManagersResource, ProjectKindGroupManagersResource } from './user.model';
import { AppHubConnection } from './hub_connection.model';
import { Contact } from 'src/app/contact/contact.component';

export const SET_AVAILABLE_USERS = '[User] Set available users';

export const SET_AVAILABLE_MANAGERS_V2 = '[User] Set available managers new';
export const SET_AVAILABLE_LOCALIZATION_COORDINATORS_V2 = '[User] Set available localization coordinators new';
export const SET_AVAILABLE_PMT_COORDINATORS_V2 = '[User] Set available pmt coordinators new';
export const SET_AVAILABLE_PROM_COORDINATORS_V2 = '[User] Set available prom coordinators new';
export const SET_AVAILABLE_PMT_MERCHANDISERS_V2 = '[User] Set available pmt merchandisers new';
export const SET_AVAILABLE_HOSTESSES_V2 = '[User] Set available hostesses new';
export const SET_AVAILABLE_SUPERVISORS_V2 = '[User] Set available supervisors new';

export const SET_AVAILABLE_MANAGERS = '[User] Set available managers';
export const SET_AVAILABLE_LOCALIZATION_COORDINATORS = '[User] Set available localization coordinators';
export const SET_AVAILABLE_PMT_COORDINATORS = '[User] Set available pmt coordinators';
export const SET_AVAILABLE_PROM_COORDINATORS = '[User] Set available prom coordinators';
export const SET_AVAILABLE_PMT_MERCHANDISERS = '[User] Set available pmt merchandisers';
export const SET_AVAILABLE_HOSTESSES = '[User] Set available hostesses';
export const SET_AVAILABLE_SUPERVISORS = '[User] Set available supervisors';
export const SET_USER_PHOTO = '[User] SET_USER_AVATAR';
export const ADD_NEW_USER = '[User] Add new user';
export const ADD_NEW_USER_RANGE = '[User] Add new users range';
export const REMOVE_USER = '[User] Remove User';
export const UPDATE_USER = '[User] Update User';
export const UPDATE_USER_BLOCKED_FLAG = '[User] Update User Blocked Flag';
export const UPDATE_USER_RANGE = '[User] Update Users';
export const UPDATE_COMMON_USERS = '[User] Update common Users';
export const USER_LOADED = '[User] User loaded';
export const USER_UNLOADED = '[User] User unloaded';
export const START_LOADING = '[User] start loading';
export const STOP_LOADING = '[User] stop loading';
export const USER_LOGGED_IN = '[User] logged in';
export const USER_LOGOUT = '[User] logout in';
export const SET_AVAILABLE_ONLINE_USERS = '[User] Set available online users';
export const CONTACT_SET_DATA_SOURCE = '[User] set data source';
export const CONTACT_UPDATE_DATA_SOURCE = '[User] update data source';
export const SET_MERCH_GROUP_NAMES = '[User] Set merchs names';
export const SET_SELECTED_GROUP_MEMBERS = '[User] Set group members';
export const SET_SELECTED_GROUP_MANAGERS = '[User] Set group managers';
export const SET_SELECTED_PROJECT_KIND_GROUP_MANAGERS = '[User] Set selected project kind group managers';
export const SET_SEARCH_USERS = '[User] Set search users';
export const SET_USER_CONTACTS = '[User] Set users contacts';
export const SET_USER_CONTACT_DETAILS = '[User] Set users contact details';
export const SET_SELECTED_USER = '[User] Set selected user';
export const SET_USER_ADDITIONAL_DATA = '[User] Set user additional data';
export const SET_SELECTED_GROUP_IDS = '[User] Set user selected group ids';
export const SET_RELOAD_DATA_GRID = '[User] Set reload data grid';

export const START_EDITING = '[User] Start editing';
export const STOP_EDITING = '[User] Stop editing';

export class SetAvailableOnlineUsers implements Action {
  readonly type = SET_AVAILABLE_ONLINE_USERS;
  constructor(public payload: AppHubConnection[]) { }
}
export class SetUserPhoto implements Action {
  readonly type = SET_USER_PHOTO;
  constructor(public payload: { id: string, avatar: string }) { }
}
export class UserLoggedIn implements Action {
  readonly type = USER_LOGGED_IN;
  constructor(public payload: AppHubConnection) { }
}
export class UserLogout implements Action {
  readonly type = USER_LOGOUT;
  constructor(public payload: AppHubConnection) { }
}
export class AddNewUsers implements Action {
  readonly type = ADD_NEW_USER_RANGE;
  constructor(public payload: User[]) { }
}
export class SetAvailableUsers implements Action {
  readonly type = SET_AVAILABLE_USERS;
  constructor(public payload: { data: User[], totalCount: number }) { }
}
export class SetSelectedUserAdditonalData implements Action {
  readonly type = SET_USER_ADDITIONAL_DATA;
  constructor(public payload: UserAdditionalData) { }
}
export class AddNewUser implements Action {
  readonly type = ADD_NEW_USER;
  constructor(public payload: User) { }
}
export class RemoveUser implements Action {
  readonly type = REMOVE_USER;
  constructor(public payload: string) { }
}
export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public payload: User) { }
}
export class UpdateCommonUsers implements Action {
  readonly type = UPDATE_COMMON_USERS;
  constructor(public payload: UserCommonRoles) { }
}
export class UpdateUserBlockedFlag implements Action {
  readonly type = UPDATE_USER_BLOCKED_FLAG;
  constructor(public payload: User) { }
}
export class UpdateUsers implements Action {
  readonly type = UPDATE_USER_RANGE;
  constructor(public payload: User[]) { }
}
export class UserLoaded implements Action {
  readonly type = USER_LOADED;
}
export class UserUnloaded implements Action {
  readonly type = USER_UNLOADED;
}
export class StartLoading implements Action {
  readonly type = START_LOADING;
}
export class StopLoading implements Action {
  readonly type = STOP_LOADING;
}

export class StartEditing implements Action {
  readonly type = START_EDITING;
}

export class StopEditing implements Action {
  readonly type = STOP_EDITING;
}

export class SetDataSouce implements Action {
  readonly type = CONTACT_SET_DATA_SOURCE;
  constructor(public payload: Contact[]) { }
}

export class UpdateDataSource implements Action {
  readonly type = CONTACT_UPDATE_DATA_SOURCE;
  constructor(public payload: User[]) { }
}

export class SetAvailableManagers implements Action {
  readonly type = SET_AVAILABLE_MANAGERS;
  constructor(public payload: UserBase[]) { }
}
export class SetAvailableLocalizationCoordinators implements Action {
  readonly type = SET_AVAILABLE_LOCALIZATION_COORDINATORS;
  constructor(public payload: UserBase[]) { }
}
export class SetAvailablePmtCoordinators implements Action {
  readonly type = SET_AVAILABLE_PMT_COORDINATORS;
  constructor(public payload: UserBase[]) { }
}
export class SetAvailablePromCoordinators implements Action {
  readonly type = SET_AVAILABLE_PROM_COORDINATORS;
  constructor(public payload: UserBase[]) { }
}
export class SetAvailablePmtMerchandisers implements Action {
  readonly type = SET_AVAILABLE_PMT_MERCHANDISERS;
  constructor(public payload: UserBase[]) { }
}
export class SetAvailableHostesses implements Action {
  readonly type = SET_AVAILABLE_HOSTESSES;
  constructor(public payload: UserBase[]) { }
}
export class SetAvailableSupervisors implements Action {
  readonly type = SET_AVAILABLE_SUPERVISORS;
  constructor(public payload: UserBase[]) { }
}

export class SetAvailableManagersV2 implements Action {
  readonly type = SET_AVAILABLE_MANAGERS_V2;
  constructor(public payload: UserListItem[]) { }
}
export class SetAvailableLocalizationCoordinatorsV2 implements Action {
  readonly type = SET_AVAILABLE_LOCALIZATION_COORDINATORS_V2;
  constructor(public payload: UserListItem[]) { }
}
export class SetAvailablePmtCoordinatorsV2 implements Action {
  readonly type = SET_AVAILABLE_PMT_COORDINATORS_V2;
  constructor(public payload: UserListItem[]) { }
}
export class SetAvailablePromCoordinatorsV2 implements Action {
  readonly type = SET_AVAILABLE_PROM_COORDINATORS_V2;
  constructor(public payload: UserListItem[]) { }
}
export class SetAvailablePmtMerchandisersV2 implements Action {
  readonly type = SET_AVAILABLE_PMT_MERCHANDISERS_V2;
  constructor(public payload: UserListItem[]) { }
}
export class SetAvailableHostessesV2 implements Action {
  readonly type = SET_AVAILABLE_HOSTESSES_V2;
  constructor(public payload: UserListItem[]) { }
}
export class SetAvailableSupervisorsV2 implements Action {
  readonly type = SET_AVAILABLE_SUPERVISORS_V2;
  constructor(public payload: UserListItem[]) { }
}

export class SetSelectedMerchGroupNames implements Action {
  readonly type = SET_MERCH_GROUP_NAMES;
  constructor(public payload: string[]) { }
}
export class SetSelectedGroupMembers implements Action {
  readonly type = SET_SELECTED_GROUP_MEMBERS;
  constructor(public payload: UserGroupMembersData) { }
}
export class SetSelectedGroupManagers implements Action {
  readonly type = SET_SELECTED_GROUP_MANAGERS;
  constructor(public payload: UserGroupManagersData) { }
}
export class SetSearchUsers implements Action {
  readonly type = SET_SEARCH_USERS;
  constructor(public payload: UserListItem[]) { }
}
export class SetUsersContacts implements Action {
  readonly type = SET_USER_CONTACTS;
  constructor(public payload: UserContactsData) { }
}
export class SetUserContactDetails implements Action {
  readonly type = SET_USER_CONTACT_DETAILS;
  constructor(public payload: UserContactDetails) { }
}
export class SetSelectedUser implements Action {
  readonly type = SET_SELECTED_USER;
  constructor(public payload: User) { }
}
export class SetSelectedProjectKindGroupManagers implements Action {
  readonly type = SET_SELECTED_PROJECT_KIND_GROUP_MANAGERS;
  constructor(public payload: ProjectKindGroupManagersResource) { }
}
export class SetSelectedGroupIds implements Action {
  readonly type = SET_SELECTED_GROUP_IDS;
  constructor(public payload: number[]) { }
}

export class SetReloadDataGrid implements Action {
  readonly type = SET_RELOAD_DATA_GRID;
  constructor(public payload: boolean) { }
}

export type UserActions =
  AddNewUsers
  | SetUserPhoto
  | SetDataSouce
  | UpdateDataSource
  | SetAvailableOnlineUsers
  | UserLoggedIn
  | UserLogout
  | SetAvailableUsers
  | AddNewUser
  | RemoveUser
  | UpdateUser
  | UpdateCommonUsers
  | UpdateUserBlockedFlag
  | UpdateUsers
  | UserLoaded
  | StopLoading
  | StartLoading
  | StartEditing
  | StopEditing
  | UserUnloaded
  | SetAvailableManagers
  | SetAvailableLocalizationCoordinators
  | SetAvailablePmtCoordinators
  | SetAvailablePromCoordinators
  | SetAvailablePmtMerchandisers
  | SetAvailableHostesses
  | SetAvailableSupervisors
  | SetSelectedMerchGroupNames
  | SetSelectedGroupMembers
  | SetSelectedGroupManagers
  | SetSearchUsers
  | SetUsersContacts
  | SetUserContactDetails
  
  | SetAvailableManagersV2
  | SetAvailableLocalizationCoordinatorsV2
  | SetAvailablePmtCoordinatorsV2
  | SetAvailablePromCoordinatorsV2
  | SetAvailablePmtMerchandisersV2
  | SetAvailableHostessesV2
  | SetAvailableSupervisorsV2
  | SetSelectedUser
  | SetSelectedUserAdditonalData
  | SetSelectedProjectKindGroupManagers
  | SetSelectedGroupIds
  | SetReloadDataGrid
  ;
