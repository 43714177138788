import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UIService {

    /*
        https://github.com/scttcper/ngx-toastr
    */

    constructor(private snackBar: MatSnackBar) { }

    openSnack(title: string, action: string = null, duration: number = 4000, isError: boolean = null): void {
        this.snackBar.open(title, action, {
            duration: duration,
            panelClass: isError?['alert', 'alert-danger']: null
        });
    }
}
