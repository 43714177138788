import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationData } from '../auth/user.model';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '../shared/ui.service';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from '../services/company/company.service';
import * as root from 'src/app/app.reducer';
import * as CompanyActions from 'src/app/redux/company/company.actions';
import * as GroupActions from 'src/app/redux/group/group.actions';
import * as UserActions from 'src/app/redux/user/user.actions';
import * as RoleActions from 'src/app/redux/role/role.actions';
import * as AuthActions from 'src/app/auth/auth.actions';
import * as CommonActions from 'src/app/shared/common.actions';
import * as ProjectKindActions from 'src/app/redux/contractor/project-kind.actions';
import * as ContractorActions from 'src/app/redux/contractor/contractor.actions';
import * as ProductActions from 'src/app/redux/project/product.actions';
import * as ProjectActions from 'src/app/redux/project/project.actions';
import * as OrderActions from 'src/app/redux/order/order.actions';
import * as ServiceActions from 'src/app/redux/service/service.actions';
import * as OrderTaskActions from 'src/app/redux/order-task/order-task.actions';
import * as EmployeeFormActions from 'src/app/redux/employee-form/employee-form.actions';
import * as CustomFieldActions from 'src/app/redux/custom-fields/custom-field.actions';
import * as AccessConfigActions from 'src/app/redux/access-config/access-config.actions';
import * as LocalizationActions from 'src/app/redux/localization/localization.actions';
import * as SurveyActions from 'src/app/redux/survey/survey.actions';
import * as CentralPromotionActions from 'src/app/redux/central-promotion/central-promotion.actions';
import { Store } from '@ngrx/store';
import { Group } from '../redux/group/group.model';
import { User, UserBase, UserCommonRoles } from '../redux/user/user.model';
import { IRole } from '../_models/role.model';
import { AppHubConnection } from '../redux/user/hub_connection.model';
import { Company } from '../redux/company/company.model';
import { EDictionary } from '../shared/common.reducer';
import { GroupHrElement } from '../redux/company/group-hr-element.model';
import { ProjectKind, ProjectKindAttachment } from '../redux/contractor/project-kind.model';
import { ProjectKindRole } from '../redux/contractor/project-kind-role.model';
import { AccessConfig } from '../redux/access-config/access-config.model';
import { Contractor } from '../redux/contractor/contractor.model';
import { Product, ProductPhoto } from '../redux/project/product.model';
import { ContractorProjectKind } from '../redux/contractor/contractor-project-kind.model';
import { Project } from '../redux/project/project.model';
import { CustomPage, CustomField } from '../redux/custom-fields/custom-filed.model';
import { Market, Localization } from '../redux/localization/localization.model';
import { DocumentApprovement } from '../redux/employee-form/document-approvement.model';
import { Order } from '../redux/order/order.model';
import { OrderTask, OrderTaskStatus, TaskPhoto } from '../redux/order-task/order-task.model';
import { Service } from '../redux/service/service.model';
import { SurveyPattern } from '../redux/survey/survey.model';
import { CentralPromotion } from '../redux/central-promotion/central-promotion.model';
import { centralPromotions } from '../redux/central-promotion/central-promotion.reducer';
import { EmployeeForm } from '../redux/employee-form/employee-form.model';

@Injectable()
export class AppHub {
    private _onDestroy = new Subject<void>();
    private hubConnection: HubConnection | undefined;

    hubConnected = false;
    hubDestroyed = false;

    reloadUsersTrigger = new EventEmitter();
    connectionReconected = new EventEmitter();
    reloadDataTrigger = new EventEmitter();
    reloadContactModuleTrigger = new EventEmitter();
    reloadHrComponentsTrigger = new EventEmitter();
    reloadTranslationsTrigger = new EventEmitter();
    reloadContractorModuleTrigger = new EventEmitter();
    reloadProjectModuleTrigger = new EventEmitter();
    reloadOrderModuleTrigger = new EventEmitter();
    reloadServiceModuleTrigger = new EventEmitter();
    reloadOrderTaskModuleTrigger = new EventEmitter();
    orderTasksUpdated = new EventEmitter();
    orderTasksUpdatedForUser = new EventEmitter();
    ordersUpdated = new EventEmitter();
    serviceUpdated = new EventEmitter(true);
    serviceUpdatedForUser = new EventEmitter(true);
    surveyPatternUpdated = new EventEmitter(true);
    reloadEmployeeFormModuleTrigger = new EventEmitter();
    reloadAccessConfigsTrigger = new EventEmitter();
    reloadCustomFieldsModuleTrigger = new EventEmitter();
    reloadLocalizationModuleTrigger = new EventEmitter();
    reloadCentralPromotionModuleTrigger = new EventEmitter();
    reloadHrHistoryTrigger = new EventEmitter<{ componentId: number, userId: string }>();
    userReportsUpdated = new EventEmitter();
    contactsUpdated = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private uiService: UIService,
        private companyService: CompanyService,
        private store: Store<root.IRootState>
    ) { }

    init() {
        if (!this.hubConnected) {
            this._onDestroy = new Subject<void>();
            this._initHub();
        }
    }

    private _initHub() {
        console.log('[HUB] Init');
        this.hubDestroyed = false;
        if (this.hubConnected) { return; }
        this._startHub();
    }

    get currentUser(): ApplicationData {
        const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser;
    }

    _startHub() {
        let currentUser = this.currentUser;
        let tokenValue = '';
        if (currentUser && currentUser.token) {
            tokenValue = '?token=' + currentUser.token;
        }

        return new Promise((r, reject) => {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(environment.hub + '/hub' + tokenValue, signalR.HttpTransportType.WebSockets)
                .configureLogging(signalR.LogLevel.None)
                .build();
            this.hubConnection.onclose(() => {
                this.hubConnected = false;
                // remove all online users
                this.store.dispatch(new UserActions.SetAvailableOnlineUsers([]));
                if (!this.hubDestroyed) {
                    console.log('%c [HUB] Hub connection closed ', 'background-color: red;color: white;');
                    this.translate.get('CONNECTION_ERROR')
                        .toPromise()
                        .then((translations) => {
                            this.uiService.openSnack(translations, null, 10_000);
                        });
                    this._tryStartHub();
                }
            });
            this.hubConnection.start()
                .then(() => {
                    this.hubConnected = true;
                    console.log('%c [HUB] Hub connected ', 'background: lawngreen;');
                    // this.userService.getOnline();
                    r();
                })
                .catch((err) => {
                    console.error('[HUB] Hub connection failed');
                    // console.error(err);
                    this.hubConnected = false;
                    reject();
                });
            this.hubConnection.on('reload_company', (payload: string) => {
                this.companyService.findById(payload);
            });
            this.hubConnection.on('reload_data', () => {
                this.reloadDataTrigger.next();
            });
            this.hubConnection.on('show_toast_translate', (payload: string) => {
                this.translate.get(payload)
                    .toPromise()
                    .then((translations) => {
                        this.uiService.openSnack(translations, 'Ok', 10_000);
                    });
            });
            this.hubConnection.on('company_create', (payload: string) => {
                console.log('[HUB] Company create');
                this.store.dispatch(new CompanyActions.Add(JSON.parse(payload)));
            });
            this.hubConnection.on('company_update', (payload: string) => {
                const company: Company = JSON.parse(payload);
                console.log('[HUB] Company update');
                currentUser = this.currentUser;
                if (currentUser.company && currentUser.company.id === company.id) {
                    currentUser.company = company;
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    this.store.dispatch(new AuthActions.SetCurrentCompany(company));
                }
                this.store.dispatch(new CompanyActions.Update(JSON.parse(payload)));
            });
            this.hubConnection.on('group_delete', (groupId) => {
                console.log('[HUB] Delete group');
                this.store.dispatch(new GroupActions.Remove(groupId));
            });
            this.hubConnection.on('group_create', (payload: string) => {
                const group: Group = JSON.parse(payload);
                console.log('[HUB] Create group');
                this.store.dispatch(new GroupActions.Add(group));
            });
            this.hubConnection.on('add_group_manager', (payload: string) => {
                const user: UserBase = JSON.parse(payload);
                console.log('[HUB] add group manager');
                this.store.dispatch(new GroupActions.AddManager(user));
            });
            this.hubConnection.on('remove_group_manager', (userId) => {
              console.log('[HUB] Delete manager group');
              this.store.dispatch(new GroupActions.RemoveManager(userId));
          });
            this.hubConnection.on('group_update', (payload: string) => {
              const group: Group = JSON.parse(payload);
              console.log('[HUB] Update group');
              this.store.dispatch(new GroupActions.Update(group));
          });
            this.hubConnection.on('user_logged_in', (payload: string) => {
                const connection: AppHubConnection = JSON.parse(payload);
                // console.log('[HUB] User logged in');
                this.store.dispatch(new UserActions.UserLoggedIn(connection));
            });
            this.hubConnection.on('user_logout', (payload: string) => {
                const connection: AppHubConnection = JSON.parse(payload);
                // console.log('[HUB] User logout');
                this.store.dispatch(new UserActions.UserLogout(connection));
            });
            this.hubConnection.on('user_create', (payload: string) => {
                console.log('[HUB] User create');
                const user: User = JSON.parse(payload);
                this.store.dispatch(new UserActions.AddNewUser(user));

            });
            this.hubConnection.on('user_update', (payload: string) => {
                const user: User = JSON.parse(payload);
                console.log('[HUB] User update');
                this.store.dispatch(new UserActions.UpdateUser(user));
            });
            this.hubConnection.on('user_create_range', (payload: string) => {
                const users: User[] = JSON.parse(payload);
                if (users.length > 0) {
                    console.log('[HUB] Users create');
                    this.store.dispatch(new UserActions.AddNewUsers(users));
                }
            });
            this.hubConnection.on('user_update_range', (payload: string) => {
                const users: User[] = JSON.parse(payload);
                if (users.length > 0) {
                    console.log('[HUB] Users update');
                    this.store.dispatch(new UserActions.UpdateUsers(users));
                }
            });
            this.hubConnection.on('user_blocked', (payload: string) => {
                const user: User = JSON.parse(payload);
                if (user) {
                    console.log('[HUB] User block flag update');
                    this.store.dispatch(new UserActions.UpdateUserBlockedFlag(user));
                }
            });
            this.hubConnection.on('user_delete', (payload: string) => {
              console.log('[HUB] Delete user');
              this.store.dispatch(new UserActions.RemoveUser(payload));
          });
            this.hubConnection.on('role_create', (payload: string) => {
                const role: IRole = JSON.parse(payload);
                console.log('[HUB] Create role');
                this.store.dispatch(new RoleActions.AddNewRole(role));
            });
            this.hubConnection.on('role_update', (payload: string) => {
                const role: IRole = JSON.parse(payload);
                console.log('[HUB] Update role');
                this.store.dispatch(new RoleActions.UpdateRole(role));
            });
            this.hubConnection.on('role_delete', (payload: number) => {
                console.log('[HUB] Delete role');
                this.store.dispatch(new RoleActions.RemoveRole(payload));
            });
            this.hubConnection.on('import_start_get_symfonia', (_) => {
                this.store.dispatch(new AuthActions.ImportStartGettingTheDataFromTheSymfonia());
            });
            this.hubConnection.on('import_stop_get_symfonia', (_) => {
                this.store.dispatch(new AuthActions.ImportStopGettingTheDataFromTheSymfonia());
            });
            this.hubConnection.on('import_start_build_structure', (_) => {
                this.store.dispatch(new AuthActions.ImportStartBuildStructure());
            });
            this.hubConnection.on('import_stop', (_) => {
                this.store.dispatch(new AuthActions.ImportStop());
            });
            this.hubConnection.on('import_start_users', (_) => {
                this.store.dispatch(new AuthActions.ImportStartUsers());
            });
            this.hubConnection.on('import_start_erp', (_) => {
                this.store.dispatch(new AuthActions.ImportStartErp());
            });
            this.hubConnection.on('import_start_groups', (_) => {
                this.store.dispatch(new AuthActions.ImportStartGroups());
            });
            this.hubConnection.on('set_import_progress', (payload: string) => {
                this.store.dispatch(new AuthActions.SetImportProgress(+payload));
            });
            this.hubConnection.on('add_import_logs', (payload) => {
                const logs = JSON.parse(payload);
                this.store.dispatch(new AuthActions.AddImportLogs(logs));
            });
            this.hubConnection.on('add_import_log', (payload) => {
                const log = JSON.parse(payload);
                this.store.dispatch(new AuthActions.AddImportLog(log));
            });
            // this.hubConnection.on('hrComponent', (payload) => {

            // })
            this.hubConnection.on('translation_add', (payload) => {
                const translation: EDictionary = JSON.parse(payload);
                if (translation) {
                    console.log('[HUB] translation added');
                    this.store.dispatch(new CommonActions.AddDictionary(translation));
                }
            });
            this.hubConnection.on('translation_update', (payload) => {
                const translation: EDictionary = JSON.parse(payload);
                if (translation) {
                    console.log('[HUB] translation updated');
                    this.store.dispatch(new CommonActions.UpdateDictionary(translation));
                }
            });
            this.hubConnection.on('translation_delete', (payload) => {
                if (+payload) {
                    console.log('[HUB] translation deleted');
                    this.store.dispatch(new CommonActions.RemoveDictionay(+payload));
                }
            });
            this.hubConnection.on('user_photo_was_loaded', (payload) => {
                const photo: { id: string, avatar: string } = JSON.parse(payload);
                if (photo) {
                    console.log('[HUB] User photo updating');
                    this.store.dispatch(new UserActions.SetUserPhoto(photo));
                    // ngx-avatar doesnt refresh photo yet
                    // this.store.dispatch(new AuthActions.SetUserAvatar(photo));
                }
            });
            this.hubConnection.on('reloadContactModuleConfig', (payload) => {
                this.reloadContactModuleTrigger.next();
            });
            this.hubConnection.on('reloadHrComponents', (payload) => {
                this.reloadHrComponentsTrigger.next();
            });
            this.hubConnection.on('reload_users', (_) => {
                this.reloadUsersTrigger.next();
            });
            this.hubConnection.on('hrComponentGroupAdd', (payload) => {
                const group: GroupHrElement = JSON.parse(payload);
                if (group) {
                    console.log('[HUB] hr group added');
                    this.store.dispatch(new CompanyActions.AddHrComponentGroup(group));
                }
            });
            this.hubConnection.on('hrComponentGroupRemove', (payload) => {
                const group: GroupHrElement = JSON.parse(payload);
                if (group) {
                    console.log('[HUB] hr group removed');
                    this.store.dispatch(new CompanyActions.RemoveHrComponentGroup(group));
                }
            });
            this.hubConnection.on('hrHistoryUpdated', (payload) => {
                const data: { componentId: number, userId: string } = JSON.parse(payload);
                if (data) {
                    this.reloadHrHistoryTrigger.next(data);
                }
            });
            this.hubConnection.on('reloadTranslations', (payload) => {
                console.log('[HUB] Reload translations trigger.');
                this.reloadTranslationsTrigger.next();
            });
            this.hubConnection.on('project_kind_create', (payload: string) => {
              const projectKind: ProjectKind = JSON.parse(payload);
              console.log('[HUB] Create project kind');
              this.store.dispatch(new ProjectKindActions.Add(projectKind));
            });
            this.hubConnection.on('project_kind_update', (payload: string) => {
              const projectKind: ProjectKind = JSON.parse(payload);
              console.log('[HUB] Update project kind');
              this.store.dispatch(new ProjectKindActions.Update(projectKind));
            });
            this.hubConnection.on('project_kind_attachment_create', (payload: string) => {
              const projectKindAttachment: ProjectKindAttachment = JSON.parse(payload);
              console.log('[HUB] Create project kind attachment');
              this.store.dispatch(new ProjectKindActions.AddProjectKindAttachment(projectKindAttachment));
            });
            this.hubConnection.on('project_kind_attachment_delete', (projectKindAttachmentId) => {
              console.log('[HUB] Delete project kind attachment');
              this.store.dispatch(new ProjectKindActions.RemoveProjectKindAttachment(projectKindAttachmentId));
            });
            this.hubConnection.on('project_kind_role_create', (payload) => {
              const projectKindRole: ProjectKindRole = JSON.parse(payload);
              console.log('[HUB] Create project kind role');
              this.store.dispatch(new ProjectKindActions.AddProjectKindRole(projectKindRole));
            });
            this.hubConnection.on('project_kind_delete', (projectKindId) => {
              console.log('[HUB] Delete project kind');
              this.store.dispatch(new ProjectKindActions.Remove(projectKindId));
            });
            this.hubConnection.on('project_kind_role_delete', (projectKindId, applicationRoleId) => {
              console.log('[HUB] Delete project kind role');
              this.store.dispatch(new ProjectKindActions.RemoveProjectKindRole(projectKindId, applicationRoleId));
            });
            this.hubConnection.on('access_configuration_create', (payload) => {
              const accessConfig: AccessConfig = JSON.parse(payload);
              console.log('[HUB] Create access config');
              this.store.dispatch(new AccessConfigActions.Add(accessConfig));
            });
            this.hubConnection.on('access_configuration_delete', (accessConfigId) => {
              console.log('[HUB] Delete access config');
              this.store.dispatch(new AccessConfigActions.Remove(accessConfigId));
            });
            this.hubConnection.on('contractor_create', (payload) => {
              const contractor: Contractor = JSON.parse(payload);
              console.log('[HUB] Create contractor');
              this.store.dispatch(new ContractorActions.Add(contractor));
            });
            this.hubConnection.on('contractor_delete', (contractorId) => {
              console.log('[HUB] Delete contractor');
              this.store.dispatch(new ContractorActions.Remove(contractorId));
            });
            this.hubConnection.on('contractor_update', (payload) => {
              const contractor: Contractor = JSON.parse(payload);
              console.log('[HUB] Update contractor');
              this.store.dispatch(new ContractorActions.Update(contractor));
            });
            this.hubConnection.on('contractor_project_kind_create', (payload) => {
              const contractorProjectKind: ContractorProjectKind = JSON.parse(payload);
              console.log('[HUB] Create contractor project kind');
              this.store.dispatch(new ContractorActions.AddContractorProjectKind(contractorProjectKind));
            });
            this.hubConnection.on('contractor_project_kind_delete', (contractorId, projectKindId) => {
              console.log('[HUB] Delete contractor project kind');
              this.store.dispatch(new ContractorActions.RemoveContractorProjectKind(contractorId, projectKindId));
            });
            this.hubConnection.on('project_create', (payload) => {
              const project: Project = JSON.parse(payload);
              console.log('[HUB] Create project');
              this.store.dispatch(new ProjectActions.Add(project));
            });
            this.hubConnection.on('project_delete', (projectId) => {
              console.log('[HUB] Delete project');
              this.store.dispatch(new ProjectActions.Remove(projectId));
            });
            this.hubConnection.on('project_update', (payload) => {
              const project: Project = JSON.parse(payload);
              console.log('[HUB] Update project');
              this.store.dispatch(new ProjectActions.Update(project));
            });
            this.hubConnection.on('order_create', (payload) => {
              const order: Order = JSON.parse(payload);
              console.log('[HUB] Create order');
              this.store.dispatch(new OrderActions.Add(order));
            });
            this.hubConnection.on('order_delete', (orderId) => {
              console.log('[HUB] Delete order');
              this.store.dispatch(new OrderActions.Remove(orderId));
            });
            this.hubConnection.on('order_update', (payload) => {
              const order: Order = JSON.parse(payload);
              console.log('[HUB] Update order');
              this.store.dispatch(new OrderActions.Update(order));
            });
            this.hubConnection.on('order_update_notify', () => {
              this.ordersUpdated.next();
            });            
            this.hubConnection.on('service_create', (payload) => {
              console.log('[HUB] Create service');
              const service: Service = JSON.parse(payload);
              this.store.dispatch(new ServiceActions.Add(service));
            });
            this.hubConnection.on('service_delete', (serviceId) => {
              console.log('[HUB] Delete service');
              this.store.dispatch(new ServiceActions.Remove(serviceId));
            });
            this.hubConnection.on('service_update', (payload) => {
              const service: Service = JSON.parse(payload);
              console.log('[HUB] Update service');
              this.store.dispatch(new ServiceActions.Update(service));
            });
            this.hubConnection.on('service_update_notify', () => {
              console.log('service_update_notify');
              this.serviceUpdated.next();
            });
            this.hubConnection.on('service_update_notify_for_user', () => {
              console.log('service_update_notify_for_user');
              this.serviceUpdatedForUser.next();
            });
            this.hubConnection.on('survey_pattern_update_notify', () => {
              console.log('survey_pattern_update_notify');
              this.surveyPatternUpdated.next();
            });
            this.hubConnection.on('market_create', (payload) => {
              const market: Market = JSON.parse(payload);
              console.log('[HUB] Create market');
              this.store.dispatch(new LocalizationActions.AddMarket(market));
            });
            this.hubConnection.on('market_update', (payload) => {
              const market: Market = JSON.parse(payload);
              console.log('[HUB] Update market');
              this.store.dispatch(new LocalizationActions.UpdateMarket(market));
            });
            this.hubConnection.on('market_delete', (marketId) => {
              console.log('[HUB] Delete market');
              this.store.dispatch(new LocalizationActions.RemoveMarket(marketId));
            });

            this.hubConnection.on('localization_create', (payload) => {
              const localization: Localization = JSON.parse(payload);
              console.log('[HUB] Create localization');
              this.store.dispatch(new LocalizationActions.AddLocalization(localization));
            });
            this.hubConnection.on('localization_update', (payload) => {
              const localization: Localization = JSON.parse(payload);
              console.log('[HUB] Update localization');
              this.store.dispatch(new LocalizationActions.UpdateLocalization(localization));
            });
            this.hubConnection.on('localization_delete', (localizationId) => {
              console.log('[HUB] Delete localization');
              this.store.dispatch(new LocalizationActions.RemoveLocalization(localizationId));
            });
            this.hubConnection.on('custom_page_create', (payload) => {
              const page: CustomPage = JSON.parse(payload);
              console.log('[HUB] Create custom page');
              this.store.dispatch(new CustomFieldActions.AddPage(page));
            });
            this.hubConnection.on('custom_field_create', (payload) => {
              const field: CustomField = JSON.parse(payload);
              console.log('[HUB] Create custom field');
              this.store.dispatch(new CustomFieldActions.AddField(field));
            });
            this.hubConnection.on('custom_page_delete', (pageId) => {
              console.log('[HUB] Delete custom page');
              this.store.dispatch(new CustomFieldActions.RemovePage(pageId));
            });
            this.hubConnection.on('custom_field_delete', (fieldId) => {
              console.log('[HUB] Delete custom field');
              this.store.dispatch(new CustomFieldActions.RemoveField(fieldId));
            });
            this.hubConnection.on('product_create', (payload) => {
              const product: Product = JSON.parse(payload);
              console.log('[HUB] Create product');
              this.store.dispatch(new ProductActions.Add(product));
            });
            this.hubConnection.on('product_update', (payload) => {
              const product: Product = JSON.parse(payload);
              console.log('[HUB] Update product');
              this.store.dispatch(new ProductActions.Update(product));
            });
            this.hubConnection.on('products_update', (payload) => {
              const products: Product[] = JSON.parse(payload);
              console.log('[HUB] Update products list');
              this.store.dispatch(new ProductActions.SetAvailableProducts(products));
            });
            this.hubConnection.on('product_delete', (productId) => {
              console.log('[HUB] Delete product');
              this.store.dispatch(new ProductActions.Remove(productId));
            });
            this.hubConnection.on('product_photo_upload', (payload) => {
              const photo: ProductPhoto = JSON.parse(payload);
              console.log('[HUB] Upload product photo');
              this.store.dispatch(new ProductActions.AddPhoto(photo));
            });
            this.hubConnection.on('product_photo_delete', (photoId) => {
              console.log('[HUB] Delete product photo');
              this.store.dispatch(new ProductActions.RemovePhoto(photoId));
            });
            this.hubConnection.on('order_task_create', (payload) => {
              const orderTask: OrderTask = JSON.parse(payload);
              this.store.dispatch(new OrderTaskActions.Add(orderTask));
              this.store.dispatch(new OrderTaskActions.AddMyTask(orderTask));
            });
            this.hubConnection.on('order_task_create_range', (payload) => {
              const orderTasks: OrderTask[] = JSON.parse(payload);
              this.store.dispatch(new OrderTaskActions.AddRange(orderTasks));
            });
            this.hubConnection.on('order_task_update', (payload) => {
              const orderTask: OrderTask = JSON.parse(payload);
              this.store.dispatch(new OrderTaskActions.Update(orderTask));
              this.store.dispatch(new OrderTaskActions.UpdateMyTask(orderTask));
              if (orderTask.status !== OrderTaskStatus.New) {
                this.store.dispatch(new OrderTaskActions.RemoveMyTask(orderTask.id));
              }
            });
            this.hubConnection.on('order_task_update_notify', () => {
              console.log('order_task_update_notify');
              this.orderTasksUpdated.next();
            });
            this.hubConnection.on('order_task_update_notify_for_user', () => {
              console.log('order_task_update_notify_for_user');
              this.orderTasksUpdatedForUser.next();
            });
            this.hubConnection.on('order_task_delete', (taskId) => {
              console.log('[HUB] Delete task');
              this.store.dispatch(new OrderTaskActions.Remove(taskId));
              this.store.dispatch(new OrderTaskActions.RemoveMyTask(taskId));
            });
            this.hubConnection.on('order_task_photo_upload', (payload) => {
              const photo: TaskPhoto = JSON.parse(payload);
              console.log('[HUB] Upload task photo');
              this.store.dispatch(new OrderTaskActions.AddPhoto(photo));
            });
            this.hubConnection.on('order_task_photo_delete', (photoId) => {
              console.log('[HUB] Delete task photo');
              this.store.dispatch(new OrderTaskActions.RemovePhoto(photoId));
            });
            this.hubConnection.on('common_users_update', (payload) => {
              const userRoles: UserCommonRoles = JSON.parse(payload);
              console.log('[HUB] common_users_update update');
              this.store.dispatch(new UserActions.UpdateCommonUsers(userRoles));
           });
            this.hubConnection.on('survey_pattern_create', (payload) => {
              const surveyPattern: SurveyPattern = JSON.parse(payload);
              console.log('[HUB] Create survey pattern');
              this.store.dispatch(new SurveyActions.Add(surveyPattern));
            });
            this.hubConnection.on('survey_pattern_update', (payload) => {
              const surveyPattern: SurveyPattern = JSON.parse(payload);
              console.log('[HUB] Update survey pattern');
              this.store.dispatch(new SurveyActions.Update(surveyPattern));
            });
            this.hubConnection.on('survey_pattern_delete', (surveyPatternId) => {
              console.log('[HUB] Delete survey pattern');
              this.store.dispatch(new SurveyActions.Remove(surveyPatternId));
            });
            this.hubConnection.on('central_promotion_create', (payload) => {
              const centralPromotion: CentralPromotion = JSON.parse(payload);
              this.store.dispatch(new CentralPromotionActions.Add(centralPromotion));
            });
            this.hubConnection.on('central_promotion_update', (payload) => {
              const centralPromotion: CentralPromotion = JSON.parse(payload);
              this.store.dispatch(new CentralPromotionActions.Update(centralPromotion));
            });
            this.hubConnection.on('central_promotion_delete', (centralPromotionId) => {
              console.log('[HUB] Delete central promotion');
              this.store.dispatch(new CentralPromotionActions.Remove(centralPromotionId));
            });
            this.hubConnection.on('employee_form_create', (payload) => {
              console.log('[HUB] Create employee form');
              const employeeForm: EmployeeForm = JSON.parse(payload);
              this.store.dispatch(new EmployeeFormActions.Add(employeeForm));
            });
            this.hubConnection.on('employee_form_delete', (employeeFormId) => {
              console.log('[HUB] Delete employee form');
              this.store.dispatch(new EmployeeFormActions.Remove(employeeFormId));
            });
            this.hubConnection.on('employee_form_update', (payload) => {
              const employeeForm: EmployeeForm = JSON.parse(payload);
              console.log('[HUB] Update employee form');
              this.store.dispatch(new EmployeeFormActions.Update(employeeForm));
            });
            this.hubConnection.on('document_approvement_create', (payload) => {
              const document: DocumentApprovement = JSON.parse(payload);
              console.log('[HUB] Create document approvement');
              this.store.dispatch(new EmployeeFormActions.AddDocument(document));
            });
            this.hubConnection.on('document_approvement_update', (payload) => {
              const document: DocumentApprovement = JSON.parse(payload);
              console.log('[HUB] Update document approvement');
              this.store.dispatch(new EmployeeFormActions.UpdateDocument(document));
            });
            this.hubConnection.on('user_report_update_notify', () => {
              console.log('user_report_update_notify');
              this.userReportsUpdated.next();
            });
            this.hubConnection.on('contacts_update_notify', () => {
              console.log('contacts_update_notify');
              this.contactsUpdated.next();
            });
        });
    }

    _tryStartHub() {
        this.store.dispatch(new AuthActions.ImportStop());
        console.log(`[HUB] Trying to reconnect the hub`);
        setTimeout(() => {
            this._startHub()
                .then(() => {
                    this.connectionReconected.next();
                    this.translate.get('CONNECTION_REFRESHED')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text, null, 10_000);
                        });
                })
                .catch(() => { this._tryStartHub(); });
        }, 10_000);
    }

    cancelSubscriptions() {
        console.log('[HUB] cancel subscriptions');
        this._onDestroy.next();
        this._onDestroy.complete();
        this.hubDestroyed = true;
        if (this.hubConnected) {
            this.hubConnected = false;
            this.hubConnection.stop()
                .then(() => { console.log('%c [HUB] Hub disconnected ', 'background: orange;color: white;'); })
                .catch((error) => { console.error('[HUB] Error while disconnecting', error); });
        }
    }
}
