import { InfoActions, SET_VERSION } from './app-information.actions';

export interface State {
    version: string;
}

const initialState: State = {
    version: null,
};

export function appInfoReducer(state = initialState, action: InfoActions) {
    switch (action.type) {
        case SET_VERSION: {
            return {
                ...state,
                version: action.payload
            };
        }        
        default: {
            return state;
        }
    }
}

export const version = (state: State) => state.version;