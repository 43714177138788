import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn
} from '@angular/forms';
import {Directive} from '@angular/core';

export function IBAN(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
        isValidIBAN(control.value) ? null : {wrongIBAN: 'Wprowadź poprawny numer rachunku'};
}

// export function isValidIBAN(input: string) {
//     return input.trim().replace(' ','').replace('-','').length === 26;
// }

export function isValidIBAN(input: string) {
    if(!input) {
      return true;
    }
    let result = false;
    input = input.trim().split(' ').join('');
    input = input.split('-').join('');

    console.log(input);
    if (input.length == 26)
    {
        let checkSum = input.substring(0, 2);
        let accountNumber = input.substring(2);
        let countryCode = 2521;
        let reversedDigits = accountNumber + countryCode + checkSum;
        let controlNum = modString(reversedDigits, 97);

        result = (controlNum == 1) ? true : false;
    }

    return result;
}

export function modString(x: string, y: number) {
    if (x.length == 0)
        return 0;
    let x2 = x.substring(0, x.length - 1); // first digits
    let x3 = Number(x.substring(x.length - 1));   // last digit
    return (modString(x2, y) * 10 + x3) % y;
}

@Directive({
  selector: '[iban]',
  providers: [{
      provide: NG_VALIDATORS,
      useExisting: IBANValidatorDirective,
      multi: true
  }]
})

export class IBANValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
      return IBAN()(control);
  }
}
