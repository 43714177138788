import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Store } from "@ngrx/store";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { Category, SurveyPattern } from "src/app/redux/survey/survey.model";
import { Page } from "src/app/services/navigation.service";
import { OrderTaskService } from "src/app/services/order-task/order-task.service";
import { environment } from "src/environments/environment";
import * as fromRoot from '../app.reducer';
import { Market } from "src/app/redux/localization/localization.model";
import { MatAccordion, MatOption, MatSelect, MatSelectChange } from "@angular/material";
import { Project, ProjectSimple } from "src/app/redux/project/project.model";
import { User, UserBase, UserListItem } from "src/app/redux/user/user.model";
import { UIService } from "src/app/shared/ui.service";
import { OrderTaskAppointment, Appointment } from "./calendar.model";
import { ApplicationUser } from "../_models/application-user.model";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy = new Subject<void>();

  allMarkets: Market[] = [];
  selectedMarkets: number[];
  allProjects: Project[] = [];
  allProjectsSimple: ProjectSimple[] = [];
  selectedProject: number;
  allUsers: User[] = [];
  availableUsers: string[] = [];
  selectedUsers: UserListItem[] = [];
  selectedRoles: string[] = [];

  valueContent: string;
  isMultiline: boolean = true;
  mail: boolean = true;
  apka: boolean = false;
  mailTitle: string;
  files = [];
  eventKind: string;
  currentUser: ApplicationUser;
  orderTaskAppointments: OrderTaskAppointment[] = [];
  appointments: Appointment[] = [];


  //selectBox supervisor
  searchControl3: FormControl = new FormControl();
  private _filteredUsers: User[] = [];
  filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  userSelectOpened = false;
  @ViewChild('userSelect', { static: false }) selectElem3: MatSelect;

  @ViewChild('f', { static: false }) f: NgForm;

  @ViewChild('allMarketsSelected', { static: false }) private allMarketsSelected: MatOption;
  @ViewChild('allProjectsSelected', { static: false }) private allProjectsSelected: MatOption;
  @ViewChild('allUsersSelected', { static: false }) private allUsersSelected: MatOption;

  groupControl = new FormControl();

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private store: Store<fromRoot.IRootState>,
    private http: HttpClient,
    private uiService: UIService) { }

  ngOnInit() {
      this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
        //this.getTasksForCalendar(this.currentUser.id);
      });

  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  cancel() {

  }

  userSelectionChange(e: UserListItem[]) {

      this.selectedUsers = e;
    
  }


  sendNotification(title, body, files) {
    this.uiService.openSnack('Trwa wysyłka powiadomienia...', null, null);
    const formData = new FormData();

    for (const file of files) {
      formData.append('files', file);
    }

    for (const user of this.selectedUsers) {
      formData.append('users', user.id);
    }
    formData.append('title', title);
    formData.append('body', body);

    this.http.post<any>(environment.apiUrl + '/notification/message', formData)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
        })
    .catch(err => {
      this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
    });
  }

  send() {
    //this.sendNotification(this.mailTitle, this.selectedUsers, this.files);
  }

  addEmployeeApplication() {
    this.uiService.openSnack('Nie masz uprawnień do dodawania wniosków.');
  }

  addEvent() {
    this.uiService.openSnack('Nie masz uprawnień do dodawania wydarzeń.');
  }

  getTasksForCalendar(userId: string)
  {
    if(this.selectedUsers.length > 0 && userId != '')
    {
      userId = this.selectedUsers[0].id;
    }

    this.http.get<OrderTaskAppointment[]>(environment.apiUrl + '/orderTask/appointment/' + userId)
    .toPromise()
    .then(
      (appointments) => {
        this.orderTaskAppointments = appointments;
       })
    .catch(err => {
        this.orderTaskAppointments = [];
        console.error(err);
    });


    this.http.get<OrderTaskAppointment[]>(environment.apiUrl + '/orderTask/ordertaskappointment/' + userId)
      .toPromise()
      .then(
        (appointments) => {
          this.orderTaskAppointments = appointments;
         })
    .catch(err => {
        this.orderTaskAppointments = [];
        console.error(err);
    });
  }
}
