import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject, } from 'rxjs';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { UserListItem } from 'src/app/redux/user/user.model';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user-search-bar-by-group',
  templateUrl: './user-search-bar-by-group.component.html',
  styleUrls: ['./user-search-bar-by-group.component.css']
})
export class UserSearchBarByGroupComponent implements OnInit {
  @Input() isEditing: boolean = true;
  @Input() groupId: number = 0;
  @Input() placeholderText: string = 'Wybierz użytkownika';

  private _onDestroy = new Subject<void>();
  userList: UserListItem[] = [];
  searchQuery: string = '';
  selectedUser: UserListItem = null;
  userSelectOpened = false;

  @Output() userSelectionChange = new EventEmitter<UserListItem>();

  searchControl: FormControl = new FormControl();
  constructor(
    private store: Store<fromRoot.IRootState>,
    private userService: UserService
  ) { }

  ngOnInit() {

    this.searchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(query => {
      this.fetchUserList();
    });

    this.store.select(fromRoot.getSearchUsers)
    .pipe(takeUntil(this._onDestroy))
    .subscribe(users => {
      this.userList = users;
    });

  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  fetchUserList(): void {
    this.userService.searchUsersByGroupId(this.groupId, this.searchQuery);
  }

  clear(): void {
    this.selectedUser = null;
    this.userSelectionChange.emit(null);
  }

  onUserSelectChange(): void {
    this.userSelectionChange.emit(this.selectedUser);
  }

}
