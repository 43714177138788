import { Component, OnInit, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Product } from 'src/app/redux/project/product.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { MatDialogRef } from '@angular/material';
import { OrderTask } from 'src/app/redux/order-task/order-task.model';
import { OrderTaskService } from 'src/app/services/order-task/order-task.service';
import { LocalizationDetailsComponent } from 'src/app/localization/localization-details/localization-details.component';


@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  selectedOrderTask: OrderTask = {};
  dataSource: Product[] = [];
  selectedProducts: number[] = [];
  expanded: boolean;
  public fullScreen: boolean;
  isLoading: boolean = false;

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  constructor(private store: Store<fromRoot.IRootState>, private taskService: OrderTaskService,
              private auth: AuthService, private service: ProjectService,
              private render: Renderer2, private dialogRef: MatDialogRef<LocalizationDetailsComponent>) { }

  ngOnInit() {

    this.store.select(fromRoot.getTaskSelected)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((task) => {
      this.selectedOrderTask = task;
    });

    this.store.select(fromRoot.getLocalizationProductList)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((products) => {
      this.dataSource = products;
    });

    this.store.select(fromRoot.getProductIsLoadingList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => this.isLoading = isLoading);

    this.service.getLocalizationProductsForReport(this.selectedOrderTask.orderId);
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel() {
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        visible: this.auth.appRoles.canProjectEdit,
        options: {
          icon: 'add',
          text: 'Dodaj do raportu',
          onClick: this.addProductsToReport.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  addProductsToReport() {
    this.setOutputCollection();
    this.taskService.addProductsToReport(this.selectedProducts, this.selectedOrderTask.id);
  }

  setOutputCollection() {
    this.selectedProducts = this.grid.selectedRowKeys;
  }

  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.assignToProduct === true) {
      e.rowElement.style.backgroundColor = 'rgba(102, 205, 96, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  }

  public onFullscreenClick() {
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    const content = document.getElementsByClassName('mat-dialog-content');

    if (this.fullScreen) {
      this.render.removeClass(dialog[0], 'full-screen');
      this.render.removeClass(content[0], 'full-screen');
    } else {
      this.render.addClass(dialog[0], 'full-screen');
      this.render.addClass(content[0], 'full-screen');
    }

    this.fullScreen = !this.fullScreen;
  }

  close() {
    this.cancel();
    this.dialogRef.close();
  }

  getRowNumber(grid, data) {
    const missedRowsNumber = this.getGroupRowsNumbers(data.row.rowIndex);
    return data.row.rowIndex + 1 - missedRowsNumber;
  }

  getGroupRowsNumbers(rowIndex: number) {

    const visibleRows = this.grid.instance.getVisibleRows();
    let counter = 0;
    for (let i = rowIndex; i >= 0; i--) {
      if (visibleRows[i] !== undefined && visibleRows[i].rowType === 'group'){
        counter++;
      }
    }
    return counter;
  }

  save() {
    const rows = this.grid.selectedRowKeys;
    this.taskService.addProductsToReport(rows, this.selectedOrderTask.id);
    this.dialogRef.close();
  }

}
