import { AuthService } from './app/auth/auth.service';
import { ErrorHandler, Inject, NgZone, Injector, Injectable } from '@angular/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    constructor(
        @Inject(NgZone) private ngZone: NgZone,
        private injector: Injector
    ) { }

    handleError(error: any): void {
        this.ngZone.run(() => {
            if (typeof (window) !== 'undefined') {
                console.error(error);
                // if (error.status === 401) {
                //     this.auth.logout();
                // }
                //why :) 
                //this.auth.logout();
            }
        });
    }

    public get auth(): AuthService {
        return this.injector.get(AuthService);
    }
}
