import { Action } from '@ngrx/store';
import { Group } from './group.model';
import { User, UserBase } from '../user/user.model';

export const SET_AVAILABLE_GROUPS = '[Group] Set available Groups';
export const SET_AVAILABLE_MANAGERS = '[Group] Set available Managers';
export const START_EDITING = '[Group] Start editing';
export const STOP_EDITING = '[Group] Stop editing';
export const ADD = '[Group] Add new group';
export const ADD_MANAGER = '[Group] Add new manager';
export const REMOVE = '[Group] Remove group';
export const REMOVE_MANAGER = '[Group] Remove manager';
export const UPDATE = '[Group] Update group';
export const SELECTED_GROUP = '[Group] Selected group';
export const START_LOADING = '[Group] start loading';
export const STOP_LOADING = '[Group] stop loading';

export class SetAvailableGroups implements Action {
    readonly type = SET_AVAILABLE_GROUPS;
    constructor(public payload: { data: Group[], totalCount: number }) { }
}
export class SetAvailableManagers implements Action {
  readonly type = SET_AVAILABLE_MANAGERS;
  constructor(public payload: UserBase[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Group) { }
}
export class AddManager implements Action {
  readonly type = ADD_MANAGER;
  constructor(public payload: UserBase) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class RemoveManager implements Action {
  readonly type = REMOVE_MANAGER;
  constructor(public payload: string) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Group) { }
}
export class SetSelectedGroup implements Action {
    readonly type = SELECTED_GROUP;
    constructor(public payload: Group) { }
}

export type GroupActions =
    SetAvailableGroups
    | SetAvailableManagers
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | AddManager
    | Remove
    | RemoveManager
    | Update
    | SetSelectedGroup;
