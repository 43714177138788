import { Contractor } from './contractor.model';
import {
    ContractorActions,
    SELECTED_CONTRACTOR,
    ADD,
    ADD_CONTRACTOR_PROJECT_KIND,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_CONTRACTORS,
    UPDATE,
    REMOVE,
    REMOVE_CONTRACTOR_PROJECT_KIND,
    START_LOADING,
    STOP_LOADING,
    SET_AVAILABLE_CONTRACTOR_PROJECT_KINDS
} from './contractor.actions';
import { ContractorProjectKind } from './contractor-project-kind.model';

export interface State {
    contractors: Contractor[];
    contractorProjectKinds: ContractorProjectKind[];
    selectedContractor: Contractor;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    contractors: [],
    contractorProjectKinds: [],
    selectedContractor: null,
    isEditing: false,
    isLoading: false
};

export function contractorReducer(state = initialState, action: ContractorActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_CONTRACTOR: {
            return {
                ...state,
                selectedContractor: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                contractors: [...state.contractors, action.payload]
            };
        }
        case ADD_CONTRACTOR_PROJECT_KIND: {
          return {
              ...state,
              contractorProjectKinds: [...state.contractorProjectKinds, action.payload]
          };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_CONTRACTORS: {
            return {
                ...state,
                contractors: action.payload
            };
        }
        case SET_AVAILABLE_CONTRACTOR_PROJECT_KINDS: {
          return {
              ...state,
              contractorProjectKinds: action.payload
          };
        }
        case UPDATE: {
            const index = state.contractors.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selContractor = state.selectedContractor;
            if (selContractor && selContractor.id === action.payload.id) {
              selContractor = action.payload;
            }
            const updatedGroups = [
                ...state.contractors.slice(0, index),
                Object.assign({}, state.contractors[index], action.payload),
                ...state.contractors.slice(index + 1)
            ];
            return {
                ...state,
                contractors: updatedGroups,
                selectedContractor: selContractor
            };
        }
        case REMOVE: {
            return {
                ...state,
                contractors: state.contractors.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_CONTRACTOR_PROJECT_KIND: {
          return {
              ...state,
              contractorProjectKinds: state.contractorProjectKinds.filter(c => c.projectKind.id !== action.projectKindId)
          };
        }
      default: {
          return state;
      }
    }
}

export const contractors = (state: State) => state.contractors;
export const contractorProjectKinds = (state: State) => state.contractorProjectKinds;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedContractor = (state: State) => state.selectedContractor;
