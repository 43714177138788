import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as root from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from 'src/app/shared/ui.service';
import { Group } from 'src/app/redux/group/group.model';
import * as GroupActions from 'src/app/redux/group/group.actions';
import { User, UserGroupMembersData } from 'src/app/redux/user/user.model';
import { PageData } from 'src/app/_models/page-data.model';
import { LoadOptions } from 'devextreme/data/load_options';

const GROUP_MEMBERS_LIST_PAGE_SIZE_KEY = 'administration-group-members-list-pageSize-';
const GROUP_MANAGERS_LIST_PAGE_SIZE_KEY = 'administration-group-managers-list-pageSize-';
const GROUP_LIST_PAGE_SIZE_KEY = 'administration-group-list-pageSize-';


@Injectable()
export class GroupService {
    private _onDestroy = new Subject<void>();

    constructor(
        private store: Store<root.IRootState>,
        private http: HttpClient,
        private translate: TranslateService,
        private uiService: UIService,
    ) { }

    initService() {
        this.cancelSubscriptions();
        this._onDestroy = new Subject<void>();
    }

    cancelSubscriptions() {
        // console.log('[Group service] Cancel subscriptions');
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    groupGetAllV2() {            
        console.log('[Role service] Get all roles v2');
        this.store.dispatch(new GroupActions.StartLoading());
        this.http.get<any>(environment.apiUrl + '/applicationUser/v2/groups')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new GroupActions.SetAvailableGroups({data : res, totalCount : res.length}));
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.store.dispatch(new GroupActions.SetAvailableGroups({data : [], totalCount : 0}));
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations.ERROR, 10_000);
                        });
                    console.error(error);
                }
            );
    }

    groupSelect(group: Group) {
        this.store.dispatch(new GroupActions.SetSelectedGroup(group));
    }

    startEdit() {
        this.store.dispatch(new GroupActions.StartEditing());
    }

    stopEdit() {
        this.store.dispatch(new GroupActions.StopEditing());
    }

    get companyId() {
        return localStorage.getItem('lastCompanyId');
    }

    get groupListPageData(): PageData {
        return JSON.parse(localStorage.getItem(GROUP_LIST_PAGE_SIZE_KEY + this.companyId));
    }

    set groupListPageData(pageData: PageData) {
        localStorage.setItem(GROUP_LIST_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }

    getGroupManagersListPageData(groupId: number): PageData {
        return JSON.parse(localStorage.getItem(GROUP_MANAGERS_LIST_PAGE_SIZE_KEY + groupId + this.companyId));
    }

    setGroupManagersListPageData(pageData: PageData, groupId: number) {
        localStorage.setItem(GROUP_MANAGERS_LIST_PAGE_SIZE_KEY + groupId + this.companyId, JSON.stringify(pageData));
    }

    get groupMembersListPageData(): PageData {
        return JSON.parse(localStorage.getItem(GROUP_MEMBERS_LIST_PAGE_SIZE_KEY + this.companyId));
    }

    set groupMembersListPageData(pageData: PageData) {
        localStorage.setItem(GROUP_MEMBERS_LIST_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }

    updateFromContact(group) {
        console.log('[Group service] Update from contact');
        this.http.put<Group>(environment.apiUrl + '/group/contact/' + group.id, group)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (_) => {
                }, (error) => {
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }

    update(group) {
        console.log('[Group service] Update');
        this.store.dispatch(new GroupActions.StartLoading());
        this.http.put<Group>(environment.apiUrl + '/group/' + group.id, group)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (_) => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });

                    this.store.dispatch(new GroupActions.StopLoading());
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }

    toggleManager(groupId: number, managerId: string) {
        console.log('[Group service] Toggle manager');

        if (!groupId) {
            this.translate.get('Użytkownik nie wybrany')
                .pipe(takeUntil(this._onDestroy))
                .subscribe((text) => {
                    this.uiService.openSnack(text);
                });
        }

        this.store.dispatch(new GroupActions.StartLoading());
        this.http.get<Group>(environment.apiUrl + '/group/user/toggle/manager/' + groupId + '/' + managerId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (updatedGroup) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    // this.store.dispatch(new GroupActions.Update(updatedGroup));
                    this.store.dispatch(new GroupActions.SetSelectedGroup(updatedGroup));
                    this.translate.get('Zakończono pomyślnie')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }

    toggleMember(groupId: number, memberId: string) {
        console.log('[Group service] Toggle member');
        this.store.dispatch(new GroupActions.StartLoading());
        this.http.get<Group>(environment.apiUrl + '/group/user/toggle/member/' + groupId + '/' + memberId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (updatedGroup) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    // this.store.dispatch(new GroupActions.Update(updatedGroup));
                    this.store.dispatch(new GroupActions.SetSelectedGroup(updatedGroup));
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                }, (err) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    if (err && err.error && err.error.messageCode) {
                        this.translate.get([err.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack('[' + memberId + '] ' + translations[err.error.messageCode] + ' '
                                    + '\'' + groupId + '\'', translations['ERROR'], 10_000);
                            });
                    } else {
                        this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                            });
                    }
                    console.error(err);
                }
            );
    }

    remove(groupId: number) {
        console.log('[Group service] remove');
        this.store.dispatch(new GroupActions.StartLoading());
        this.http.delete<Group>(environment.apiUrl + '/group/' + groupId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (_) => {
                    this.translate.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new GroupActions.StopLoading());
                },
                (error) => {
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    this.store.dispatch(new GroupActions.StopLoading());
                    console.log(error);
                }
            );
    }

    create(group: Group) {
        console.log('[Group service] create');
        this.store.dispatch(new GroupActions.StartLoading());
        this.http.post<Group>(environment.apiUrl + '/group', group)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (createdGroup) => {
                    this.translate.get('Grupa została dodana')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new GroupActions.SetSelectedGroup(createdGroup));
                    this.store.dispatch(new GroupActions.StopLoading());
                }, (error) => {
                    this.store.dispatch(new GroupActions.StopLoading());
                    this.translate.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }
    
    getParams(loadOptions: LoadOptions)
    {
        function isNotEmpty(value: any): boolean {
            return value !== undefined && value !== null && value !== '';
          }
  
          let params: HttpParams = new HttpParams();
          [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary'
          ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params = params.set(i, JSON.stringify(loadOptions[i]));
            }
          });
          return params;
    }
}
