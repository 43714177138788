import { PipeTransform, Pipe } from '../../../../node_modules/@angular/core';
import { EDictionary } from '../common.reducer';

@Pipe({ name: 'eDictionary' })
export class EDictionaryPipe implements PipeTransform {

    getDictionaryId(value: string, dictionary: EDictionary[]) {
        if (!value || !dictionary) {
            return null;
        }
        const row = dictionary.find(c => c.key.toUpperCase() === value.toUpperCase());
        if (row) {
            return row.id;
        }
        return null;
    }

    getTranslation(row: EDictionary, language: string = null): string {
        if (!language) {
            language = localStorage.getItem('language');
        }
        if (language === 'pl' && row.polish) {
            return row.polish;
        }
        if (language === 'en' && row.english) {
            return row.english;
        }
        if (language === 'ru' && row.russian) {
            return row.russian;
        }
        if (language === 'ua' && row.ukrainian) {
            return row.ukrainian;
        }
        if (language === 'de' && row.deutsch) {
            return row.deutsch;
        }
        // return `[${row.key}]`;
        return row.key;
    }

    transform(value: string, dictionary: EDictionary[], language: string = null) {
        if (!value || !dictionary) {
            return value;
        }
        if (!language) {
            language = localStorage.getItem('language');
        }
        const row = dictionary.find(c => c.key.toUpperCase() === value.toUpperCase());
        if (row) {
            if (language === 'pl' && row.polish) {
                return row.polish;
            }
            if (language === 'en' && row.english) {
                return row.english;
            }
            if (language === 'ru' && row.russian) {
                return row.russian;
            }
            if (language === 'ua' && row.ukrainian) {
                return row.ukrainian;
            }
            if (language === 'de' && row.deutsch) {
                return row.deutsch;
            }
        }
        // return `[${value}]`;
        return value;
    }
}
