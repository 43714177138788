import { ProjectKind } from "../contractor/project-kind.model";
import { Market } from "../localization/localization.model";
import { Project } from "../project/project.model";
import { EmploymentStatus } from "../service/service.model";

export interface ComponentValue {
  key: string;
  value: string;
  parentId?: number;
}
export interface User {
  id: string;
  blocked: boolean;
  disabled: boolean;
  contactElements?: ComponentValue[];
  email: string;
  firstName: string;
  lastName: string;
  userName: string;
  pesel?: string;
  passwordWasReset: boolean;
  forceChangePassword?: boolean;
  hasForeignIds: boolean;
  roles?: any[];
  additionalPermissions?: any[];
  firstLogin?: boolean;
  lastLogin?: boolean;
  isAvailable?: boolean;
  facebookId?: string;
  googleId?: string;
  croppedPhoto?: string;
  hasPhoto?: boolean;
  createdDate?: Date;
  startWorkDate?: Date;
  projectKinds?: number[];
  userMarkets?: number[];
  userProjects?: number[];
  userManagers?: string;
  accountType?: AccountType;
  clientAccountType?: ClientAccountType;
  phoneNumber?: string;
  employmentStatus?: EmploymentStatus;
  fv?: boolean;
  delegation?: boolean;
  
  _localManager?: boolean; // if true => from ePMT
  _localMember?: boolean; // if true => from ePMT
  _username?: string;
  _manager?: string;
  _managerId?: string;
  _manager2?: string;
  _manager2Id?: string;
  _manager3?: string;
  _manager3Id?: string;
  _deputyUserName?: string;
  _mouseOvered?: boolean;
  _checked?: boolean;
  _tooltipSting?: string;
  _nomanager?: boolean;
  _avatarWasLoaded?: boolean;
  _avatar?: string;
  _elementsLoaded?: boolean;
}

export interface UserAdditionalData {
  employmentStatus: EmploymentStatus;
  employmentStatusText: string;
  phoneNumber: string;
  mail: string;
}

export interface UserCompany {
  applicationUserId: string;
  companyId: string;
  userName: string;
  pesel: string;
  email: string;
  disabled: boolean;
  fv: boolean;
  delegation: boolean;
  firstName: string;
  lastName: string;
  projectKinds: number[];
  phoneNumber: string;
  userProjects: number[];
  userMarkets: number[];
  clientAccountType?: ClientAccountType;
  employmentStatus?: EmploymentStatus;
}

export interface UserCompany {
  applicationUserId: string;
  companyId: string;
  userName: string;
  pesel: string;
  email: string;
  disabled: boolean;
  firstName: string;
  lastName: string;
  projectKinds: number[];
  phoneNumber: string;
  userProjects: number[];
  userMarkets: number[];
  clientAccountType?: ClientAccountType;
  employmentStatus?: EmploymentStatus;
  fv: boolean;
  delegation: boolean;
}

export interface UserGroupMembersData {
  data: UserGroupMember[];
  totalCount: number;
}

export interface UserGroupManagersData {
  data: UserGroupManager[];
  totalCount: number;
}

export interface ProjectKindGroupManagersResource {
  id: number;
  groupsManagers: GroupManagersResource[];
}

export interface GroupManagersResource {
  id: number;
  name: string;
  managers: UserListItem[];
  _managerFullNames?: string[];
  _managerIds?: string[];
}

export interface GroupManagersBody {
  groupId: number;
  managers: string[];
}

export interface UserGroupMember {
  applicationUserId: string;
  manager1Id: string;
  manager2Id: string;
  manager3Id: string;
  fullName: string;
  email: string;
  manager1Name: string;
  manager2Name: string;
  manager3Name: string;
}

export interface UserGroupManager {
  applicationUserId: string;
  fullName: string;
  deputyName: string;
}

export enum AccountType {
  Office = 0,
  Merch = 1,
  Client = 2,
  Standard = 3
}

export enum ClientAccountType {
  SalesRepresentative = 0, // Przedstawiciel handlowy
  RegionalManager = 1, // Menedżer regionalny
  Headquarters = 2, // Centrala
}

export interface UserBase {
  id?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  accounttype?: AccountType;
  fullName?: string;
  mail?: string;
  phoneNumber?: string;
  pesel?: string;
  employmentStatus?: string;
}

export interface UserSimple {
  id?: string;
  firstName?: string;
  lastName?: string;
}

export interface UserListItem {
  id?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

export interface UserContactsData {
  data: UserContactListItem[];
  totalCount: number;
  groupCount: number;
  summary: number[];
}

export interface UserContactListItem {
  id?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  disabled?: boolean;
  blocked?: boolean;
  SuperiorFirstName?: string;
  SuperiorLastName?: string;
  phoneNumber?: string;
  groups?: string[];
  managersFullNames?: string[];
  facebookId?: string;
  hasPhoto?: boolean;
  fv: boolean;
  delegation: boolean;

  _avatarWasLoaded?: boolean;
  _avatar?: string;
}

export interface UserContactDetails {
  id?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  disabled?: boolean;
  blocked?: boolean;
  fv?: boolean;
  delegation?: boolean;
  SuperiorFirstName?: string;
  SuperiorLastName?: string;
  phoneNumber?: string;
  facebookId?: string;
  hasPhoto?: boolean;
  lastLogin?: Date;
  groups?: UserContactDetailsGroup[];
  employmentStatus?: EmploymentStatus;
  employmentStatusText?: string;
  contactElements?: ComponentValue[];
  clientAccountType?: ClientAccountType;
  projectKindsIds: number[];
  marketsIds: number[];
  projectsIds: number[];
  accountType: AccountType;
  pesel?: string;
  _avatarWasLoaded?: boolean;
  _avatar?: string;

}

export interface UserContactDetailsGroup {
  groupName?: string;
  managerName?: string;
}

export interface UserCommonRoles {
  user: UserBase;
  isManager: boolean;
  isCoordinator: boolean;
  isLocalizationCoordinator: boolean;
  isPromCoordinator: boolean;
  isMerchandiser: boolean;
  isHostess: boolean;
  isSupervisor: boolean;
}

export interface ContactImport {
  login?: string;
  imie?: string;
  nazwisko?: string;
  telefon?: string;
  pesel?: string;
  status_zatrudnienia?: string;
  mail?: string;
  data_urodzenia?: string;
  data_roczpoczecia_pracy?: string;
  role?: string;
  grupy?: string;
  typ_konta?: string;
  przypisane_projekty?: string;
  typ_konta_klienta?: string;
  rodzaje_projektow?: string;
  wyslij_haslo?: string;
  nieaktywny?: string;
}

export interface ContactUser {
  userName?: string;
  name?: string;
  lastName?: string;
  phoneNumber?: string;
  pesel?: string;
  mail?: string;
  birthDate?: string;
  startWorkDate?: number;
  roles?: string;
  groups?: string;
  accountType?: string;
  clientProjects?: string;
  clientAccountType?: string;
  projectKinds?: string;
  sendPassword?: boolean;
  disabled?: boolean;
  employmentStatus?: string;
}

export interface UserProject {
  projectId?: number;
  projectName?: string;
}

export interface UserMarket {
  marketId?: number;
  marketCode?: string;
}

export const USER_PMT_COORDINATOR_ROLENAME = 'Koordynator ds. Merchandisingu';
export const USER_PROM_COORDINATOR_ROLENAME = 'Koordynator ds. Promocji';
export const USER_SUPERVISOR_ROLENAME = 'Specjalista';
export const USER_LOC_COORDINATOR_ROLENAME = 'Koordynator lokalizacji';
export const USER_MANAGER_ROLENAME = 'Menedżer Regionalny';