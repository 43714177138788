import { UserContactsData, User, UserBase, UserGroupManagersData, UserGroupMembersData, UserListItem, UserContactDetails, UserAdditionalData, GroupManagersResource, ProjectKindGroupManagersResource } from './user.model';
import {
    UserActions,
    USER_LOADED,
    USER_UNLOADED,
    ADD_NEW_USER,
    ADD_NEW_USER_RANGE,
    REMOVE_USER,
    UPDATE_USER_BLOCKED_FLAG,
    UPDATE_USER_RANGE,
    UPDATE_USER,
    SET_AVAILABLE_USERS,
    SET_AVAILABLE_MANAGERS,
    SET_AVAILABLE_LOCALIZATION_COORDINATORS,
    SET_AVAILABLE_PMT_COORDINATORS,
    SET_AVAILABLE_PROM_COORDINATORS,
    SET_AVAILABLE_PMT_MERCHANDISERS,
    SET_AVAILABLE_SUPERVISORS,
    SET_AVAILABLE_MANAGERS_V2,
    SET_AVAILABLE_LOCALIZATION_COORDINATORS_V2,
    SET_AVAILABLE_PMT_COORDINATORS_V2,
    SET_AVAILABLE_PROM_COORDINATORS_V2,
    SET_AVAILABLE_PMT_MERCHANDISERS_V2,
    SET_AVAILABLE_SUPERVISORS_V2,
    SET_AVAILABLE_HOSTESSES_V2,
    START_LOADING,
    STOP_LOADING,
    USER_LOGGED_IN,
    USER_LOGOUT,
    SET_AVAILABLE_ONLINE_USERS,
    SET_USER_PHOTO,
    CONTACT_UPDATE_DATA_SOURCE,
    CONTACT_SET_DATA_SOURCE,
    START_EDITING,
    STOP_EDITING,
    UPDATE_COMMON_USERS,
    SET_AVAILABLE_HOSTESSES,
    SET_MERCH_GROUP_NAMES,
    SET_SELECTED_GROUP_MANAGERS,
    SET_SELECTED_GROUP_MEMBERS,
    SET_SEARCH_USERS,
    SET_USER_CONTACTS,
    SET_USER_CONTACT_DETAILS,
    SET_SELECTED_USER,
    SET_USER_ADDITIONAL_DATA,
    SET_SELECTED_PROJECT_KIND_GROUP_MANAGERS,
    SET_SELECTED_GROUP_IDS,
    SET_RELOAD_DATA_GRID,
} from './user.actions';
import { AppHubConnection } from './hub_connection.model';
import { Contact } from 'src/app/contact/contact.component';
import { EmploymentStatus } from '../service/service.model';


export interface State {
    selectedUser: User;
    users: User[];
    usersContacts: UserContactsData;
    usersTotalCount: number;
    searchUsers: UserListItem[];

    managersV2: UserListItem[];
    localizationCoordinatorsV2: UserListItem[];
    pmtCoordinatorsV2: UserListItem[];
    promCoordinatorsV2: UserListItem[];
    pmtMerchandisersV2: UserListItem[];
    hostessesV2: UserListItem[];
    supervisorsV2: UserListItem[];

    managers: UserBase[];
    localizationCoordinators: UserBase[];
    pmtCoordinators: UserBase[];
    promCoordinators: UserBase[];
    pmtMerchandisers: UserBase[];
    hostesses: UserBase[];
    supervisors: UserBase[];
    updatedUser: User;
    online: AppHubConnection[];
    isUserLoaded: boolean;
    isLoading: boolean;
    isEditing: boolean;
    contactDataSource: Contact[];
    selectedContactDetails: UserContactDetails;
    selectedMerchGroupNames: string[];
    selectedGroupMembers: UserGroupMembersData;
    selectedGroupManagers: UserGroupManagersData;
    selectedUserAdditionalData: UserAdditionalData;
    selectedProjectKindGroupManagers: ProjectKindGroupManagersResource;
    selectedGroupIds: number[];
    reloadDataGrid: boolean;
}

const initialState: State = {
    selectedUser: null,
    users: [],
    usersContacts: null,
    usersTotalCount: 0,
    searchUsers: [],
    managersV2: [],
    localizationCoordinatorsV2: [],
    pmtCoordinatorsV2: [],
    promCoordinatorsV2: [],
    pmtMerchandisersV2: [],
    hostessesV2: [],
    supervisorsV2: [],
    managers: [],
    localizationCoordinators: [],
    pmtCoordinators: [],
    promCoordinators: [],
    pmtMerchandisers: [],
    hostesses: [],
    supervisors: [],
    updatedUser: null,
    online: [] = [],
    isUserLoaded: false,
    isLoading: false,
    isEditing: false,
    contactDataSource: null,
    selectedContactDetails: null,
    selectedMerchGroupNames: null,
    selectedGroupMembers: null,
    selectedGroupManagers: null,
    selectedUserAdditionalData: null,
    selectedProjectKindGroupManagers: null,
    selectedGroupIds: [],
    reloadDataGrid: false,
};


export function userReducer(state = initialState, action: UserActions) {
    switch (action.type) {
        case CONTACT_SET_DATA_SOURCE: {
            return {
                ...state,
                contactDataSource: action.payload
            };
        }
        case CONTACT_UPDATE_DATA_SOURCE: {
            // const dataSource = state.contactDataSource;
            // if (dataSource) {
            //     action.payload.forEach(c => c._elementsLoaded = false);
            //     for (const data of dataSource) {
            //         const user = action.payload.find(c => c.id === data.userId);
            //         if (user) {
            //             data.user.additionalPermissions = user.additionalPermissions;
            //             data.user.blocked = user.blocked;
            //             data.user.contactElements = user.contactElements;
            //             data.user.croppedPhoto = user.croppedPhoto;
            //             data.user.disabled = user.disabled;
            //             data.user.email = user.email;
            //             data.user.facebookId = user.facebookId;
            //             data.user.firstLogin = user.firstLogin;
            //             data.user.forceChangePassword = user.forceChangePassword;
            //             data.user.hasForeignIds = user.hasForeignIds;
            //             data.user.hasPhoto = user.hasPhoto;
            //             data.user.isAvailable = user.isAvailable;
            //             data.user.lastLogin = user.lastLogin;
            //             data.user.lastName = user.lastName;
            //             data.user.passwordWasReset = user.passwordWasReset;
            //             data.user.roles = user.roles;
            //             data.user.userName = user.userName;
            //             data.user._elementsLoaded = false;
            //             data.user._avatarWasLoaded = user._avatarWasLoaded;
            //         }
            //     }
            // }
            return {
                ...state,
                // contactDataSource: dataSource
            };
        }
        case SET_AVAILABLE_ONLINE_USERS: {
            return {
                ...state,
                online: action.payload
            };
        }
        case USER_LOGGED_IN: {
            // if (!state.online.some(u => u.userIdentifier === action.payload.userIdentifier)) {
            return {
                ...state,
                online: [...state.online, action.payload]
            };
            // }
            // return state;
        }
        case USER_LOGOUT: {
            return {
                ...state,
                online: state.online.filter(c => c.connectionId !== action.payload.connectionId)
            };
        }
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case START_EDITING: {
          return {
              ...state,
              isEditing: true
          };
      }
      case STOP_EDITING: {
        return {
            ...state,
            isEditing: false
        };
    }
        case USER_LOADED: {
            return {
                ...state,
                isUserLoaded: true
            };
        }
        case USER_UNLOADED: {
            return {
                ...state,
                isUserLoaded: false
            };
        }
        case ADD_NEW_USER: {
            if (!state.users.some(u => u.id === action.payload.id)) {
                return {
                    ...state,
                    users: [...state.users, action.payload]
                };
            }

            return state;
        }
        case ADD_NEW_USER_RANGE: {
            const arrayToAdd = [];
            for (const e of action.payload) {
                if (!state.users.some(u => u.id === e.id)) {
                    arrayToAdd.push(e);
                }
            }

            return {
                ...state,
                users: [...state.users, ...arrayToAdd]
            };
        }
        case REMOVE_USER: {
            return {
                ...state,
                users: state.users.filter(c => c.id !== action.payload)
            };
        }
        case UPDATE_USER_BLOCKED_FLAG: {
            const user = state.users.find(u => u.id === action.payload.id);
            if (user) {
                const usertoUpdate = { ...user };
                usertoUpdate.blocked = action.payload.blocked;
                const index = state.users.map(d => d.id).indexOf(action.payload.id);
                const users = [
                    ...state.users.slice(0, index),
                    Object.assign({}, state.users[index], usertoUpdate),
                    ...state.users.slice(index + 1)
                ];
                return {
                    ...state,
                    users,
                    updatedUser: usertoUpdate
                };
            }
            return state;
        }
        case UPDATE_USER_RANGE: {
            let users = state.users;

            for (const u of action.payload) {
                const index = users.map(d => d.id).indexOf(u.id);

                if (index === -1) {
                    continue;
                }

                const isAvailable = state.users[index].isAvailable;
                u.isAvailable = isAvailable;

                users = [
                    ...users.slice(0, index),
                    Object.assign({}, users[index], u),
                    ...users.slice(index + 1)
                ];
            }
            return {
                ...state,
                users
            };
        }
        case UPDATE_COMMON_USERS: {
          let managers = state.managers;
          let pmtCoordinators = state.pmtCoordinators;
          let promCoordinators = state.promCoordinators;
          let localizationCoordinators = state.localizationCoordinators;
          let pmtMerchandisers = state.pmtMerchandisers;
          let hostesses = state.hostesses;
          let supervisors = state.supervisors;

          const managerIndex = managers.map(d => d.id).indexOf(action.payload.user.id);
          const pmtCoordinatorIndex = pmtCoordinators.map(d => d.id).indexOf(action.payload.user.id);
          const promCoordinatorIndex = promCoordinators.map(d => d.id).indexOf(action.payload.user.id);
          const localizationCoordinatorIndex = localizationCoordinators.map(d => d.id).indexOf(action.payload.user.id);
          const merchandiserIndex = pmtMerchandisers.map(d => d.id).indexOf(action.payload.user.id);
          const hostessIndex = hostesses.map(d => d.id).indexOf(action.payload.user.id);
          const supervisorIndex = supervisors.map(d => d.id).indexOf(action.payload.user.id);

          if (action.payload.isManager) {
            if (managerIndex === -1) {
              managers = [...state.managers, action.payload.user];
            } else {
              managers = [
                ...state.managers.slice(0, managerIndex),
                Object.assign({}, state.managers[managerIndex], action.payload.user),
                ...state.managers.slice(managerIndex + 1)
            ];
            }
          } else {
            if (managerIndex !== -1) {
              managers = state.managers.filter(c => c.id !== action.payload.user.id);
            }
          }

          if (action.payload.isCoordinator) {
            if (pmtCoordinatorIndex === -1) {
              pmtCoordinators = [...state.pmtCoordinators, action.payload.user];
            } else {
              pmtCoordinators = [
                ...state.pmtCoordinators.slice(0, pmtCoordinatorIndex),
                Object.assign({}, state.pmtCoordinators[pmtCoordinatorIndex], action.payload.user),
                ...state.pmtCoordinators.slice(pmtCoordinatorIndex + 1)
            ];
            }
          } else {
            if (pmtCoordinatorIndex !== -1) {
              pmtCoordinators = state.pmtCoordinators.filter(c => c.id !== action.payload.user.id);
            }
          }

          if (action.payload.isPromCoordinator) {
            if (promCoordinatorIndex === -1) {
              promCoordinators = [...state.promCoordinators, action.payload.user];
            } else {
              promCoordinators = [
                ...state.promCoordinators.slice(0, promCoordinatorIndex),
                Object.assign({}, state.promCoordinators[promCoordinatorIndex], action.payload.user),
                ...state.promCoordinators.slice(promCoordinatorIndex + 1)
            ];
            }
          } else {
            if (promCoordinatorIndex !== -1) {
              promCoordinators = state.promCoordinators.filter(c => c.id !== action.payload.user.id);
            }
          }

          if (action.payload.isLocalizationCoordinator) {
            if (localizationCoordinatorIndex === -1) {
              localizationCoordinators = [...state.localizationCoordinators, action.payload.user];
            }
          } else {
            if (localizationCoordinatorIndex !== -1) {
              localizationCoordinators = state.localizationCoordinators.filter(c => c.id !== action.payload.user.id);
            }
          }

          if (action.payload.isMerchandiser) {
            if (merchandiserIndex === -1) {
              pmtMerchandisers = [...state.pmtMerchandisers, action.payload.user];
            } else {
              pmtMerchandisers = [
                ...state.pmtMerchandisers.slice(0, merchandiserIndex),
                Object.assign({}, state.pmtMerchandisers[merchandiserIndex], action.payload.user),
                ...state.pmtMerchandisers.slice(merchandiserIndex + 1)
            ];
            }
          } else {
            if (merchandiserIndex !== -1) {
              pmtMerchandisers = state.pmtMerchandisers.filter(c => c.id !== action.payload.user.id);
            }
          }

          if (action.payload.isHostess) {
            if (hostessIndex === -1) {
              hostesses = [...state.hostesses, action.payload.user];
            } else {
              hostesses = [
                ...state.hostesses.slice(0, hostessIndex),
                Object.assign({}, state.hostesses[hostessIndex], action.payload.user),
                ...state.hostesses.slice(hostessIndex + 1)
            ];
            }
          } else {
            if (hostessIndex !== -1) {
              hostesses = state.hostesses.filter(c => c.id !== action.payload.user.id);
            }
          }

          if (action.payload.isSupervisor) {
            if (supervisorIndex === -1) {
              supervisors = [...state.supervisors, action.payload.user];
            } else {
              supervisors = [
                ...state.supervisors.slice(0, supervisorIndex),
                Object.assign({}, state.supervisors[supervisorIndex], action.payload.user),
                ...state.supervisors.slice(supervisorIndex + 1)
            ];
            }
          } else {
            if (supervisorIndex !== -1) {
              supervisors = state.supervisors.filter(c => c.id !== action.payload.user.id);
            }
          }
          return {
              ...state,
              managers: managers.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
              pmtCoordinators: pmtCoordinators.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
              promCoordinators: promCoordinators.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
              localizationCoordinators: localizationCoordinators.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
              pmtMerchandisers: pmtMerchandisers.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
              hostess: hostesses.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
              supervisors: supervisors.sort((obj1, obj2) => (obj1.firstName + ' ' + obj1.lastName  > obj2.firstName + ' ' + obj2.lastName ? 1 : -1)),
          };
      }
        case SET_USER_PHOTO: {
            const index = state.users.map(d => d.id).indexOf(action.payload.id);
            if (index < 0) {
                return state;
            }
            const newuser: User = { ...state.users[index], hasPhoto: true, _avatar: action.payload.avatar };
            const users = [
                ...state.users.slice(0, index),
                newuser,
                ...state.users.slice(index + 1)
            ];
            return {
                ...state,
                users
            };
        }
        case UPDATE_USER: {
            const index = state.users.map(d => d.id).indexOf(action.payload.id);
            // if (action.payload && state.users[index]) {
            //     // action.payload._checked = state.users[index]._checked;
            //     action.payload._mouseOvered = state.users[index]._mouseOvered;
            // }
            if (index === -1) {
                return state;
            }

            const isAvailable = state.users[index].isAvailable;
            action.payload.isAvailable = isAvailable;

            const users = [
                ...state.users.slice(0, index),
                Object.assign({}, state.users[index], action.payload),
                ...state.users.slice(index + 1)
            ];
            return {
                ...state,
                users,
                updatedUser: action.payload
            };
        }
        case SET_AVAILABLE_USERS: {
            return {
                ...state,
                users: action.payload.data,
                usersTotalCount: action.payload.totalCount
            };
        }
        case SET_AVAILABLE_MANAGERS_V2: {
          return {
              ...state,
              managersV2: action.payload
          };
        }
        case SET_AVAILABLE_LOCALIZATION_COORDINATORS_V2: {
          return {
              ...state,
              localizationCoordinatorsV2: action.payload
          };
        }
        case SET_AVAILABLE_PMT_COORDINATORS_V2: {
          return {
              ...state,
              pmtCoordinatorsV2: action.payload
          };
        }
        case SET_AVAILABLE_PROM_COORDINATORS_V2: {
          return {
              ...state,
              promCoordinatorsV2: action.payload
          };
        }
        case SET_AVAILABLE_PMT_MERCHANDISERS_V2: {
          return {
              ...state,
              pmtMerchandisersV2: action.payload
          };
        }
        case SET_AVAILABLE_HOSTESSES_V2: {
          return {
              ...state,
              hostessesV2: action.payload
          };
        }
        case SET_AVAILABLE_SUPERVISORS_V2: {
          return {
              ...state,
              supervisorsV2: action.payload
          };
        }
        case SET_AVAILABLE_MANAGERS: {
          return {
              ...state,
              managers: action.payload
          };
        }
        case SET_AVAILABLE_LOCALIZATION_COORDINATORS: {
          return {
              ...state,
              localizationCoordinators: action.payload
          };
        }
        case SET_AVAILABLE_PMT_COORDINATORS: {
          return {
              ...state,
              pmtCoordinators: action.payload
          };
        }
        case SET_AVAILABLE_PROM_COORDINATORS: {
          return {
              ...state,
              promCoordinators: action.payload
          };
        }
        case SET_AVAILABLE_PMT_MERCHANDISERS: {
          return {
              ...state,
              pmtMerchandisers: action.payload
          };
        }
        case SET_AVAILABLE_HOSTESSES: {
          return {
              ...state,
              hostesses: action.payload
          };
        }
        case SET_AVAILABLE_SUPERVISORS: {
          return {
              ...state,
              supervisors: action.payload
          };
        }
        case SET_MERCH_GROUP_NAMES: {
          return {
              ...state,
              selectedMerchGroupNames: action.payload
          };
        }    
        case SET_SELECTED_GROUP_MEMBERS: {
          return {
              ...state,
              selectedGroupMembers: action.payload
          };
        }       
        case SET_SELECTED_GROUP_MANAGERS: {
          return {
              ...state,
              selectedGroupManagers: action.payload
          };
        }         
        case SET_SEARCH_USERS: {
          return {
              ...state,
              searchUsers: action.payload
          };
        }    
        case SET_USER_CONTACTS: {
          return {
              ...state,
              usersContacts: action.payload
          };
        }   
        case SET_USER_CONTACT_DETAILS: {
          return {
              ...state,
              selectedContactDetails: action.payload
          };
        }   
        case SET_SELECTED_USER: {
          return {
              ...state,
              selectedUser: action.payload
          };
        }  
        case SET_USER_ADDITIONAL_DATA: {
          return {
              ...state,
              selectedUserAdditionalData: action.payload
          };
        }  
        case SET_SELECTED_PROJECT_KIND_GROUP_MANAGERS: {
          return {
              ...state,
              selectedProjectKindGroupManagers: action.payload
          };
        }  
        case SET_SELECTED_GROUP_IDS: {
          return {
              ...state,
              selectedGroupIds: action.payload
          };
        }  
        case SET_RELOAD_DATA_GRID: {
          return {
              ...state,
              reloadDataGrid: action.payload
          };
        }  
        default: {
            return state;
        }
    }
}

export const getUsers = (state: State) => state.users;
export const getUsersTotalCount = (state: State) => state.usersTotalCount;

export const getManagersV2 = (state: State) => state.managersV2;
export const getLocalizationCoordinatorsV2 = (state: State) => state.localizationCoordinatorsV2;
export const getPmtCoordinatorsV2 = (state: State) => state.pmtCoordinatorsV2;
export const getPromCoordinatorsV2 = (state: State) => state.promCoordinatorsV2;
export const getPmtMerchandisersV2 = (state: State) => state.pmtMerchandisersV2;
export const getHostessesV2 = (state: State) => state.hostessesV2;
export const getSupervisorsV2 = (state: State) => state.supervisorsV2;

export const getContactDataSource = (state: State) => state.contactDataSource;
export const getUpdatedUser = (state: State) => state.updatedUser;
export const getOnline = (state: State) => state.online;
export const getIsLoading = (state: State) => state.isLoading;
export const getIsLoaded = (state: State) => state.isUserLoaded;
export const getIsEditing = (state: State) => state.isEditing;
export const getSelectedMerchGroupNames = (state: State) => state.selectedMerchGroupNames;
export const getSelectedGroupMembers = (state: State) => state.selectedGroupMembers;
export const getSelectedGroupManagers = (state: State) => state.selectedGroupManagers;
export const getSearchUsers = (state: State) => state.searchUsers;
export const getUsersContacts = (state: State) => state.usersContacts;
export const getSelectedContactDetails = (state: State) => state.selectedContactDetails;
export const getSelectedUser = (state: State) => state.selectedUser;
export const getSelectedUserAdditionalData = (state: State) => state.selectedUserAdditionalData;
export const getSelectedProjectKindGroupManagers = (state: State) => state.selectedProjectKindGroupManagers;
export const getSelectedGroupIds = (state: State) => state.selectedGroupIds;
export const getReloadDataGrid = (state: State) => state.reloadDataGrid;









