import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { IAuthData } from 'src/app/auth/auth-data.model';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as Auth from '../../auth/auth.actions';
import * as fromRoot from '../../app.reducer';
import { ApplicationUser } from '../../_models/application-user.model';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService } from 'src/app/shared/ui.service';
import { HOME_PATH } from 'src/app/app.routing.module';
import { Company } from 'src/app/redux/company/company.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  private password: string;
  isLoading$: Observable<boolean>;
  currentUser: ApplicationUser;
  company;
  isAuth: boolean;
  returnUrl: string;
  returnParams: string;

  _auth: IAuthData = {
    username: '',
    password: '',
    provider: 'ePMT'
  };

  get auth() {
    return this._auth;
  }

  set auth(authdata: IAuthData) {
    if (this.currentUser && this.currentUser.applicationUserCompany && this.currentUser.applicationUserCompany.length > 1) {
      this._auth = authdata;
    }
  }

  constructor(
    private authSerivce: AuthService,
    private store: Store<fromRoot.IRootState>,
    private UIservice: UIService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);

    this.store.select(fromRoot.getRedirectUrl)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(returnUrl => { this.returnUrl = returnUrl });

    this.store.select(fromRoot.getRedirectParameters)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(returnParams => this.returnParams = returnParams);

    this.store.select(fromRoot.getIsAuth)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isAuth => {
        this.isAuth = isAuth;
        if (isAuth) {
          if (this.returnUrl !== undefined && this.returnUrl !== null && this.returnUrl !== '') {
            let navigateUrl = this.returnUrl;
            let paramsToPass = null;
            if (this.returnParams !== undefined && this.returnParams !== null && this.returnParams !== '') {
              paramsToPass = this.queryStringToObj(this.returnParams);
            }
            if (paramsToPass === null) {
              this.router.navigate([navigateUrl]);
            }
            else {
              this.router.navigate([navigateUrl, paramsToPass]);
            }
          }
          else {
            this.router.navigate([HOME_PATH]);
          }
        }
      });
    this.store.select(fromRoot.getAuthData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(auth => this.auth = auth);
    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((user) => {
        this.currentUser = user;
        if (this.isAuth && this.currentUser) {
          return;
        }

        if (user) {
          if (!this.currentUser.applicationUserCompany.some(c => c.company.isActive)) {
            this.UIservice.openSnack('Nie masz żadnych firm.', 'Ok');
          }
        }

        const lastCompanyId = localStorage.getItem('lastCompanyId');
        if (lastCompanyId && user) {
          const lastCmpany = this.currentUser.applicationUserCompany.find(c => c.companyId === lastCompanyId && c.company.isActive);
          if (lastCmpany) {
            this.company = lastCmpany.company;
          }
        }
        if (this.currentUser && this.companyList.length === 1) {
          this.authSerivce.startLoading();
          const c: Company = this.companyList[0].company;
          if (c.isActive) {
            this.company = c;
            this.selectCompany();
          }
        }
      });
    this.route.params.subscribe(params => {
      const url = params['url'];
      const parameters = params['params'];
      if (url !== undefined) {
        this.authSerivce.setRedirectData(url, parameters !== undefined ? parameters : null);
      }
      else {
        this.authSerivce.setRedirectData(null, null);
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  queryStringToObj(queryString: string): { [key: string]: string } {
    const obj: { [key: string]: string } = {};
    if (queryString) {
      const pairs = queryString.split(';');

      for (const pair of pairs) {
        const [key, value] = pair.split('=');
        obj[key] = value;
      }
    }
    return obj;
  }

  navigateToPrivacy() {
    this.router.navigate(['privacy']);
  }

  onSubmit() {
    this.auth.provider = 'ePMT';
    this.store.dispatch(new Auth.SetAuthData(this.auth));
    this.password = this.auth.password;
    this.authSerivce.login(this.auth, false);
  }

  get companyList() {
    if (this.currentUser && this.currentUser.applicationUserCompany) {
      const companies = this.currentUser.applicationUserCompany.filter(c => c.company.isActive && !c.disabled);
      return companies.sort((one, two) => one.company.shortcut > two.company.shortcut ? 1 : -1);
    }
    return [];
  }

  selectCompany() {
    this.authSerivce.setCompany({ ...this.auth, password: this.password }, this.company);
  }
}