import { UserReportsDateFilter, UserReportsProject, UserReportsMarket, UserReportsPerson, UserReport, UserReportData } from './user-reports.model';
import { TaskActions, START_LOADING, STOP_LOADING, SET_INITIAL_FILTER, SET_PROJECTS, SET_COORDINATORS, SET_MARKETS, SET_RESPONSIBLE_PERSONS, SET_REPORTS, SET_REPORTS_DETAILS, SET_ORDERS } from './user-reports.actions';
import { OrderTask } from 'src/app/redux/order-task/order-task.model';

export interface State {
    isLoading: boolean;
    filterResult: UserReportsDateFilter;
    projects: UserReportsProject[];
    coordinators: UserReportsPerson[];
    markets: UserReportsMarket[];
    responsiblePersons: UserReportsPerson[];
    reports: UserReport[];
    reportsDetails: UserReportData[];    
    orders: OrderTask[],
}

const initialState: State = {
    isLoading: false,
    filterResult: null,
    projects: null,
    coordinators: null,
    markets: null,
    responsiblePersons: null,
    reports: null,
    reportsDetails: null,
    orders: null,
};


export function userReportsReducer(state = initialState, action: TaskActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_INITIAL_FILTER: {
            return {
                ...state,
                filterResult: action.payload
            };
        }
        case SET_PROJECTS: {
            return {
                ...state,
                projects: action.payload
            };
        }
        case SET_COORDINATORS: {
            return {
                ...state,
                coordinators: action.payload
            };
        }
        case SET_MARKETS: {
            return {
                ...state,
                markets: action.payload
            };
        }
        case SET_RESPONSIBLE_PERSONS: {
            return {
                ...state,
                responsiblePersons: action.payload
            };
        }
        case SET_REPORTS: {
            return {
                ...state,
                reports: action.payload
            };
        }
        case SET_REPORTS_DETAILS: {
            return {
                ...state,
                reportsDetails: action.payload
            };
        }
        case SET_ORDERS: {
            return {
                ...state,
                orders: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const isLoading = (state: State) => state.isLoading;
export const filterResult = (state: State) => state.filterResult;
export const projects = (state: State) => state.projects;
export const coordinators = (state: State) => state.coordinators;
export const markets = (state: State) => state.markets;
export const responsiblePersons = (state: State) => state.responsiblePersons;
export const reports = (state: State) => state.reports;
export const reportsDetails = (state: State) => state.reportsDetails;
export const orders = (state: State) => state.orders;