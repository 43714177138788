import { PaginationData } from "../shared/shared.model";

export interface ClientTicketDetails {
    id: number,
    type: ClientTicketType,
    comment: string,
    projectId: number,
    orderTaskId: number,
    localizationId: number,
    localizationDescription: string,
    status: number,
    reportedBy: ClientTicketPerson,
    manager: ClientTicketPerson,
    coordinator: ClientTicketPerson,
    supervisor: ClientTicketPerson,
    responsiblePerson: ClientTicketPerson,
    updatedAt: Date,
    createdAt: Date,
    orderRealizationDate: Date,
    photoFileName1: string,
    photoFileName2: string,
}

export interface ClientTicketListItem {
    id: number,
    type: number,
    orderTaskId: number,
    projectName: string,
    orderTitle: string,
    localizationDetails: string,
    status: number,
    reportedBy: string,
    updatedAt: Date,
    createdAt: Date,
}

export interface ClientTicketListingResponse {
    data: ClientTicketListItem[];
    paginationData: PaginationData;
}

export interface ClientTicketDataGridData {
    data: ClientTicketDataGridItem[];
    totalCount: number;
    groupCount: number;
    summary: number[];
}

export interface ClientTicketFormDetails {
    projectName: string,
    localizationDescription: string
}

export interface ClientTicketDataGridItem {
    id: number,
    type: string,
    orderTaskId: number,
    projectName: string,
    orderTitle: string,
    localizationDetails: string,
    status: string,
    reportedBy: string,
    updatedAt: Date,
    createdAt: Date,
}

export interface ClientTicketPerson {
    applicationUserId: string,
    firstName: string,
    lastName: string
}

export interface ClientTicketMessage {
    isError: boolean,
    message: string,
}

export enum ClientTicketType {
    Uwaga = 0,
    Pochwała = 1
}

export enum ClientTicketStatus {
    Nowe = 0,
    WTrakcie = 1,
    Zrobione = 2,
    Anulowane = 3
}