import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../app.reducer';

@Component({
  selector: 'app-password-reminder',
  templateUrl: './password-reminder.component.html',
  styleUrls: ['./password-reminder.component.scss']
})
export class PasswordReminderComponent implements OnInit {

  isLoading$: Observable<boolean>;
  model: any = {};

  constructor(private authService: AuthService, private store: Store<fromRoot.IRootState>) { }

  ngOnInit() {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
  }

  onSubmit() {
    this.authService.forgotPassword({
      email: this.model.email,
      username: this.model.username,
      language: localStorage.getItem('language')
    });
  }

}
