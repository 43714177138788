import { Company } from './company.model';
import {
    CompanyActions,
    SELECT_ACTIVE,
    REMOVE,
    UPDATE,
    ADD,
    STOP_EDITING,
    START_EDITING,
    SET_AVAILABLE_COMPANIES,
    START_LOADING,
    STOP_LOADING,
    STOP_EDITING_CONTACT,
    START_EDITING_CONTACT,
    SET_AVAILABLE_HR_COMPONENTS,
    SET_AVAILABLE_GROUPED_HR_COMPONENTS,
    SET_AVAILABLE_HR_COMPONENT_GROUPS,
    ADD_HR_COMPONENT_GROUP,
    REMOVE_HR_COMPONENT_GROUP
} from './company.actions';
import { HrComponent, HrcomponentType } from 'src/app/_models/hrComponent.model';
import { GroupHrElement } from './group-hr-element.model';

export interface State {
    companies: Company[];
    hrComponents: HrComponent[];
    hrGroupedComponents: HrComponent[];
    hrComponentGroups: GroupHrElement[];
    selectedCompany: Company;
    isEditing: boolean;
    isEditingContact: boolean;
    isLoading: boolean;
}
const initialState: State = {
    hrComponentGroups: [],
    hrComponents: [],
    hrGroupedComponents: [],
    companies: [],
    selectedCompany: null,
    isEditing: false,
    isEditingContact: false,
    isLoading: false
};

export function companyReducer(state = initialState, action: CompanyActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECT_ACTIVE: {
            return {
                ...state,
                selectedCompany: action.payload
            };
        }
        case REMOVE: {
            return {
                ...state,
                companies: state.companies.filter(c => c.id !== action.payload)
            };
        }
        case UPDATE: {
            const index = state.companies.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            const updatedCompanies = [
                ...state.companies.slice(0, index),
                Object.assign({}, state.companies[index], action.payload),
                ...state.companies.slice(index + 1)
            ];

            const id = localStorage.getItem('lastCompanyId');
            let companyInsideForm = state.selectedCompany;
            if (id === action.payload.id) {
                companyInsideForm = action.payload;
            }

            return {
                ...state,
                companies: updatedCompanies,
                selectedCompany: companyInsideForm
            };
        }
        case ADD: {
            return {
                ...state,
                companies: [...state.companies, action.payload]
            };
        }
        case STOP_EDITING_CONTACT:
            return {
                ...state,
                isEditingContact: false
            };
        case START_EDITING_CONTACT:
            return {
                ...state,
                isEditingContact: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case SET_AVAILABLE_COMPANIES:
            return {
                ...state,
                companies: action.payload
            };
        case SET_AVAILABLE_HR_COMPONENTS:
            return {
                ...state,
                hrComponents: action.payload
            };
        case SET_AVAILABLE_GROUPED_HR_COMPONENTS:
            return {
                ...state,
                hrGroupedComponents: action.payload
            };
        case SET_AVAILABLE_HR_COMPONENT_GROUPS: {
            return {
                ...state,
                hrComponentGroups: action.payload
            };
        }
        case ADD_HR_COMPONENT_GROUP: {
            return {
                ...state,
                hrComponentGroups: [...state.hrComponentGroups, action.payload]
            };
        }
        case REMOVE_HR_COMPONENT_GROUP: {
            return {
                ...state,
                hrComponentGroups: state.hrComponentGroups.filter(c => c.id !== action.payload.id)
            };
        }
        default: {
            return state;
        }
    }
}

export const companies = (state: State) => state.companies;
export const hrComponents = (state: State) => state.hrComponents;
export const hrComponentGroups = (state: State) => state.hrComponentGroups;
export const hrGroupedComponents = (state: State) => state.hrGroupedComponents;
export const isEditingContact = (state: State) => state.isEditingContact;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedCompany = (state: State) => state.selectedCompany;
