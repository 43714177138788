import { Action } from '@ngrx/store';
import { Service, ServiceDetails } from './service.model';

export const SET_AVAILABLE_SERVICES = '[Service] Set available services';
export const SET_USER_SERVICES = '[Service] Set user services';
export const START_EDITING = '[Service] Start editing';
export const STOP_EDITING = '[Service] Stop editing';
export const ADD = '[Service] Add new service';
export const REMOVE = '[Service] Remove service';
export const UPDATE = '[Service] Update service';
export const SELECTED_SERVICE = '[Service] Selected service';
export const SELECTED_SERVICE_PROJECT_KIND = '[Service] Selected service project kind';
export const START_LOADING = '[Service] start loading';
export const STOP_LOADING = '[Service] stop loading';
export const START_SM_LOADING = '[Service] start Savings Managment loading';
export const STOP_SM_LOADING = '[Service] stop Savings Managment loading';
export const SET_RELOAD_DATA_GRID = '[Service] set reload data grid';
export const SET_ERROR = '[Service] set error';
export const SET_TOTAL_SAVINGS = '[Service] set total savings';

export class SetAvailableServices implements Action {
    readonly type = SET_AVAILABLE_SERVICES;
    constructor(public payload: Service[]) { }
}
export class SetAvailableServicesForUser implements Action {
  readonly type = SET_USER_SERVICES;
  constructor(public payload: Service[]) { }
}
export class StartSavingsManagmentLoading implements Action {
    readonly type = START_SM_LOADING;
}
export class StopSavingsManagmentLoading implements Action {
    readonly type = STOP_SM_LOADING;
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Service) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Service) { }
}
export class SetSelectedService implements Action {
    readonly type = SELECTED_SERVICE;
    constructor(public payload: ServiceDetails) { }
}
export class SetSelectedServiceProjectKind implements Action {
    readonly type = SELECTED_SERVICE_PROJECT_KIND;
    constructor(public payload: number) { }
}
export class SetReloadDataGrid implements Action {
    readonly type = SET_RELOAD_DATA_GRID;
    constructor(public payload: boolean) { }
}
export class SetError implements Action {
    readonly type = SET_ERROR;
    constructor(public payload: boolean) { }
}
export class SetTotalSavings implements Action {
    readonly type = SET_TOTAL_SAVINGS;
    constructor(public payload: number) { }
}

export type ServiceActions =
    SetAvailableServices
    | SetAvailableServicesForUser
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | Remove
    | Update
    | SetSelectedService
    | SetReloadDataGrid
    | SetError
    | SetTotalSavings
    | StartSavingsManagmentLoading
    | StopSavingsManagmentLoading
    | SetSelectedServiceProjectKind;
