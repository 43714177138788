import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as CustomFieldsActions from 'src/app/redux/custom-fields/custom-field.actions';
import { environment } from 'src/environments/environment';
import { CustomField, CustomPage, SystemArea, ValueType } from 'src/app/redux/custom-fields/custom-filed.model';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CustomFieldService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();

      this.unSelectCustomField();
  }

  stopEdit() {
    this.store.dispatch(new CustomFieldsActions.StopEditing());
  }

  startEdit() {
    this.store.dispatch(new CustomFieldsActions.StartEditing());
  }

  selectCustomField(customField: CustomField) {
    this.store.dispatch(new CustomFieldsActions.SetSelectedCustomField(customField));
  }

  unSelectCustomField() {
    this.store.dispatch(new CustomFieldsActions.SetSelectedCustomField(null));
  }

  getCustomFields() {
    this.store.dispatch(new CustomFieldsActions.StartLoading());
    this.http.get<CustomField[]>(environment.apiUrl + '/customField/field')
      .toPromise()
      .then(
        (customFields) => {
          this.store.dispatch(new CustomFieldsActions.SetAvailableCustomFields(customFields));
          this.store.dispatch(new CustomFieldsActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new CustomFieldsActions.SetAvailableCustomFields(null));
        this.store.dispatch(new CustomFieldsActions.StopLoading());
        console.error(err);
    });
  }

  getCustomPages() {
    this.store.dispatch(new CustomFieldsActions.StartLoading());
    this.http.get<CustomPage[]>(environment.apiUrl + '/customField/page')
      .toPromise()
      .then(
        (customPages) => {
          this.store.dispatch(new CustomFieldsActions.SetAvailableCustomPages(customPages));
          this.store.dispatch(new CustomFieldsActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new CustomFieldsActions.SetAvailableCustomPages(null));
        this.store.dispatch(new CustomFieldsActions.StopLoading());
        console.error(err);
    });
  }

  removePage(customPageId: number) {
    console.log('[Custom filed service] remove page');
    this.store.dispatch(new CustomFieldsActions.StartLoading());
    this.http.delete<CustomField>(environment.apiUrl + '/customField/page/' + customPageId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new CustomFieldsActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new CustomFieldsActions.StopLoading());
                console.log(error);
            }
        );
  }

  removeField(customFieldId: number) {
    console.log('[Custom filed service] remove field');
    this.store.dispatch(new CustomFieldsActions.StartLoading());
    this.http.delete<CustomField>(environment.apiUrl + '/customField/field/' + customFieldId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new CustomFieldsActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new CustomFieldsActions.StopLoading());
                console.log(error);
            }
        );
  }

  setCustomPagesList(customPages: CustomPage[]) {
    this.store.dispatch(new CustomFieldsActions.SetAvailableCustomPages(customPages));
  }

  setCustomFieldsList(customFields: CustomField[]) {
    this.store.dispatch(new CustomFieldsActions.SetAvailableCustomFields(customFields));
  }

  createPage(customPage: any) {
    console.log('[custom page service] create');
    this.store.dispatch(new CustomFieldsActions.StartLoading());
    this.http.post<CustomPage>(environment.apiUrl + '/customField/page', customPage)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.store.dispatch(new CustomFieldsActions.StopLoading());
            }, (error) => {
                this.store.dispatch(new CustomFieldsActions.StopLoading());
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
            }
        );
  }

  createField(formData: FormData) {
    console.log('[custom field service] create');
    this.store.dispatch(new CustomFieldsActions.StartLoading());

    this.http.post<CustomField>(environment.apiUrl + '/customField/field', formData)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Pole zostało dodane');
                this.store.dispatch(new CustomFieldsActions.StopLoading());
            }, (error) => {
                this.store.dispatch(new CustomFieldsActions.StopLoading());
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
            }
        );
  }
}
