import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { Product } from 'src/app/redux/project/product.model';
import { Contractor } from 'src/app/redux/contractor/contractor.model';
import { Subject, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectKind } from 'src/app/redux/contractor/project-kind.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { ContractorProjectKind } from 'src/app/redux/contractor/contractor-project-kind.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CentralPromotion } from 'src/app/redux/central-promotion/central-promotion.model';
import { Project, ProjectSimple } from 'src/app/redux/project/project.model';
import { CentralPromotionService } from 'src/app/services/central-promotion/central-promotion.service';
import { MatDatepickerInputEvent, MatSelect, MatSelectChange } from '@angular/material';
import { Localization, Market } from 'src/app/redux/localization/localization.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-central-promotion-details',
  templateUrl: './central-promotion-details.component.html',
  styleUrls: ['./central-promotion-details.component.css']
})
export class CentralPromotionDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  model: CentralPromotion = {};
  allProjects: Project[] = [];
  allProjectsSimple: ProjectSimple[] = [];
  allMarkets: Market[] = [];
  isEditing = false;
  centralPromotionApp: CentralPromotion;
  selectedProject: ProjectSimple = {};
  selectedMarket: Market = {};
  public fullScreen: boolean;
  isLoadingMarkets: boolean = true;

  searchControl: FormControl = new FormControl();
  private _filteredProjects: ProjectSimple[] = [];
  filteredProjects: ReplaySubject<ProjectSimple[]> = new ReplaySubject<Project[]>(1);
  @ViewChild('projectSelect', { static: false }) selectElem: MatSelect;
  projectSelectOpened = false;

  // selectBox Market
  searchControl2: FormControl = new FormControl();
  private _filteredMarkets: Market[] = [];
  filteredMarkets: ReplaySubject<Market[]> = new ReplaySubject<Localization[]>(1);
  @ViewChild('marketSelect', { static: false }) selectElem2: MatSelect;
  marketSelectOpened = false;

  constructor(
    private localizationService: LocalizationService,
    public auth: AuthService,
    private service: CentralPromotionService,
    private store: Store<fromRoot.IRootState>,
    private dialogRef: MatDialogRef<CentralPromotionDetailsComponent>,
    private render: Renderer2) {}

  ngOnInit() {
    this.store.select(fromRoot.getLocalizationMarketsIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((isLoading) => {
        this.isLoadingMarkets = isLoading;
      });
    this.store.select(fromRoot.getCentralPromotionSelected)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        res => {
          if (res !== null) {
            this.centralPromotionApp = res;
            this.model = { ...this.centralPromotionApp };
          } else {
            this.centralPromotionApp = {};
            this.model = {};
          }
      });

    this.store.select(fromRoot.getCentralPromotionIsEditing)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(res => this.isEditing = res);

    this.store.select(fromRoot.getProjectsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projects) => {
        this.allProjects = projects;
        this.allProjectsSimple.splice(0);
        projects.forEach(element => {
          this.allProjectsSimple.push({
            id: element.id,
            name: element.name,
            projectKind: element.projectKind,
            contractor: element.contractor });
        })
        this.filterProjects();
      });

    this.store.select(fromRoot.getMarketsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((markets) => {
        if (markets && markets.length > 0) {
          this.allMarkets = markets;
        }
        this.filterMarkets();
      });
  }

  ngAfterViewInit() {
    if (this.allMarkets.length === 0) {
      this.localizationService.getMarkets();
    }
    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.projectSelectOpened || this._filteredProjects.length === 0) {
          this.filterProjects();
        }
      });

    this.searchControl2.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.marketSelectOpened || this._filteredMarkets.length === 0) {
          this.filterMarkets();
        }
      });
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  cancel(): void {
    this.service.stopEditing();
    this.model = { ...this.centralPromotionApp };
  }

  save() {
    this.service.stopEditing();
    if (!this.model.id || this.model.id === 0 ) {
      this.service.createCentralPromotion(this.model);
    } else {
      this.service.updateCentralPromotion(this.model);
      this.centralPromotionApp = { ...this.model };
    }
    //this.dialogRef.close();
  }

  startEditing() {
    this.service.startEditing();
  }

  filterProjects() {

    if (!this.allProjectsSimple) {
      return;
    }
    // get the search keyword
    let search = this.searchControl.value;
    if (!search) {
      this.filteredProjects.next(this.allProjectsSimple.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the projects
    this.filteredProjects.next(
      this.allProjectsSimple.filter(l => l.name.toLowerCase().indexOf(search) > -1
      || l.contractor.shortcut.toLowerCase().indexOf(search) > -1)
    );
  }

  filterMarkets() {
    if (!this.allMarkets) {
      return;
    }
    // get the search keyword
    let search = this.searchControl2.value;
    if (!search) {
      this.filteredMarkets.next(this.allMarkets.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the supervisors
    this.filteredMarkets.next(
      this.allMarkets.filter(l => l.code.toLowerCase().indexOf(search) > -1)
    );
  }

  compareFn(project1: ProjectSimple, project2: ProjectSimple) {
    return project1 && project2 ? project1.id === project2.id : false;
  }

  compareFn2(market1: Market, market2: Market) {
    return market1 && market2 ? market1.id === market2.id : false;
  }

  projectSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedProject = e.value;
      this.model.projectId = e.value.id;
      this.model.project.id = e.value.id;
    }
  }

  marketSelectionChange(e: MatSelectChange) {
    if (e.value) {
      this.selectedMarket = e.value;
      this.model.marketId = e.value.id;
      this.model.market.id = e.value.id;
    }
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.model.start = new Date(event.value);
      this.model.startLong = this.model.start.getTime();
    } else {
      this.model.start = null;
      this.model.startLong = 0;
    }
  }

  setDate2(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.model.finish = new Date(event.value);
      this.model.finishLong = this.model.finish.getTime();
    } else {
      this.model.finish = null;
      this.model.finishLong = 0;
    }
  }
  public onFullscreenClick() {
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    const content = document.getElementsByClassName('mat-dialog-content');

    if (this.fullScreen) {
      this.render.removeClass(dialog[0], 'full-screen');
      this.render.removeClass(content[0], 'full-screen');
    } else {
      this.render.addClass(dialog[0], 'full-screen');
      this.render.addClass(content[0], 'full-screen');
    }

    this.fullScreen = !this.fullScreen;
  }

  close() {
    this.cancel();
    this.dialogRef.close();
  }
}
