import { Contact } from 'src/app/contact/contact.component';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetDataSouce, UpdateDataSource, UpdateUser } from '../actions/contact.action';

export class ContactStateModel {
    dataSource: Contact[];
}

@State<ContactStateModel>({
    name: 'contact',
    defaults: {
        dataSource: null
    }
})
export class ContactState {
    @Selector()
    static getDataSource(state: ContactStateModel) {
        return state.dataSource;
    }

    @Action(SetDataSouce)
    setDataSoruce({ patchState }: StateContext<ContactStateModel>, { payload }: SetDataSouce) {
        patchState({
            dataSource: payload
        });
    }

    @Action(UpdateUser)
    updateUser({ patchState, getState }: StateContext<ContactStateModel>, { payload }: UpdateUser) {
        const state = getState();
        const data = state.dataSource.find(c => c.userId === payload.id);
        if (data) {
            data.user = { ...data.user, ...payload };
        }
        patchState({
            dataSource: state.dataSource
        });
    }

    @Action(UpdateDataSource)
    updateDataSoruce({ patchState, getState }: StateContext<ContactStateModel>, { payload }: UpdateDataSource) {
        const state = getState();
        const dataSource = state.dataSource;
        if (dataSource) {
            payload.forEach(c => c._elementsLoaded = false);
            for (const data of dataSource) {
                const user = payload.find(c => c.id === data.userId);
                if (user) {
                    data.user.additionalPermissions = user.additionalPermissions;
                    data.user.blocked = user.blocked;
                    data.user.contactElements = user.contactElements;
                    data.user.croppedPhoto = user.croppedPhoto;
                    data.user.disabled = user.disabled;
                    data.user.email = user.email;
                    data.user.facebookId = user.facebookId;
                    data.user.firstLogin = user.firstLogin;
                    data.user.forceChangePassword = user.forceChangePassword;
                    data.user.hasForeignIds = user.hasForeignIds;
                    data.user.hasPhoto = user.hasPhoto;
                    data.user.isAvailable = user.isAvailable;
                    data.user.lastLogin = user.lastLogin;
                    data.user.lastName = user.lastName;
                    data.user.passwordWasReset = user.passwordWasReset;
                    data.user.roles = user.roles;
                    data.user.userName = user.userName;
                    data.user._elementsLoaded = false;
                    data.user._avatarWasLoaded = user._avatarWasLoaded;
                }
            }
        }

        patchState({
            dataSource: dataSource
        });
    }
}
