import { Action } from '@ngrx/store';
import { CentralPromotion } from './central-promotion.model';

export const SET_AVAILABLE_CENTRAL_PROMOTIONS = '[Central promotion] Set available central promotions';
export const START_EDITING = '[Central promotion] Start editing';
export const STOP_EDITING = '[Central promotion] Stop editing';
export const ADD = '[Central promotion] Add new central promotion';
export const REMOVE = '[Central promotion] Remove central promotion';
export const UPDATE = '[Central promotion] Update central promotion';
export const SELECTED_CENTRAL_PROMOTION = '[Central promotion] Selected central promotion';
export const START_LOADING = '[Central promotion] start loading';
export const STOP_LOADING = '[Central promotion] stop loading';

export class SetAvailableCentralPromotions implements Action {
    readonly type = SET_AVAILABLE_CENTRAL_PROMOTIONS;
    constructor(public payload: CentralPromotion[]) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: CentralPromotion) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: number) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: CentralPromotion) { }
}
export class SetSelectedCentralPromotion implements Action {
    readonly type = SELECTED_CENTRAL_PROMOTION;
    constructor(public payload: CentralPromotion) { }
}

export type CentralPromotionActions =
    SetAvailableCentralPromotions
    | StartLoading
    | StopLoading
    | StartEditing
    | StopEditing
    | Add
    | Remove
    | Update
    | SetSelectedCentralPromotion;
