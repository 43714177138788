import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { User, UserBase } from 'src/app/redux/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { MatSelectChange, MatSelect } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  private allUsers: UserBase[];

  private _user: UserBase;

  @Input() title: string;

  @Output() userChange = new EventEmitter<UserBase>();
  @Input()
  get user() {
    return this._user;
  }
  set user(value: UserBase) {
    this._user = value;
    this.userChange.emit(this._user);
  }
  @Input() set users(users: UserBase[]) {
    this.allUsers = users;
    this.filter();
  }

  private _filteredUsers: UserBase[] = [];
  private _previousSearch = '';
  private _step = 630;
  private _incrementLength = 20;
  RELOAD_TOP_SCROLL_POSITION = this._step;
  @ViewChild('userSelect', { static: false }) selectElem: MatSelect;
  userSelectOpened = false;
  userCtrl = new FormControl();
  filteredUsers: ReplaySubject<UserBase[]> = new ReplaySubject<UserBase[]>(1);
  searchControl: FormControl = new FormControl();

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.userSelectOpened || this._filteredUsers.length === 0) {
          this.filter();
        }
      });
    this.setTimeOut();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filter() {
    this.allUsers = this.allUsers.sort((one, two) => one.fullName.toLowerCase().localeCompare(two.fullName.toLowerCase()));
    if (!this.allUsers) {
      return;
    }
    // get the search keyword
    let search = this.searchControl.value;
    if (!search) {
      this.filteredUsers.next(this.allUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the supervisors
    this.filteredUsers.next(
      this.allUsers.filter(user => user.lastName.toLowerCase().indexOf(search) > -1
      || user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  // filter() {
  //   if (!this.allUsers || this.allUsers.length === 0) {
  //     return;
  //   }

  //   let search = this.searchControl.value;
  //   if (search !== this._previousSearch) {
  //     this._filteredUsers = [];
  //     this.RELOAD_TOP_SCROLL_POSITION = this._step;
  //   }

  //   if (!search) {
  //     this._filteredUsers = this.allUsers
  //       .slice(0, this._filteredUsers.length + this._incrementLength);

  //     if (this.user && !this._filteredUsers.some(row => row.id === this.user.id)) {
  //       this._filteredUsers.push(this.user);
  //     }

  //     this.filteredUsers.next(this._filteredUsers);
  //     this._previousSearch = search;
  //     return;
  //   }
  //   search = search.toLowerCase();
  //   const users = this.allUsers.filter(u =>
  //     (u.userName.toLowerCase().indexOf(search) > -1
  //       || u.lastName.toLowerCase().indexOf(search) > -1
  //       || u.firstName.toLowerCase().indexOf(search) > -1
  //     )
  //   );

  //   this._filteredUsers = users.slice(0, this._filteredUsers.length + this._incrementLength);
  //   if (this.user && !this._filteredUsers.some(row => row.id === this.user.id)) {
  //     this._filteredUsers.push(this.user);
  //   }
  //   this.filteredUsers.next(this._filteredUsers);

  //   this._previousSearch = search;
  // }

  setTimeOut() {
    setTimeout(() => {
      if (!this.initSelect()) {
        this.setTimeOut();
      }
    }, 500);
  }

  initSelect() {
    if (!this.selectElem) {
      return false;
    }
    this.selectElem.openedChange
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (value) => {
          if (value) {
            this._filteredUsers = [];
            this.userSelectOpened = true;
            this.registerPanelScrollEvent();
            this.filter();
          } else {
            this.RELOAD_TOP_SCROLL_POSITION = this._step;
            this.userSelectOpened = false;
            this._filteredUsers = [];
            this.filter();
          }
        },
      );
    return true;
  }

  registerPanelScrollEvent() {
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener('scroll', event => this.loadAllOnScroll(event));
  }

  loadAllOnScroll(event) {
    if (event.target.scrollTop > this.RELOAD_TOP_SCROLL_POSITION) {
      this.RELOAD_TOP_SCROLL_POSITION += this._step;
      this.filter();
    }
  }

  // getFacebookId() {
  //   if (this.user._avatar || !this.user.facebookId) {
  //     return null;
  //   }
  //   return this.user.facebookId;
  // }

  // getPhoto() {
  //   if (!this.user) {
  //     return null;
  //   }

  //   return this.userService.getAvatar(this.user);
  // }

  selectionChange(e: MatSelectChange) {
    this.user = null;
    if (e.value) {
      this.user = e.value;
    }
  }

}
