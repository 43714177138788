import { Action } from '@ngrx/store';
import { Company } from './company.model';
import { HrComponent } from 'src/app/_models/hrComponent.model';
import { GroupHrElement } from './group-hr-element.model';

export const SET_AVAILABLE_COMPANIES = '[Company] Set available Companies';
export const SELECT_ACTIVE = '[Company] Set company';
export const START_EDITING = '[Company] Start editing';
export const STOP_EDITING = '[Company] Stop editing';
export const ADD = '[Company] Add new Company';
export const REMOVE = '[Company] Remove Company';
export const UPDATE = '[Company] Update Company';
export const START_LOADING = '[Company] start loading';
export const STOP_LOADING = '[Company] stop loading';

export const START_EDITING_CONTACT = '[Contact] Start editing';
export const STOP_EDITING_CONTACT = '[Contact] Stop editing';

export const SET_AVAILABLE_HR_COMPONENTS = '[HR] SET_AVAILABLE_HR_COMPONENTS';
export const SET_AVAILABLE_GROUPED_HR_COMPONENTS = '[HR] SET_AVAILABLE_GROUPED_HR_COMPONENTS';
export const SET_AVAILABLE_HR_COMPONENT_GROUPS = '[HR] SET_AVAILABLE_HR_COMPONENT_GROUPS';
export const ADD_HR_COMPONENT_GROUP = '[HR] ADD_HR_COMPONENT_GROUP';
export const REMOVE_HR_COMPONENT_GROUP = '[HR] REMOVE_HR_COMPONENT_GROUP';

export class RemoveHrComponentGroup implements Action {
    readonly type = REMOVE_HR_COMPONENT_GROUP;
    constructor(public payload: GroupHrElement) { }
}
export class AddHrComponentGroup implements Action {
    readonly type = ADD_HR_COMPONENT_GROUP;
    constructor(public payload: GroupHrElement) { }
}
export class SetAvailableHrComponentGroups implements Action {
    readonly type = SET_AVAILABLE_HR_COMPONENT_GROUPS;
    constructor(public payload: GroupHrElement[]) { }
}
export class SetAvailableGroupedComponents implements Action {
    readonly type = SET_AVAILABLE_GROUPED_HR_COMPONENTS;
    constructor(public payload: HrComponent[]) { }
}
export class SetAvailableComponents implements Action {
    readonly type = SET_AVAILABLE_HR_COMPONENTS;
    constructor(public payload: HrComponent[]) { }
}
export class SetAvailableCompanies implements Action {
    readonly type = SET_AVAILABLE_COMPANIES;
    constructor(public payload: Company[]) { }
}
export class SelectActive implements Action {
    readonly type = SELECT_ACTIVE;
    constructor(public payload: Company) { }
}
export class StartEditing implements Action {
    readonly type = START_EDITING;
}
export class StopEditing implements Action {
    readonly type = STOP_EDITING;
}
export class StartEditingContact implements Action {
    readonly type = START_EDITING_CONTACT;
}
export class StopEditingContact implements Action {
    readonly type = STOP_EDITING_CONTACT;
}
export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: Company) { }
}
export class Remove implements Action {
    readonly type = REMOVE;
    constructor(public payload: string) { }
}
export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Company) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export type CompanyActions =
    SetAvailableCompanies
    | SetAvailableGroupedComponents
    | SetAvailableHrComponentGroups
    | AddHrComponentGroup
    | RemoveHrComponentGroup
    | SelectActive
    | SetAvailableComponents
    | StartEditing
    | StopEditing
    | StartEditingContact
    | StopEditingContact
    | Add
    | Remove
    | Update
    | StartLoading
    | StopLoading;
