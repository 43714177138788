import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeFormDialogComponent } from 'src/app/shared/password-change-form/password-change-form-dialog.component';
import { AppHubConnection } from 'src/app/redux/user/hub_connection.model';
import { Company } from 'src/app/redux/company/company.model';
import { ScreenService } from 'src/app/redux/screen-state/screen.service';
import { NavigationService, Page } from 'src/app/services/navigation.service';

export const DRAWER_STATE = 'drawer-state';

export enum DeviceType {
  mobile,
  normal
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: false }) drawer;
  private _onDestroy = new Subject<void>();
  sidenavClosed = true;
  isAuth: boolean;
  customCollapsedHeight = '48px';
  customExpandedHeight = '48px';
  sidenavWidth = 3.6;
  showDelay = new FormControl(500);
  user: ApplicationUser;
  company: Company;
  sideWidth = 11;
  mode = 'side';
  device = DeviceType.normal;
  language = 'ru';
  importStatus: string;
  importProgress = 0;
  online: AppHubConnection[] = [];
  isMobile = false;

  fafafa = false;

  constructor(
    private store: Store<fromRoot.IRootState>,
    private screen: ScreenService,
    private router: Router,
    public auth: AuthService,
    private dialog: MatDialog,
    private navService: NavigationService,
    private translate: TranslateService) {
    if (window.innerWidth < 598) {
      this.mode = 'over';
      this.device = DeviceType.mobile;
      if (!this.sidenavClosed) {
        this.toggle();
      }
    } else {
      this.mode = 'side';
      this.device = DeviceType.normal;
    }
  }

  ngOnInit() {
    this.navService.navigatedPage$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(page => {
        switch (page) {
          // case Page.home:
          // case Page.contact_user:
          //   this.fafafa = true;
          //   break;

          default:
            this.fafafa = false;
            break;
        }
      });
    this.screen.mobile$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => this.isMobile = c);
    this.auth.toolbarToggled
      .pipe(takeUntil(this._onDestroy))
      .subscribe(_ => this.toggle());
    this.store.select(fromRoot.importStatus)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(importStatus => this.importStatus = importStatus);
    this.store.select(fromRoot.getIsAuth)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => this.isAuth = res);
    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(user => this.user = user);
    this.store.select(fromRoot.getCurrentCompany)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(company => this.company = company);
    this.store.select(fromRoot.importProggress)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(importProgress => this.importProgress = importProgress);
    this.store.select(fromRoot.getUserOnline)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((online) => {
        this.online = online;
      });

    const key = localStorage.getItem(DRAWER_STATE);
    // if (!key) {
    //   localStorage.setItem(DRAWER_STATE, DeviceType.normal.toString());
    // }
    if (key === '1') {
      this.sidenavClosed = false;
      this.sidenavWidth < 6
        ? this.sidenavWidth = this.sideWidth
        : this.sidenavWidth = 3.6;
    }

    // set language
    const value = localStorage.getItem('language');
    switch (value) {
      case 'en': {
        this.language = 'en';
        break;
      }
      case 'ru': {
        this.language = 'ru';
        break;
      }
      case 'de': {
        this.language = 'de';
        break;
      }
      case 'ua': {
        this.language = 'ua';
        break;
      }
      default: {
        this.language = 'pl';
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  isOnline(id): boolean {
    return this.online.some(c => c.userIdentifier === id);
  }

  doImport() {
    this.auth.doImport();
  }

  getFacebookId(user) {
    if (!user.facebookId || user.avatar) {
      return null;
    }
    return user.facebookId;
  }

  getPhoto(user) {
    if (user.avatar) {
      return user.avatar;
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    // tslint:disable-next-line:no-unused-expression
    if (width < 598) {
      if (!this.sidenavClosed) {
        this.toggle();
      }
      this.mode = 'over';
      this.device = DeviceType.mobile;
      // localStorage.setItem(DRAWER_STATE, DeviceType.mobile.toString());
    } else {
      this.mode = 'side';
      this.device = DeviceType.normal;
      // localStorage.setItem(DRAWER_STATE, DeviceType.normal.toString());
    }

  }

  changePassword() {
    this.dialog.open(PasswordChangeFormDialogComponent);
  }

  get companyList() {
    if (!this.user || !this.user.applicationUserCompany) {
      return [];
    }
    const companies = this.user.applicationUserCompany.filter(c => c.companyId !== this.company.id && c.company.isActive
      && !c.disabled);

    return companies.sort((one, two) => one.company.shortcut > two.company.shortcut ? 1 : -1);
  }

  changeLanguage(lang) {
    this.language = lang;
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

  navigationTo(path) {
    this.onNavItemClick();
    this.router.navigate([path]);
  }

  logout() {
    this.onNavItemClick();
    this.auth.logout();
  }

  onNavItemClick() {
    if (this.device === DeviceType.mobile && !this.sidenavClosed) {
      this.toggle();
    }
  }

  openedChage(e) {
    if (!e && !this.sidenavClosed) {
      this.sidenavClosed = !this.sidenavClosed;
      this.sidenavWidth < 6
        ? this.sidenavWidth = this.sideWidth
        : this.sidenavWidth = 3.6;
    }
  }

  changeCompany(company) {
    this.auth.changeCompany(company);
  }

  toggle() {
    !this.sidenavClosed
      ? localStorage.setItem(DRAWER_STATE, DeviceType.mobile.toString())
      : localStorage.setItem(DRAWER_STATE, DeviceType.normal.toString());

    this.drawer.toggle();
    if (!this.sidenavClosed) {
      setTimeout(() => {
        this.sidenavClosed = !this.sidenavClosed;
      }, 50);
      setTimeout(() => {
        this.sidenavWidth < 6
          ? this.sidenavWidth = this.sideWidth
          : this.sidenavWidth = 3.6;
      }, 420);

    } else {
      setTimeout(() => {
        this.sidenavClosed = !this.sidenavClosed;
      }, 30);
      this.sidenavWidth < 6
        ? this.sidenavWidth = this.sideWidth
        : this.sidenavWidth = 3.6;
    }
  }
}
