import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryOrder, NgxGalleryImageSize, NgxGalleryComponent } from 'ngx-gallery';
import { ActivatedRoute, Router } from '@angular/router';
import 'hammerjs';
import * as fromRoot from '../app.reducer';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskPhoto, TasksGallery } from '../redux/order-task/order-task.model';
import { OrderTaskService } from '../services/order-task/order-task.service';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private _onDestroy = new Subject<void>();
  galleryOptionsOnlyThumbnails: NgxGalleryOptions[][] = [];
  galleryOptionsImage: NgxGalleryOptions[][] = [];
  galleryImages: NgxGalleryImage[][] = [];
  tasksGallery: TasksGallery[] = [];
  thumbnails = false;
  gallery = true;
  details = false;
  isLoading: boolean;
  isChrome = window.navigator.userAgent.indexOf('Chrome') >= 0;
  selectedTasksIds: number[] = [];

  config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  @ViewChild('scrollableContent', { static: false }) componentRef: PerfectScrollbarComponent;
  @ViewChild('area', { static: false }) area: NgxGalleryComponent;

  constructor(private service: OrderTaskService,
    private store: Store<fromRoot.IRootState>,
    private route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.service.getTasksGallery(this.selectedTasksIds);
  }


  scrollToBottom() {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const idParam = params['tasksIds'];
      this.selectedTasksIds = idParam.split(',');
    });

    this.store.select(fromRoot.getTaskGalleryIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => this.isLoading = isLoading);

    this.store.select(fromRoot.getTasksGalleryList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.tasksGallery = res;
        this.setGalleryImages();
        this.calculateImagesOptions();
      });

  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  close() {
    this.galleryOptionsImage.splice(0);
    this.galleryOptionsOnlyThumbnails.splice(0);
    this.tasksGallery.splice(0);
    this.service.clearTaskGallery();
    window.close();
  }

  downloadPhoto(photo: TaskPhoto) {
    this.service.downloadPhoto(photo.attachemntId, photo.fileName, photo.fileType);
  }

  downloadGallery() {
    let taskIds: number[] = [];
    this.tasksGallery.forEach(gallery => {
      gallery.taskPhotos.forEach(image => {
        if (!taskIds.includes(image.taskId))
          taskIds.push(image.taskId);
      });
    });
    this.service.downloadGallery(taskIds, 'Images.zip', 'application/zip');
  }

  setGalleryImages() {
    let i = 0;
    this.galleryImages.splice(0);
    this.tasksGallery.forEach(element => {
      this.galleryImages.push([]);
      element.taskPhotos.forEach(taskPhoto => {
        this.galleryImages[i].push({
          small: 'data:image/jpeg;base64,' + taskPhoto.photoBase64,
          medium: 'data:image/jpeg;base64,' + taskPhoto.photoBase64,
          big: 'data:image/jpeg;base64,' + taskPhoto.photoBase64,
          //opis pod zdjęciem
          //description: taskPhoto.description
        });
      });
      i = i + 1;
    });
  }

  setThumbnails() {
    this.thumbnails = true;
    this.gallery = false;
    this.details = false;
  }

  setGallery() {
    this.thumbnails = false;
    this.gallery = true;
    this.details = false;
    console.log(this.area);
  }

  setDetails() {
    this.thumbnails = false;
    this.gallery = false;
    this.details = true;
  }

  calculateImagesOptions() {
    this.galleryOptionsOnlyThumbnails = [[]];
    this.galleryOptionsImage = [[]];
    let i = 0;
    this.galleryImages.forEach(element => {
      this.galleryOptionsImage.push();
      this.galleryOptionsOnlyThumbnails.push();
      this.createGalleryOptions(element.length, i);
      i = i + 1;
    });
  }

  createGalleryOptions(imagesCount: number, elementNumber: number) {
    const thumbnailsColumns1 = 6;
    const thumbnailsColumns2 = 4;
    let thumbnailsRows1 = 1;
    let thumbnailsRows2 = 1;
    let height1 = '200px';
    let height2 = '200px';

    if (imagesCount <= 4) {
      thumbnailsRows1 = 1;
      thumbnailsRows2 = 1;
      height1 = '200px';
      height2 = '200px';
    }
    if (imagesCount > 4 && imagesCount <= 6) {
      thumbnailsRows1 = 1;
      thumbnailsRows2 = 2;
      height1 = '200px';
      height2 = '400px';
    }
    if (imagesCount > 6 && imagesCount <= 8) {
      thumbnailsRows1 = 2;
      thumbnailsRows2 = 2;
      height1 = '400px';
      height2 = '400px';
    }
    if (imagesCount > 8 && imagesCount <= 12) {
      thumbnailsRows1 = 2;
      thumbnailsRows2 = 3;
      height1 = '400px';
      height2 = '600px';
    }
    if (imagesCount > 12 && imagesCount <= 16) {
      thumbnailsRows1 = 3;
      thumbnailsRows2 = 4;
      height1 = '600px';
      height2 = '800px';
    }
    if (imagesCount > 16 && imagesCount <= 18) {
      thumbnailsRows1 = 3;
      thumbnailsRows2 = 5;
      height1 = '600px';
      height2 = '1000px';
    }
    if (imagesCount > 18 && imagesCount <= 20) {
      thumbnailsRows1 = 4;
      thumbnailsRows2 = 5;
      height1 = '800px';
      height2 = '1000px';
    }
    this.galleryOptionsOnlyThumbnails[elementNumber] = [
      {
        width: '100%',
        height: height1,
        thumbnailsColumns: thumbnailsColumns1,
        thumbnailsRows: thumbnailsRows1,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewFullscreen: true,
        previewDownload: true,
        imageDescription: true,
        previewBullets: true,
        previewAnimation: false,
        lazyLoading: this.isChrome,
        image: false,
        thumbnailsOrder: NgxGalleryOrder.Page,
        thumbnailSize: NgxGalleryImageSize.Contain
      },
      //max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: height2,
        thumbnailsPercent: 80,
        thumbnailsColumns: thumbnailsColumns2,
        thumbnailsRows: thumbnailsRows2,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryOptionsImage[elementNumber] = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        previewFullscreen: true,
        previewDownload: true,
        previewAnimation: false,
        imageDescription: true,
        previewBullets: true,
        lazyLoading: this.isChrome,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }
  onScrollDownEvent(event: any) {
    //this.componentRef.directiveRef.scrollToBottom();
  }

  onScrollUpEvent(event: any) {
    //this.componentRef.directiveRef.scrollToTop();
  }

  mouseenter(event: any) {
    //this.area.show(0);
  }
}

