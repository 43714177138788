import * as fromRoot from 'src/app/app.reducer';
import {
    CompanyActions,
    HIDE_PARENT_TAB,
    SHOW_PARENT_TAB,
    CAN_HIDE_PARENT_TAB,
    SET_SECURITY,
    SET_PASSWORD_CONFIG,
    SET_USER_LIST_FILTER,

} from './company.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PasswordPolicy } from './security/password-policy-form/password-policy.model';
import { Security } from 'src/app/_models/security.model';
import { UserTableFilterModel } from './company-user/user-table-filter/user-table-filter.model';

export interface CompanyState {
    hideParentTab: boolean;
    canHideParentTab: boolean;
    security: Security;
    passwordConfig: PasswordPolicy;
    userTableFilter: UserTableFilterModel;
}

export interface State extends fromRoot.IRootState {
    companies: CompanyState;
}

const initialState: CompanyState = {
    hideParentTab: false,
    canHideParentTab: true,
    security: null,
    passwordConfig: null,
    userTableFilter: null,
};

export function companyReducer(state = initialState, action: CompanyActions) {
    switch (action.type) {
        case SET_USER_LIST_FILTER: {
            return {
                ...state,
                userTableFilter: action.payload
            };
        }
        case SET_SECURITY: {
            return {
                ...state,
                security: action.payload
            };
        }
        case SET_PASSWORD_CONFIG: {
            return {
                ...state,
                passwordConfig: action.payload
            };
        }
        case CAN_HIDE_PARENT_TAB: {
            return {
                ...state,
                canHideParentTab: action.payload
            };
        }
        case HIDE_PARENT_TAB: {
            return {
                ...state,
                hideParentTab: true
            };
        }
        case SHOW_PARENT_TAB: {
            return {
                ...state,
                hideParentTab: false
            };
        }
        default: {
            return state;
        }
    }
}

export const getCompanyState = createFeatureSelector<CompanyState>('company');
export const hideParentTab = createSelector(getCompanyState, (state: CompanyState) => state.hideParentTab);
export const canHideParentTab = createSelector(getCompanyState, (state: CompanyState) => state.canHideParentTab);
export const getSecurity = createSelector(getCompanyState, (state: CompanyState) => state.security);
export const getPasswordPolicy = createSelector(getCompanyState, (state: CompanyState) => state.passwordConfig);
export const getUserTableFilter = createSelector(getCompanyState, (state: CompanyState) => state.userTableFilter);
