import { AccessConfig } from './access-config.model';
import {
    AccessConfigActions,
    ADD,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_ACCESS_CONFIGS,
    UPDATE,
    REMOVE,
    START_LOADING,
    STOP_LOADING
} from './access-config.actions';

export interface State {
    accessConfigs: AccessConfig[];
    selectedAccessConfig: AccessConfig;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    accessConfigs: [],
    selectedAccessConfig: null,
    isEditing: false,
    isLoading: false
};

export function accessConfigReducer(state = initialState, action: AccessConfigActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case ADD: {
            return {
                ...state,
                accessConfigs: [...state.accessConfigs, action.payload]
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_ACCESS_CONFIGS: {
            return {
                ...state,
                accessConfigs: action.payload
            };
        }
        case UPDATE: {
            const index = state.accessConfigs.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selProject = state.selectedAccessConfig;
            if (selProject && selProject.id === action.payload.id) {
              selProject = action.payload;
            }
            const updatedGroups = [
                ...state.accessConfigs.slice(0, index),
                Object.assign({}, state.accessConfigs[index], action.payload),
                ...state.accessConfigs.slice(index + 1)
            ];
            return {
                ...state,
                accessConfigs: updatedGroups,
                selectedAccessConfig: selProject
            };
        }
        case REMOVE: {
            return {
                ...state,
                accessConfigs: state.accessConfigs.filter(c => c.id !== action.payload)
            };
        }
        default: {
            return state;
        }
    }
}

export const accessConfigs = (state: State) => state.accessConfigs;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedAccessConfig = (state: State) => state.selectedAccessConfig;
