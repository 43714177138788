import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface MessageModel {
    titleKey?: string;
    contentKey?: string;
}

@Component({
    selector: 'app-oos-confirmation-dialog',
    template: `
        <h2 mat-dialog-title *ngIf="model.titleKey">{{ model.titleKey }}</h2>
        <mat-dialog-content *ngIf="model.contentKey">
        <span [innerHTML]="parseMessageWithLink(model.contentKey)"></span>
      </mat-dialog-content>
        <mat-dialog-actions float-right margin-top>
            <button mat-button color="primary" (click)="close()">
                {{ 'Ok' }}
            </button>
        </mat-dialog-actions>
    `
})

export class MessageDialogComponent {
    model: MessageModel;

    constructor(
        public dialogRef: MatDialogRef<MessageDialogComponent>,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: MessageModel
    ) {
        this.model = data;
    }

    parseMessageWithLink(message: string): string {
        const linkPattern = /(http[s]?:\/\/[^\s]+)/g;
        return message.replace(linkPattern, '<a href="$&" target="_blank">$&</a>');
      }

    close() {
        this.dialogRef.close();
    }
}