import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CompanyActions from 'src/app/redux/company/company.actions';
import * as root from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Company } from 'src/app/redux/company/company.model';
import { Router } from '@angular/router';
import { HOME_PATH } from 'src/app/app.routing.module';
import { PageData } from 'src/app/_models/page-data.model';
import { HrComponent } from 'src/app/_models/hrComponent.model';
import { GroupHrElement } from 'src/app/redux/company/group-hr-element.model';

const LICENSE_PAGE_SIZE_KEY = 'license-pageSize-';

@Injectable()
export class CompanyService {
    private _onDestroy = new Subject<void>();

    constructor(
        private translateSerivce: TranslateService,
        private uiService: UIService,
        private http: HttpClient,
        private store: Store<root.IRootState>,
        private router: Router
    ) { }

    initService() {
        this.cancelSubscriptions();
        this._onDestroy = new Subject<void>();
    }

    cancelSubscriptions() {
        // console.log('[Company service] Cancel subscriptions');
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    get companyId() {
        return localStorage.getItem('lastCompanyId');
    }

    uploadLicense(file) {
        console.log('[Company service] Upload license');
        const formData = new FormData();
        formData.append('file', file);
        this.http.post<any>(environment.apiUrl + '/company/license', formData)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (_) => {
                    this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                },
                error => {
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }

    getAll() {
        console.log('[Company service] Fetch Available Companies');
        this.store.dispatch(new CompanyActions.StartLoading());
        this.http.get<Company[]>(environment.apiUrl + '/company')
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (companies) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SetAvailableCompanies(companies));
                }, (error) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SetAvailableCompanies([]));
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }

    findById(companyId: string): void {
        console.log('[Company service] Find by id');
        this.store.dispatch(new CompanyActions.SelectActive(null));
        this.store.dispatch(new CompanyActions.StartLoading());
        this.http.get<Company>(environment.apiUrl + '/company/' + companyId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (company: Company) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SelectActive(company));
                }, (error) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.router.navigate([HOME_PATH]);
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.log(error);
                }
            );
    }

    getHrComponents() {
        return new Promise((r) => {
            this.store.dispatch(new CompanyActions.StartLoading());
            this.http.get<{ components: HrComponent[], groupedComponents: HrComponent[] }>
                (environment.apiUrl + '/hrComponent')
                .toPromise()
                .then((response) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SetAvailableComponents(response.components));
                    this.store.dispatch(new CompanyActions.SetAvailableGroupedComponents(response.groupedComponents));
                    r();
                })
                .catch((err) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SetAvailableComponents([]));
                    this.store.dispatch(new CompanyActions.SetAvailableGroupedComponents([]));
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.log(err);
                    r();
                });
        });
    }

    setHrComponentGroups(groups: GroupHrElement[]) {
        this.store.dispatch(new CompanyActions.SetAvailableHrComponentGroups(groups));
    }

    deleteHrComponentContactGroup(group: GroupHrElement) {
        return new Promise((r) => {
            this.store.dispatch(new CompanyActions.StartLoading());
            this.http.delete<any>
                (environment.apiUrl + '/hrComponent/group/' + group.id)
                .toPromise()
                .then(_ => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    r();
                })
                .catch((err) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.log(err);
                });
        });
    }

    createHrComponentContactGroup(group: GroupHrElement) {
        return new Promise((r) => {
            this.store.dispatch(new CompanyActions.StartLoading());
            let language = localStorage.getItem('language');
            if (!language) {
                language = 'pl';
            }
            this.http.post<GroupHrElement>
                (environment.apiUrl + '/hrComponent/group', { ...group, fromLanguage: language })
                .toPromise()
                .then(_ => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    r();
                })
                .catch((err) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.log(err);
                });
        });
    }

    getHrComponentContactGroups() {
        return new Promise((r) => {
            this.store.dispatch(new CompanyActions.StartLoading());
            this.http.get<GroupHrElement[]>
                (environment.apiUrl + '/hrComponent/group')
                .toPromise()
                .then((response) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SetAvailableHrComponentGroups(response));
                    r();
                })
                .catch((err) => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    this.store.dispatch(new CompanyActions.SetAvailableHrComponentGroups([]));
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.log(err);
                });
        });
    }

    // remove(id: string) {
    //     console.log('[company service] remove by id');
    //     this.store.dispatch(new CompanyActions.StartLoading());
    //     this.http.delete<string>(environment.apiUrl + '/company/' + id)
    //         .pipe(takeUntil(this._onDestroy))
    //         .subscribe(
    //             (removedCompanyId) => {
    //                 this.store.dispatch(new CompanyActions.StopLoading());

    //                 // TODO6: livedatabse
    //                 // this.hubConnected
    //                 //     ? this.hubConnection.invoke('SendMessage', Hub.create('company_remove', res))
    //                 //         .catch(() => this.store.dispatch(new CompanyActions.RemoveCompany(res)))
    //                 //     : this.store.dispatch(new CompanyActions.RemoveCompany(res));

    //                 this.translateSerivce.get('COMMON.SAVE_SUCCESS')
    //                     .pipe(takeUntil(this._onDestroy))
    //                     .subscribe((text) => {
    //                         this.uiService.openSnack(text);
    //                     });
    //             }, (err) => {
    //                 this.store.dispatch(new CompanyActions.StopLoading());
    //                 if (err && err.error && err.error.messageCode) {
    //                     this.translateSerivce.get([err.error.messageCode, 'ERROR'])
    //                         .pipe(takeUntil(this._onDestroy))
    //                         .subscribe((translations) => {
    //                             this.uiService.openSnack(translations[err.error.messageCode], translations['ERROR'], 10_000);
    //                         });
    //                 } else {
    //                     this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
    //                         .pipe(takeUntil(this._onDestroy))
    //                         .subscribe((translations) => {
    //                             this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
    //                         });
    //                 }
    //                 console.error(err);
    //             }
    //         );
    // }

    update(company) {
        console.log('[Company service] Update');
        let language = localStorage.getItem('language');
        if (!language) {
            language = 'pl';
        }
        return new Promise((r) => {
            this.store.dispatch(new CompanyActions.StartLoading());
            this.http.put<Company>(environment.apiUrl + '/company', { ...company, sourceLanguage: language })
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    (_) => {
                        // this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        //     .pipe(takeUntil(this._onDestroy))
                        //     .subscribe((text) => {
                        //         this.uiService.openSnack(text);
                        //     });
                        this.stopEditing();
                        this.store.dispatch(new CompanyActions.StopLoading());
                        r();
                    }, (err) => {
                        this.store.dispatch(new CompanyActions.StopLoading());
                        if (err && err.error && err.error.messageCode) {
                            this.translateSerivce.get([err.error.messageCode, 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((translations) => {
                                    this.uiService.openSnack(translations[err.error.messageCode], translations['ERROR'], 10_000);
                                });
                        } else {
                            this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe((translations) => {
                                    this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                                });
                        }
                        console.error(err);
                    }
                );
        });
    }

    сreate(company) {
        console.log('[Company service] create');
        this.store.dispatch(new CompanyActions.StartLoading());
        this.http.post<any>(environment.apiUrl + '/company', company)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                _ => {
                    this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new CompanyActions.StopLoading());
                }, err => {
                    this.store.dispatch(new CompanyActions.StopLoading());
                    if (err && err.error && err.error.messageCode) {
                        this.translateSerivce.get([err.error.messageCode, 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations[err.error.messageCode], translations['ERROR'], 10_000);
                            });
                    } else {
                        this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe((translations) => {
                                this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                            });
                    }
                    console.error(err);
                }
            );
    }

    stopEditing() {
        this.store.dispatch(new CompanyActions.StopEditing());
    }

    startEditing() {
        this.store.dispatch(new CompanyActions.StartEditing());
    }

    startEditingContact() {
        this.store.dispatch(new CompanyActions.StartEditingContact());
    }

    stopEditingContact() {
        this.store.dispatch(new CompanyActions.StopEditingContact());
    }

    get companyLicensePageData(): PageData {
        return JSON.parse(localStorage.getItem(LICENSE_PAGE_SIZE_KEY + this.companyId));
    }

    set companyLicensePageData(pageData: PageData) {
        localStorage.setItem(LICENSE_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }
}
