import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ErrorStateMatcher, DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import * as fromRoot from 'src/app/app.reducer';
import * as fromCompany from '../../company.reducer';
import { Store } from '@ngrx/store';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { User, AccountType, UserListItem, ProjectKindGroupManagersResource, GroupManagersResource } from 'src/app/redux/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectKind } from 'src/app/redux/contractor/project-kind.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { PasswordConfig } from 'src/app/_models/password-config.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { Project } from 'src/app/redux/project/project.model';
import { Market } from 'src/app/redux/localization/localization.model';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import { environment } from 'src/environments/environment';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-company-create-user',
  templateUrl: './company-create-user.component.html',
  styleUrls: ['./company-create-user.component.scss']
})
export class CompanyCreateUserComponent implements OnInit, AfterViewInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  maxDate = new Date();
  birthday: Date;
  startWorkDate: Date;
  allProjectKinds: ProjectKind[] = [];
  projectKinds: ProjectKind[] = [];
  projects: Project[] = [];
  markets: Market[] = [];
  phoneNumber: string;
  coordinators: UserListItem[] = [];
  filteredProjectKinds: Observable<ProjectKind[]>;
  filteredCoordinators: Observable<UserListItem[]>;
  filteredProjects: Observable<Project[]>;
  filteredMarkets: Observable<Market[]>;
  projectKindCtrl = new FormControl();
  coordinatorCtrl = new FormControl();
  projectListCtrl = new FormControl();
  marketListCtrl = new FormControl();

  currentUser: ApplicationUser;
  projectKindsManagerData: ProjectKindGroupManagersResource[] = [];
  allCoordinators: UserListItem[] = [];
  allProjects: Project[] = [];
  marketsByProject: Market[] = [];

  accessAccordingToMarkets = false;

  @ViewChild('projectKindInput', { static: false }) projectKindInput: ElementRef<HTMLInputElement>;
  @ViewChild('coordinatorInput', { static: false }) coordinatorInput: ElementRef<HTMLInputElement>;
  @ViewChild('projectListInput', { static: false }) projectListInput: ElementRef<HTMLInputElement>;
  @ViewChild('marketListInput', { static: false }) marketListInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  user: User = {
    blocked: false,
    disabled: false,
    email: '',
    firstName: '',
    lastName: '',
    passwordWasReset: false,
    userName: '',
    id: '',
    hasForeignIds: false,
    startWorkDate: null,
    projectKinds: [],
    userMarkets: [],
    userProjects: [],
    accountType: null,
    clientAccountType: null,
    phoneNumber: '',
    employmentStatus: null
  };

  password1 = '';
  password2 = '';

  validPass = false;

  passwordPolicy: PasswordConfig;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();

  isLoading: boolean;

  constructor(private service: UserService,
    private store: Store<fromCompany.State>,
    private dialogRef: MatDialogRef<CompanyCreateUserComponent>,
    private adapter: DateAdapter<any>,
    private translate: TranslateService,
    private http: HttpClient,
    private uiService: UIService,
    public auth: AuthService) {
    this.filteredCoordinators = this.coordinatorCtrl.valueChanges.pipe(
      startWith(null),
      map((value: UserListItem | string | null) => value ? this._filter2(value) : this.allCoordinators.slice()));
  }

  ngOnInit() {
    this.store.select(fromRoot.getUserIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => this.isLoading = isLoading);

    this.translate.onLangChange
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => {
        this.adapter.setLocale(c.lang);
      });
    let value = localStorage.getItem('language');
    if (value) {
      if (value === 'ua') {
        value = 'uk';
      }
      this.adapter.setLocale(value);
    }

    this.store.select(fromRoot.getProjectKindsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projectKinds) => {
        this.allProjectKinds = projectKinds;
      });

    this.store.select(fromRoot.getCurrentUser)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });

    this.store.select(fromRoot.passwordConfig)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res) {
          this.passwordPolicy = res;
        }
      });

    this.store.select(fromRoot.getProjectsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projects) => {
        this.allProjects = projects;
      });

    this.store.select(fromRoot.getSelectedProjectKindGroupManagers)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        if (res !== null && res !== undefined) {
          const newElement = { ...res };
          let currentUserItem = null;

          //check if selected coordinators exist, add to group managers
          if (this.coordinators.length > 0) {
            newElement.groupsManagers.forEach(group => {
              this.coordinators.forEach(user => {
                if (group.managers.some(manager => manager.id === user.id)) {
                  if (!group._managerFullNames) {
                    group._managerFullNames = [];
                    group._managerIds = [];
                  }
                  if (!group._managerIds.includes(user.id)) {
                    group._managerFullNames.push(user.firstName + ' ' + user.lastName);
                    group._managerIds.push(user.id);
                  }
                }
              });
            });
          }

          //searching if logged person is a manager, if yes adding to group
          newElement.groupsManagers.forEach(group => {
            if (group.managers.some(x => x.id == this.currentUser.id)) {
              if (!group._managerFullNames) {
                group._managerFullNames = [];
                group._managerIds = [];
              }
              if (!group._managerIds.includes(this.currentUser.id)) {
                group._managerFullNames.push(this.currentUser.firstName + ' ' + this.currentUser.lastName);
                group._managerIds.push(this.currentUser.id);
              }
              currentUserItem = group.managers.find(x => x.id == this.currentUser.id);
            }
          });

          if (currentUserItem !== null && !this.coordinators.some(item => item.id === currentUserItem.id)) {
            this.coordinators.push(currentUserItem);
          }

          this.projectKindsManagerData.push(newElement);
          this.resetAllCoordinators();
        }
        else {
          this.projectKindsManagerData = [];
        }
      });

    this.filteredProjectKinds = this.projectKindCtrl.valueChanges.pipe(
      startWith(null),
      map((value: ProjectKind | string | null) => value ? this._filter(value) : this.allProjectKinds.slice()));

    this.filteredProjects = this.projectListCtrl.valueChanges.pipe(
      startWith(null),
      map((value: Project | string | null) => value ? this._filter3(value) : this.allProjects.slice()));

    this.filteredMarkets = this.marketListCtrl.valueChanges.pipe(
      startWith(null),
      map((value: Market | string | null) => value ? this._filter4(value) : this.marketsByProject.slice()));

    setTimeout(() => {
      this.auth.getPasswordConfig();
    }, 100);
  }

  ngAfterViewInit() {
    this.filteredCoordinators = this.coordinatorCtrl.valueChanges.pipe(
      startWith(null),
      map((value: UserListItem | string | null) => value ? this._filter2(value) : this.allCoordinators.slice()));
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  setBirthDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.birthday = new Date(event.value);
    } else {
      this.birthday = null;
    }
  }

  setStartWorkDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.startWorkDate = new Date(event.value);
    } else {
      this.startWorkDate = null;
    }
  }

  getManagersFullNames(group: GroupManagersResource) {
    if (group._managerFullNames && group._managerFullNames.length > 0) {
      let prefix = group._managerFullNames.length == 1 ? ' --- Przełożony: ' : ' --- Przełożeni: '
      return prefix + group._managerFullNames.join(', ');
    }
    return '';
  }

  submit() {
    const groupWithManagers = this.projectKindsManagerData.reduce((acc, projectKindGroupManager) => {
      const groupData = projectKindGroupManager.groupsManagers.map((groupManager) => ({
        groupId: groupManager.id,
        managers: groupManager._managerIds
      }));
      return acc.concat(groupData);
    }, []);
    this.user.email = this.emailFormControl.value;
    this.service.create(this.user, this.password1, this.birthday,
      this.startWorkDate, this.user.projectKinds, this.user.accountType, this.user.clientAccountType,
      this.user.userProjects, this.user.userMarkets,
      groupWithManagers, this.user.pesel, this.user.employmentStatus).then(() => {
        this.onClose();
        this.dialogRef.close();
      });
  }

  onClose() {
    this.service.resetProjectKindGroupsManagers();
  }

  validPassword(value: boolean) {
    this.validPass = value;
  }

  resetAllCoordinators() {
    this.allCoordinators = [];
    this.projectKindsManagerData.forEach((projectKind) => {
      projectKind.groupsManagers.forEach((groupManager) => {
        this.allCoordinators = this.allCoordinators.concat(groupManager.managers);
      });
    });
  }

  selectedProjectKind(event: MatAutocompleteSelectedEvent) {
    const pk: ProjectKind = event.option.value;
    if (pk && !this.projectKinds.some(p => pk.id == p.id)) {
      this.projectKinds.push(pk);
      this.user.projectKinds.push(pk.id);
      this.service.groupGetManagersByProjectKind(pk.id);
      this.refreshCoordinatorInGroups();
    }
    this.projectKindInput.nativeElement.value = '';
    this.projectKindCtrl.setValue(null);
  }

  selectedCoordinator(event: MatAutocompleteSelectedEvent) {
    const user: UserListItem = event.option.value;
    if (user && !this.coordinators.some(u => user.id == u.id)) {
      this.coordinators.push(user);
    }

    this.refreshCoordinatorInGroups();

    this.coordinatorInput.nativeElement.value = '';
    this.coordinatorCtrl.setValue(null);
  }

  refreshCoordinatorInGroups() {
    this.projectKindsManagerData.forEach((item) => {
      item.groupsManagers.forEach((group) => {
        const coordinatorInGroup = this.coordinators.filter((coordinator) =>
          group.managers.some((manager) => manager.id === coordinator.id)
        );

        if (coordinatorInGroup) {
          group._managerFullNames = coordinatorInGroup.map(x => x.firstName + ' ' + x.lastName);
          group._managerIds = coordinatorInGroup.map(x => x.id);
        }
        else {
          group._managerFullNames = [];
          group._managerIds = [];
        }
      });
    });
  }

  selectedProject(event: MatAutocompleteSelectedEvent) {
    // const p: Project = event.option.value;
    // if (p && !this.projects.includes(p)) {
    //   this.projects.push(p);
    //   this.user.userProjects.push(p.id);
    //   this.getMarketsByProject(this.projects.map(pr => pr.id));
    // }

    this.projects.push(event.option.value);
    this.user.userProjects.push(event.option.value.id);
    this.projectListInput.nativeElement.value = '';
    this.projectListCtrl.setValue(null);
  }

  selectedMarket(event: MatAutocompleteSelectedEvent) {
    const p: Market = event.option.value;
    if (p && !this.markets.includes(p)) {
      this.markets.push(p);
      this.user.userMarkets.push(p.id);
    }
    this.marketListInput.nativeElement.value = '';
    this.marketListCtrl.setValue(null);
  }

  add(event: MatChipInputEvent) {
    this.projectKindInput.nativeElement.value = '';
    this.projectKindCtrl.setValue(null);
  }

  add2(event: MatChipInputEvent) {
    this.coordinatorInput.nativeElement.value = '';
    this.coordinatorCtrl.setValue(null);
  }

  addProject(event: MatChipInputEvent) {

    // const value = (event.value || '').trim();

    // // Add our fruit
    // if (value) {
    //   this.projects.push(value);
    // }

    // // Clear the input value
    // event.input!.clear();

    this.projectListInput.nativeElement.value = '';
    this.projectListCtrl.setValue(null);
  }

  addMarket(event: MatChipInputEvent) {
    this.marketListInput.nativeElement.value = '';
    this.marketListCtrl.setValue(null);
  }

  removeProjectKind(projectKind: ProjectKind): void {
    const index = this.projectKinds.indexOf(projectKind);
    const userIndex = this.user.projectKinds.indexOf(projectKind.id);
    if (index >= 0) {
      const projectKindsManagerDataToDelete = this.projectKindsManagerData.find(item => item.id === projectKind.id);
      this.projectKindsManagerData.splice(index, 1);
      this.projectKinds.splice(index, 1);
      this.projectKindsManagerData = this.projectKindsManagerData.filter(item => item.id !== projectKind.id);
      this.resetAllCoordinators();
      this.refreshCoordinatorInGroups();
    }
    if (userIndex >= 0) {
      this.user.projectKinds.splice(userIndex, 1);
    }
  }

  removeCoordinator(coordinator: UserListItem): void {
    const index = this.coordinators.indexOf(coordinator);
    if (index >= 0) {
      this.coordinators.splice(index, 1);
      this.refreshCoordinatorInGroups();
    }
  }

  removeProject(project: Project): void {
    const index = this.projects.indexOf(project);
    const userIndex = this.user.userProjects.indexOf(project.id);
    if (index >= 0) {
      this.projects.splice(index, 1);
      this.getMarketsByProject(this.projects.map(pr => pr.id));
    }
    if (userIndex >= 0) {
      this.user.userProjects.splice(userIndex, 1);
    }
  }

  removeMarket(market: Market): void {
    const index = this.markets.indexOf(market);
    if (index >= 0) {
      this.markets.splice(index, 1);
      this.user.userMarkets.splice(index, 1);
    }
  }

  private _filter(value: ProjectKind | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.allProjectKinds.filter(gr => gr.name.toLowerCase().includes(filterValue));
    }
    return this.allProjectKinds;
  }

  private _filter2(value: UserListItem | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.allCoordinators.
        filter(gr => gr.firstName.toLowerCase().includes(filterValue) ||
          gr.lastName.toLowerCase().includes(filterValue));
    }

    return this.allCoordinators;
  }

  private _filter3(value: Project | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.allProjects.filter(p => p.name.toLowerCase().includes(filterValue));
    }
    return this.allProjects;
  }

  private _filter4(value: Market | string) {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.marketsByProject.filter(p => p.code.toLowerCase().includes(filterValue));
    }
    return this.marketsByProject;
  }

  loginGenerate() {

    const length = this.passwordPolicy.autoGenerateLoginLength;

    if (length === 5) {
      this.user.userName = String(Math.floor(Math.random() * (99999 - 10000)) + 10000);
    }
    if (length === 6) {
      this.user.userName = String(Math.floor(Math.random() * (999999 - 100000)) + 100000);
    }
    if (length === 7) {
      this.user.userName = String(Math.floor(Math.random() * (9999999 - 1000000)) + 1000000);
    }
    if (length === 8) {
      this.user.userName = String(Math.floor(Math.random() * (99999999 - 10000000)) + 10000000);
    }
    if (length === 9) {
      this.user.userName = String(Math.floor(Math.random() * (999999999 - 100000000)) + 100000000);
    }
    if (length === 10) {
      this.user.userName = String(Math.floor(Math.random() * (9999999999 - 1000000000)) + 1000000000);
    }
    this.password1 = this.password2 = this.passGenerate(10);
  }

  passGenerate(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getAccountTypeCounter() {
    let counter = 0;
    if (this.auth.appRoles.canStructureAndContactAddUserOffice) {
      counter++;
    }
    if (this.auth.appRoles.canStructureAndContactAddUserOutOfOffice) {
      counter++;
    }
    if (this.auth.appRoles.canStructureAndContactAddUserClient) {
      counter++;
    }

    if (counter === 1) {
      if (this.auth.appRoles.canStructureAndContactAddUserOffice) {
        this.user.accountType = AccountType.Office;
      }
      if (this.auth.appRoles.canStructureAndContactAddUserOutOfOffice) {
        this.user.accountType = AccountType.Merch;
      }
      if (this.auth.appRoles.canStructureAndContactAddUserClient) {
        this.user.accountType = AccountType.Client;
      }
    }
    return counter;
  }

  clearAccountType() {
    this.user.accountType = null;
    this.user.clientAccountType = null;
    this.projectKinds.splice(0);
    this.coordinators.splice(0);
    this.allCoordinators.splice(0);
    this.projects.splice(0);
    this.markets.splice(0);
    this.projectKindsManagerData = [];
  }

  clearClientAccountType() {
    this.user.clientAccountType = null;
  }

  clearEmploymentStatus() {
    this.user.employmentStatus = null;
  }

  getMarketsByProject(projects: number[]) {
    this.http.post<Market[]>(environment.apiUrl + '/localization/marketsbyproject', projects)
      .toPromise()
      .then(
        (markets) => {
          this.marketsByProject = markets;
          const marketsToRemove: Market[] = [];
          this.markets.forEach(m => {
            const list = this.marketsByProject.filter(p => p.id === m.id);
            if (list.length === 0) {
              marketsToRemove.push(m);
            }
          });
          for (let i = 0; i < marketsToRemove.length; i++) {
            const index = this.markets.indexOf(marketsToRemove[i]);
            if (index >= 0) {
              this.markets.splice(index, 1);
            }
          }
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }
}
