import { Component, Input, Output, EventEmitter } from "@angular/core";
import * as SurveyKo from "survey-knockout";
import * as SurveyCreator from "survey-creator";
import * as widgets from "surveyjs-widgets";
import { init as initCustomWidget } from "./customwidget";
import $ from "jquery";
import { ApplicationData } from "src/app/auth/user.model";
import { FileUploadreData, SessionStorageFileUploaderDataKey, FileInfoResource } from '../../redux/survey/survey.model'
import { environment } from "src/environments/environment";

widgets.icheck(SurveyKo);
widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo);
widgets.jqueryuidatepicker(SurveyKo);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo);
//widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
// widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);
widgets.bootstrapslider(SurveyKo);
//widgets.emotionsratings(SurveyKo);
initCustomWidget(SurveyKo);
SurveyCreator.StylesManager.applyTheme("bootstrap");

// var CkEditor_ModalEditor = {
//   afterRender: function(modalEditor, htmlElement) {
//     var editor = window["CKEDITOR"].replace(htmlElement);
//     editor.on("change", function() {
//       modalEditor.editingValue = editor.getData();
//     });
//     editor.setData(modalEditor.editingValue);
//   },
//   destroy: function(modalEditor, htmlElement) {
//     var instance = window["CKEDITOR"].instances[htmlElement.id];
//     if (instance) {
//       instance.removeAllListeners();
//       window["CKEDITOR"].remove(instance);
//     }
//   }
// };
// SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget(
//   "html",
//   CkEditor_ModalEditor
// );
// CKEditor is the third party library
/* Steps to use CKEditor in the project:
    1. run 'npm i ckeditor'
    2. update the "build" section of the angular.json: add ckeditor.js script in the scripts section.
*/

@Component({
  selector: "survey-creator",
  template: `
    <div id="surveyCreatorContainer"></div>
  `
})
export class SurveyCreatorComponent {
  surveyCreator: SurveyCreator.SurveyCreator;
  @Input() json: any;
  @Output() surveySaved: EventEmitter<Object> = new EventEmitter();

  ngOnInit() {
    SurveyKo.JsonObject.metaData.addProperty(
      "questionbase",
      "popupdescription:text"
    );
    SurveyKo.JsonObject.metaData.addProperty("page", "popupdescription:text");
    //SurveyKo.JsonObject.metaData.findProperty("file", "storeDataAsText").defaultValue = false;
    //SurveyKo.JsonObject.metaData.findProperty("file", "storeDataAsText").visible = false;
    let options = { showEmbededSurveyTab: true, generateValidJSON: true, haveCommercialLicense: true };
    SurveyCreator.editorLocalization.currentLocale = 'pl';
    let locales = SurveyCreator.editorLocalization.locales['pl'];
    console.log(locales);

    this.surveyCreator = new SurveyCreator.SurveyCreator(
      "surveyCreatorContainer",
      options
    );
    if (this.json && this.json !== '') {
      this.surveyCreator.text = this.json;
    }
    this.surveyCreator.saveSurveyFunc = this.saveMySurvey;
    this.surveyCreator.onQuestionAdded.add((sender, options) => {
      if (options.question.getType() === "file") {
        options.question.storeDataAsText = false;
      }
    });

    this.surveyCreator.onUploadFile.add(function (creator, options) {
      options
        .files
        .forEach(function (file) {
          const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
          var formData = new FormData();
          formData.append("file", file);
          formData.append("addCompression", "true");
          formData.append("maxWidthHeight", "800");
          formData.append("doNotDelete", "true");

          $.ajax({
            url: environment.apiUrl + '/attachment/addimage/',
            type: "POST",

            xhr: function () {
              var myXhr = $.ajaxSettings.xhr();
              if (myXhr.upload) {
                  myXhr.upload.addEventListener('progress', function (event) {
                      var percent = 0;
                      var position = event.loaded || event.position;
                      var total = event.total;
                  }, false);
              }
              return myXhr;
          },
          success: function (data) {
              options.callback("success", environment.apiUrl + data);
          },
            headers: { 'Authorization': `Bearer ${currentUser.token}` },
            error: function (error) {
              console.log(error)

            },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000
          });
        })
    });    
  }

  saveMySurvey = () => {
    this.surveySaved.emit(JSON.parse(this.surveyCreator.text));
  };
}
