import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { ProjectKind, ProjectKindAttachment } from 'src/app/redux/contractor/project-kind.model';
import * as ProjectKindActions from 'src/app/redux/contractor/project-kind.actions';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import * as UI from '../../shared/ui.actions';
import { ProjectKindRole} from 'src/app/redux/contractor/project-kind-role.model';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable()
export class ProjectKindService {
    private _onDestroy = new Subject<void>();

    constructor(
        private store: Store<root.IRootState>,
        private http: HttpClient,
        private uiService: UIService,
    ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();
  }

  projectSelect(project: ProjectKind) {
    this.store.dispatch(new ProjectKindActions.SetSelectedProjectKind(project));
  }

  startEdit() {
    this.store.dispatch(new ProjectKindActions.StartEditing());
  }

  stopEdit() {
    this.store.dispatch(new ProjectKindActions.StopEditing());
  }

  setProjectKindsList(projectKinds: ProjectKind[]) {
    this.store.dispatch(new ProjectKindActions.SetAvailableProjectKinds(projectKinds));
  }

  setProjectKindRoleList(projectKindRoles: ProjectKindRole[]) {
    this.store.dispatch(new ProjectKindActions.SetAvailableProjectKindRole(projectKindRoles));
  }

  setProjectKindAttachmentList(projectKindAttachments: ProjectKindAttachment[]) {
    this.store.dispatch(new ProjectKindActions.SetAvailableProjectKindAttachments(projectKindAttachments));
  }

  getAll() {
    this.store.dispatch(new UI.StartLoading());
    this.http.get<ProjectKind[]>(environment.apiUrl + '/contractor/projectKind/')
        .subscribe(
            (projectKinds) => {
                this.store.dispatch(new ProjectKindActions.SetAvailableProjectKinds(projectKinds));
                this.store.dispatch(new UI.StopLoading());
            }, (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
                this.store.dispatch(new ProjectKindActions.SetAvailableProjectKinds(null));
                this.store.dispatch(new UI.StopLoading());
            }
        );
  }

  getAllProjectKindRole() {
    this.store.dispatch(new UI.StartLoading());
    this.http.get<ProjectKindRole[]>(environment.apiUrl + '/contractor/projectKindRole/')
        .subscribe(
            (projectKindRoles) => {
                this.store.dispatch(new ProjectKindActions.SetAvailableProjectKindRole(projectKindRoles));
                this.store.dispatch(new UI.StopLoading());
            }, (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
                this.store.dispatch(new ProjectKindActions.SetAvailableProjectKindRole(null));
                this.store.dispatch(new UI.StopLoading());
            }
        );
  }

  getAllProjectKindAttachments() {
    this.store.dispatch(new UI.StartLoading());
    this.http.get<ProjectKindAttachment[]>(environment.apiUrl + '/project/projectKindAttachment/')
        .subscribe(
            (projectKindAttachment) => {
                this.store.dispatch(new ProjectKindActions.SetAvailableProjectKindAttachments(projectKindAttachment));
                this.store.dispatch(new UI.StopLoading());
            }, (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
                this.store.dispatch(new ProjectKindActions.SetAvailableProjectKindAttachments(null));
                this.store.dispatch(new UI.StopLoading());
            }
        );
  }

  remove(projectKindId: number) {
    console.log('[Project kind service] remove');
    this.store.dispatch(new ProjectKindActions.StartLoading());
    this.http.delete<ProjectKind>(environment.apiUrl + '/contractor/projectKind/' + projectKindId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new ProjectKindActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new ProjectKindActions.StopLoading());
                console.log(error);
            }
        );
}

create(projectKind: ProjectKind) {
    console.log('[Project kind service] create');
    this.store.dispatch(new ProjectKindActions.StartLoading());
    this.http.post<ProjectKind>(environment.apiUrl + '/contractor/projectKind', projectKind)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.store.dispatch(new ProjectKindActions.StopLoading());
            }, (error) => {
                this.store.dispatch(new ProjectKindActions.StopLoading());
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
            }
        );
}

addProjectKindRoles(projectKindRoles: any[]) {
  console.log('[Project kind service] create');
  this.store.dispatch(new ProjectKindActions.StartLoading());
  this.http.post<ProjectKindRole[]>(environment.apiUrl + '/contractor/projectKindRole', projectKindRoles)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.store.dispatch(new ProjectKindActions.StopLoading());
            }, (error) => {
                this.store.dispatch(new ProjectKindActions.StopLoading());
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
            }
        );
}

addProjectKindAttachment(formData) {
  console.log('[Project kind service] create project kind attachment');
  this.store.dispatch(new ProjectKindActions.StartLoading());
  this.http.post<any>(environment.apiUrl + '/project/projectKindAttachment', formData)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.store.dispatch(new ProjectKindActions.StopLoading());
            }, (error) => {
                this.store.dispatch(new ProjectKindActions.StopLoading());
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                console.error(error);
            }
        );
}

removeProjectKindRole(projectKindRole: ProjectKindRole) {
  console.log('[Project kind service] remove project kind role');
  this.store.dispatch(new ProjectKindActions.StartLoading());
  this.http.delete<ProjectKindRole>(environment.apiUrl + '/contractor/projectKindRole/' +
    projectKindRole.projectKind.id + '/' + projectKindRole.applicationRole.id)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new ProjectKindActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new ProjectKindActions.StopLoading());
                console.log(error);
            }
        );
}

removeProjectKindAttachment(projectKindAttachmentId: number) {
  console.log('[Project kind service] remove project kind attachment');
  this.store.dispatch(new ProjectKindActions.StartLoading());
  this.http.delete<ProjectKindAttachment>(environment.apiUrl + '/project/projectKindAttachment/' + projectKindAttachmentId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
            (_) => {
                this.uiService.openSnack('Usunięto pomyślnie');
                this.store.dispatch(new ProjectKindActions.StopLoading());
            },
            (error) => {
                this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
                this.store.dispatch(new ProjectKindActions.StopLoading());
                console.log(error);
            }
        );
}

downloadAttachment(attachment, fileName) {
  this.http.get(environment.apiUrl + '/project/attachment/' + attachment.id, {
      responseType: 'arraybuffer'})
      .toPromise()
      .then((res) => {
          const blob = new Blob([res], { type: attachment.type });
          importedSaveAs(blob, fileName);
      })
      .catch(err => {
          console.error(err);
      });
}


}

