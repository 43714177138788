import { Action } from '@ngrx/store';
export const SET_VERSION = '[App Info] set version';

export class SetVersion implements Action {
  readonly type = SET_VERSION;
  constructor(public payload: string) { }
}

export type InfoActions =
SetVersion;
