import { Injectable } from '@angular/core';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { SetScreen } from './screen-state.actions';

@Injectable()
export class ScreenService {
    mobile$ = this.store.select(state => state.screen.isMobile);
    tablet$ = this.store.select(state => state.screen.isTablet);
    desktop$ = this.store.select(state => state.screen.isDesktop);

    constructor(private store: Store<fromRoot.IRootState>) { }

    setWindowWidth(windowWidth: number): void {
        this.store.dispatch(new SetScreen(windowWidth));
    }
}
