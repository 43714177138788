import { Audit } from "./audit.model";
import { Action } from '@ngrx/store';

export const SET_SELECTED_AUDIT = '[Audit] Set selected audit';
export const START_LOADING = '[Audit] start loading';
export const STOP_LOADING = '[Audit] stop loading';

export class SetSelectedAudit implements Action {
    readonly type = SET_SELECTED_AUDIT;
    constructor(public payload: Audit) { }
}
export class StartLoading implements Action {
    readonly type = START_LOADING;
}
export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}
export type AuditActions =
    SetSelectedAudit
    | StopLoading
    | StartLoading
    ;