import { PasswordReminderFormComponent } from './password-reminder-from/password-reminder-from.component';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from 'src/app/auth/auth-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PasswordReminderComponent } from './password-reminder/password-reminder.component';

@NgModule({
    declarations: [
        LoginComponent,
        PasswordReminderComponent,
        PasswordReminderFormComponent
    ],
    imports: [
        AuthRoutingModule,
        SharedModule
    ]
})
export class AuthModule { }
