import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as EmployeeFormActions from 'src/app/redux/employee-form/employee-form.actions';
import { environment } from 'src/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { EmployeeForm } from 'src/app/redux/employee-form/employee-form.model';
import { ProjectKindAttachment } from 'src/app/redux/contractor/project-kind.model';
import { DocumentApprovement } from 'src/app/redux/employee-form/document-approvement.model';
import { Attachment } from 'src/app/redux/attachment.model';

@Injectable()
export class EmployeeFormService {
    private _onDestroy = new Subject<void>();

  constructor(
      private store: Store<root.IRootState>,
      private http: HttpClient,
      private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
      this._onDestroy.next();
      this._onDestroy.complete();

      this.unSelectEmployeeForm();
  }

  stopEditing() {
    this.store.dispatch(new EmployeeFormActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new EmployeeFormActions.StartEditing());
  }

  selectEmployeeForm(employeeForm: EmployeeForm) {
    this.store.dispatch(new EmployeeFormActions.SetSelected(employeeForm));
  }

  unSelectEmployeeForm() {
    this.store.dispatch(new EmployeeFormActions.SetSelected(null));
  }

  getAllEmployeesForms() {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.get<EmployeeForm[]>(environment.apiUrl + '/employeeform')
      .toPromise()
      .then(
        (empForms) => {
          this.store.dispatch(new EmployeeFormActions.SetAvailableEmployeeForms(empForms));
          this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.SetAvailableEmployeeForms(null));
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  getEmployeesFormsForUser() {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.get<EmployeeForm[]>(environment.apiUrl + '/employeeform/user')
      .toPromise()
      .then(
        (empForms) => {
          this.store.dispatch(new EmployeeFormActions.SetAvailableEmployeeForms(empForms));
          this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.SetAvailableEmployeeForms(null));
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  createEmployeeForm(employeeForm: EmployeeForm) {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.post<EmployeeForm>(environment.apiUrl + '/employeeform', employeeForm)
      .toPromise()
      .then(
        (model) => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  updateEmployeeForm(employeeForm: EmployeeForm) {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.put<EmployeeForm>(environment.apiUrl + '/employeeform', employeeForm)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  deleteEmployeeForm(id: number) {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.delete(environment.apiUrl + '/employeeform/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  downloadLocalizationAttachment(attachment, fileName) {
    this.http.get(environment.apiUrl + '/localization/localization/attachment/' + attachment.id, {
        responseType: 'arraybuffer'})
        .toPromise()
        .then((res) => {
            const blob = new Blob([res], { type: attachment.type });
            importedSaveAs(blob, fileName);
        })
        .catch(err => {
            console.error(err);
        });
  }

  getAllDocuments() {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.get<ProjectKindAttachment[]>(environment.apiUrl + '/employeeform/documents')
      .toPromise()
      .then(
        (documents) => {
          this.store.dispatch(new EmployeeFormActions.SetAvailableDocuments(documents));
          this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.SetAvailableEmployeeForms(null));
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  getDocumentsApprovement(userId: string) {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.get<DocumentApprovement[]>(environment.apiUrl + '/employeeform/documents/' + userId)
      .toPromise()
      .then(
        (documents) => {
          this.store.dispatch(new EmployeeFormActions.SetAvailableDocumentApprovement(documents));
          this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.SetAvailableEmployeeForms(null));
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  approveDocuments(documents: DocumentApprovement[]) {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.post<DocumentApprovement>(environment.apiUrl + '/employeeform/documents', documents)
      .toPromise()
      .then(
        (model) => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  approveDocumentsWithCode(code: string) {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.post(environment.apiUrl + '/employeeform/authorizationcode', { authorizationCode: code })
      .toPromise()
      .then(
        () => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new EmployeeFormActions.StopLoading());
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }
  resendAuthorizationCode() {
    this.store.dispatch(new EmployeeFormActions.StartLoading());
    this.http.post(environment.apiUrl + '/employeeform/resendauthorizationcode', null)
      .toPromise()
      .then(
        () => {
              this.uiService.openSnack('Zakończono pomyślnie');
              this.store.dispatch(new EmployeeFormActions.StopLoading());
          })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new EmployeeFormActions.StopLoading());
        console.error(err);
    });
  }

  downloadDocument(attachment: Attachment, fileName: string, employeeFormId: number) {
    this.uiService.openSnack('Trwa pobieranie pliku...', null, null);
    this.http.get(environment.apiUrl + '/employeeform/download/' + attachment.id + "/" + employeeFormId, {
        responseType: 'arraybuffer'})
        .toPromise()
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf'});
            importedSaveAs(blob, fileName);
            this.uiService.openSnack('Zakończono pomyślnie');
        })
        .catch(err => {
            console.error(err);
        });
  }
}
