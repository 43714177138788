import {
    RoleActions,
    REMOVE_ROLE,
    SET_AVAILABLE_PERMISSIONS,
    STOP_EDITING_ROLE_USER,
    START_EDITING_ROLE_USER,
    STOP_EDITING_ROLE,
    START_EDITING_ROLE,
    SET_AVAILABLE_ROLES,
    ADD_NEW_ROLE,
    UPDATE_ROLE,
    ROLE_SELECTED,
    ROLE_SELECTED_USER,
    START_LOADING,
    STOP_LOADING
} from './role.actions';
import { IRole } from 'src/app/_models/role.model';
import { User } from '../user/user.model';

export interface State {
    isLoading: boolean;
    roles: IRole[];
    rolesTotalCount: number;
    permissions: any[];
    selectedRole: IRole;
    selectedRoleUser: User;
    roleIsEditing: boolean;
    roleUserIsEditing: boolean;
}
const initialState: State = {
    isLoading: false,
    permissions: [],
    roles: [],
    rolesTotalCount: 0,
    selectedRole: null,
    selectedRoleUser: null,
    roleIsEditing: false,
    roleUserIsEditing: false,
};

export function roleReducer(state = initialState, action: RoleActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_AVAILABLE_PERMISSIONS: {
            return {
                ...state,
                permissions: action.payload
            };
        }
        case STOP_EDITING_ROLE_USER:
            return {
                ...state,
                roleUserIsEditing: false
            };
        case START_EDITING_ROLE_USER:
            return {
                ...state,
                roleUserIsEditing: true
            };
        case STOP_EDITING_ROLE:
            return {
                ...state,
                roleIsEditing: false
            };
        case START_EDITING_ROLE:
            return {
                ...state,
                roleIsEditing: true
            };
        case SET_AVAILABLE_ROLES: {
            return {
                ...state,
                roles: action.payload.data,
                rolesTotalCount: action.payload.totalCount
            };
        }
        case ADD_NEW_ROLE: {
            return {
                ...state,
                roles: [...state.roles, action.payload],
            };
        }
        case UPDATE_ROLE: {
            const index = state.roles.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }

            let selectedRole = state.selectedRole;
            if (selectedRole && selectedRole.id === action.payload.id) {
                selectedRole = action.payload;
            }
            const roles = [
                ...state.roles.slice(0, index),
                Object.assign({}, state.roles[index], action.payload),
                ...state.roles.slice(index + 1)
            ];
            return {
                ...state,
                selectedRole: selectedRole,
                roles: roles
            };
        }
        case REMOVE_ROLE: {
            return {
                ...state,
                roles: state.roles.filter(c => c.id !== action.payload)
            };
        }
        case ROLE_SELECTED: {
            return {
                ...state,
                selectedRole: action.payload
            };
        }
        case ROLE_SELECTED_USER: {
            return {
                ...state,
                selectedRoleUser: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const getRoles = (state: State) => state.roles;
export const getRolesTotalCount = (state: State) => state.rolesTotalCount;
export const getPermissions = (state: State) => state.permissions;
export const getRoleIsEditing = (state: State) => state.roleIsEditing;
export const getRoleUserIsEditing = (state: State) => state.roleUserIsEditing;
export const getRoleSelected = (state: State) => state.selectedRole;
export const getRoleUserSelected = (state: State) => state.selectedRoleUser;
export const getIsLoading = (state: State) => state.isLoading;
