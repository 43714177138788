import { Product, ProductPhoto } from './product.model';
import {
    ProductActions,
    SELECTED,
    ADD,
    ADD_PHOTO,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_PRODUCTS,
    SET_AVAILABLE_LOCALIZATION_PRODUCTS,
    SET_AVAILABLE_PRODUCT_PHOTOS,
    SET_SELECTED_PRODUCTS,
    UPDATE,
    REMOVE,
    REMOVE_PHOTO,
    START_LOADING,
    STOP_LOADING,
    START_LOADING_LIST,
    STOP_LOADING_LIST
} from './product.actions';

export interface State {
    products: Product[];
    localizationProducts: Product[];
    selectedProducts: Product[];
    photos: ProductPhoto[];
    selected: Product;
    isEditing: boolean;
    isLoading: boolean;
    isLoadingList: boolean;
}
const initialState: State = {
    products: [],
    localizationProducts: [],
    selectedProducts: [],
    photos: [],
    selected: null,
    isEditing: false,
    isLoading: false,
    isLoadingList: false
};

export function productReducer(state = initialState, action: ProductActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case START_LOADING_LIST: {
          return {
              ...state,
              isLoadingList: true
          };
      }
      case STOP_LOADING_LIST: {
          return {
              ...state,
              isLoadingList: false
          };
      }
        case SELECTED: {
            return {
                ...state,
                selected: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                products: [...state.products, action.payload]
            };
        }
        case ADD_PHOTO: {
          return {
              ...state,
              photos: [...state.photos, action.payload]
          };
      }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_PRODUCTS: {
            return {
                ...state,
                products: action.payload
            };
        }
        case SET_AVAILABLE_LOCALIZATION_PRODUCTS: {
          return {
              ...state,
              localizationProducts: action.payload
          };
      }
        case SET_AVAILABLE_PRODUCT_PHOTOS: {
          return {
              ...state,
              photos: action.payload
          };
        }
        case SET_SELECTED_PRODUCTS: {
          return {
              ...state,
              selectedProducts: action.payload
          };
        }
        case UPDATE: {
            const index = state.products.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selProduct = state.selected;
            if (selProduct && selProduct.id === action.payload.id) {
              selProduct = action.payload;
            }
            const updatedProducts = [
                ...state.products.slice(0, index),
                Object.assign({}, state.products[index], action.payload),
                ...state.products.slice(index + 1)
            ];
            return {
                ...state,
                products: updatedProducts,
                selected: selProduct
            };
        }
        case REMOVE: {
            return {
                ...state,
                products: state.products.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_PHOTO: {
          return {
              ...state,
              photos: state.photos.filter(c => c.attachemntId !== action.payload)
          };
      }
      default: {
          return state;
      }
    }
}

export const products = (state: State) => state.products;
export const localizationProducts = (state: State) => state.localizationProducts;
export const selectedProducts = (state: State) => state.selectedProducts;
export const photos = (state: State) => state.photos;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const isLoadingList = (state: State) => state.isLoadingList;
export const selected = (state: State) => state.selected;
