import { Market } from "../localization/localization.model";
import { ProjectSimple } from "../project/project.model";
import { SET_CITY_LIST, SET_COORDINATOR_EMPLOYEE_LIST, SET_COORDINATOR_LIST, SET_DEFINITION, SET_MANAGER_LIST, SET_MARKET_LIST, SET_PROJECT_LIST, SET_STREET_LIST, SET_WORK_SCHEDULE, START_LOADING, STOP_LOADING, WorkScheduleActions } from "./work-schedule.actions";
import { WorkScheduleByRequiredDate, WorkScheduleEmployee, WorkScheduleFilterForm } from "./work-schedule.model";

export interface State {
    isLoading: boolean;
    definition: WorkScheduleFilterForm;
    projectList: ProjectSimple[];
    marketList: Market[];
    cityList: string[];
    streetList: string[];
    managerList: WorkScheduleEmployee[];
    coordinatorList: WorkScheduleEmployee[];
    coordinatorEmployeeList: WorkScheduleEmployee[];
    workSchedule: WorkScheduleByRequiredDate[];
}

const initialState: State = {
    isLoading: false,
    definition: null,
    projectList: null,
    marketList: null,
    cityList: null,
    streetList: null,
    managerList: null,
    coordinatorList: null,
    coordinatorEmployeeList: null,
    workSchedule: null,
};

export function workScheduleReducer(state = initialState, action: WorkScheduleActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_DEFINITION: {
            return {
                ...state,
                definition: action.payload
            }
        }
        case SET_PROJECT_LIST: {
            return {
                ...state,
                projectList: action.payload
            }
        }
        case SET_MARKET_LIST: {
            return {
                ...state,
                marketList: action.payload
            }
        }
        case SET_CITY_LIST: {
            return {
                ...state,
                cityList: action.payload
            }
        }
        case SET_STREET_LIST: {
            return {
                ...state,
                streetList: action.payload
            }
        }
        case SET_MANAGER_LIST: {
            return {
                ...state,
                managerList: action.payload
            }
        }
        case SET_COORDINATOR_LIST: {
            return {
                ...state,
                coordinatorList: action.payload
            }
        }
        case SET_COORDINATOR_EMPLOYEE_LIST: {
            return {
                ...state,
                coordinatorEmployeeList: action.payload
            }
        }        
        case SET_WORK_SCHEDULE: {
            return {
                ...state,
                workSchedule: action.payload
            }
        }       
        default: {
            return state;
        }
    }
}

export const isLoading = (state: State) => state.isLoading;
export const definition = (state: State) => state.definition;
export const projectList = (state: State) => state.projectList;
export const marketList = (state: State) => state.marketList;
export const cityList = (state: State) => state.cityList;
export const streetList = (state: State) => state.streetList;
export const managerList = (state: State) => state.managerList;
export const coordinatorList = (state: State) => state.coordinatorList;
export const coordinatorEmployeeList = (state: State) => state.coordinatorEmployeeList;
export const workSchedule = (state: State) => state.workSchedule;