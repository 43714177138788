import { User } from 'src/app/redux/user/user.model';

export class SelectUser {
    static readonly type = '[User] select user';
    constructor(public payload: User) { }
}
export class UnselectUser {
    static readonly type = '[User] unselect user';
}
export class ClearUserState {
    static readonly type = '[User] clear user state';
}
