import { Injectable } from '../../../node_modules/@angular/core';
import { Store } from '../../../node_modules/@ngrx/store';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { EDictionary } from './common.reducer';
import { environment } from '../../environments/environment';

import * as fromRoot from '../app.reducer';
import * as UI from '../shared/ui.actions';
import * as Common from '../shared/common.actions';
import { UIService } from './ui.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class CommonService {
    private _onDestroy = new Subject<void>();

    constructor(
        private store: Store<fromRoot.IRootState>,
        private http: HttpClient,
        private uiService: UIService,
        private translateSerivce: TranslateService
    ) { }

    cancelSubscription() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getDictionaries() {
        this.store.dispatch(new UI.StartLoading());
        this.http.get<EDictionary[]>(environment.apiUrl + '/translation')
            .subscribe(
                (dictionaries) => {
                    this.store.dispatch(new Common.SetAvailableDictionaries(dictionaries));
                    this.store.dispatch(new UI.StopLoading());
                }, (error) => {
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                    this.store.dispatch(new Common.SetAvailableDictionaries(null));
                    this.store.dispatch(new UI.StopLoading());
                }
            );
    }

    addDictionay(obj: EDictionary) {
        let language = localStorage.getItem('language');
        if (!language) {
            language = 'pl';
        }
        this.store.dispatch(new UI.StartLoading());
        this.http.post<EDictionary>(environment.apiUrl + '/translation', { ...obj, fromLanguage: language })
            .subscribe(
                (_) => {
                    this.store.dispatch(new UI.StopLoading());
                    this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                }, (error) => {
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                    this.store.dispatch(new UI.StopLoading());
                }
            );
    }

    updateDictionary(obj: EDictionary) {
        let language = localStorage.getItem('language');
        if (!language) {
            language = 'pl';
        }
        this.store.dispatch(new UI.StartLoading());
        this.http.put<EDictionary>(environment.apiUrl + '/translation/' + obj.id, { ...obj, fromLanguage: language })
            .subscribe(
                (_) => {
                    this.store.dispatch(new UI.StopLoading());
                    this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                }, (error) => {
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                    this.store.dispatch(new UI.StopLoading());
                }
            );
    }

    removeDictionay(id: number) {
        this.store.dispatch(new UI.StartLoading());
        this.http.delete<number>(environment.apiUrl + '/translation/' + id)
            .subscribe(
                (_) => {
                    this.store.dispatch(new UI.StopLoading());
                    this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                }, (error) => {
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                    this.store.dispatch(new UI.StopLoading());
                }
            );
    }
}
