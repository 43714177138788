import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationData } from 'src/app/auth/user.model';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { OrderTaskDetailsComponent } from '../order-task-details/order-task-details.component';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from '../../app.reducer';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DxButtonComponent } from 'devextreme-angular';
import { UIService } from 'src/app/shared/ui.service';
import { OrderTaskService } from 'src/app/services/order-task/order-task.service';
import { YesNoDialogComponent, YesNoModel } from 'src/app/shared/yes-no-dialog/yes-no-dialog.component';
import { GenerationPlan, OrderTask } from 'src/app/redux/order-task/order-task.model';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as OrderTaskActions from 'src/app/redux/order-task/order-task.actions';

@Component({
  selector: 'app-task-generation-plan',
  templateUrl: './task-generation-plan.component.html',
  styleUrls: ['./task-generation-plan.component.scss']
})

export class TaskGenerationPlanComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();
  expanded = false;
  myTasks = true;
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('editDxButton', { static: false }) editButton: DxButtonComponent;

  dataSource: GenerationPlan[];
  storageKey: string;
  currentUser: ApplicationUser;
  isLoading: boolean = false;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    public auth: AuthService,
    private router: Router,
    private orderTaskService: OrderTaskService,
    private store: Store<fromRoot.IRootState>,
    private uiService: UIService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private zone: NgZone) {

    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser === undefined || currentUser === null) {
      return;
    }
    this.storageKey = 'generationTask_table_' + currentUser.company.id + currentUser.id;
  }

  ngOnInit() {
    this.store.select(fromRoot.getTaskIsLoading)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(isLoading => this.isLoading = isLoading);

      this.getPlanGeneration();
  }

  ngOnDestroy(): void {
    this.cancel();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onRowPrepared(e) {
    if (e.rowType === 'group' || e.rowType === 'header') {
      e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
      e.rowElement.style.fontWeight = 'bold';
    }

    if (e.rowType === 'data' && e.data.blocked === true) {
      e.rowElement.style.backgroundColor = 'rgba(238, 186, 186, 0.3)';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  close() {
    this.router.navigate(['order-task-list']);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  }

  onRowClick(e) {

  }

  cancel() {

  }

  getPlanGeneration() {
    //this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<GenerationPlan[]>(environment.apiUrl + '/orderTask/plangeneration')
      .toPromise()
      .then(
        (plan) => {
          //this.store.dispatch(new OrderTaskActions.StopLoading());
          this.dataSource = plan;
         })
    .catch(err => {
        //this.store.dispatch(new OrderTaskActions.StopLoading());
        this.dataSource = [];
        console.error(err);
    });
  }

  onInitializedEventHandler(e) {
    setTimeout(() => {
      this.grid.instance.repaint();
    }, 0_500);
  }
}
