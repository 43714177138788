import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationData } from 'src/app/auth/user.model';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ApplicationUser } from 'src/app/_models/application-user.model';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from '../../app.reducer';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DxButtonComponent } from 'devextreme-angular';
import { UIService } from 'src/app/shared/ui.service';
import { OrderService } from 'src/app/services/order/order.service';
import { Order, OrderImport, ReportFrequency, SettlementKind } from 'src/app/redux/order/order.model';
import { OrderTaskService } from 'src/app/services/order-task/order-task.service';
import { User, UserBase, UserListItem } from 'src/app/redux/user/user.model';
import { YesNoModel, YesNoDialogComponent } from 'src/app/shared/yes-no-dialog/yes-no-dialog.component';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe, formatDate, WeekDay } from '@angular/common';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { Order2Excel } from 'src/app/_models/order-excel.model';
import { SelectMerchDialogComponent } from 'src/app/shared/select-merch-dialog/select-merch-dialog.component';
import { UserProjectKind } from './user-project-configuration.model';
import { SurveyPattern } from 'src/app/redux/survey/survey.model';
import { EmployeeForm } from 'src/app/redux/employee-form/employee-form.model';
import { ProjectKind } from 'src/app/redux/contractor/project-kind.model';
import { ThrowStmt } from '@angular/compiler';

type AOA = any[][];

@Component({
  selector: 'app-user-project-configuration',
  templateUrl: './user-project-configuration.component.html',
  styleUrls: ['./user-project-configuration.component.scss']
})

export class UserProjectConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() userId: string;
  @Input() employeeFormId: number;
  @Input() isEditing: boolean;
  @Output() submitConfiguration = new EventEmitter<UserProjectKind[]>();

  private _onDestroy = new Subject<void>();
  expanded = false;

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('editDxButton', { static: false }) editButton: DxButtonComponent;

  dataSource: UserProjectKind[] = [];
  allUsers: User[];
  allUsersBase: UserBase[] = [];
  selectedEmployeeForm: EmployeeForm;
  loading: boolean;
  tempId: number = 0;
  companies: any[] = [];
  allProjectKinds: ProjectKind[] = [];
  allCoordinators: UserListItem[];
  localizationCoordinators: UserBase[];
  pmtCoordinators: UserListItem[];
  coordinatorsFromSelectedGroup: UserListItem[] = [];

  public setValue;

  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    private orderService: OrderService,
    private store: Store<fromRoot.IRootState>,
    private uiService: UIService,
    private http: HttpClient) {
      this.getfilteredManagersDataSource = this.getfilteredManagersDataSource.bind(this);

      this.setValue = function (rowData: any, value: any) {
        let temp = '';
        let id = Number(value);
        for(let y = 0; y < id; y++) {
          temp += ' ';
        }
        rowData.coordinatorId = temp;
        (<any>this).defaultSetCellValue(rowData, value);
      };
    }

  // setValue(rowData: any, value: any, currentRowData: any) {
  //     rowData.projectKindId = value;
  //   };

  onEditorPreparing(e) {
    if(e.parentType === "dataRow" && e.dataField === "coordnatorId") {
        e.editorOptions.disabled = (typeof e.row.data.projectKindId !== "number");
    }
  }

  ngOnInit() {
    this.store.select(fromRoot.getUserList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(users => {
        this.allUsers = users;
        users.forEach(element => {
          this.allUsersBase.push({
            id: element.id,
            fullName: element.firstName + ' ' + element.lastName
          });
        })
      });

      this.store.select(fromRoot.getProjectKindsList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((projectKinds) => {
        this.allProjectKinds = projectKinds;
      });

      this.store.select(fromRoot.getPmtCoordinatorsListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((managers) => {
        this.pmtCoordinators = managers;
      });

    this.store.select(fromRoot.getLocalizationCoordinatorsListV2)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((managers) => {
        this.localizationCoordinators = managers;
      });

      this.initCompanies();
  }

  ngAfterViewInit() {
    this.getUserProjectConfig();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onRowPrepared(e) {
    // if (e.rowType === 'group' || e.rowType === 'header') {
    //   e.rowElement.style.backgroundColor = 'rgba(193, 215, 215, 0.3)';
    //   e.rowElement.style.fontWeight = 'bold';
    // }

    // if (e.rowType === 'data' && e.data.blocked === true) {
    //   e.rowElement.style.backgroundColor = 'rgba(238, 186, 186, 0.3)';
    //   e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    // }
    //e.rowElement.style.height = '5';
  }

  onToolbarPreparing(e) {

  }

  initCompanies() {
    this.companies.push({id: 0, name: 'BB'});
    this.companies.push({id: 1, name: 'HR'});
    this.companies.push({id: 2, name: 'INV'});
    this.companies.push({id: 3, name: 'ISP'});
    this.companies.push({id: 4, name: 'LA'});
    this.companies.push({id: 4, name: 'SE'});
  }

  deleteSelectedRows() {
    const dialogTextData: YesNoModel = {
      titleKey: 'Usuwanie konfiguracji projektów',
      contentKey: 'Czy chcesz usunąć wybrane pozycje z konfiguracji projektów?',
      description: 'Ilość wybranych konfiguracji: ' + this.grid.selectedRowKeys.length
    };
    const ref = this.dialog.open(YesNoDialogComponent, {
      width: '25%',
      minWidth: '300px',
      data: dialogTextData,
      autoFocus: false
    });
    ref.afterClosed()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((dialogRes) => {
          if (dialogRes) {
            const rows = this.grid.selectedRowKeys;
            if (rows.length > 0) {
              this.orderService.deleteReportConfigs(rows);
            } else {
              this.uiService.openSnack('Nie wybrano konfiguracji do usunięcia.');
            }
            this.grid.selectedRowKeys = [];
          }
    });
  }

  updateUserProjectKindConfig(item) {
      item.startWorkDateLong = item.startWorkDate !== undefined ? new Date(item.startWorkDate).getTime() : 0;

    this.http.put<UserProjectKind>(environment.apiUrl + '/employeeform/projectkindconfig', item)
      .toPromise()
      .then(
        () => {
          this.getUserProjectConfig();
          this.uiService.openSnack('Zakończono pomyślnie');
         })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
    });
  }

  onInitNewRow(e) {
    e.data.id = this.tempId-1;
    this.tempId = e.data.id;
    e.data.userId = this.userId;
  }

  onRowRemoving(e) {
    if (e.data.id > 0) {
      //this.deleteUserProjectKindConfig(e.data.id);
      this.saveConfiguration();
    }
  }

  onRowUpdated(e) {
    e.data.startWorkDateLong = e.data.startWorkDate !== undefined ? new Date(e.data.startWorkDate).getTime() : 0;
    //this.updateUserProjectKindConfig(e.data);
    this.saveConfiguration();
  }

  onRowInserting(e) {
    e.data.startWorkDateLong = e.data.startWorkDate !== undefined ? new Date(e.data.startWorkDate).getTime() : 0;
    //this.updateUserProjectKindConfig(e.data);
    this.saveConfiguration();
  }

  deleteUserProjectKindConfig(userProjectKindConfigId: number) {
    this.http.delete(environment.apiUrl + '/employeeform/projectkindconfig/' + userProjectKindConfigId )
      .toPromise()
      .then(
        () => {
          this.getUserProjectConfig();
          this.uiService.openSnack('Zakończono pomyślnie');
         })
    .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
    });
  }

  getUserProjectConfig() {
    this.http.get<UserProjectKind[]>(environment.apiUrl + '/employeeform/projectkindconfig/' + this.userId)
      .toPromise()
      .then(
        (rep) => {
          this.dataSource = rep;
          this.submitConfiguration.emit(this.dataSource);
         })
    .catch(err => {
        this.dataSource = [];
        console.error(err);
    });
  }

  getfilteredManagers(projectKindId: number) {
    let projectKind = this.allProjectKinds.filter(p => p.id === projectKindId)[0];

    this.coordinatorsFromSelectedGroup.splice(0);
    this.allCoordinators = this.pmtCoordinators.concat(this.localizationCoordinators);
    projectKind.groups.forEach(g => {
      const managers = g.users.filter(u => u.manager === true);

      if (managers !== undefined && managers.length > 0) {
        managers.forEach(element => {
          const selManager = this.allCoordinators.filter(m => m.id === element.applicationUserId)[0];

          if (selManager !== undefined) {
            const index = this.coordinatorsFromSelectedGroup.indexOf(selManager);
            if (index < 0) {
              this.coordinatorsFromSelectedGroup.push(selManager);
            }
          }
        });
      }
    });
  }

  getfilteredManagersDataSource(options: any): any {

    this.allCoordinators = this.pmtCoordinators.concat(this.localizationCoordinators);

    const store = {
      store: this.allCoordinators,
      filter: null
    };

    if (!options.data) { return store; }

    if(options.data.projectKindId) {
      this.getfilteredManagers(options.data.projectKindId);
    } else {
      this.coordinatorsFromSelectedGroup.splice(0);
    }

    const filter: any[] = [];
    for (let y = 0; y < this.coordinatorsFromSelectedGroup.length; y++) {
      const coordinator = this.coordinatorsFromSelectedGroup[y];
      filter.push(['id', '=', coordinator.id]);
      if (y !== this.coordinatorsFromSelectedGroup.length - 1) { filter.push('or'); }
    }

    store.filter = filter;

    return store;
  }

  saveConfiguration() {
    this.submitConfiguration.emit(this.dataSource);
  }

  onInitializedEventHandler(e) {
    setTimeout(() => {
      this.grid.instance.repaint();
    }, 0_500);
  }

}
