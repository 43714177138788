import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '../../shared/ui.service';
import { takeUntil } from 'rxjs/operators';
import * as CompanyActions from './company.actions';
import * as fromCompany from './company.reducer';
import { Store } from '@ngrx/store';
import * as UI from '../../shared/ui.actions';
import { HttpClient } from '@angular/common/http';
import { PageData } from 'src/app/_models/page-data.model';
import { PasswordPolicy } from './security/password-policy-form/password-policy.model';
import { Security } from 'src/app/_models/security.model';
import { UserTableFilterModel } from './company-user/user-table-filter/user-table-filter.model';

interface UpdateSecurityViewModel {
    security: Security;
    passwordConfig: PasswordPolicy;
}

const USER_FILTER = 'administration-user-table-filter-';
const USER_LIST_PAGE_SIZE_KEY = 'administration-user-list-pageSize-';
const USER_PERMS_LIST_PAGE_SIZE_KEY = 'administration-user-perms-list-pageSize-';
const TRANSLATION_LIST_PAGE_SIZE_KEY = 'administration-translation-user-list-pageSize-';
const ROLE_LIST_PAGE_SIZE_KEY = 'administration-role-list-pageSize-';

@Injectable()
export class CompanySecurityService {
    private _onDestroy = new Subject<void>();

    constructor(
        private translateSerivce: TranslateService,
        private uiService: UIService,
        private store: Store<fromCompany.State>,
        private http: HttpClient
    ) { }

    cancelSubscriptions() {
        console.log('[company service] cancel subscriptions');
        this._onDestroy.next();
        this._onDestroy.complete();
    }


    hideTabHeader() {
        this.store.dispatch(new CompanyActions.HideParentTab());
    }

    showTabHeader() {
        this.store.dispatch(new CompanyActions.ShowParentTab());
    }

    canHideTabHeader(canHide: boolean) {
        this.store.dispatch(new CompanyActions.CanHideParentTab(canHide));
    }

    userTableSetFilter(filter: UserTableFilterModel) {
        this.store.dispatch(new CompanyActions.SetUserListFilter(filter));
    }

    get companyId() {
        return localStorage.getItem('lastCompanyId');
    }

    set userFilter(filter: UserTableFilterModel) {
        localStorage.setItem(USER_FILTER + this.companyId, JSON.stringify(filter));
    }

    get userFilter(): UserTableFilterModel {
        return JSON.parse(localStorage.getItem(USER_FILTER + this.companyId));
    }

    get userPermsListPageData(): PageData {
        return JSON.parse(localStorage.getItem(USER_PERMS_LIST_PAGE_SIZE_KEY + this.companyId));
    }

    set userPermsListPageData(pageData: PageData) {
        localStorage.setItem(USER_PERMS_LIST_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }

    get userListPageData(): PageData {
        return JSON.parse(localStorage.getItem(USER_LIST_PAGE_SIZE_KEY + this.companyId));
    }

    set userListPageData(pageData: PageData) {
        localStorage.setItem(USER_LIST_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }

    get translationListPageData(): PageData {
        return JSON.parse(localStorage.getItem(TRANSLATION_LIST_PAGE_SIZE_KEY + this.companyId));
    }

    set translationListPageData(pageData: PageData) {
        localStorage.setItem(TRANSLATION_LIST_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }

    get roleListPageData(): PageData {
        return JSON.parse(localStorage.getItem(ROLE_LIST_PAGE_SIZE_KEY + this.companyId));
    }

    set roleListPageData(pageData: PageData) {
        localStorage.setItem(ROLE_LIST_PAGE_SIZE_KEY + this.companyId, JSON.stringify(pageData));
    }

    securityUpdatePasswordPolicy(post: PasswordPolicy) {
        this.store.dispatch(new UI.StartLoading());
        this.http.put<PasswordPolicy>(environment.apiUrl + '/security/passwordConfig', post)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.translateSerivce.get('COMMON.SAVE_SUCCESS')
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((text) => {
                            this.uiService.openSnack(text);
                        });
                    this.store.dispatch(new UI.StopLoading());
                    this.store.dispatch(new CompanyActions.SetPasswordConfig(res));
                }, (error) => {
                    this.store.dispatch(new UI.StopLoading());
                    this.store.dispatch(new CompanyActions.SetPasswordConfig(null));
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }

    securityFetchSecurityAndPasswordPolicy(companyId) {
        this.store.dispatch(new UI.StartLoading());
        this.http.get<UpdateSecurityViewModel>(environment.apiUrl + '/security/' + companyId)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                (res) => {
                    this.store.dispatch(new UI.StopLoading());
                    this.store.dispatch(new CompanyActions.SetSecurity(res.security));
                    this.store.dispatch(new CompanyActions.SetPasswordConfig(res.passwordConfig));
                }, (error) => {
                    this.store.dispatch(new UI.StopLoading());
                    this.store.dispatch(new CompanyActions.SetSecurity(null));
                    this.store.dispatch(new CompanyActions.SetPasswordConfig(null));
                    this.translateSerivce.get(['Nieoczekiwany błąd', 'ERROR'])
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe((translations) => {
                            this.uiService.openSnack(translations['Nieoczekiwany błąd'], translations['ERROR'], 10_000);
                        });
                    console.error(error);
                }
            );
    }
}

