import { Group } from './group.model';
import {
    GroupActions,
    SELECTED_GROUP,
    ADD,
    ADD_MANAGER,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_GROUPS,
    SET_AVAILABLE_MANAGERS,
    UPDATE,
    REMOVE,
    REMOVE_MANAGER,
    START_LOADING,
    STOP_LOADING
} from './group.actions';
import { User, UserBase } from '../user/user.model';

export interface State {
    groups: Group[];
    groupsTotalCount: number,
    managers: UserBase[];
    selectedGroup: Group;
    isEditing: boolean;
    isLoading: boolean;
}
const initialState: State = {
    managers: [],
    groups: [],
    groupsTotalCount: 0,
    selectedGroup: null,
    isEditing: false,
    isLoading: false
};

export function groupReducer(state = initialState, action: GroupActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SELECTED_GROUP: {
            return {
                ...state,
                selectedGroup: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                groups: [...state.groups, action.payload]
            };
        }
        case ADD_MANAGER: {
          return {
              ...state,
              managers: [...state.managers, action.payload]
          };
      }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_GROUPS: {
            return {
                ...state,
                groups: action.payload.data,
                groupsTotalCount: action.payload.totalCount
            };
        }
        case SET_AVAILABLE_MANAGERS: {
          return {
              ...state,
              managers: action.payload
          };
      }
        case UPDATE: {
            const index = state.groups.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selGroup = state.selectedGroup;
            if (selGroup && selGroup.id === action.payload.id) {
                selGroup = action.payload;
            }
            state.groups[index].projectKindId = action.payload.projectKindId;
            const updatedGroups = [
                ...state.groups.slice(0, index),
                Object.assign({}, state.groups[index], action.payload),
                ...state.groups.slice(index + 1)
            ];
            return {
                ...state,
                groups: updatedGroups,
                selectedGroup: selGroup
            };
        }
        case REMOVE: {
            return {
                ...state,
                groups: state.groups.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_MANAGER: {
          return {
              ...state,
              managers: state.managers.filter(c => c.id !== action.payload)
          };
      }
        default: {
            return state;
        }
    }
}

export const groups = (state: State) => state.groups;
export const groupsTotalCount = (state: State) => state.groupsTotalCount;
export const managers = (state: State) => state.managers;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const selectedGroup = (state: State) => state.selectedGroup;
