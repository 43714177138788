import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import * as fromRoot from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { Localization, LocalizationListItem } from 'src/app/redux/localization/localization.model';

@Component({
  selector: 'app-localization-search-bar',
  templateUrl: './localization-search-bar.component.html',
  styleUrls: ['./localization-search-bar.component.css']
})
export class LocalizationSearchBarComponent implements OnInit, OnDestroy {
  @Input() isEditing: boolean = true;
  @Input() placeholderText: string = 'Wybierz';
  @Input() underline: boolean = false;
  @Input() localizationControl: FormControl;

  private _onDestroy = new Subject<void>();
  locList: LocalizationListItem[] = [];
  selectedLoc: LocalizationListItem = null;
  locSelectOpened = false;
  searchQuery: string = '';

  matSelectFocused = false;
  @Output() localizationSelectionChange = new EventEmitter<LocalizationListItem>();

  locSearchControl: FormControl = new FormControl();
  constructor(
    private store: Store<fromRoot.IRootState>,
    private service: LocalizationService
  ) { }

  ngOnInit() {

    this.locSearchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(query => {
      this.fetchLocalizationList();
    });

    this.store.select(fromRoot.getSearchedLocalizationList)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        this.updateLocList(res);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  applyDefaultValue(value: LocalizationListItem) {
    if (value === null) {
      this.selectedLoc = null;
      return;
    }
    if (value === undefined) {
      this.selectedLoc = null;
      return;
    }
    if (value.id === null || value.id === undefined) {
      this.selectedLoc = null;
      return;
    }
    if (Object.keys(value).length === 0) {
      return;
    }
    if (this.selectedLoc && this.selectedLoc.id === value.id) {
      return;
    }

    this.locList.push(value);
    this.selectedLoc = value;
  }

  fetchLocalizationList(): void {
    this.service.searchLocalization(this.searchQuery);
  }

  updateLocList(locList: LocalizationListItem[]): void {
    if (this.matSelectFocused) {
      this.locList = locList ? locList : [];

      if (this.locList.length === 0) {
        this.selectedLoc = null;
        this.localizationSelectionChange.emit(this.selectedLoc);
      }
    }
  }

  clear(): void {
    this.selectedLoc = null;
    this.localizationSelectionChange.emit(null);
  }

  onFocus() {
    this.matSelectFocused = true;
  }

  onClosed() {
    this.matSelectFocused = false;
  }

  onLocSelectChange(event): void {
    this.selectedLoc = event.value;
    this.localizationSelectionChange.emit(this.selectedLoc);
  }
}
